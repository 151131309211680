import React from 'react';
import '../Network.scss';

const NetworkTexts = ({ selected }) => {
  return (
    <g id="subjects" transform="translate(-42,-40)">            
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M376.7,319.8c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C376.7,319.7,376.7,319.8,376.7,319.8z"/>
        <path d="M381.1,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C380.1,319.2,380.6,319.1,381.1,319.1z M380.3,325.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S380,325.2,380.3,325.2z"/>
        <path d="M386.6,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L386.6,319.1L386.6,319.1z"/>
      </g>

      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path d="M265.6,408.4c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C264.6,408.5,265.1,408.4,265.6,408.4z M264.7,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S264.5,414.5,264.7,414.5z"/>
		<path d="M273.6,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
			C272.9,408.5,273.3,408.4,273.6,408.4z"/>
		<path d="M283.3,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C284.1,411.3,283.4,411.3,283.3,411.3z M283.4,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C283.4,410.7,283.4,410.7,283.4,410.7z"/>
		<path d="M290.2,408.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.4,0-0.6,0.1-0.6,0.3c0,0,0,0.1,0.1,0.2l1,1.6
			c0.1-0.1,0.2-0.4,0.4-0.6c0.2-0.3,0.4-0.5,0.5-0.7s0.2-0.3,0.2-0.4s0-0.1-0.1-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0s-0.2,0-0.2,0
			s0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0c0.2,0,0.3,0,0.5,0c0.5,0,0.9,0,1.2-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2,0,0.2c-0.7,0-1.1,0.2-1.2,0.3c-0.1,0.2-0.3,0.5-0.6,0.8s-0.5,0.7-0.7,0.9c-0.2,0.2-0.3,0.4-0.3,0.4l1.9,2.8
			c0.3,0.4,0.7,0.6,1.2,0.6c0,0,0,0,0,0.1s0,0.1,0,0.2s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.5,0s-0.3,0-0.5,0s-0.4,0-0.6,0
			s-0.4,0-0.6,0s-0.4,0-0.6,0c0-0.1,0-0.1,0-0.3c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2v-0.1
			c-0.5-0.9-1-1.5-1.2-2c-0.1,0.1-0.2,0.3-0.5,0.7c-0.3,0.4-0.5,0.7-0.6,1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2
			c0,0,0.1,0.1,0.2,0.1s0.2,0,0.2,0s0.1,0,0.3,0h0.1c0,0,0,0,0,0.1s0,0.1,0,0.2s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0
			s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2s0-0.2,0-0.2s0.1,0,0.2,0s0.2,0,0.2,0s0.1,0,0.2,0
			s0.2,0,0.2-0.1c0,0,0.1,0,0.2-0.1s0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2c1-1.4,1.5-2.1,1.5-2.2l-1.8-2.7
			c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.2C289.2,408.5,289.7,408.6,290.2,408.6z"/>
		<path d="M297.4,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C296.9,413.4,297.1,413.3,297.4,413.3z"/>
		<path d="M306.6,408.4c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
			s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
			s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S306,408.4,306.6,408.4z"/>
		<path d="M313.3,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C311.5,408.7,312.3,408.3,313.3,408.3z M313.1,408.9c-0.4,0-0.8,0.1-1.1,0.4
			s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2C313.9,409,313.5,408.9,313.1,408.9z"/>
		<path d="M322.3,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9
			c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2
			c-0.3,0.1-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
			c0.5-0.4,0.9-0.7,1.2-0.8C321.7,408.4,322,408.4,322.3,408.4z"/>
		<path d="M331.3,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C332.1,411.3,331.5,411.3,331.3,411.3z M331.4,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C331.4,410.7,331.4,410.7,331.4,410.7z"/>
		<path d="M340.3,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C338.6,408.7,339.3,408.3,340.3,408.3z M340.1,408.9c-0.4,0-0.8,0.1-1.1,0.4
			s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2C341,409,340.6,408.9,340.1,408.9z"/>
		<path d="M349.3,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
			C348.6,408.5,349,408.4,349.3,408.4z"/>
		<path d="M354.3,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C355.1,411.3,354.5,411.3,354.3,411.3z M354.4,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C354.4,410.7,354.4,410.7,354.4,410.7z"/>
	</g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path d="M169.5,408.7c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7
          V408.7z"/>
        <path d="M176.3,408.4c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S175.7,408.4,176.3,408.4z"/>
        <path d="M183.8,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S183.5,408.4,183.8,408.4z"/>
        <path d="M188.7,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S188.5,413.3,188.7,413.3z"/>
        <path d="M196.5,408.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6
          s0.1-0.4,0.2-0.5s0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          s-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3
          c0,0-0.1,0.1-0.1,0.2s-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S196.3,408.6,196.5,408.6z"/>
        <path d="M209.8,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S209.3,408.4,209.8,408.4z M209,414.5c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S208.7,414.5,209,414.5z"/>
        <path d="M215.3,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H215.3z"/>
        <path d="M219.4,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S219.5,411.3,219.4,411.3z
          M219.5,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S219.4,410.7,219.5,410.7z"/>
        <path d="M229.1,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S228.8,408.4,229.1,408.4z"/>
        <path d="M231.8,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S231.6,413.3,231.8,413.3z"/>
        <path d="M166.6,424.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C165.5,424.5,166,424.6,166.6,424.6z"/>
        <path d="M176.1,424.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S175.1,424.3,176.1,424.3z M175.9,424.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S176.4,424.9,175.9,424.9z"/>
        <path d="M185.5,426.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
          s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
          h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
          s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H185.5z"/>
        <path d="M192.9,424.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S192.5,424.4,192.9,424.4z"/>
        <path d="M204.8,425.2c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S204.8,425.1,204.8,425.2z"/>
        <path d="M210.1,424.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S209.8,424.4,210.1,424.4z"/>
        <path d="M216.8,426.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
          s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1L219,431c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
          H217c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
          s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H216.8z"/>
        <path d="M222.5,424.4c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S221.9,424.4,222.5,424.4z"/>
        <path d="M230,424.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S229.7,424.4,230,424.4z"/>
      </g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path d="M334,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8
          C335.1,325.9,334.6,326,334,326z M334.3,319.9c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8
          c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C335.5,320.2,335,319.9,334.3,319.9z"/>
        <path d="M340.2,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C341,322,340.4,322,340.2,322z M340.3,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C340.3,321.4,340.3,321.4,340.3,321.4z"/>
        <path d="M348.1,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          v0.6H348.1z M346.8,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S346.9,317.3,346.8,317.2z"/>
        <path d="M354.5,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C353.8,319.2,354.1,319,354.5,319z"/>
        <path d="M362.5,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M361.7,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C362.4,326,362.1,325.9,361.7,325.9z M361.1,319.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C361.9,319.7,361.6,319.5,361.1,319.5z"/>
        <path d="M374.3,319c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
          c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2
          c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3
          c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6C373.7,319.1,374,319,374.3,319z M373.6,319.9c-0.3,0-0.6,0.1-0.9,0.2
          s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2
          c0-0.9-0.2-1.6-0.6-2.1C374.7,320.2,374.2,319.9,373.6,319.9z"/>
        <path d="M382.4,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
          c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0
          l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C381.8,319.2,382.1,319,382.4,319z"/>
        <path d="M385.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C386.1,322,385.5,322,385.3,322z M385.4,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C385.4,321.4,385.4,321.4,385.4,321.4z"/>
        <path d="M395,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M394.3,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C395,326,394.7,325.9,394.3,325.9z M393.7,319.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C394.5,319.7,394.1,319.5,393.7,319.5z"/>
        <path d="M402.7,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C401.9,319.2,402.3,319,402.7,319z"/>
        <path d="M409.7,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C408.7,319.2,409.2,319.1,409.7,319.1z M408.8,325.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S408.6,325.2,408.8,325.2z"/>
        <path d="M417.7,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C417,319.2,417.4,319,417.7,319z"/>
        <path d="M423.5,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          s-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L423.5,319.1
          L423.5,319.1z"/>
        <path d="M427.5,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C427,324,427.2,323.9,427.5,323.9z"/>
        <path d="M352.1,335.3c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          c0.3,0,0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0
          s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V336h-0.9
          c0,0,0,0,0-0.1s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9c-0.3,0.6-0.5,1.2-0.5,2v0.8L352.1,335.3L352.1,335.3z"/>
        <path d="M360.7,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
          c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0
          l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C360,335.2,360.3,335,360.7,335z"/>
        <path d="M363.6,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C364.3,338,363.7,338,363.6,338z M363.7,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C363.7,337.4,363.6,337.4,363.7,337.4z"/>
        <path d="M370.2,339.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C369.7,340,370,339.9,370.2,339.9z"/>
        <path d="M377.9,340.1c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1H377c0,0,0,0,0-0.1s0-0.2,0.1-0.4c0.1-0.2,0.1-0.2,0.1-0.2h0.6
          c0.1,0,0.1,0,0.1-0.1V335c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3c0.3-0.4,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
          c0.2,0,0.4,0.1,0.8,0.2c0.3,0.1,0.6,0.2,0.7,0.2s0.3,0,0.5-0.1s0.3-0.1,0.4-0.2s0.2-0.1,0.2-0.1l0,0c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.1c0,0.6,0,1.1,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.1
          c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1s-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6-0.1
          c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.3,0.1-0.7,0.1-1.1v-6c0-0.1,0-0.3-0.1-0.6c0-0.3-0.1-0.5-0.1-0.6c-0.1-0.2-0.3-0.5-0.7-0.8
          c-0.3-0.3-0.7-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.3-0.4,0.6-0.5,1.1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8c0,0,0,0,0,0.1
          c0,0,0,0,0.1,0h1.7h0.1c0,0,0,0,0,0.1c0,0.2-0.1,0.4-0.2,0.6H379c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6c0,0.7,0.1,1.2,0.1,1.4
          c0,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1s-0.6,0-1.7,0.1
          c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2C377.9,340.9,377.9,340.5,377.9,340.1z"/>
        <path d="M386.5,335.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C385.5,335.2,386,335.2,386.5,335.2z"/>
        <path d="M395.1,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          v0.6H395.1z M393.8,333.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S394,333.3,393.8,333.2z"/>
        <path d="M401.5,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C400.8,335.2,401.2,335,401.5,335z"/>
        <path d="M409.5,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V340.8z M408.8,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C409.5,342,409.1,341.9,408.8,341.9z M408.2,335.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C409,335.7,408.6,335.5,408.2,335.5z"/>
      </g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path d="M84.1,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C85.1,325.9,84.6,326,84.1,326z M84.3,319.9c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8
          c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C85.6,320.2,85,319.9,84.3,319.9z"/>
        <path d="M90.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C91,322,90.4,322,90.3,322z M90.3,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S90.3,321.4,90.3,321.4z"/>
        <path d="M98.1,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L98.1,320.8L98.1,320.8z
          M96.8,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S97,317.3,96.8,317.2z"/>
        <path d="M104.5,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S104.2,319,104.5,319z"/>
        <path d="M112.5,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M111.8,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C112.4,326,112.1,325.9,111.8,325.9z M111.1,319.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C112,319.7,111.6,319.5,111.1,319.5z"/>
        <path d="M124.3,319c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6
          C123.7,319.1,124,319,124.3,319z M123.6,319.9c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
          c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C124.8,320.2,124.2,319.9,123.6,319.9z"/>
        <path d="M132.5,319c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C131.8,319.2,132.1,319,132.5,319z"/>
        <path d="M135.4,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C136.1,322,135.5,322,135.4,322z M135.5,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S135.4,321.4,135.5,321.4z"/>
        <path d="M145.1,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M144.3,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C145,326,144.7,325.9,144.3,325.9z M143.7,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C144.5,319.7,144.2,319.5,143.7,319.5z"/>
        <path d="M152.7,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S152.3,319,152.7,319z"/>
        <path d="M159.7,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C158.7,319.2,159.2,319.1,159.7,319.1z M158.9,325.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S158.6,325.2,158.9,325.2z"/>
        <path d="M167.7,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S167.4,319,167.7,319z"/>
        <path d="M173.5,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
          c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1
          l0,0v2.1H173.5z"/>
        <path d="M177.5,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S177.3,323.9,177.5,323.9z"/>
        <path d="M123.9,335.3c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V336h-1c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8L123.9,335.3L123.9,335.3z"/>
        <path d="M132.5,335c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C131.8,335.2,132.1,335,132.5,335z"/>
        <path d="M135.4,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C136.1,338,135.5,338,135.4,338z M135.4,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S135.4,337.4,135.4,337.4z"/>
      </g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path d="M109.1,135.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
			C110.2,135.1,109.6,135.2,109.1,135.2z M109.4,129.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C110.6,129.5,110.1,129.2,109.4,129.2z"/>
		<path d="M115.3,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C116.1,131.2,115.5,131.2,115.3,131.2z M115.4,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S115.4,130.7,115.4,130.7z"/>
		<path d="M123.1,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L123.1,130
			L123.1,130z M121.9,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S122,126.6,121.9,126.4z"/>
		<path d="M129.6,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S129.2,128.3,129.6,128.3z"/>
		<path d="M137.5,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L137.5,134.1L137.5,134.1z M136.8,135.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C137.5,135.2,137.2,135.2,136.8,135.2z
			 M136.2,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C137,128.9,136.6,128.7,136.2,128.7z"/>
		<path d="M149.3,128.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C148.7,128.4,149.1,128.3,149.3,128.3z M148.7,129.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C149.8,129.5,149.3,129.2,148.7,129.2z"/>
		<path d="M157.5,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C156.8,128.5,157.2,128.3,157.5,128.3z"/>
		<path d="M160.4,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C161.2,131.2,160.6,131.2,160.4,131.2z M160.5,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S160.5,130.7,160.5,130.7z"/>
		<path d="M170.1,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L170.1,134.1L170.1,134.1z M169.4,135.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C170.1,135.2,169.7,135.2,169.4,135.2z
			 M168.8,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C169.6,128.9,169.2,128.7,168.8,128.7z"/>
		<path d="M177.7,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S177.4,128.3,177.7,128.3z"/>
		<path d="M184.8,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C183.7,128.4,184.3,128.3,184.8,128.3z M183.9,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S183.6,134.4,183.9,134.4z"/>
		<path d="M192.8,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S192.4,128.3,192.8,128.3z"/>
		<path d="M198.6,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H198.6z"/>
		<path d="M202.6,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S202.3,133.2,202.6,133.2z"/>
		<path d="M137.6,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C136.5,144.5,137,144.3,137.6,144.3z"/>
		<path d="M145.4,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S145,144.3,145.4,144.3z"/>
		<path d="M152.4,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C151.4,144.4,151.9,144.3,152.4,144.3z M151.6,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S151.3,150.4,151.6,150.4z"/>
		<path d="M158,149.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
			c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2
			c0-0.1,0.1-0.1,0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2
			c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3
			c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1
			c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5
			c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H158z"/>
		<path d="M164.5,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C165.3,147.2,164.7,147.2,164.5,147.2z M164.6,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S164.6,146.7,164.6,146.7z"/>
		<path d="M172.6,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C171.4,144.5,172,144.3,172.6,144.3z"/>
	</g>
    
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M252.2,98.9c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S252.2,98.8,252.2,98.9z"/>
        <path d="M256.6,98.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6c-0.4,0.3-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          s0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2
          s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S256.1,98.1,256.6,98.1z M255.8,104.2
          c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7
          c0,0.3,0.1,0.5,0.3,0.8S255.5,104.2,255.8,104.2z"/>
        <path d="M262.1,98.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H260c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L262.1,98.2L262.1,98.2z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M84.1,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3
          c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          c-0.3,0.3-0.8,0.6-1.2,0.8C85.1,325.9,84.6,326,84.1,326z M84.3,319.9c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3
          c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
          c0-0.9-0.2-1.6-0.6-2.1C85.6,320.2,85,319.9,84.3,319.9z"/>
        <path d="M90.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C91,322,90.4,322,90.3,322z M90.3,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C90.3,321.4,90.3,321.4,90.3,321.4z"/>
        <path d="M98.1,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          L98.1,320.8L98.1,320.8z M96.8,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S97,317.3,96.8,317.2z"/>
        <path d="M104.5,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C103.8,319.2,104.2,319,104.5,319z"/>
        <path d="M112.5,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M111.8,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C112.4,326,112.1,325.9,111.8,325.9z M111.1,319.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C112,319.7,111.6,319.5,111.1,319.5z"/>
        <path d="M124.3,319c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
          c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2
          c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3
          c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6C123.7,319.1,124,319,124.3,319z M123.6,319.9c-0.3,0-0.6,0.1-0.9,0.2
          s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2
          c0-0.9-0.2-1.6-0.6-2.1C124.8,320.2,124.2,319.9,123.6,319.9z"/>
        <path d="M132.5,319c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C131.8,319.2,132.1,319,132.5,319z"/>
        <path d="M135.4,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C136.1,322,135.5,322,135.4,322z M135.5,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
          C135.5,321.4,135.4,321.4,135.5,321.4z"/>
        <path d="M145.1,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M144.3,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C145,326,144.7,325.9,144.3,325.9z M143.7,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C144.5,319.7,144.2,319.5,143.7,319.5z"/>
        <path d="M152.7,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C152,319.2,152.3,319,152.7,319z"/>
        <path d="M159.7,319.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
          c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
          c0.3-0.3,0.8-0.6,1.3-0.9C158.7,319.2,159.2,319.1,159.7,319.1z M158.9,325.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S158.6,325.2,158.9,325.2z"/>
        <path d="M167.7,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C167,319.2,167.4,319,167.7,319z"/>
        <path d="M173.5,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
          c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2
          s0.1,0,0.1-0.1h0.1l0,0v2.1H173.5z"/>
        <path d="M177.5,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C177,324,177.3,323.9,177.5,323.9z"/>
        <path d="M102.5,335.3c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          c0.3,0,0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0
          s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V336h-0.9
          c0,0,0,0,0-0.1s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9c-0.3,0.6-0.5,1.2-0.5,2v0.8L102.5,335.3L102.5,335.3z"/>
        <path d="M111.1,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
          c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0
          l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C110.4,335.2,110.7,335,111.1,335z"/>
        <path d="M114,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C114.8,338,114.1,338,114,338z M114.1,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C114.1,337.4,114.1,337.4,114.1,337.4z"/>
        <path d="M120.6,339.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C120.1,340,120.4,339.9,120.6,339.9z"/>
        <path d="M129.8,335c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.2,0.2,0.3,0.3
          c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6
          c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
          c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
          c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
          c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C128.7,335.2,129.2,335,129.8,335
          z"/>
        <path d="M137.6,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C136.9,335.2,137.3,335,137.6,335z"/>
        <path d="M144.6,335.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
          c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
          c0.3-0.3,0.8-0.6,1.3-0.9C143.6,335.2,144.1,335.1,144.6,335.1z M143.8,341.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S143.5,341.2,143.8,341.2z"/>
        <path d="M150.2,340.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
          c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2
          c0-0.1,0.1-0.1,0.1-0.1V336c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2
          c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9
          c0.3,0.3,0.7,0.7,1.1,1.2c0.4,0.5,0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4
          c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H150.2z"/>
        <path d="M156.8,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C157.5,338,156.9,338,156.8,338z M156.8,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
          C156.8,337.4,156.8,337.4,156.8,337.4z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M162.2,408.4c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S161.8,408.4,162.2,408.4z M161.7,409c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S162.1,409,161.7,409z"/>
        <path d="M169.9,408.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S168.9,408.3,169.9,408.3z M169.7,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S170.1,408.9,169.7,408.9z"/>
        <path d="M178.5,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V414.2z M177.8,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S178.2,415.3,177.8,415.3z M177.2,408.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S177.6,408.8,177.2,408.8z"/>
        <path d="M182.8,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S182.5,413.3,182.8,413.3z"/>
        <path d="M193.5,408.7c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7
          V408.7z"/>
        <path d="M202,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V414.2z M201.2,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S201.6,415.3,201.2,415.3z M200.6,408.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S201.1,408.8,200.6,408.8z"/>
        <path d="M209.3,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S209,408.4,209.3,408.4z"/>
        <path d="M215.1,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409H213c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L215.1,408.4L215.1,408.4z"/>
        <path d="M220.3,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H220.3z M219,406.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S219.2,406.6,219,406.5z"/>
        <path d="M226.7,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S226.4,408.4,226.7,408.4z"/>
        <path d="M234.7,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1H236c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V414.2z M234,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S234.3,415.3,234,415.3z M233.4,408.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S233.8,408.8,233.4,408.8z"/>
        <path d="M238.9,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S238.7,413.3,238.9,413.3z"/>
        <path d="M166.3,424.4c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S165.7,424.4,166.3,424.4z"/>
        <path d="M174,424.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S173.7,424.4,174,424.4z"/>
        <path d="M181.1,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S180.5,424.4,181.1,424.4z M180.2,430.5c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S179.9,430.5,180.2,430.5z"/>
        <path d="M186.6,429.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1H189
          h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1L187,428l0,0v1.4H186.6z"/>
        <path d="M193.2,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S193.3,427.3,193.2,427.3z
          M193.2,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S193.2,426.7,193.2,426.7z"/>
        <path d="M199.8,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S199.5,429.3,199.8,429.3z"/>
        <path d="M208.5,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V425h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H208.5z"/>
        <path d="M213.7,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H213.7z M212.4,422.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S212.5,422.6,212.4,422.5z"/>
        <path d="M219.8,430.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V430.2z M219,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S219.4,431.3,219,431.3z M218.4,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S218.9,424.8,218.4,424.8z"/>
        <path d="M224.1,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S224.2,427.3,224.1,427.3z
          M224.1,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S224.1,426.7,224.1,426.7z"/>
        <path d="M233.8,424.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S233.5,424.4,233.8,424.4z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M309.3,135.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S309.9,135.2,309.3,135.2z M309.6,129.2
          c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S310.3,129.2,309.6,129.2z"/>
        <path d="M315.5,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S315.7,131.2,315.5,131.2z
          M315.6,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S315.6,130.7,315.6,130.7z"/>
        <path d="M323.4,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H323.4z
          M322.1,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S322.2,126.6,322.1,126.4z"/>
        <path d="M329.8,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S329.4,128.3,329.8,128.3z"/>
        <path d="M337.8,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1
          c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
          c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2L337.8,134.1L337.8,134.1z M337,135.2
          c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S337.4,135.2,337,135.2z M336.4,128.7
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S336.9,128.7,336.4,128.7z"/>
        <path d="M349.6,128.3c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S349.3,128.3,349.6,128.3z M348.9,129.2
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S349.5,129.2,348.9,129.2z"/>
        <path d="M357.7,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S357.4,128.3,357.7,128.3z"/>
        <path d="M360.6,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S360.8,131.2,360.6,131.2z
          M360.7,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S360.7,130.7,360.7,130.7z"/>
        <path d="M370.3,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1
          c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
          c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2L370.3,134.1L370.3,134.1z M369.6,135.2
          c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S370,135.2,369.6,135.2z M369,128.7
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S369.4,128.7,369,128.7z"/>
        <path d="M377.9,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S377.6,128.3,377.9,128.3z"/>
        <path d="M385,128.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S384.5,128.3,385,128.3z M384.1,134.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S383.9,134.4,384.1,134.4z"/>
        <path d="M393,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S392.7,128.3,393,128.3z"/>
        <path d="M398.8,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L398.8,128.4L398.8,128.4z"/>
        <path d="M402.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S402.5,133.2,402.8,133.2z"/>
        <path d="M327.4,144.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8s-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V145h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7
          V144.6z"/>
        <path d="M336,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S335.6,144.3,336,144.3z
          "/>
        <path d="M338.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S339,147.2,338.9,147.2z
          M338.9,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S338.9,146.7,338.9,146.7z"/>
        <path d="M345.5,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S345.2,149.2,345.5,149.2z"/>
        <path d="M353.2,149.3c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1h-0.8c0,0,0,0,0-0.1s0-0.2,0.1-0.4s0.1-0.2,0.1-0.2h0.6
          c0.1,0,0.1,0,0.1-0.1v-0.2c0-0.5,0.1-1.1,0.3-1.6s0.4-0.9,0.7-1.3s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.2,0,0.4,0.1,0.8,0.2
          s0.6,0.2,0.7,0.2s0.3,0,0.5-0.1s0.3-0.1,0.4-0.2s0.2-0.1,0.2-0.1l0,0c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4
          v7.1c0,0.6,0,1.1,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1
          s-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3s0-0.2,0.1-0.2s0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.3,0.1-0.7,0.1-1.1v-6
          c0-0.1,0-0.3-0.1-0.6s-0.1-0.5-0.1-0.6c-0.1-0.2-0.3-0.5-0.7-0.8s-0.7-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.8,0.4s-0.4,0.6-0.5,1.1
          s-0.2,0.9-0.2,1.3v0.8c0,0,0,0,0,0.1s0,0,0.1,0h1.7h0.1c0,0,0,0,0,0.1c0,0.2-0.1,0.4-0.2,0.6h-1.6c0,0,0,0-0.1,0s0,0,0,0.1v3.6
          c0,0.7,0.1,1.2,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1
          s-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3s0-0.2,0.1-0.2s0.3,0,0.6-0.1s0.4-0.1,0.4-0.2C353.2,150.1,353.2,149.7,353.2,149.3z"/>
        <path d="M361.8,144.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C360.8,144.5,361.3,144.5,361.8,144.5z"/>
        <path d="M370.4,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H370.4z
          M369.1,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S369.3,142.6,369.1,142.4z"/>
        <path d="M376.8,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S376.5,144.3,376.8,144.3z"/>
        <path d="M384.8,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1
          c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
          c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2L384.8,150.1L384.8,150.1z M384.1,151.2
          c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S384.4,151.2,384.1,151.2z M383.5,144.7
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S383.9,144.7,383.5,144.7z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
		<path d="M110.4,151.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
			v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
			s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S110.9,151.2,110.4,151.2z M110.6,145.2
			c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
			s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S111.3,145.2,110.6,145.2z"/>
		<path d="M116.6,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S116.7,147.2,116.6,147.2z
			 M116.6,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S116.6,146.7,116.6,146.7z"/>
		<path d="M124.4,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L124.4,146
			L124.4,146z M123.1,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2S123.3,142.6,123.1,142.4z"/>
		<path d="M130.8,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S130.5,144.3,130.8,144.3z"/>
		<path d="M138.8,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1
			c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2L138.8,150.1L138.8,150.1z M138.1,151.2
			c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S138.4,151.2,138.1,151.2z M137.4,144.7
			c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S137.9,144.7,137.4,144.7z"/>
		<path d="M150.6,144.3c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
			s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
			c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S150.3,144.3,150.6,144.3z M149.9,145.2
			c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
			s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S150.5,145.2,149.9,145.2z"/>
		<path d="M158.8,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
			S158.4,144.3,158.8,144.3z"/>
		<path d="M161.7,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S161.8,147.2,161.7,147.2z
			 M161.8,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S161.7,146.7,161.8,146.7z"/>
		<path d="M171.4,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1
			c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2L171.4,150.1L171.4,150.1z M170.6,151.2
			c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S171,151.2,170.6,151.2z M170,144.7
			c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S170.5,144.7,170,144.7z"/>
		<path d="M179,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S178.6,144.3,179,144.3z"/>
		<path d="M186,144.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S185.5,144.3,186,144.3z M185.2,150.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S184.9,150.4,185.2,150.4z"/>
		<path d="M194,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S193.7,144.3,194,144.3z"/>
		<path d="M199.8,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H199.8z"/>
	</g>
    
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M310.2,144.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S309.7,144.3,310.2,144.3z M309.3,150.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S309,150.4,309.3,150.4z"/>
        <path d="M318.2,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4S316,146,316,146v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S317.8,144.3,318.2,144.3z"/>
        <path d="M327.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S328,147.2,327.9,147.2z
          M327.9,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S327.9,146.7,327.9,146.7z"/>
        <path d="M334.8,144.5c0.5,0,1,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.4,0-0.6,0.1-0.6,0.3c0,0,0,0.1,0.1,0.2l1,1.6
          c0.1-0.1,0.2-0.4,0.4-0.6s0.4-0.5,0.5-0.7s0.2-0.3,0.2-0.4c0-0.1,0-0.1-0.1-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0s-0.2,0-0.2,0
          s0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0s0.3,0,0.5,0c0.5,0,0.9,0,1.2-0.1c0,0,0,0.1,0,0.3s0,0.2,0,0.2
          c-0.7,0-1.1,0.2-1.2,0.3c-0.1,0.2-0.3,0.5-0.6,0.8s-0.5,0.7-0.7,0.9s-0.3,0.4-0.3,0.4l1.9,2.8c0.3,0.4,0.7,0.6,1.2,0.6
          c0,0,0,0,0,0.1s0,0.1,0,0.2s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.5,0s-0.3,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.6,0s-0.4,0-0.6,0
          c0-0.1,0-0.1,0-0.3s0-0.2,0-0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.2v-0.1c-0.5-0.9-1-1.5-1.2-2c-0.1,0.1-0.2,0.3-0.5,0.7
          s-0.5,0.7-0.6,1c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.1,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0,0.2,0s0.1,0,0.3,0h0.1c0,0,0,0,0,0.1s0,0.1,0,0.2
          s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2
          s0-0.2,0-0.2s0.1,0,0.2,0s0.2,0,0.2,0s0.1,0,0.2,0s0.2,0,0.2-0.1s0.1,0,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2
          c1-1.4,1.5-2.1,1.5-2.2l-1.8-2.7c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.2
          C333.8,144.5,334.3,144.5,334.8,144.5z"/>
        <path d="M341.9,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S341.7,149.2,341.9,149.2z"/>
        <path d="M351.2,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S350.6,144.3,351.2,144.3z"/>
        <path d="M357.9,144.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S356.9,144.3,357.9,144.3z M357.7,144.8c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S358.1,144.8,357.7,144.8z"/>
        <path d="M366.8,144.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C366.3,144.3,366.6,144.3,366.8,144.3z"/>
        <path d="M375.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S376.1,147.2,375.9,147.2z
          M376,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
          s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S376,146.7,376,146.7z"/>
        <path d="M384.9,144.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S383.9,144.3,384.9,144.3z M384.7,144.8c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S385.1,144.8,384.7,144.8z"/>
        <path d="M393.9,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S393.6,144.3,393.9,144.3z"/>
        <path d="M398.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S399,147.2,398.9,147.2z
          M399,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
          s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S399,146.7,399,146.7z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
		<path d="M124.3,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S124.4,147.2,124.3,147.2z
			 M124.3,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S124.3,146.7,124.3,146.7z"/>
		<path d="M133,144.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S132.5,144.3,133,144.3z M132.2,150.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S131.9,150.4,132.2,150.4z"/>
		<path d="M140.7,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
			S140.4,144.3,140.7,144.3z"/>
		<path d="M144.5,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H144.5z"/>
		<path d="M151.6,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
			c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S151.3,144.3,151.6,144.3z"/>
		<path d="M159.4,144.3c0.2,0,0.5,0.1,0.9,0.2s0.8,0.2,0.9,0.2c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0.1s0.1,0.2,0.1,0.2
			c-0.1,0.3-0.2,0.7-0.2,1.2v6.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.3c0-0.1,0-0.1,0-0.1s0,0-0.1,0c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.1,0.3
			s-0.7-0.1-1-0.2s-0.6-0.3-0.8-0.5s-0.4-0.5-0.6-0.8s-0.3-0.6-0.4-0.9s-0.1-0.6-0.1-0.9c0-0.4,0.1-0.8,0.2-1.2s0.4-0.7,0.6-1
			s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5S159,144.3,159.4,144.3z M158.9,144.8c-0.3,0-0.7,0.1-0.9,0.3s-0.5,0.4-0.7,0.7s-0.3,0.6-0.4,0.8
			s-0.1,0.6-0.1,0.8c0,0.9,0.2,1.6,0.6,2.1s1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.8-0.2s0.4-0.3,0.4-0.4V146c0-0.3-0.1-0.6-0.4-0.8
			S159.3,144.8,158.9,144.8z"/>
		<path d="M168.1,146.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
			s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
			h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
			s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
			s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H168.1z"/>
		<path d="M174.5,144.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S174,144.3,174.5,144.3z M173.6,150.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S173.3,150.4,173.6,150.4z"/>
		<path d="M180,149.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
			c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
			h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
			c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
			s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H180z"/>
		<path d="M186.6,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S186.7,147.2,186.6,147.2z
			 M186.7,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S186.6,146.7,186.7,146.7z"/>
	</g>
      
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M342.9,319c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C342.1,319.1,342.5,319,342.9,319z M342.4,319.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S342.8,319.7,342.4,319.7z"
          />
        <path d="M350.6,319c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C348.8,319.3,349.6,319,350.6,319z M350.4,319.5c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S350.8,319.5,350.4,319.5z"/>
        <path d="M359.3,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M358.5,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C359.2,326,358.9,325.9,358.5,325.9z M357.9,319.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C358.7,319.7,358.4,319.5,357.9,319.5z"/>
        <path d="M363.5,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S363.2,323.9,363.5,323.9z"/>
        <path d="M374.2,319.3c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V320h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8L374.2,319.3L374.2,319.3z"/>
        <path d="M382.7,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H384c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M382,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C382.6,326,382.3,325.9,382,325.9z M381.3,319.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C382.2,319.7,381.8,319.5,381.3,319.5z"/>
        <path d="M390.1,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V317c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1
          c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7
          S389.7,319,390.1,319z"/>
        <path d="M395.8,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L395.8,319.1L395.8,319.1z"/>
        <path d="M401,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H401z M399.7,317.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S399.9,317.3,399.7,317.2z"/>
        <path d="M407.4,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S407.1,319,407.4,319z"/>
        <path d="M415.4,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M414.7,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C415.4,326,415,325.9,414.7,325.9z M414.1,319.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C414.9,319.7,414.5,319.5,414.1,319.5z"/>
        <path d="M419.7,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S419.4,323.9,419.7,323.9z"/>
        <path d="M347,335c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C345.8,335.2,346.4,335,347,335z"/>
        <path d="M354.7,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S354.4,335,354.7,335z"/>
        <path d="M361.8,335.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C360.8,335.2,361.3,335.1,361.8,335.1z M360.9,341.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S360.6,341.2,360.9,341.2z"/>
        <path d="M367.3,340.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1V336
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H367.3z"/>
        <path d="M373.9,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C374.7,338,374,338,373.9,338z M374,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S374,337.4,374,337.4z"/>
        <path d="M380.5,339.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S380.3,339.9,380.5,339.9z"/>
        <path d="M389.2,335.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H387c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L389.2,335.1L389.2,335.1z"/>
        <path d="M394.4,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H394.4z M393.1,333.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S393.3,333.3,393.1,333.2z"/>
        <path d="M400.5,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V340.8z M399.8,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C400.4,342,400.1,341.9,399.8,341.9z M399.1,335.5
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C399.9,335.7,399.6,335.5,399.1,335.5z"/>
        <path d="M404.8,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C405.6,338,404.9,338,404.8,338z M404.9,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S404.9,337.4,404.9,337.4z"/>
        <path d="M414.5,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C413.9,335.2,414.2,335,414.5,335z"/>
      </g>
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M117.6,319c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C116.4,319.2,117,319,117.6,319z"/>
        <path d="M125.3,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S125,319,125.3,319z"/>
        <path d="M132.4,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C131.3,319.2,131.9,319.1,132.4,319.1z M131.5,325.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S131.2,325.2,131.5,325.2z"/>
        <path d="M137.9,324.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1V320
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H137.9z"/>
        <path d="M144.5,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C145.2,322,144.6,322,144.5,322z M144.6,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S144.5,321.4,144.6,321.4z"/>
      </g>
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M325.5,128.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8s-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V129h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7
          V128.6z"/>
        <path d="M332.3,128.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S331.7,128.3,332.3,128.3z"/>
        <path d="M339.8,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S339.5,128.3,339.8,128.3z"/>
        <path d="M344.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S344.5,133.2,344.8,133.2z"/>
        <path d="M352.5,128.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6
          s0.1-0.4,0.2-0.5s0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          s-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2s-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3
          c0,0-0.1,0.1-0.1,0.2s-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S352.3,128.5,352.5,128.5z"/>
        <path d="M365.8,128.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S365.3,128.3,365.8,128.3z M365,134.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S364.7,134.4,365,134.4z"/>
        <path d="M371.3,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L371.3,128.4L371.3,128.4z"/>
        <path d="M375.4,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S375.5,131.2,375.4,131.2z
          M375.5,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S375.5,130.7,375.5,130.7z"/>
        <path d="M385.1,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S384.8,128.3,385.1,128.3z"/>
        <path d="M387.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S387.6,133.2,387.8,133.2z"/>
        <path d="M322.6,144.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C321.5,144.5,322,144.5,322.6,144.5z"/>
        <path d="M332.1,144.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S331.2,144.3,332.1,144.3z M331.9,144.8c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S332.4,144.8,331.9,144.8z"/>
        <path d="M341.5,146.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
          s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
          h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
          s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H341.5z"/>
        <path d="M348.9,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S348.6,144.3,348.9,144.3z"/>
        <path d="M360.8,145.1c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
          s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
          s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2S360.8,145,360.8,145.1z"
          />
        <path d="M366.2,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S365.8,144.3,366.2,144.3z"/>
        <path d="M372.9,146.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
          s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
          h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
          s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H372.9z"/>
        <path d="M378.5,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S377.9,144.3,378.5,144.3z"/>
        <path d="M386,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S385.7,144.3,386,144.3z"/>
      </g>
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
		<path d="M117.2,151.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
			v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
			s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S117.7,151.2,117.2,151.2z M117.4,145.2
			c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
			s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S118.1,145.2,117.4,145.2z"/>
		<path d="M123,142.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V142.4z"/>
		<path d="M129.4,144.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3c0.5-0.2,0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1
			s-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2
			s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C128.4,144.4,128.9,144.3,129.4,144.3z M128.6,150.4
			c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7
			c0,0.3,0.1,0.5,0.3,0.8C128,150.3,128.3,150.4,128.6,150.4z"/>
		<path d="M138.3,145.1c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
			s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2S138.3,145,138.3,145.1z"
			/>
		<path d="M141.6,149.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
			c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1H144
			h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
			c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
			s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H141.6z"/>
		<path d="M157.7,145.1c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
			s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2S157.7,145,157.7,145.1z"
			/>
		<path d="M162.1,144.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S161.6,144.3,162.1,144.3z M161.3,150.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S161,150.4,161.3,150.4z"/>
		<path d="M167.6,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H167.6z"/>
		<path d="M171.6,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S171.4,149.2,171.6,149.2z"/>
		<path d="M179.2,142.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L179.2,142.4L179.2,142.4z"/>
		<path d="M184.6,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H184.6z
			 M183.3,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			s-0.3,0.2-0.6,0.2S183.5,142.6,183.3,142.4z"/>
		<path d="M192,145.1c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
			s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2S192,145,192,145.1z"/>
		<path d="M194.3,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S194.5,147.2,194.3,147.2z
			 M194.4,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S194.4,146.7,194.4,146.7z"/>
	</g>
    
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path d="M184.7,408.4c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S184.1,408.4,184.7,408.4z"/>
        <path d="M192.5,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S192.1,408.4,192.5,408.4z"/>
        <path d="M199.5,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S199,408.4,199.5,408.4z M198.7,414.5c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S198.4,414.5,198.7,414.5z"/>
        <path d="M205.1,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H205.1z"/>
        <path d="M211.6,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S211.7,411.3,211.6,411.3z
          M211.7,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S211.7,410.7,211.7,410.7z"/>
      </g>
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path d="M334,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S334.6,326,334,326z M334.3,320c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S335,320,334.3,320z"/>
        <path d="M340.2,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S340.4,322,340.2,322z
          M340.3,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S340.3,321.4,340.3,321.4z"/>
        <path d="M348.1,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H348.1z M346.8,317.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S346.9,317.3,346.8,317.2z"/>
        <path d="M354.5,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S354.1,319,354.5,319z"/>
        <path d="M362.5,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M361.7,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S362.1,325.9,361.7,325.9z M361.1,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S361.6,319.5,361.1,319.5z"/>
        <path d="M374.3,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S374,319,374.3,319z M373.6,320
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S374.2,320,373.6,320z"/>
        <path d="M382.4,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S382.1,319,382.4,319z"/>
        <path d="M385.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S385.5,322,385.3,322z
          M385.4,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S385.4,321.4,385.4,321.4z"/>
        <path d="M395,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M394.3,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S394.7,325.9,394.3,325.9z M393.7,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S394.1,319.5,393.7,319.5z"/>
        <path d="M402.7,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S402.3,319,402.7,319z"/>
        <path d="M409.7,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S409.2,319.1,409.7,319.1z M408.8,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S408.6,325.2,408.8,325.2z"/>
        <path d="M417.7,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S417.4,319,417.7,319z"/>
        <path d="M423.5,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L423.5,319.1L423.5,319.1z"/>
        <path d="M427.5,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S427.2,324,427.5,324z"/>
        <path d="M352.5,335.3c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V336h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8
          L352.5,335.3L352.5,335.3z"/>
        <path d="M361,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S360.7,335,361,335z"/>
        <path d="M364,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S364.1,338,364,338z
          M364,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
          s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S364,337.4,364,337.4z"/>
        <path d="M370.6,340c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S370.3,340,370.6,340z"/>
        <path d="M379.8,335c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S379.2,335,379.8,335z"/>
        <path d="M387.6,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S387.2,335,387.6,335z"/>
        <path d="M394.6,335.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S394.1,335.1,394.6,335.1z M393.8,341.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S393.5,341.2,393.8,341.2z"/>
        <path d="M400.2,340.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V336c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
          h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H400.2z"/>
        <path d="M406.7,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S406.9,338,406.7,338z
          M406.8,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S406.8,337.4,406.8,337.4z"/>
      </g>
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path d="M309.3,135.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C310.4,135.1,309.9,135.2,309.3,135.2z M309.6,129.2c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8
          c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C310.8,129.5,310.3,129.2,309.6,129.2z"/>
        <path d="M315.5,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C316.3,131.2,315.7,131.2,315.5,131.2z M315.6,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S315.6,130.7,315.6,130.7z"/>
        <path d="M323.4,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H323.4
          z M322.1,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S322.2,126.6,322.1,126.4z"/>
        <path d="M329.8,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S329.4,128.3,329.8,128.3z"/>
        <path d="M337.8,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.2,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L337.8,134.1L337.8,134.1z M337,135.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C337.7,135.2,337.4,135.2,337,135.2z M336.4,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
          c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C337.2,128.9,336.9,128.7,336.4,128.7z"/>
        <path d="M349.6,128.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C348.9,128.4,349.3,128.3,349.6,128.3z M348.9,129.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C350,129.5,349.5,129.2,348.9,129.2z"/>
        <path d="M357.7,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C357.1,128.5,357.4,128.3,357.7,128.3z"/>
        <path d="M360.6,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C361.4,131.2,360.8,131.2,360.6,131.2z M360.7,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S360.7,130.7,360.7,130.7z"/>
        <path d="M370.3,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.2,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L370.3,134.1L370.3,134.1z M369.6,135.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C370.3,135.2,370,135.2,369.6,135.2z M369,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
          c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C369.8,128.9,369.4,128.7,369,128.7z"/>
        <path d="M377.9,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S377.6,128.3,377.9,128.3z"/>
        <path d="M385,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C384,128.4,384.5,128.3,385,128.3z M384.1,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S383.9,134.4,384.1,134.4z"/>
        <path d="M393,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S392.7,128.3,393,128.3z"/>
        <path d="M398.8,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L398.8,128.4L398.8,128.4z"/>
        <path d="M402.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S402.5,133.2,402.8,133.2z"/>
        <path d="M349.2,144.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8s-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V145h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6
          c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.1
          c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7V144.6z"/>
        <path d="M357.7,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C357.1,144.5,357.4,144.3,357.7,144.3z"/>
        <path d="M360.6,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C361.4,147.2,360.8,147.2,360.6,147.2z M360.7,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S360.7,146.7,360.7,146.7z"/>
      </g>
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
		<path d="M110.7,151.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3
			c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.8,0.6-1.2,0.8
			C111.7,151.1,111.2,151.2,110.7,151.2z M110.9,145.2c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C112.2,145.5,111.6,145.2,110.9,145.2z"/>
		<path d="M116.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C117.7,147.2,117,147.2,116.9,147.2z M117,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C117,146.6,117,146.7,117,146.7z"/>
		<path d="M124.7,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L124.7,146L124.7,146z M123.4,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
			c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
			S123.6,142.6,123.4,142.4z"/>
		<path d="M131.1,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C130.4,144.4,130.8,144.3,131.1,144.3
			z"/>
		<path d="M139.1,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.2,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L139.1,150.1L139.1,150.1z M138.4,151.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C139,151.2,138.7,151.2,138.4,151.2z
			 M137.8,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C138.6,144.9,138.2,144.7,137.8,144.7z"/>
		<path d="M150.9,144.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
			c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6C150.3,144.4,150.6,144.3,150.9,144.3z M150.2,145.2
			c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
			c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S150.9,145.2,150.2,145.2z"/>
		<path d="M159.1,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C158.4,144.5,158.8,144.3,159.1,144.3z"/>
		<path d="M162,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
			s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C162.8,147.2,162.1,147.2,162,147.2z M162.1,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4
			c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
			s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C162.1,146.6,162.1,146.7,162.1,146.7z"/>
		<path d="M171.7,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H173c0.1,0.1,0.2,0.2,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L171.7,150.1L171.7,150.1z M171,151.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C171.6,151.2,171.3,151.2,171,151.2z
			 M170.3,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C171.1,144.9,170.8,144.7,170.3,144.7z"/>
		<path d="M179.3,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C178.6,144.4,179,144.3,179.3,144.3z"
			/>
		<path d="M186.3,144.3c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
			s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
			c0.3-0.3,0.8-0.6,1.3-0.9C185.3,144.4,185.8,144.3,186.3,144.3z M185.5,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S185.2,150.4,185.5,150.4z"/>
		<path d="M194.3,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C193.6,144.4,194,144.3,194.3,144.3z"
			/>
		<path d="M200.1,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V145H198c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
			c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0
			L200.1,144.4L200.1,144.4z"/>
	</g>
    
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M335.6,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S336.1,326,335.6,326z M335.8,320c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S336.6,320,335.8,320z"/>
        <path d="M341.8,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S341.9,322,341.8,322z
          M341.9,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S341.9,321.4,341.9,321.4z"/>
        <path d="M349.6,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H349.6z M348.4,317.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S348.5,317.3,348.4,317.2z"/>
        <path d="M356.1,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S355.7,319,356.1,319z"/>
        <path d="M364,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M363.3,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S363.7,325.9,363.3,325.9z M362.7,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S363.1,319.5,362.7,319.5z"/>
        <path d="M375.8,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S375.6,319,375.8,319z M375.2,320
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S375.8,320,375.2,320z"/>
        <path d="M384,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S383.7,319,384,319z"/>
        <path d="M386.9,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S387.1,322,386.9,322z
          M387,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
          s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S387,321.4,387,321.4z"/>
        <path d="M396.6,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M395.9,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S396.2,325.9,395.9,325.9z M395.3,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S395.7,319.5,395.3,319.5z"/>
        <path d="M404.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S403.9,319,404.2,319z"/>
        <path d="M411.3,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S410.8,319.1,411.3,319.1z M410.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S410.1,325.2,410.4,325.2z"/>
        <path d="M419.3,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S418.9,319,419.3,319z"/>
        <path d="M425,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H425v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L425,319.1L425,319.1z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M86,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S86.5,326,86,326z M86.2,320c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S86.9,320,86.2,320z"/>
        <path d="M92.2,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S92.3,322,92.2,322z
          M92.3,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S92.2,321.4,92.3,321.4z"/>
        <path d="M100,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L100,320.8L100,320.8z
          M98.7,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S98.9,317.3,98.7,317.2z"/>
        <path d="M106.4,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S106.1,319,106.4,319z"/>
        <path d="M114.4,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M113.7,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S114,325.9,113.7,325.9z M113.1,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S113.5,319.5,113.1,319.5z"/>
        <path d="M126.2,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S125.9,319,126.2,319z M125.5,320
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S126.2,320,125.5,320z"/>
        <path d="M134.4,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S134.1,319,134.4,319z"/>
        <path d="M137.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S137.4,322,137.3,322z
          M137.4,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S137.4,321.4,137.4,321.4z"/>
        <path d="M147,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M146.3,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S146.6,325.9,146.3,325.9z M145.6,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S146.1,319.5,145.6,319.5z"/>
        <path d="M154.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S154.3,319,154.6,319z"/>
        <path d="M161.6,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S161.1,319.1,161.6,319.1z M160.8,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S160.5,325.2,160.8,325.2z"/>
        <path d="M169.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S169.3,319,169.6,319z"/>
        <path d="M175.4,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H175.4z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M159.7,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S160.2,415.3,159.7,415.3z M160,409.3
          c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4
          s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S160.7,409.3,160,409.3z"/>
        <path d="M165.6,406.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L165.6,406.5L165.6,406.5z"/>
        <path d="M171.9,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S171.4,408.4,171.9,408.4z M171.1,414.5c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S170.8,414.5,171.1,414.5z"/>
        <path d="M180.9,409.2c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S180.9,409.1,180.9,409.2z"/>
        <path d="M184.1,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H184.1z"/>
        <path d="M200.3,409.2c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S200.3,409.1,200.3,409.2z"/>
        <path d="M204.7,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S204.2,408.4,204.7,408.4z M203.8,414.5c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S203.5,414.5,203.8,414.5z"/>
        <path d="M210.1,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409H208c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L210.1,408.4L210.1,408.4z"/>
        <path d="M214.1,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S213.9,413.3,214.1,413.3z"/>
        <path d="M221.7,406.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L221.7,406.5L221.7,406.5z"/>
        <path d="M227.1,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H227.1z M225.9,406.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S226,406.6,225.9,406.5z"/>
        <path d="M234.5,409.2c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S234.5,409.1,234.5,409.2z"/>
        <path d="M236.9,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S237,411.3,236.9,411.3z
          M236.9,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S236.9,410.7,236.9,410.7z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M277.4,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S277.6,411.3,277.4,411.3z
          M277.5,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S277.5,410.7,277.5,410.7z"/>
        <path d="M286.2,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S285.7,408.4,286.2,408.4z M285.4,414.5c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S285.1,414.5,285.4,414.5z"/>
        <path d="M293.9,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S293.6,408.4,293.9,408.4z"/>
        <path d="M297.6,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L297.6,408.4L297.6,408.4z"/>
        <path d="M304.8,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S304.5,408.4,304.8,408.4z"/>
        <path d="M312.5,408.4c0.2,0,0.5,0.1,0.9,0.2s0.8,0.2,0.9,0.2s0.2,0,0.3,0c0,0,0.1,0,0.1,0.1s0.1,0.2,0.1,0.2
          c-0.1,0.3-0.2,0.7-0.2,1.2v6.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.3c0-0.1,0-0.1,0-0.1s0,0-0.1,0c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.1,0.3
          c-0.3,0-0.7-0.1-1-0.2s-0.6-0.3-0.8-0.5s-0.4-0.5-0.6-0.8s-0.3-0.6-0.4-0.9s-0.1-0.6-0.1-0.9c0-0.4,0.1-0.8,0.2-1.2s0.4-0.7,0.6-1
          s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5S312.2,408.4,312.5,408.4z M312.1,408.9c-0.3,0-0.7,0.1-0.9,0.3s-0.5,0.4-0.7,0.7s-0.3,0.6-0.4,0.8
          s-0.1,0.6-0.1,0.8c0,0.9,0.2,1.6,0.6,2.1s1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.8-0.2s0.4-0.3,0.4-0.4V410c0-0.3-0.1-0.6-0.4-0.8
          S312.4,408.9,312.1,408.9z"/>
        <path d="M321.3,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
          s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
          h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
          s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H321.3z"/>
        <path d="M327.6,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S327.1,408.4,327.6,408.4z M326.8,414.5c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S326.5,414.5,326.8,414.5z"/>
        <path d="M333.2,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H333.2z"/>
        <path d="M339.8,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S339.9,411.3,339.8,411.3z
          M339.8,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S339.8,410.7,339.8,410.7z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
		<path d="M309.3,135.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
			C310.4,135.1,309.9,135.2,309.3,135.2z M309.6,129.2c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C310.8,129.5,310.3,129.2,309.6,129.2z"/>
		<path d="M315.5,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C316.3,131.2,315.7,131.2,315.5,131.2z M315.6,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S315.6,130.7,315.6,130.7z"/>
		<path d="M323.4,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H323.4
			z M322.1,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S322.2,126.6,322.1,126.4z"/>
		<path d="M329.8,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S329.4,128.3,329.8,128.3z"/>
		<path d="M337.8,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.2,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L337.8,134.1L337.8,134.1z M337,135.2c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C337.7,135.2,337.4,135.2,337,135.2z M336.4,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C337.2,128.9,336.9,128.7,336.4,128.7z"/>
		<path d="M349.6,128.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C348.9,128.4,349.3,128.3,349.6,128.3z M348.9,129.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C350,129.5,349.5,129.2,348.9,129.2z"/>
		<path d="M357.7,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
			l0,0c0.2-0.4,0.4-0.8,0.8-1.1C357.1,128.5,357.4,128.3,357.7,128.3z"/>
		<path d="M360.6,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C361.4,131.2,360.8,131.2,360.6,131.2z M360.7,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S360.7,130.7,360.7,130.7z"/>
		<path d="M370.3,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.2,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L370.3,134.1L370.3,134.1z M369.6,135.2c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C370.3,135.2,370,135.2,369.6,135.2z M369,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C369.8,128.9,369.4,128.7,369,128.7z"/>
		<path d="M377.9,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S377.6,128.3,377.9,128.3z"/>
		<path d="M385,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C384,128.4,384.5,128.3,385,128.3z M384.1,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S383.9,134.4,384.1,134.4z"/>
		<path d="M393,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S392.7,128.3,393,128.3z"/>
		<path d="M398.8,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L398.8,128.4L398.8,128.4z"/>
		<path d="M402.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S402.5,133.2,402.8,133.2z"/>
		<path d="M337.8,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.2,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.2,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
			c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C336.7,144.5,337.2,144.3,337.8,144.3z"/>
		<path d="M345.6,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S345.3,144.3,345.6,144.3z"/>
		<path d="M352.6,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C351.6,144.4,352.1,144.3,352.6,144.3z M351.8,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S351.5,150.4,351.8,150.4z"/>
		<path d="M358.2,149.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
			c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2
			c0-0.1,0.1-0.1,0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2
			c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3
			c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3
			s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5
			c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H358.2z"/>
		<path d="M364.7,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C365.5,147.2,364.9,147.2,364.7,147.2z M364.8,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S364.8,146.7,364.8,146.7z"/>
		<path d="M372.8,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.2,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.2,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
			c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C371.6,144.5,372.2,144.3,372.8,144.3z"/>
	</g>
    </g>
  );
};

export default NetworkTexts;
