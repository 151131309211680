import React from 'react';
import '../Network.scss';

const NetworkTexts = ({ selected }) => {
  return (
    <g id='subjects' transform="translate(-42,-40)">
      <g id='en' style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path  d="M92.6,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1H95l0.1-0.1
			c0,0,0.1,0,0.1,0h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5
			c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.9-0.8,1.4-1C91.5,80.4,92,80.2,92.6,80.2z M91.7,86.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C91.2,86.2,91.5,86.3,91.7,86.3z"/>
		<path  d="M103.5,87.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1H102h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V77
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4V81c0,0,0,0,0,0.1
			l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8C104.5,87,104,87.1,103.5,87.2z
			 M103.8,81.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8
			c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1
			C105,81.4,104.4,81.1,103.8,81.2L103.8,81.2z"/>
		<path  d="M111.8,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.2,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C110.6,80.5,111.2,80.3,111.8,80.2z M110.9,86.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C110.4,86.2,110.7,86.3,110.9,86.3z"/>
		<path  d="M118.1,87.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4V81c0,0,0,0,0,0.1
			l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.6c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8C119,87,118.5,87.1,118.1,87.2z M118.3,81.2
			c-0.2,0-0.4,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.3c0-0.7-0.2-1.5-0.6-2.1C119.6,81.4,119,81.1,118.3,81.2
			L118.3,81.2z"/>
		<path  d="M124,80.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.6-2.3,5.7c0,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C123,80.4,123.5,80.4,124,80.4z"/>
		<path  d="M130.8,85.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C130.3,85.2,130.6,85.1,130.8,85.1z"/>
		<path  d="M140.3,87.2c-0.4,0-0.8-0.1-1.1-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			V81c0,0,0,0,0,0.1l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.3,0.8,0.2,1.7-0.1,2.6c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C141.3,87,140.8,87.1,140.3,87.2z M140.6,81.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C141.8,81.4,141.2,81.1,140.6,81.2L140.6,81.2z"/>
		<path  d="M146.5,83.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L146.5,83.2z
			 M146.6,82.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.4,1
			C146.6,82.5,146.6,82.6,146.6,82.6L146.6,82.6z"/>
		<path  d="M154.3,82v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H152c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L154.3,82z M153.1,78.4
			c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
			c-0.1,0.1-0.3,0.2-0.6,0.2C153.4,78.6,153.2,78.5,153.1,78.4L153.1,78.4z"/>
		<path  d="M160.8,80.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.6-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0.1
			c0.3-0.3,0.6-0.5,1-0.7C159.9,80.4,160.3,80.2,160.8,80.2z"/>
		<path  d="M168.7,86c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.8,1.2c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,0.9
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.2,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2L168.7,86z M168,87.1
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C168.6,87.1,168.3,87.1,168,87.1z M167.4,80.7c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C168.3,80.9,167.8,80.6,167.4,80.7L167.4,80.7z"/>
		<path  d="M182,81c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1l-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0-0.3,0-0.4,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.2,1.3,0.8,2.5,2.1,2.6c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-1.1,0-2.2-0.7-2.7-1.7c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2C181.9,80.8,182,80.9,182,81z"/>
		<path  d="M187.4,80.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.5l0,0l0,0c0.3-0.3,0.6-0.5,1-0.7C186.5,80.4,187,80.2,187.4,80.2z"/>
		<path  d="M194.4,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1
			c0,0,0.1,0,0.1,0h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5
			c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5L193,84l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C193.3,80.5,193.9,80.3,194.4,80.2z M193.6,86.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C193,86.2,193.3,86.3,193.6,86.3z"/>
		<path  d="M200.4,80.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C199.3,80.4,199.9,80.2,200.4,80.2z"/>
		<path  d="M204.9,83.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L204.9,83.2z M205,82.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.4,1
			C205,82.5,205,82.6,205,82.6L205,82.6z"/>
		<path  d="M214.2,80.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1c0,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1
			c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1l-0.5,0.1l-0.6,0.1L216,87l-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.6
			c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.9-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.3-0.4,0.6-0.8,1-1.2c0.4-0.3,0.8-0.6,1.2-0.8C213.5,80.3,213.9,80.2,214.2,80.2z M213.7,80.9
			c-0.6,0-1.1,0.3-1.4,0.8c-0.7,1.2-0.7,2.6,0.1,3.8c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5C214.5,80.9,214.1,80.8,213.7,80.9z"/>
		<path  d="M219.6,85.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C219.1,85.2,219.3,85.1,219.6,85.1z"/>
		<path  d="M98.2,92.2c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v2h1.8c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H95h-0.5c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-4h-0.9c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3c0.3-0.3,0.5-0.6,0.9-0.8
			C97.6,92.3,97.9,92.2,98.2,92.2z"/>
		<path  d="M102.6,96.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C101.4,96.5,102,96.3,102.6,96.2z M101.7,102.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.4
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C101.2,102.2,101.4,102.3,101.7,102.3z"/>
		<path  d="M106.9,94.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1H107h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L106.9,94.3z"/>
		<path  d="M110.9,94.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1H111h-0.6h-0.6c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L110.9,94.3z"/>
		<path  d="M116.3,98v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H114c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V98z M115.1,94.4
			c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.6,0.2C115.4,94.6,115.2,94.5,115.1,94.4
			L115.1,94.4z"/>
		<path  d="M122.8,96.2c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0,0.8,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5H123h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2L120,103h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C122,96.4,122.4,96.2,122.8,96.2z"/>
		<path  d="M130.7,102c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,0.9
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.3-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5l0.5-0.4c0.2-0.1,0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2L130.7,102z M130,103.1
			c-0.5,0-1,0-1.4,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.4,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C130.6,103.1,130.3,103.1,130,103.1L130,103.1z M129.4,96.7c-0.4,0-0.8,0.2-1,0.6
			c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C130.3,96.9,129.9,96.6,129.4,96.7L129.4,96.7z"/>
		<path  d="M140.5,96.3h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.8,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			l0,0L140.5,96.3z"/>
		<path  d="M144.6,99.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-1,0.4-1.9,1-2.7
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.6
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L144.6,99.2z
			 M144.7,98.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C144.7,98.5,144.7,98.6,144.7,98.6L144.7,98.6z"/>
		<path  d="M151.3,99.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L151.3,99.2z M151.4,98.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C151.4,98.5,151.4,98.6,151.4,98.6L151.4,98.6z"/>
		<path  d="M158.8,96.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.8,0.4,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.3
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5
			h-0.9c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0L158.8,96.3z"/>
		<path  d="M166,96.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H162h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0.1
			c0.3-0.3,0.6-0.5,1-0.7C165.1,96.4,165.6,96.2,166,96.2z"/>
		<path  d="M170.9,101.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C170.4,101.2,170.6,101.1,170.9,101.1z"/>
		<path  d="M178.6,101.2c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1h-0.8c0,0-0.1,0-0.1-0.1c0-0.1,0-0.3,0.1-0.4
			c0.1-0.2,0.1-0.2,0.1-0.2h0.6c0.1,0,0.1,0,0.1-0.1v-0.2c0-1,0.4-2.1,1-2.9c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3
			c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.2l0.2-0.1l0,0c0.1,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.1c0,0.5,0,0.9,0.1,1.4c0,0.1,0.2,0.2,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.1
			c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3l-1.7-0.1l-1.7,0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.7,0.1-1.1v-6c0-0.1,0-0.3-0.1-0.6c0-0.2-0.1-0.4-0.1-0.6c-0.2-0.3-0.4-0.6-0.7-0.8
			c-0.3-0.2-0.6-0.4-1-0.4c-0.3,0-0.6,0.2-0.8,0.4c-0.2,0.3-0.4,0.6-0.5,1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8l0,0l0,0h1.7h0.1
			c0,0,0,0,0,0.1c0,0.2-0.1,0.4-0.2,0.6h-1.6c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6c0,0.5,0,0.9,0.1,1.4c0,0.1,0.1,0.2,0.4,0.2
			c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3l-1.7-0.1l-1.7,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2C178.5,101.9,178.6,101.6,178.6,101.2z"/>
		<path  d="M187.2,96.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.2c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6
			c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5
			c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8
			c0.1-0.3,0.3-0.5,0.4-0.8c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			C186.2,96.4,186.7,96.4,187.2,96.4z"/>
		<path  d="M195.8,98v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V98z
			 M194.5,94.4c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c-0.1,0.1-0.3,0.2-0.6,0.2C194.8,94.6,194.6,94.5,194.5,94.4L194.5,94.4z"/>
		<path  d="M202.2,96.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C201.4,96.4,201.8,96.2,202.2,96.2z"/>
		<path  d="M210.2,102c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,0.9
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.7-1.7c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1c-0.1,0-0.1,0-0.1,0c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2L210.2,102z M209.5,103.1
			c-0.5,0-1,0-1.4,0.1c-0.2,0.1-0.5,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C210.1,103.1,209.8,103.1,209.5,103.1L209.5,103.1z M208.8,96.7c-0.4,0-0.8,0.2-1,0.6
			c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C209.7,96.9,209.3,96.6,208.8,96.7z"/>
		<path  d="M214.4,101.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C213.9,101.2,214.2,101.1,214.4,101.1z"/>
		<path  d="M113.1,112.2c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.5,0.6,0.6,1c0.6-0.7,1.4-1.1,2.3-1.3c0.3,0,0.6,0,0.9,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1H118h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.8
			c0-0.2-0.1-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.5,0.1-0.7,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3v0.1c0,0,0,0.1,0,0.1c0.1,0.4,0.1,0.7,0.1,1.1v1.9c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2
			c-0.3-0.4-0.7-0.6-1.2-0.6c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.6c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.1,0.7-0.1,0.8s0,0.1,0,0.1l0,0c0.4-0.3,0.8-0.6,1.2-0.8C112.4,112.3,112.7,112.2,113.1,112.2z"/>
		<path  d="M124.4,112.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C122.6,112.5,123.5,112.2,124.4,112.2z M124.2,112.7
			c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.3-0.9,0.2-1.9-0.1-2.8c-0.1-0.5-0.4-0.9-0.8-1.2
			C125.1,112.9,124.7,112.7,124.2,112.7z"/>
		<path  d="M133.4,112.2c0.3,0,0.6,0,0.9,0.1c0.5,0.2,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.6-0.1h-0.5h-0.6
			h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H130h-0.6
			h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.7
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0.1c0.3-0.3,0.6-0.5,1-0.7
			C132.6,112.4,133,112.2,133.4,112.2z"/>
		<path  d="M138.4,115.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L138.4,115.2z M138.5,114.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.3-0.4,0.6-0.4,1C138.5,114.5,138.5,114.6,138.5,114.6z"/>
		<path  d="M145,112.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C143.9,112.4,144.5,112.4,145,112.4z"/>
		<path  d="M151.8,117.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C151.3,117.2,151.6,117.1,151.8,117.1z"/>
		<path  d="M161,112.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.2,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3s-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C159.8,112.4,160.4,112.2,161,112.2z"/>
		<path  d="M168.8,112.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.6-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7,0h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0.1c0.3-0.3,0.6-0.5,1-0.7C168,112.4,168.4,112.3,168.8,112.2z"/>
		<path  d="M175.8,112.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C174.6,112.5,175.2,112.3,175.8,112.2z M175,118.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C174.4,118.2,174.7,118.3,175,118.3L175,118.3z"/>
		<path  d="M181.4,117.2c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-0.9l0.4-0.4
			l0.4-0.3l0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1L184,113h-0.1h-0.1h-0.1h-0.2c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-0.2,0-0.4,0-0.6,0.1
			c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2c0.2,0.2,0.4,0.4,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.3-2.6c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1l0,0L181.4,117.2z"/>
		<path  d="M187.9,115.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L187.9,115.2z
			 M188,114.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.4,1
			C188,114.5,188,114.6,188,114.6L188,114.6z"/>
		<path  d="M196,112.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.2-0.5-0.2-0.8
			c-0.1-0.3-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
			c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4
			C194.8,112.3,195.4,112.2,196,112.2z"/>
		<path  d="M200.4,117.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C199.9,117.2,200.1,117.1,200.4,117.1z"/>
		<path  d="M99.1,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.4,0.2,0.6
			c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.7-0.1
			l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
			c0.3,0.3,0.6,0.4,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4
			c-0.2-0.1-0.3-0.2-0.4-0.3s-0.3-0.2-0.6-0.3s-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
			c0-0.5,0.2-1,0.7-1.4C97.9,128.3,98.5,128.2,99.1,128.2z"/>
		<path  d="M106.4,128.2c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1
			c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
			c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.4c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.4v0.1l0,0c0.3-0.2,0.6-0.4,0.9-0.6C105.7,128.3,106,128.2,106.4,128.2z M105.7,129.1c-0.3,0-0.6,0.1-0.9,0.2
			c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1
			c0.2-0.4,0.2-0.8,0.2-1.2c0-0.8-0.2-1.5-0.6-2.1C106.9,129.4,106.3,129.1,105.7,129.1z"/>
		<path  d="M112.6,130v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2L112,135h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1
			L112.6,130z M111.4,126.4c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
			c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.6,0.2C111.7,126.6,111.5,126.5,111.4,126.4L111.4,126.4z"/>
		<path  d="M118.3,128.2c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.5,0.1c0,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2
			c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1l-0.5,0.1l-0.6,0.1c-0.2,0-0.3,0.1-0.5,0.1l-0.3,0.1h-0.2c-0.1,0-0.1-0.1-0.1-0.3v-0.6
			c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.4c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.9-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C117.5,128.2,117.9,128.2,118.3,128.2z M117.8,128.9
			c-0.6,0-1.1,0.3-1.4,0.8c-0.4,0.5-0.5,1.2-0.5,1.8c0,0.7,0.2,1.4,0.6,1.9c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C118.6,128.9,118.2,128.8,117.8,128.9z"/>
		<path  d="M123.8,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L123.8,131.2z M123.9,130.6h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.3-0.4,0.6-0.4,1C123.9,130.5,123.9,130.6,123.9,130.6L123.9,130.6z"/>
		<path  d="M133.5,128.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.9-0.1l-1.8,0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7s0,0,0,0h0.1
			c0.2-0.4,0.5-0.8,0.8-1.1C132.8,128.4,133.1,128.3,133.5,128.2z"/>
		<path  d="M137.8,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0l-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C136.7,128.4,137.2,128.2,137.8,128.2z"/>
		<path  d="M142.2,133.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C141.7,133.2,141.9,133.1,142.2,133.1z"/>
		<path  d="M150.9,128.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.3
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5
			h-0.9c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0h0.1L150.9,128.3z"/>
		<path  d="M157.2,128.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C155.4,128.5,156.3,128.2,157.2,128.2z M157,128.7
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.1-0.5-0.4-0.9-0.8-1.2
			C157.9,128.9,157.4,128.7,157,128.7L157,128.7z"/>
		<path  d="M165.9,128.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7s0,0,0,0l0,0
			c0.2-0.4,0.5-0.8,0.8-1.1C165.1,128.5,165.5,128.3,165.9,128.2z"/>
		<path  d="M172.2,128.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1
			h0.1c0.3-0.3,0.6-0.5,1-0.7C171.4,128.4,171.8,128.2,172.2,128.2z"/>
		<path  d="M179.2,128.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C178,128.5,178.6,128.3,179.2,128.2z M178.4,134.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C177.8,134.2,178.1,134.3,178.4,134.3z"/>
		<path  d="M186.5,128.2c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.5,0.1c0,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.2,1.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1
			c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1l-0.5,0.1l-0.5,0.1l-0.5,0.1L188,135h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.6
			c0-0.1,0-0.1-0.1-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.4c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.9-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C185.8,128.2,186.1,128.2,186.5,128.2z M186,128.9
			c-0.6,0-1.1,0.3-1.4,0.8c-0.7,1.2-0.7,2.6,0.1,3.8c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5C186.8,128.9,186.4,128.8,186,128.9L186,128.9z"/>
		<path  d="M194.2,128.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C192.5,128.5,193.3,128.2,194.2,128.2z M194.1,128.7
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C194.9,128.9,194.5,128.7,194.1,128.7z"/>
		<path  d="M199.9,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L199.9,131.2z M200,130.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.3-0.4,0.6-0.4,1C200,130.5,200,130.6,200,130.6L200,130.6z"/>
		<path  d="M208,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.2,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.4,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3s-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C206.8,128.4,207.4,128.2,208,128.2z"/>
		<path  d="M212.3,133.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C211.9,133.2,212.1,133.1,212.3,133.1z"/>
		<path  d="M138.8,144.4h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2l0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9l0.2-0.6
			c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.4-0.3-0.7-0.3
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6h0.8l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c-0.5,0-0.8,0.2-0.8,0.5
			c0,0,0,0.1,0,0.2s0.1,0.2,0.1,0.3l0.2,0.5l0.3,0.7l0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0-0.1,0.1-0.2,0.1-0.4
			c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1h-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6h0.8h0.5h0.5h0.5
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.6,4.2-2.2,5.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0-0.2,0-0.3,0-0.5h0.5h0.5
			L138.8,144.4z"/>
		<path  d="M152.1,144.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C151,144.5,151.5,144.3,152.1,144.2z M151.3,150.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			l-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8C150.7,150.2,151,150.3,151.3,150.3
			L151.3,150.3z"/>
		<path  d="M157.6,144.3h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0l0,0
			L157.6,144.3z"/>
		<path  d="M161.7,147.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L161.7,147.2z
			 M161.8,146.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.4,1
			C161.8,146.5,161.8,146.6,161.8,146.6L161.8,146.6z"/>
		<path  d="M171.4,144.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.3,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2L169,151l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7s0,0,0,0h0.1
			c0.2-0.4,0.5-0.8,0.8-1.1C170.7,144.4,171,144.3,171.4,144.2z"/>
		<path  d="M330.7,319.8c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,1.3,0.8,2.5,2.1,2.6c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4C330.6,319.5,330.6,319.7,330.7,319.8
			L330.7,319.8z"/>
		<path  d="M335.1,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C334,319.3,334.6,319.1,335.1,319.1z M334.3,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C333.7,325,334,325.2,334.3,325.1L334.3,325.1z"/>
		<path  d="M340.6,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5
			h-0.9c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0L340.6,319.1z"/>
		<path  d="M346,319c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.7-0.4-0.9
			c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
			c0-0.5,0.2-1,0.7-1.4C344.9,319.1,345.4,319,346,319z"/>
		<path  d="M350.4,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C349.9,324.1,350.2,324,350.4,324z"/>
		<path  d="M360.8,315c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1H361c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.9c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H357
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V320h-0.9
			c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6V319c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.3,0.6-0.6,0.9-0.9C360.2,315.1,360.5,315,360.8,315z"/>
		<path  d="M365.1,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C364,319.3,364.6,319.1,365.1,319.1z M364.3,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C363.7,325,364,325.2,364.3,325.1L364.3,325.1z"/>
		<path  d="M369.5,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H369h-0.5c0-0.1-0.1-0.2,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L369.5,317.1z"/>
		<path  d="M373.5,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L373.5,317.1z"/>
		<path  d="M378.9,320.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L378.9,320.8z
			 M377.6,317.2c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.6,0.2
			C378,317.4,377.8,317.3,377.6,317.2z"/>
		<path  d="M385.3,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H382h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C384.5,319.2,384.9,319,385.3,319z"/>
		<path  d="M393.3,324.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.3-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.8-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1l0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L393.3,324.8z M392.6,325.9
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.4,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C393.2,326,392.9,325.9,392.6,325.9z M392,319.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C392.9,319.7,392.4,319.5,392,319.5L392,319.5z"/>
		<path  d="M403.1,319.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H401c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			h0L403.1,319.1z"/>
		<path  d="M407.2,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L407.2,322z M407.2,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.1,0.5C407.2,321.4,407.2,321.4,407.2,321.4z"/>
		<path  d="M413.9,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L413.9,322z M414,321.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C414,321.4,414,321.4,414,321.4L414,321.4z"/>
		<path  d="M421.4,319.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0
			L421.4,319.1z"/>
		<path  d="M428.5,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H424c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5c0,0,0,0,0,0h0
			c0.3-0.3,0.6-0.5,1-0.7C427.7,319.1,428.1,319,428.5,319z"/>
		<path  d="M433.5,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,0.9-1.2,1.2
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C433,324,433.2,324,433.5,324z"/>
		<path  d="M340.9,335c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			l-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.7-0.4-0.9
			c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
			c0-0.5,0.2-1.1,0.7-1.4C339.8,335.1,340.3,335,340.9,335z"/>
		<path  d="M348.7,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5H349h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C347.9,335.2,348.3,335,348.7,335z"/>
		<path  d="M355.7,335.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.6-0.4c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C354.5,335.2,355.1,335.1,355.7,335.1z M354.9,341.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C354.3,341,354.6,341.2,354.9,341.1z"/>
		<path  d="M361.3,340.1c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H359c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1-0.1l-0.1,0h-0.1h-0.1
			h-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0.1,0.1,0.1,0.3,0,0.5
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0L361.3,340.1z"/>
		<path  d="M367.8,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L367.8,338z M367.9,337.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C367.9,337.4,367.9,337.4,367.9,337.4z"/>
		<path  d="M375.9,335c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0,0.4c0,0.6-0.2,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.3-0.1-0.7-0.4-0.9c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C374.7,335.1,375.3,335,375.9,335z"/>
		<path  d="M380.2,340c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C379.7,340.1,380,340,380.2,340z"/>
		<path  d="M388,335.2h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6
			s0.1-0.4,0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.4-0.3-0.7-0.3
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2l0.6,0h0.8l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c-0.5,0-0.8,0.2-0.8,0.5
			c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3l0.2,0.5l0.3,0.7l0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
			c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1h-0.3c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2l0.6,0
			c0.3,0,0.5,0,0.8,0h0.5h0.5h0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4
			c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.7l-1.7,4.3
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2h0.5h0.5L388,335.2z"/>
		<path  d="M401.3,335.1c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.2s0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C400.1,335.3,400.7,335.1,401.3,335.1z M400.5,341.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C399.9,341,400.2,341.1,400.5,341.1z"/>
		<path  d="M406.8,335.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.2-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0L406.8,335.1
			z"/>
		<path  d="M410.9,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.3-0.9,0.9-1.6,1.7-2c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L410.9,338z
			 M410.9,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.3-0.4,0.6-0.5,1
			C410.9,337.4,410.9,337.4,410.9,337.4z"/>
		<path  d="M420.6,335c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.3,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2s0.6,0.1,0.8,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2
			v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.5-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0h0
			c0.2-0.4,0.5-0.8,0.8-1.1C419.8,335.3,420.2,335.1,420.6,335z"/>
		<path  d="M111.9,319.1c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1l0,0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.2s0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C110.7,319.3,111.3,319.1,111.9,319.1z M111.1,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C110.5,325,110.8,325.1,111.1,325.1z"/>
		<path  d="M122.8,326c-0.4,0-0.8-0.1-1.2-0.2l-0.2,0l-0.2,0h-0.2H121h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8C123.8,325.9,123.3,326,122.8,326z M123.1,320
			c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C124.4,320.3,123.7,320,123.1,320z"/>
		<path  d="M131.1,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1l0,0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C129.9,319.3,130.5,319.1,131.1,319.1z M130.2,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C129.7,325,130,325.1,130.2,325.1z"/>
		<path  d="M137.4,326c-0.4,0-0.8-0.1-1.2-0.2l-0.2,0l-0.2,0h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C138.4,325.9,137.9,326,137.4,326z M137.6,320c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C138.9,320.3,138.3,320,137.6,320z"/>
		<path  d="M143.3,319.2c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.2c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6l-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0.1-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.1c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8
			c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C142.2,319.2,142.8,319.2,143.3,319.2z"/>
		<path  d="M150.1,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,0.9-1.2,1.2
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C149.7,324,149.9,324,150.1,324z"/>
		<path  d="M84.1,342c-0.4,0-0.8-0.1-1.2-0.2l-0.2,0l-0.2,0h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1l0,0
			H83c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.7,0.7-1.7,1.1-2.8,1.1L84.1,342z M84.3,336c-0.2,0-0.5,0.1-0.7,0.1
			c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4
			c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C85.6,336.3,84.9,336,84.3,336z"/>
		<path  d="M90.3,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.3,0.3,0.5,0.7,0.6,1.1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L90.3,338z
			 M90.3,337.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C90.3,337.4,90.3,337.4,90.3,337.4z"/>
		<path  d="M98.1,336.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H97h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L98.1,336.8z
			 M96.8,333.2c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
			c-0.1,0.1-0.3,0.2-0.6,0.2C97.2,333.4,97,333.3,96.8,333.2z"/>
		<path  d="M104.5,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H100c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H100c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0.1
			c0.3-0.3,0.6-0.5,1-0.7C103.7,335.2,104.1,335,104.5,335z"/>
		<path  d="M112.5,340.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.3-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.3,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.9,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L112.5,340.8z M111.8,341.9
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C112.4,342,112.1,341.9,111.8,341.9z M111.1,335.5c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C112,335.7,111.6,335.5,111.1,335.5L111.1,335.5z"/>
		<path  d="M124.3,335c0.9,0,1.7,0.4,2.2,1.1c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H120
			c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.4c0-0.3,0-0.5-0.1-0.8
			c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H120c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2l-0.1,0.3c0,0.2,0,0.3,0,0.4s0,0.1,0,0.1l0,0c0.3-0.2,0.6-0.4,0.9-0.6C123.6,335.2,123.9,335,124.3,335z
			 M123.6,335.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1c0.4-1.1,0.3-2.4-0.4-3.3C124.8,336.2,124.2,336,123.6,335.9L123.6,335.9z"/>
		<path  d="M132.5,335c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.9-0.1l-1.8,0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0
			h0.1c0.2-0.4,0.5-0.8,0.8-1.1C131.7,335.3,132.1,335.1,132.5,335z"/>
		<path  d="M135.4,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.3-0.9,0.9-1.6,1.7-2c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.3,0.3,0.5,0.7,0.6,1.1
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L135.4,338z M135.5,337.4h2.5
			c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.3-0.4,0.6-0.4,1
			C135.4,337.4,135.4,337.4,135.5,337.4z"/>
		<path  d="M145.1,340.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.8,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1l0.4,0.1l1.7-0.2
			c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1H146c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L145.1,340.8z M144.3,341.9c-0.5,0-1,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.3
			C145,342,144.7,341.9,144.3,341.9L144.3,341.9z M143.7,335.5c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4
			c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C144.6,335.7,144.2,335.5,143.7,335.5
			L143.7,335.5z"/>
		<path  d="M152.7,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0c0.3-0.3,0.6-0.5,1-0.7C151.8,335.2,152.2,335,152.7,335z"/>
		<path  d="M159.7,335.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C158.5,335.3,159.1,335.1,159.7,335.1z M158.9,341.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C158.3,341,158.6,341.1,158.9,341.1z"/>
		<path  d="M167.7,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H168h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0.1
			c0.3-0.3,0.6-0.5,1-0.7C166.9,335.2,167.3,335,167.7,335z"/>
		<path  d="M173.5,335.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0L173.5,335.1
			z"/>
		<path  d="M177.5,340c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5C177,340.1,177.3,340,177.5,340z"
			/>
		<path  d="M96.5,347c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.4-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H95v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V352h-0.9
			c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6V351c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.3,0.6-0.6,0.9-0.9C95.9,347.1,96.2,347,96.5,347z"/>
		<path  d="M100.8,351.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C99.7,351.3,100.3,351.1,100.8,351.1z M100,357.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.4,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C99.4,357,99.7,357.2,100,357.1L100,357.1z"/>
		<path  d="M105.2,349.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L105.2,349.1z"/>
		<path  d="M109.2,349.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6H108
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L109.2,349.1z"/>
		<path  d="M114.6,352.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L114.6,352.8z
			 M113.3,349.2c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c-0.1,0.1-0.3,0.2-0.6,0.2C113.7,349.4,113.5,349.3,113.3,349.2z"/>
		<path  d="M121,351c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H117h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C120.1,351.2,120.6,351,121,351z"/>
		<path  d="M129,356.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1l0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L129,356.8z M128.3,357.9
			c-0.5,0-1,0-1.4,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C128.9,358,128.6,357.9,128.3,357.9L128.3,357.9z M127.7,351.5c-0.4,0-0.8,0.2-1,0.6
			c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C128.5,351.7,128.1,351.5,127.7,351.5L127.7,351.5z"/>
		<path  d="M138.8,351.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8
			v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0h0.1L138.8,351.1z"/>
		<path  d="M142.9,354c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L142.9,354z M142.9,353.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C143,353.4,142.9,353.4,142.9,353.4L142.9,353.4z"/>
		<path  d="M149.6,354c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.3,0.3,0.5,0.7,0.6,1.1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L149.6,354z
			 M149.7,353.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C149.7,353.4,149.7,353.4,149.7,353.4z"/>
		<path  d="M157.1,351.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H155
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0l0,0L157.1,351.1z"/>
		<path  d="M164.2,351c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6H164c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3
			c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.5c0,0,0,0,0.1,0l0,0c0.3-0.3,0.6-0.5,1-0.7C163.4,351.2,163.8,351,164.2,351z"/>
		<path  d="M136.5,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C135.4,408.6,135.9,408.4,136.5,408.4z M135.7,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C135.1,414.4,135.4,414.5,135.7,414.5L135.7,414.5z"/>
		<path  d="M146.2,415.3c-0.4,0-0.8-0.1-1.2-0.2h-0.2c-0.1,0-0.1,0-0.2,0h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3
			c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1.2
			c-0.4,0.3-0.8,0.6-1.2,0.8C147.2,415.2,146.7,415.3,146.2,415.3z M146.4,409.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3
			c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1
			c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C147.7,409.6,147.1,409.3,146.4,409.3L146.4,409.3z"/>
		<path  d="M153.8,408.4c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.5-0.5-0.9-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C152.7,408.5,153.2,408.4,153.8,408.4z M153,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C152.4,414.4,152.7,414.5,153,414.5L153,414.5z"/>
		<path  d="M159.4,415.3c-0.4,0-0.8-0.1-1.2-0.2h-0.2c-0.1,0-0.1,0-0.2,0h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3
			c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1s0,0,0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.8,0.6-1.2,0.8
			C160.5,415.2,160,415.3,159.4,415.3z M159.7,409.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C160.9,409.6,160.3,409.3,159.7,409.3L159.7,409.3z"/>
		<path  d="M164.7,408.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c-0.3,0-0.3-0.1,0-0.2c0-0.2,0-0.2,0-0.2C163.7,408.5,164.2,408.6,164.7,408.6z"
			/>
		<path  d="M170.9,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C170.4,413.3,170.7,413.3,170.9,413.3z"/>
		<path  d="M179.1,415.3c-0.4,0-0.8-0.1-1.2-0.2h-0.2h-0.2h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			s0,0,0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C180.1,415.2,179.6,415.3,179.1,415.3z M179.4,409.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C180.6,409.6,180,409.3,179.4,409.3L179.4,409.3z"/>
		<path  d="M184.7,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L184.7,411.3z
			 M184.8,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C184.8,410.7,184.8,410.7,184.8,410.7L184.8,410.7z"/>
		<path  d="M191.9,410.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1
			L191.9,410.1z M190.6,406.5c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.4,0.8,0.8
			c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.6,0.2C191,406.7,190.7,406.6,190.6,406.5z"/>
		<path  d="M197.7,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5H198h-0.6c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0.1
			c0.3-0.3,0.6-0.5,1-0.7C196.9,408.5,197.3,408.4,197.7,408.4z"/>
		<path  d="M205,414.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.8,0.6-1.8,1-2.8,1c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.4,0.2-0.5
			c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.4-0.6-0.4-0.9
			c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.6
			c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.1,0,0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L205,414.1z M204.3,415.3c-0.5,0-1,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.3
			C204.9,415.3,204.6,415.3,204.3,415.3L204.3,415.3z M203.6,408.8c-0.4,0-0.8,0.2-1,0.5c-0.3,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C204.5,409,204.1,408.8,203.6,408.8L203.6,408.8z"/>
		<path  d="M215.5,408.4c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1
			c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
			c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.4c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1l-0.1,0.3
			c0,0.1,0,0.2-0.1,0.4v0.1l0,0c0.3-0.2,0.6-0.4,0.9-0.6C214.8,408.4,215.2,408.3,215.5,408.4z M214.9,409.3c-0.3,0-0.6,0.1-0.9,0.2
			c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1
			c0.2-0.4,0.2-0.8,0.2-1.2c0-0.8-0.2-1.5-0.6-2.1C216.1,409.5,215.5,409.3,214.9,409.3z"/>
		<path  d="M223.1,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C222.3,408.6,222.6,408.4,223.1,408.4z"/>
		<path  d="M225.3,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L225.3,411.3z
			 M225.4,410.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C225.4,410.7,225.4,410.7,225.4,410.7L225.4,410.7z"/>
		<path  d="M234.4,414.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.8,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.1,0,0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L234.4,414.1z M233.7,415.3c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.3C234.3,415.3,234,415.3,233.7,415.3L233.7,415.3z M233,408.8
			c-0.4,0-0.8,0.2-1,0.5c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C233.9,409,233.5,408.8,233,408.8L233,408.8z"/>
		<path  d="M241.4,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1H238h-0.6h-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.1,0.7-0.1,0.9s0,0.1,0,0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C240.5,408.5,240.9,408.4,241.4,408.4z"/>
		<path  d="M247.8,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C246.6,408.6,247.2,408.4,247.8,408.4z M246.9,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C246.4,414.4,246.6,414.5,246.9,414.5z"/>
		<path  d="M255.1,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H256h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1
			l0,0c0.3-0.3,0.6-0.5,1-0.7C254.3,408.5,254.7,408.4,255.1,408.4z"/>
		<path  d="M260.2,408.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0.1,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.2-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.1h0L260.2,408.5z"/>
		<path  d="M263.6,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C263.1,413.4,263.4,413.3,263.6,413.3z"/>
		<path  d="M147.9,424.6c0.8,0,1.6,0,2.3-0.2h0.1c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.4-0.1,0.8-0.1,1.1v3.5
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-3
			c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H145h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-4.1H144c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3
			c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-1.3,0.6-2.6,1.6-3.5c0.4-0.4,1-0.6,1.6-0.6c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.8,0.5
			c0.3,0.3,0.5,0.6,0.5,1c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6l-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2
			c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9c-0.3,0.6-0.5,1.3-0.5,2v0.8
			L147.9,424.6z"/>
		<path  d="M155.8,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s-0.1,0.6-0.1,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C155,424.6,155.4,424.4,155.8,424.4z"/>
		<path  d="M158.1,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L158.1,427.3z M158.2,426.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C158.2,426.7,158.2,426.7,158.2,426.7L158.2,426.7z"/>
		<path  d="M164.1,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C163.6,429.4,163.9,429.3,164.1,429.3z"/>
		<path  d="M174,424.4c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.6,0.6,1c0.6-0.7,1.4-1.1,2.3-1.3c0.3,0,0.6,0,0.9,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H179h-0.6h-0.6
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.7
			c0-0.3,0-0.6,0-0.9c0-0.2-0.1-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3v0.1c0,0,0,0.1,0,0.1c0,0.4,0.1,0.7,0.1,1.1v1.9
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6
			c0.1-0.7,0-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.1,0.7-0.1,0.8s0,0.1,0,0.1l0,0
			c0.4-0.3,0.8-0.6,1.2-0.8C173.4,424.3,173.7,424.3,174,424.4z"/>
		<path  d="M184.7,424.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.5-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C182.9,424.7,183.8,424.3,184.7,424.3z M184.5,424.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C185.4,425,184.9,424.8,184.5,424.9z"/>
		<path  d="M193.1,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.3-0.1,0.6-0.1,0.9v0.1h0.1c0.3-0.3,0.6-0.5,1-0.7C192.2,424.5,192.6,424.3,193.1,424.4z"/>
		<path  d="M197.4,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L197.4,427.3z
			 M197.5,426.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C197.5,426.7,197.5,426.7,197.5,426.7L197.5,426.7z"/>
		<path  d="M203.4,424.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6
			c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5
			c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8
			c0.1-0.2,0.2-0.5,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			C202.3,424.5,202.8,424.6,203.4,424.6z"/>
		<path  d="M209.6,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C209.1,429.4,209.3,429.3,209.6,429.3z"/>
		<path  d="M217.5,424.4c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C216.3,424.5,216.9,424.3,217.5,424.4z"/>
		<path  d="M224.6,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0c0.3-0.3,0.6-0.5,1-0.7C223.8,424.5,224.2,424.3,224.6,424.4z"/>
		<path  d="M231,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C229.8,424.6,230.4,424.4,231,424.4z M230.2,430.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C229.6,430.4,229.9,430.5,230.2,430.5L230.2,430.5z"/>
		<path  d="M235.9,429.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1V421
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6
			c0.5-0.3,0.9-0.6,1.3-1l0.4-0.4l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1
			c0,0-0.1,0-0.1,0h-0.1h-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2
			s0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0,0L235.9,429.4z"/>
		<path  d="M241.8,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L241.8,427.3z
			 M241.9,426.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C241.9,426.7,241.9,426.7,241.9,426.7L241.9,426.7z"/>
		<path  d="M249.2,424.4c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C248.1,424.5,248.7,424.3,249.2,424.4z"/>
		<path  d="M253,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C252.5,429.4,252.8,429.3,253,429.3z"/>
		<path  d="M184.1,440.6h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2l0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8l0.4-1.1c0.2-0.4,0.3-0.7,0.4-0.9l0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5
			c0-0.1,0-0.1,0.1-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2h0.6c0.3,0,0.5,0,0.8,0l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
			s0.1,0.2,0.1,0.3l0.2,0.5l0.3,0.7l0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0-0.1,0.1-0.2,0.1-0.4
			c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2h0.6c0.3,0,0.5,0,0.8,0h0.5h0.5
			h0.5c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.4-0.8-0.4c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2h0.5h0.5L184.1,440.6z"/>
		<path  d="M196.8,440.4c0.5,0,1,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C195.7,440.6,196.2,440.4,196.8,440.4z M195.9,446.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C195.4,446.4,195.6,446.5,195.9,446.5z"/>
		<path  d="M201.6,440.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.1h0.1L201.6,440.5z"/>
		<path  d="M205,443.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L205,443.3z
			 M205.1,442.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C205.1,442.7,205.1,442.7,205.1,442.7L205.1,442.7z"/>
		<path  d="M214.1,440.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s-0.1,0.6-0.1,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C213.3,440.6,213.7,440.4,214.1,440.4z"/>
		<path  d="M275.3,415.3c-0.4,0-0.8-0.1-1.1-0.2H274h-0.2h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0h0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C276.3,415.2,275.8,415.3,275.3,415.3z M275.6,409.3c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C276.8,409.6,276.2,409.3,275.6,409.3L275.6,409.3z"/>
		<path  d="M280.9,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L280.9,411.3z
			 M281,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C281,410.7,281,410.7,281,410.7L281,410.7z"/>
		<path  d="M288.1,410.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H287h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1L288.1,410.1z
			 M286.8,406.5c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.1,0.1,0.2,0.3,0.2,0.5
			c0,0.2-0.1,0.4-0.2,0.6C287.6,406.8,287.1,406.8,286.8,406.5z"/>
		<path  d="M293.9,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C293.1,408.5,293.5,408.4,293.9,408.4z"/>
		<path  d="M301.2,414.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.6-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L301.2,414.1z M300.5,415.3c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.7-0.3C301.1,415.3,300.8,415.3,300.5,415.3L300.5,415.3z M299.9,408.8
			c-0.4,0-0.8,0.2-1,0.5c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C300.7,409,300.3,408.8,299.9,408.8L299.9,408.8z"/>
		<path  d="M313.2,409.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-1,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C313.1,409,313.1,409.1,313.2,409.2z"/>
		<path  d="M318,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0c0.3-0.3,0.6-0.5,1-0.7
			C317.2,408.4,317.6,408.3,318,408.4z"/>
		<path  d="M324.3,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C323.2,408.6,323.7,408.4,324.3,408.4z M323.5,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C322.9,414.4,323.2,414.5,323.5,414.5z"/>
		<path  d="M329.7,408.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C328.6,408.5,329.2,408.3,329.7,408.4z"/>
		<path  d="M333.5,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L333.5,411.3z
			 M333.6,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C333.6,410.7,333.6,410.7,333.6,410.7L333.6,410.7z"/>
		<path  d="M342.2,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3v-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2
			c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1L345,415c-0.2,0-0.4,0.1-0.5,0.1l-0.5,0.1l-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.8-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,1-1.2c0.4-0.3,0.8-0.6,1.2-0.8C341.4,408.5,341.8,408.4,342.2,408.4z M341.7,409
			c-0.6,0-1.1,0.3-1.4,0.8c-0.4,0.5-0.5,1.2-0.5,1.8c0,0.7,0.2,1.4,0.6,2c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C342.5,409.1,342.1,409,341.7,409L341.7,409z"/>
		<path  d="M346.9,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C346.5,413.3,346.7,413.3,346.9,413.3z"/>
		<path  d="M279.1,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L279.1,427.3z
			 M279.1,426.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C279.2,426.7,279.1,426.7,279.1,426.7L279.1,426.7z"/>
		<path  d="M287.2,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C286,424.6,286.6,424.4,287.2,424.4z M286.3,430.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C285.8,430.4,286.1,430.5,286.3,430.5z"/>
		<path  d="M294.3,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H290
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C293.5,424.6,293.8,424.4,294.3,424.4z"/>
		<path  d="M297.4,424.5h2c0.1,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2
			c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4l0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1h0.1h0L297.4,424.5z"/>
		<path  d="M303.9,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5c0,0,0,0,0,0l0,0
			c0.3-0.3,0.6-0.5,1-0.7C303,424.4,303.4,424.3,303.9,424.4z"/>
		<path  d="M311,424.4c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.6,0.2,1,0.2c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.8-0.2,1.2v6.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-1.7-0.1H312h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.3c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.3,0.2-0.6,0.4-0.9,0.6
			c-0.3,0.2-0.7,0.3-1.1,0.3c-0.7,0-1.3-0.3-1.8-0.7c-0.2-0.2-0.4-0.5-0.6-0.8c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.1-0.6-0.1-0.9
			c0-0.4,0.1-0.8,0.2-1.2c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5C310.2,424.4,310.6,424.4,311,424.4z
			 M310.5,424.9c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.3,0.5-0.4,0.8c-0.1,0.3-0.1,0.5-0.1,0.8
			c0,0.8,0.2,1.5,0.6,2.1c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4V426c0-0.3-0.2-0.6-0.5-0.8
			C311.2,425,310.9,424.9,310.5,424.9L310.5,424.9z"/>
		<path  d="M319.1,426.2c0-0.3,0-0.6-0.1-0.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.7,0,1.4-0.1,2.1-0.3c0,0,0.1,0,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1v3.3c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1l-0.5,0.1
			c-0.2,0-0.4,0.1-0.5,0.1l-0.5,0.1l-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.7v-0.1c-0.3,0.3-0.6,0.5-1,0.7
			c-0.4,0.2-0.8,0.3-1.2,0.3c-0.6,0-1.1-0.3-1.4-0.7c-0.1-0.2-0.3-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3,0-0.6,0-0.8v-2
			c0-0.3,0-0.6-0.1-0.9c-0.1-0.2-0.4-0.3-0.9-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.7,0,1.4-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1.1v2.4c0,0.6,0.1,1.3,0.4,1.8c0.3,0.4,0.7,0.6,1.2,0.6c0.4,0,0.7-0.1,1-0.3
			c0.3-0.2,0.5-0.4,0.5-0.6L319.1,426.2z"/>
		<path  d="M324.8,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.5-0.5-0.9-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C323.7,424.5,324.2,424.4,324.8,424.4z M323.9,430.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C323.4,430.4,323.7,430.5,323.9,430.5L323.9,430.5z"/>
		<path  d="M329.7,429.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H328h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1c0,0-0.1,0-0.1,0h-0.1h-0.2
			c0-0.1-0.1-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2l1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.7-0.1-1.1-0.3c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0L329.7,429.4z"/>
		<path  d="M335.6,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L335.6,427.3z
			 M335.7,426.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C335.7,426.7,335.7,426.7,335.7,426.7L335.7,426.7z"/>
		<path  d="M341.6,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C341.1,429.4,341.4,429.3,341.6,429.3z"/>
		<path  d="M267.9,436.3c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2l-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-4.1h-0.9
			c0,0-0.1,0-0.1-0.1s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.8
			C267.3,436.4,267.6,436.3,267.9,436.3z"/>
		<path  d="M271.6,440.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C270.4,440.6,271,440.4,271.6,440.4z M270.8,446.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C270.2,446.4,270.5,446.5,270.8,446.5z"/>
		<path  d="M275.3,438.5c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L275.3,438.5z"/>
		<path  d="M278.7,438.5c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L278.7,438.5z"/>
		<path  d="M283.5,442.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1
			L283.5,442.1z M282.2,438.5c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.4,0.4-0.8,0.8-0.8c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.5
			c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2C282.5,438.7,282.3,438.6,282.2,438.5L282.2,438.5z"/>
		<path  d="M289.2,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6H289c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.1,0.7-0.1,0.9s0,0.1,0,0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C288.4,440.5,288.8,440.4,289.2,440.4z"/>
		<path  d="M296.6,446.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L296.6,446.1z M295.9,447.3c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.8,0,1.5-0.3,2-0.9c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C296.5,447.3,296.2,447.3,295.9,447.3L295.9,447.3z M295.2,440.8c-0.4,0-0.8,0.2-1,0.5
			c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C296.1,441,295.7,440.8,295.2,440.8L295.2,440.8z"/>
		<path  d="M305,440.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H305v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1s0-0.1,0.2-0.2l0.2-0.3
			h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.2l0,0L305,440.5z"/>
		<path  d="M308.5,443.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
			c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L308.5,443.3z M308.6,442.7h2.5
			c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C308.6,442.7,308.6,442.7,308.6,442.7L308.6,442.7z"/>
		<path  d="M314.6,443.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L314.6,443.3z
			 M314.7,442.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C314.7,442.7,314.7,442.7,314.7,442.7L314.7,442.7z"/>
		<path  d="M321.4,440.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.1h0L321.4,440.5z"/>
		<path  d="M328,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V437
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5 M325.8,441.4
			L325.8,441.4c0.3-0.3,0.7-0.5,1-0.7c0.4-0.2,0.8-0.3,1.2-0.3L325.8,441.4z"/>
		<path  d="M332.2,445.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2l0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5C331.7,445.4,332,445.3,332.2,445.3z"/>
		<path  d="M341.6,440.6c0.8,0,1.6,0,2.3-0.2h0.1c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.4-0.1,0.8-0.1,1.1v3.5
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-3
			c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-4.1h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5
			v-0.2c0-1.3,0.6-2.6,1.6-3.5c0.4-0.4,1-0.6,1.6-0.6c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.8,0.5c0.3,0.3,0.5,0.6,0.5,1
			c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6l-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1-0.1-0.2
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9c-0.3,0.6-0.5,1.3-0.5,2v0.8L341.6,440.6z"/>
		<path  d="M349.5,440.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C348.7,440.6,349.1,440.4,349.5,440.4z"/>
		<path  d="M351.8,443.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L351.8,443.3z M351.9,442.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C351.9,442.7,351.9,442.7,351.9,442.7L351.9,442.7z"/>
      </g>
      <g id='fr' style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path  d="M306,129.3c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1l-0.3-0.3l-0.3-0.2
			c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-1,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9c-0.2,1.3,0.8,2.5,2.1,2.6
			c0.2,0,0.3,0,0.5,0c0.3,0,0.7-0.1,1-0.2c0.2-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.6-0.4
			c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2C305.9,129.1,306,129.2,306,129.3z"/>
		<path  d="M310.4,128.5c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l1-0.5l1.1-0.4c0.2-0.1,0.4-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3
			v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C309.3,128.6,309.9,128.5,310.4,128.5z M309.6,134.6c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C309,134.4,309.3,134.6,309.6,134.6L309.6,134.6z"/>
		<path  d="M315.9,128.5h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.2-0.5-0.1-0.8v-4.5h-0.9
			c0,0-0.1-0.1-0.1-0.1s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0
			l0,0L315.9,128.5z"/>
		<path  d="M321.3,128.5c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0.1,0,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.2-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C320.2,128.6,320.7,128.4,321.3,128.5z"/>
		<path  d="M325.7,133.4c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C325.2,133.5,325.4,133.4,325.7,133.4z"/>
		<path  d="M336.1,124.4c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.8c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6
			c0.2-0.5,0.4-0.9,0.7-1.3c0.2-0.3,0.5-0.6,0.9-0.8C335.5,124.4,335.8,124.4,336.1,124.4z"/>
		<path  d="M340.4,128.5c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l1-0.5l1.1-0.4c0.2-0.1,0.4-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3
			v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C339.3,128.6,339.9,128.5,340.4,128.5z M339.6,134.6c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C339,134.4,339.3,134.6,339.6,134.6L339.6,134.6z"/>
		<path  d="M344.8,126.6c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L344.8,126.6z"/>
		<path  d="M348.8,126.6c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c-0.1-0.1-0.1-0.3,0-0.5c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L348.8,126.6z"/>
		<path  d="M354.2,130.2v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.1,0.3,0,0.5
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1L354.2,130.2z
			 M352.9,126.6c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.5,0.2c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
			c-0.1,0.1-0.3,0.2-0.5,0.2C353.3,126.8,353.1,126.7,352.9,126.6z"/>
		<path  d="M360.6,128.4c0.3,0,0.6,0,0.9,0.1c0.5,0.1,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.1,0.3,0,0.5l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0c0.3-0.3,0.6-0.5,1-0.8
			C359.7,128.5,360.2,128.4,360.6,128.4z"/>
		<path  d="M368.6,134.2c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.8,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1
			c0.1,0.1,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1
			c0,0-0.1,0-0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7
			c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.3,0-0.4-0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L368.6,134.2z M367.9,135.3c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6
			c0,0.5,0.2,0.9,0.5,1.3c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.4-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8
			c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.3C368.5,135.4,368.2,135.3,367.9,135.3z M367.3,128.9
			c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C368.1,129.1,367.7,128.9,367.3,128.9L367.3,128.9z"/>
		<path  d="M378.4,128.5h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1
			c0,0,0.1,0,0.1,0h0L378.4,128.5z"/>
		<path  d="M382.5,131.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2l-2,0.1
			L382.5,131.4z M382.5,130.8h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.6-0.6-1-0.6
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C382.5,130.8,382.5,130.8,382.5,130.8L382.5,130.8z"/>
		<path  d="M389.2,131.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.6
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2l-2,0.1L389.2,131.4z M389.3,130.8h2.5
			c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C389.3,130.8,389.2,130.8,389.3,130.8L389.3,130.8z"/>
		<path  d="M396.7,128.5h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2
			c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0h0L396.7,128.5z"/>
		<path  d="M403.8,128.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c-0.1-0.1-0.1-0.3,0-0.5c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c-0.1-0.1-0.1-0.3,0-0.5c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0c0,0,0,0,0,0c0.3-0.3,0.6-0.5,1-0.8C403,128.5,403.4,128.4,403.8,128.4z"/>
		<path  d="M408.8,133.4c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C408.3,133.4,408.5,133.4,408.8,133.4z"/>
		<path  d="M316.2,144.5c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0.1,0,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3l-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C315.1,144.6,315.6,144.4,316.2,144.5z"/>
		<path  d="M324,144.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1
			l0,0c0.3-0.3,0.6-0.5,1-0.8C323.2,144.5,323.6,144.4,324,144.4z"/>
		<path  d="M331,144.5c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l0.9-0.3l1.1-0.4c0.2-0.1,0.4-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C329.8,144.7,330.4,144.5,331,144.5z M330.2,150.6c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C329.6,150.4,329.9,150.6,330.2,150.6L330.2,150.6z"/>
		<path  d="M336.6,149.5c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1-0.1L339,145h-0.1h-0.1h-0.2
			c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.1-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9l1.1,1.2c0.5,0.5,0.8,0.9,1.1,1.2c0.2,0.2,0.4,0.4,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4l-0.1-0.1l-0.1-0.1l0,0L336.6,149.5z"/>
		<path  d="M343.1,147.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.6
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2l-2,0.1L343.1,147.4z M343.2,146.8h2.5
			c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C343.2,146.8,343.2,146.8,343.2,146.8L343.2,146.8z"/>
		<path  d="M351.1,144.5c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0.1,0,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C350,144.6,350.6,144.4,351.1,144.5z"/>
		<path  d="M355.5,149.4c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C355,149.4,355.3,149.4,355.5,149.4z"/>
		<path  d="M363.3,144.6h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2l0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1l0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
			s0.1-0.4,0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.2-0.4-0.3-0.7-0.3
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6c0.3,0,0.5,0,0.8,0l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.5,0-0.8,0.2-0.8,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5l0.3,0.7c0.1,0.2,0.2,0.6,0.4,1l0.5,1.2
			c0.8-1.9,1.2-3.1,1.4-3.5c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1c-0.1,0-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2h0.6c0.2,0,0.5,0,0.8,0h0.6h0.5h0.5c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1
			c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5
			l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.4-0.9-0.4
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2h0.5h0.5L363.3,144.6z"/>
		<path  d="M376.6,144.5c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1
			c0,0,0.1,0,0.1-0.1h0.1c0,0,0,0,0,0l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4
			c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l0.9-0.3l1.1-0.4
			c0.2-0.1,0.4-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5
			c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9C375.4,144.7,376,144.5,376.6,144.5z M375.8,150.6
			c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5
			c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C375.2,150.4,375.5,150.6,375.8,150.6L375.8,150.6z"/>
		<path  d="M382.1,144.5h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H380
			c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4l0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1h0h0
			L382.1,144.5z"/>
		<path  d="M386.2,147.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2l-2,0.1
			L386.2,147.4z M386.2,146.8h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.2,0.5C386.2,146.8,386.2,146.8,386.2,146.8z"/>
		<path  d="M395.9,144.5c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0L396,146l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1c-0.2,0.2-0.4,0.5-0.4,0.9
			v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1s-0.7,0-1.8,0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7s0,0.6,0,0.7s0,0,0,0.1
			l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C395.1,144.7,395.5,144.5,395.9,144.5z"/>
		<path  d="M120,124.2c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.1
			c-0.3,0-0.5,0.1-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.8c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V129h-0.9
			c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.3,0.6-0.6,0.9-0.8C119.4,124.2,119.7,124.1,120,124.2z"/>
		<path  d="M124.3,128.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1l0,0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C123.1,128.5,123.7,128.3,124.3,128.2z M123.5,134.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C122.9,134.2,123.2,134.3,123.5,134.3z"/>
		<path  d="M128.7,126.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.6-0.1h-0.5h-0.6h-0.6
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V126.3z"/>
		<path  d="M132.7,126.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L132.7,126.3
			z"/>
		<path  d="M138.1,130v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H137h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1
			L138.1,130z M136.8,126.4c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.6,0.2C137.2,126.6,137,126.5,136.8,126.4L136.8,126.4z"/>
		<path  d="M144.5,128.2c0.3,0,0.6,0,0.9,0.1c0.5,0.2,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0.1,0.7,0.1,1.2v1.7
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.7
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H140c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1l0,0c0.3-0.3,0.6-0.5,1-0.7
			C143.7,128.4,144.1,128.2,144.5,128.2z"/>
		<path  d="M152.5,134c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,0.9
			c-0.8,0.6-1.8,1-2.8,1c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.4,0.2-0.5
			c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.4-0.6-0.4-0.9
			c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.6
			c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.1,0.1,0.1,0.2
			c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L152.5,134z M151.8,135.1c-0.5,0-1,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5c0.8,0,1.5-0.3,2-0.9
			c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.3
			C152.4,135.1,152.1,135.1,151.8,135.1z M151.2,128.7c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4
			c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C152,128.9,151.6,128.6,151.2,128.7
			L151.2,128.7z"/>
		<path  d="M162.3,128.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.3
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.2-0.5-0.1-0.8
			v-4.5h-0.9c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0l0,0L162.3,128.3z"/>
		<path  d="M166.4,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L166.4,131.2z M166.4,130.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.4,1
			C166.4,130.5,166.4,130.6,166.4,130.6L166.4,130.6z"/>
		<path  d="M173.1,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L173.1,131.2z
			 M173.2,130.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.4,1
			C173.2,130.5,173.1,130.6,173.2,130.6L173.2,130.6z"/>
		<path  d="M180.6,128.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.3
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5
			h-0.9c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0l0,0L180.6,128.3z"/>
		<path  d="M187.7,128.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H188h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5c0,0,0,0,0.1,0l0,0
			c0.3-0.3,0.6-0.5,1-0.7C186.9,128.3,187.3,128.2,187.7,128.2z"/>
		<path  d="M192.6,133.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C192.2,133.2,192.4,133.1,192.6,133.1z"/>
		<path  d="M116,144.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.7-0.1l-0.6-0.1c-0.1-0.3-0.2-0.5-0.2-0.8
			c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
			c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4
			C114.8,144.3,115.4,144.2,116,144.2z"/>
		<path  d="M123.8,144.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5H124h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.6,0h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C123,144.4,123.3,144.2,123.8,144.2z"/>
		<path  d="M130.8,144.2c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C129.6,144.5,130.2,144.3,130.8,144.2z M130,150.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C129.4,150.2,129.7,150.3,130,150.3L130,150.3z"/>
		<path  d="M136.4,149.2c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6
			c0.5-0.3,0.9-0.6,1.3-0.9l0.5-0.4l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l0,0h-0.1h-0.1h-0.1h-0.2
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2
			c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.3-2.6l-0.1-0.1l-0.1-0.1l0,0L136.4,149.2z"/>
		<path  d="M142.9,147.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-1,0.4-1.9,1-2.6
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L142.9,147.2z M143,146.6h2.6
			c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.1,0.5
			C143,146.5,143,146.6,143,146.6L143,146.6z"/>
		<path  d="M150.9,144.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C149.8,144.4,150.4,144.2,150.9,144.2z"/>
		<path  d="M155.3,149.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2l0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5C154.8,149.2,155.1,149.1,155.3,149.1z"/>
		<path  d="M163.1,144.4h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2l0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8l0.4-1.1l0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6l0.2-0.5c0-0.1,0-0.1,0.1-0.2
			c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6h0.8
			l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0.1,0,0.1,0,0.2l0.1,0.3c0,0.1,0.1,0.3,0.2,0.5l0.3,0.7
			c0.1,0.2,0.2,0.6,0.4,1l0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
			l-0.3-0.1h-0.3c0,0,0-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6h0.8h0.5h0.5h0.5c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1
			c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5
			l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.9-0.3
			c0-0.2,0-0.3,0-0.5h0.5h0.5L163.1,144.4z"/>
		<path  d="M176.4,144.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C175.2,144.5,175.8,144.3,176.4,144.2z M175.5,150.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C175,150.2,175.3,150.3,175.5,150.3L175.5,150.3z"/>
		<path  d="M181.9,144.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.3
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5
			h-0.9c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1
			c0,0,0.1,0,0.1,0h0.1L181.9,144.3z"/>
		<path  d="M186,147.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L186,147.2z M186.1,146.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C186,146.5,186,146.6,186.1,146.6L186.1,146.6z"/>
		<path  d="M195.7,144.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.9-0.1l-1.8,0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s-0.1,0.7-0.1,0.7s0,0,0,0
			l0,0c0.2-0.4,0.5-0.8,0.8-1.1C194.9,144.5,195.3,144.2,195.7,144.2z"/>
		<path  d="M96.5,315c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.4-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H95v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V320h-0.9
			c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6V319c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.3,0.6-0.6,0.9-0.9C95.9,315.1,96.2,315,96.5,315z"/>
		<path  d="M100.8,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C99.7,319.3,100.3,319.1,100.8,319.1z M100,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.4,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C99.4,325,99.7,325.2,100,325.1L100,325.1z"/>
		<path  d="M105.2,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L105.2,317.1z"/>
		<path  d="M109.2,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L109.2,317.1z"/>
		<path  d="M114.6,320.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L114.6,320.8z
			 M113.3,317.2c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c-0.1,0.1-0.3,0.2-0.6,0.2C113.7,317.4,113.5,317.3,113.3,317.2z"/>
		<path  d="M121,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H117h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C120.1,319.2,120.6,319,121,319z"/>
		<path  d="M129,324.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1l0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L129,324.8z M128.3,325.9
			c-0.5,0-1,0-1.4,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C128.9,326,128.6,325.9,128.3,325.9L128.3,325.9z M127.7,319.5c-0.4,0-0.8,0.2-1,0.6
			c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C128.5,319.7,128.1,319.5,127.7,319.5L127.7,319.5z"/>
		<path  d="M138.8,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8
			v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0h0.1L138.8,319.1z"/>
		<path  d="M142.9,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L142.9,322z M142.9,321.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C143,321.4,142.9,321.4,142.9,321.4L142.9,321.4z"/>
		<path  d="M149.6,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.3,0.3,0.5,0.7,0.6,1.1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L149.6,322z
			 M149.7,321.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
			C149.7,321.4,149.7,321.4,149.7,321.4z"/>
		<path  d="M157.1,319.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H155
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0l0,0L157.1,319.1z"/>
		<path  d="M164.2,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6H164c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3
			c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.5c0,0,0,0,0.1,0l0,0c0.3-0.3,0.6-0.5,1-0.7C163.4,319.2,163.8,319,164.2,319z"/>
		<path  d="M160,408.4c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C158.9,408.5,159.4,408.3,160,408.4z"/>
		<path  d="M167.8,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1
			l0,0c0.3-0.3,0.6-0.5,1-0.7C166.9,408.5,167.3,408.4,167.8,408.4z"/>
		<path  d="M174.8,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.5-0.5-0.9-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C173.7,408.6,174.2,408.4,174.8,408.4z M173.9,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C173.4,414.4,173.7,414.5,173.9,414.5L173.9,414.5z"/>
		<path  d="M180.4,413.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V405c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1c0,0-0.1,0-0.1,0h-0.1h-0.2
			c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2l1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4l-0.1-0.1l-0.1-0.1l0,0L180.4,413.4z"/>
		<path  d="M186.9,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L186.9,411.3z
			 M187,410.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C187,410.7,187,410.7,187,410.7L187,410.7z"/>
		<path  d="M194.9,408.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C193.8,408.5,194.4,408.3,194.9,408.4z"/>
		<path  d="M199.3,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C198.8,413.4,199.1,413.3,199.3,413.3z"/>
		<path  d="M207.1,408.6h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1l0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
			s0.1-0.4,0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.2-0.4-0.3-0.7-0.3
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6c0.3,0,0.5,0,0.8,0l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1-0.1,0.2
			c-0.5,0-0.8,0.2-0.8,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5l0.3,0.7c0.1,0.2,0.2,0.6,0.4,1l0.5,1.2
			c0.8-1.9,1.2-3.1,1.4-3.5c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1h-0.3
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6c0.2,0,0.5,0,0.8,0h0.5h0.5h0.5c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2
			c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1
			c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.4-0.9-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2h0.5h0.5L207.1,408.6z"/>
		<path  d="M220.4,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C219.2,408.6,219.8,408.4,220.4,408.4z M219.5,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C219,414.4,219.3,414.5,219.5,414.5z"/>
		<path  d="M225.9,408.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.4,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1h0.1h0.1
			L225.9,408.5z"/>
		<path  d="M230,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
			c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L230,411.3z M230.1,410.7h2.5
			c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C230,410.7,230,410.7,230.1,410.7L230.1,410.7z"/>
		<path  d="M239.7,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C238.9,408.6,239.3,408.4,239.7,408.4z"/>
		<path  d="M276.8,404.3c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1H277c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			H273c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-4.1h-0.9
			c0,0-0.1,0-0.1-0.1s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.8
			C276.2,404.4,276.5,404.3,276.8,404.3z"/>
		<path  d="M281.2,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C280,408.6,280.6,408.4,281.2,408.4z M280.3,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C279.8,414.4,280.1,414.5,280.3,414.5z"/>
		<path  d="M285.5,406.5c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H285h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L285.5,406.5z"/>
		<path  d="M289.5,406.5c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H289h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L289.5,406.5z"/>
		<path  d="M295,410.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1V410.1z M293.7,406.5
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.4,0.4-0.8,0.8-0.8c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.4,0.2-0.6,0.2C294.1,406.7,293.8,406.6,293.7,406.5L293.7,406.5z"/>
		<path  d="M301.4,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H298h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.1,0.7-0.1,0.9s0,0.1,0,0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C300.5,408.5,300.9,408.3,301.4,408.4z"/>
		<path  d="M309.4,414.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L309.4,414.1z M308.6,415.3c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.8,0,1.5-0.3,2-0.9c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C309.3,415.3,309,415.3,308.6,415.3L308.6,415.3z M308,408.8c-0.4,0-0.8,0.2-1,0.5
			c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C308.9,409,308.5,408.8,308,408.8L308,408.8z"/>
		<path  d="M319.1,408.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0.1,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H317c-0.1,0-0.1,0-0.1-0.1s0-0.1,0.2-0.2
			l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.1l0,0L319.1,408.5z"/>
		<path  d="M323.2,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
			c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L323.2,411.3z M323.3,410.7h2.5
			c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C323.3,410.7,323.3,410.7,323.3,410.7L323.3,410.7z"/>
		<path  d="M329.9,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L329.9,411.3z
			 M330,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C330,410.7,330,410.7,330,410.7L330,410.7z"/>
		<path  d="M337.4,408.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.2-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.1h0L337.4,408.5z"/>
		<path  d="M344.6,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V405
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5 M342.4,409.4
			L342.4,409.4c0.3-0.3,0.7-0.5,1-0.7c0.4-0.2,0.8-0.3,1.2-0.3L342.4,409.4z"/>
      </g>
      <g id='jp' style={{ opacity: selected === 'JP' ? 1 : 0 }}>
      <path d="M222.8,98.9c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,1.3,0.8,2.5,2.1,2.6c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-1.1,0-2.2-0.6-2.8-1.6c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2C222.7,98.7,222.8,98.8,222.8,98.9z"/>
		<path d="M227.4,98.1c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C225.7,98.4,226.5,98,227.4,98.1z M227.3,98.6
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C228.1,98.8,227.7,98.6,227.3,98.6z"/>
		<path d="M237.4,98.9c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			c0.1-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4
			c0.1,0.1,0.1,0.1,0.1,0.2C237.3,98.8,237.3,98.8,237.4,98.9z"/>
		<path d="M240.6,103.1c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6
			c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4l0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1
			l-0.1-0.1H243h-0.1h-0.1h-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2
			c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4c0,0-0.1-0.1-0.1-0.1l-0.1-0.1l0,0L240.6,103.1
			z"/>
		<path d="M250.9,98.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s-0.1,0.7-0.1,0.7
			c0,0,0,0,0,0.1l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C250,98.4,250.4,98.1,250.9,98.1z"/>
		<path d="M256,98.1c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C254.2,98.4,255.1,98,256,98.1z M255.8,98.6
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.2,0.5,0.4,0.9,0.7,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.3-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C256.7,98.7,256.3,98.6,255.8,98.6L255.8,98.6z"/>
		<path d="M263.8,98.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0l0,0
			c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C262.6,98.4,263.2,98.2,263.8,98.1z M262.9,104.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.4,0.4-0.3,1.1,0,1.5C262.4,104.1,262.6,104.2,262.9,104.2z"/>
		<path d="M272.7,98.9c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			c0.1-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4
			C272.5,98.6,272.6,98.8,272.7,98.9L272.7,98.9z"/>
		<path d="M278.1,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H279h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0l0,0c0.3-0.3,0.6-0.5,1-0.7
			C277.3,98.2,277.7,98.1,278.1,98.1z"/>
		<path d="M283.1,101.1c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L283.1,101.1z M283.2,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.3-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.1,0.5C283.2,100.4,283.2,100.5,283.2,100.5z"/>
		<path d="M291.2,98.1c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.7-0.4-0.9
			c-0.1-0.1-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4
			C290,98.2,290.6,98.1,291.2,98.1z"/>
		<path d="M316.4,119.7c-0.4,0-0.8-0.1-1.2-0.2h-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1v-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.2c0,0,0,0,0,0.1
			l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,1-0.4,1.9-1.1,2.6c-0.3,0.4-0.8,0.6-1.2,0.8C317.4,119.5,316.9,119.7,316.4,119.7z M316.7,113.7
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C317.9,113.9,317.3,113.6,316.7,113.7
			L316.7,113.7z"/>
		<path d="M322.6,115.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L322.6,115.7z M322.7,115.1h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C322.7,115.1,322.7,115.1,322.7,115.1L322.7,115.1z"/>
		<path d="M330.4,114.5v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V114.5z M329.2,110.9
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.4,0.4-0.8,0.8-0.8c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.6c0,0.4-0.3,0.8-0.7,0.8
			c0,0,0,0-0.1,0C329.5,111.1,329.3,111,329.2,110.9z"/>
		<path d="M336.9,112.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9s0,0.1,0,0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C336,112.9,336.4,112.8,336.9,112.7z"/>
		<path d="M344.8,118.5c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6l0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2
			L344.8,118.5z M344.1,119.6c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,1,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.3C344.8,119.7,344.4,119.6,344.1,119.6z M343.5,113.2c-0.4,0-0.8,0.2-1,0.6
			c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.6c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C344.4,113.4,343.9,113.2,343.5,113.2z"/>
		<path d="M358.1,113.5c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.4c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C358,113.4,358,113.5,358.1,113.5z"/>
		<path d="M363.5,112.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H359c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0c0.3-0.3,0.6-0.5,1-0.7
			C362.7,112.9,363.1,112.7,363.5,112.7z"/>
		<path d="M370.5,112.8c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C369.4,113.1,369.9,112.8,370.5,112.8z M369.7,118.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C369.1,118.7,369.4,118.9,369.7,118.9z"/>
		<path d="M376.6,112.7c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.6
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3l-0.5-0.3l-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C375.4,112.9,376,112.7,376.6,112.7z"/>
		<path d="M381,115.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L381,115.7z M381.1,115.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.1,0.5C381.1,115.1,381.1,115.1,381.1,115.1L381.1,115.1z"/>
		<path d="M390.3,112.7c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0,0.5,0.1c0,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2
			c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1s-0.3,0-0.5,0.1c-0.2,0-0.4,0.1-0.5,0.1l-0.5,0.1l-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.9-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,1-1.2c0.4-0.3,0.8-0.6,1.2-0.8C389.5,112.8,389.9,112.7,390.3,112.7z M389.8,113.4
			c-0.6,0-1.1,0.3-1.4,0.8c-0.4,0.5-0.5,1.2-0.5,1.8c0,0.7,0.2,1.4,0.6,2c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.3-0.5-0.5C390.6,113.5,390.2,113.4,389.8,113.4z"/>
		<path d="M395.7,117.6c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C395.2,117.7,395.5,117.6,395.7,117.6z"/>
		<path d="M320.8,131.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0.1-0.5,0.2-1,0.5-1.4
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L320.8,131.7z M320.9,131.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.1,0.5C320.9,131.1,320.9,131.1,320.9,131.1z"/>
		<path d="M329.5,128.8c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1l0.1-0.1
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3V131
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C328.4,129,328.9,128.8,329.5,128.8z M328.7,134.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C328.1,134.7,328.4,134.9,328.7,134.9z"/>
		<path d="M337.2,128.7c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2-0.1l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1-0.1-1.7-0.1-1.9-0.1l-1.8,0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H333
			c-0.1-0.1-0.1-0.3,0-0.4c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7s0,0.6,0,0.7s0,0,0,0
			l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C336.5,129,336.8,128.8,337.2,128.7z"/>
		<path d="M341,128.8h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5H341v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1-0.1-0.1-0.1s0-0.1,0.2-0.2
			l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.2l0,0V128.8z"/>
		<path d="M348.2,128.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H349h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2
			v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3
			v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.9
			c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0c0.3-0.3,0.6-0.5,1-0.7
			C347.3,128.9,347.7,128.7,348.2,128.7z"/>
		<path d="M355.9,128.7c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.6,0.2,0.9,0.2c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.8-0.2,1.2v6.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V135c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c-0.3,0.2-0.6,0.4-0.9,0.6
			c-0.3,0.2-0.7,0.3-1.1,0.3c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.6-0.8c-0.3-0.5-0.5-1.1-0.5-1.7
			c0-0.4,0.1-0.8,0.2-1.2c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5
			C355.2,128.8,355.5,128.8,355.9,128.7z M355.4,129.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.4-0.7,0.7c-0.1,0.3-0.3,0.5-0.4,0.8
			c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.8,0.2,1.5,0.7,2.1c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-3.7
			c0-0.3-0.2-0.6-0.5-0.8C356.1,129.4,355.8,129.3,355.4,129.3z"/>
		<path d="M364.6,130.6c0-0.3,0-0.6-0.1-0.9c-0.1-0.2-0.4-0.3-0.9-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.7,0,1.4-0.1,2.1-0.3c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1v3.3c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1s-0.3,0-0.5,0.1
			c-0.2,0-0.4,0.1-0.5,0.1l-0.5,0.1l-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.7v-0.1c-0.3,0.3-0.6,0.5-1,0.7
			c-0.4,0.2-0.8,0.3-1.2,0.3c-0.6,0-1.1-0.2-1.5-0.6c-0.1-0.2-0.3-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3,0-0.6,0-0.8v-2
			c0-0.3,0-0.6-0.1-0.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.7,0,1.4-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,1.1v2.3c0,0.6,0.1,1.3,0.4,1.8c0.3,0.4,0.7,0.6,1.2,0.6c0.4,0,0.7-0.1,1-0.3
			c0.3-0.2,0.5-0.4,0.5-0.6L364.6,130.6z"/>
		<path d="M371,128.8c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1l0.1-0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3V131c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C369.8,129,370.4,128.8,371,128.8z M370.2,134.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.4,0.4-0.3,1.1,0,1.5C369.6,134.7,369.9,134.9,370.2,134.9L370.2,134.9z"/>
		<path d="M376.6,133.8c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1H379h-0.1h-0.4c0-0.1,0-0.2,0-0.3
			c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-0.2,0-0.4,0-0.6,0.1
			c-0.2,0-0.4,0.1-0.6,0.3c-1.1,0.8-2,1.5-2.5,1.9l1.1,1.2c0.5,0.5,0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3
			c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
			c-0.3-0.1-0.6-0.2-0.8-0.4l-2.2-2.4c0,0-0.1,0-0.1-0.1l-0.1-0.1l0,0V133.8z"/>
		<path d="M383.1,131.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L383.1,131.7z
			 M383.2,131.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C383.2,131.1,383.2,131.1,383.2,131.1z"/>
		<path d="M389.7,133.6c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C389.3,133.7,389.5,133.7,389.7,133.6z"/>
		<path d="M307.4,140.7c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v2h1.8c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.7v4.1c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-4.1h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-0.3c0-1,0.4-2.1,1-2.9c0.2-0.3,0.5-0.6,0.9-0.8
			C306.8,140.8,307.1,140.7,307.4,140.7z"/>
		<path d="M311.7,144.8c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1l0.1-0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3V147c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C310.5,145,311.1,144.8,311.7,144.8z M310.9,150.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C310.3,150.7,310.6,150.9,310.9,150.9z"/>
		<path d="M316.1,142.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6H315c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L316.1,142.9z"/>
		<path d="M320.1,142.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L320.1,142.9z"/>
		<path d="M325.5,146.5v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c-0.1-0.1-0.1-0.3,0-0.4
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1L325.5,146.5z M324.2,142.9
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.3-0.2,0.6-0.2c0.4,0,0.8,0.4,0.8,0.8c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.3,0.2-0.5,0.2C324.6,143.1,324.4,143,324.2,142.9z"/>
		<path d="M331.9,144.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2
			v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3
			v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8
			c0-0.3,0-0.6-0.1-0.9c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c-0.1-0.1-0.1-0.3,0-0.4c0.8-0.1,1.5-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0c0.3-0.3,0.6-0.5,1-0.7
			C331.1,144.9,331.5,144.8,331.9,144.7z"/>
		<path d="M339.9,150.5c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.8,0.6-1.8,1-2.9,1c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.4,0.2-0.5
			c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.4-0.6-0.4-0.9
			c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7
			c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L339.9,150.5z M339.2,151.6
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,1,0.5,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C339.8,151.7,339.5,151.6,339.2,151.6z M338.5,145.2c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.6c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C339.4,145.4,339,145.2,338.5,145.2z"/>
		<path d="M349.6,144.8h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c0,0-0.1-0.1-0.1-0.1s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0
			l0,0L349.6,144.8z"/>
		<path d="M353.7,147.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L353.7,147.7z
			 M353.8,147.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C353.8,147.1,353.8,147.1,353.8,147.1L353.8,147.1z"/>
		<path d="M360.5,147.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L360.5,147.7z
			 M360.5,147.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C360.5,147.1,360.5,147.1,360.5,147.1L360.5,147.1z"/>
		<path d="M368,144.8h2c0.1,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5H368v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1-0.1-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4l0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.1
			L368,144.8z"/>
		<path d="M375.1,144.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H376h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0l0,0c0.3-0.3,0.6-0.5,1-0.7
			C374.2,144.8,374.7,144.7,375.1,144.7z"/>
		<path d="M380,149.6c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C379.5,149.7,379.8,149.6,380,149.6z"/>
		<path d="M390.7,145c0.8,0,1.6,0,2.3-0.2h0.1c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1H392h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9
			c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-4.1h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2
			c0-0.6,0.2-1.3,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6c0.4-0.4,1-0.6,1.6-0.6c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.5
			c0.3,0.2,0.5,0.6,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1-0.1-0.1-0.1-0.2
			c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9
			c-0.3,0.6-0.5,1.3-0.5,2v0.9H390.7z"/>
		<path d="M399.2,144.7c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2-0.1l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1-0.1-1.7-0.1-1.9-0.1l-1.8,0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H395
			c-0.1-0.1-0.1-0.3,0-0.4c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7s0,0.6,0,0.7s0,0,0,0
			l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C398.5,145,398.8,144.8,399.2,144.7z"/>
		<path d="M402.2,147.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L402.2,147.7z
			 M402.2,147.1h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C402.2,147.1,402.2,147.1,402.2,147.1L402.2,147.1z"/>
		<path d="M117.4,151.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.8,0,1.6,0.4,2.1,1.1c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C118.4,151,117.9,151.1,117.4,151.2z M117.7,145.2c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.3
			c0-0.8-0.2-1.5-0.6-2.1C119,145.4,118.3,145.1,117.7,145.2L117.7,145.2z"/>
		<path d="M123.7,147.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-1,0.4-1.9,1-2.6
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.6
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L123.7,147.2z
			 M123.7,146.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C123.7,146.5,123.7,146.6,123.7,146.6L123.7,146.6z"/>
		<path d="M131.5,146v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V146z
			 M130.2,142.4c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c-0.1,0.1-0.3,0.2-0.6,0.2C130.5,142.6,130.3,142.5,130.2,142.4L130.2,142.4z"/>
		<path d="M137.9,144.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.6-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H134h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C137.1,144.4,137.5,144.2,137.9,144.2z"/>
		<path d="M145.9,150c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,0.9
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L145.9,150z M145.1,151.1
			c-0.5,0-1,0-1.4,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C145.8,151.1,145.5,151.1,145.1,151.1z M144.5,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C145.4,144.9,145,144.6,144.5,144.7z"/>
		<path d="M159.1,145c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,1.3,0.8,2.5,2.1,2.6c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4C158.9,144.7,159,144.9,159.1,145
			L159.1,145z"/>
		<path d="M164.6,144.2c0.3,0,0.6,0,0.9,0.1c0.5,0.2,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2L166,151h-0.5h-0.6
			h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.9
			c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0.1c0.3-0.3,0.6-0.5,1-0.7
			C163.7,144.3,164.1,144.2,164.6,144.2z"/>
		<path d="M171.6,144.2c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C170.4,144.5,171,144.3,171.6,144.2z M170.7,150.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C170.2,150.2,170.4,150.3,170.7,150.3L170.7,150.3z"/>
		<path d="M177.6,144.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.2,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.4,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3s-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C176.4,144.4,177,144.2,177.6,144.2z"/>
		<path d="M182,147.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-1,0.4-1.9,1-2.6
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.6
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L182,147.2z
			 M182.1,146.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.1,0.5
			C182.1,146.5,182.1,146.6,182.1,146.6L182.1,146.6z"/>
		<path d="M191.4,144.2c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.5,0.1c0,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2
			c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1l-0.5,0.1l-0.6,0.1c-0.2,0-0.3,0.1-0.5,0.1l-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.6
			c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.4c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.8-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C190.5,144.2,190.9,144.2,191.4,144.2z M190.9,144.9
			c-0.6,0-1.1,0.3-1.4,0.8c-0.7,1.2-0.7,2.6,0.1,3.8c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5C191.6,144.9,191.2,144.8,190.9,144.9z"/>
		<path d="M114.4,315c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.8c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V320h-0.9
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6V319c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.3,0.5-0.6,0.9-0.9C113.8,315.1,114.1,315,114.4,315z"/>
		<path d="M118.8,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C117.6,319.3,118.2,319.1,118.8,319.1z M118,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			l-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C117.4,325,117.7,325.2,118,325.1L118,325.1z"
			/>
		<path d="M123.2,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6H122
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L123.2,317.1z"/>
		<path d="M127.1,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			H126c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L127.1,317.1z"/>
		<path d="M132.6,320.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.1,0.7-0.1,1
			L132.6,320.8z M131.3,317.2c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c0,0,0,0,0,0c-0.1,0.1-0.3,0.2-0.6,0.2C131.6,317.4,131.4,317.3,131.3,317.2L131.3,317.2z"/>
		<path d="M139,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H135h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0c0.3-0.3,0.6-0.5,1-0.7C138.1,319.2,138.6,319,139,319z"/>
		<path d="M147,324.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.8,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.2,0.1l0.2,0.1l0.4,0.1l1.7-0.2
			c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L147,324.8z M146.3,325.9
			c-0.5,0-1,0-1.4,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.3C146.9,326,146.6,325.9,146.3,325.9L146.3,325.9z M145.7,319.5c-0.4,0-0.8,0.2-1,0.6
			c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C146.5,319.7,146.1,319.4,145.7,319.5L145.7,319.5z"/>
		<path d="M116.8,335.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5
			h-0.9c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.3-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0l0,0L116.8,335.1z"/>
		<path d="M120.9,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-1,0.4-1.9,1-2.6
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L120.9,338z
			 M120.9,337.4h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
			C120.9,337.4,120.9,337.4,120.9,337.4z"/>
		<path d="M127.6,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L127.6,338z M127.7,337.4h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.2,0.5C127.7,337.4,127.7,337.4,127.7,337.4z"/>
		<path d="M135.1,335.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H133
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0L135.1,335.1z"/>
		<path d="M142.2,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.6-0.3,2.3-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0.1c0.3-0.3,0.6-0.5,1-0.7
			C141.4,335.2,141.8,335,142.2,335z"/>
		<path d="M363.9,315c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.8c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V320h-0.9
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6V319c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.3,0.5-0.6,0.9-0.9C363.3,315.1,363.6,315,363.9,315z"/>
		<path d="M368.3,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1l0,0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.2s0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C367.1,319.3,367.7,319.1,368.3,319.1z M367.5,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C366.9,325,367.2,325.1,367.5,325.1z"/>
		<path d="M372.7,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L372.7,317.1z"/>
		<path d="M376.6,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L376.6,317.1z"/>
		<path d="M382.1,320.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V320.8z M380.8,317.2
			c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.5,0.2c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
			c-0.1,0.1-0.3,0.2-0.5,0.2C381.1,317.4,380.9,317.3,380.8,317.2z"/>
		<path d="M388.5,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H384c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H384c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C387.7,319.2,388.1,319,388.5,319z"/>
		<path d="M396.5,324.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.8,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1l0.4,0.1l1.7-0.2
			c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L396.5,324.8z M395.8,325.9
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.5,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C396.4,326,396.1,325.9,395.8,325.9z M395.1,319.5c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C396,319.7,395.6,319.5,395.1,319.5L395.1,319.5z"/>
		<path d="M366.3,335.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5
			h-0.9c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0l0,0L366.3,335.1z"/>
		<path d="M370.4,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,1
			c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L370.4,338z
			 M370.4,337.4h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.1,0.5
			C370.4,337.4,370.4,337.4,370.4,337.4L370.4,337.4z"/>
		<path d="M377.1,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-1,0.4-1.9,1-2.6
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L377.1,338z
			 M377.2,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.1,0.5
			C377.2,337.4,377.1,337.4,377.2,337.4L377.2,337.4z"/>
		<path d="M384.6,335.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0h0L384.6,335.1z"/>
		<path d="M391.7,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5H392h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0c0.3-0.3,0.6-0.5,1-0.7
			C390.9,335.1,391.3,335,391.7,335z"/>
		<path d="M152,409.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.5,0-1,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.8-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C151.9,409,152,409.1,152,409.2z"/>
		<path d="M156.6,408.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.5-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C154.8,408.7,155.7,408.3,156.6,408.3z M156.4,408.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C157.3,409,156.9,408.8,156.4,408.9L156.4,408.9z"/>
		<path d="M166.6,409.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.2,1.3,0.8,2.5,2.1,2.6c0.2,0,0.3,0,0.5,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C166.5,409,166.5,409.1,166.6,409.2z"/>
		<path d="M169.8,413.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v5.6c0.5-0.3,0.9-0.6,1.3-1l0.4-0.4l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1
			c0,0-0.1,0-0.1,0h-0.1h-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2l1.1,1.2
			c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0L169.8,413.4z"/>
		<path d="M180,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s-0.1,0.6-0.1,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C179.2,408.6,179.6,408.4,180,408.4z"/>
		<path d="M185.2,408.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-0.9-1.6-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C183.4,408.7,184.3,408.3,185.2,408.3z M185,408.9
			c-0.4,0-0.7,0.2-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.2-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C185.9,409,185.5,408.8,185,408.9L185,408.9z"/>
		<path d="M192.9,408.4c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			l0,0l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.4,0-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C191.8,408.5,192.4,408.4,192.9,408.4z M192.1,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C191.5,414.4,191.8,414.5,192.1,414.5z"/>
		<path d="M201.9,409.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0.1,0.1,0.1,0.1,0.1,0.2
			C201.8,409,201.8,409.1,201.9,409.2z"/>
		<path d="M207.3,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1H204h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V405c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0.1c0.3-0.3,0.6-0.5,1-0.7
			C206.5,408.4,206.9,408.3,207.3,408.4z"/>
		<path d="M212.3,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
			c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L212.3,411.3z M212.4,410.7h2.5
			c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C212.4,410.7,212.4,410.7,212.4,410.7L212.4,410.7z"/>
		<path d="M220.3,408.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.4,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C219.2,408.5,219.8,408.3,220.3,408.4z"/>
		<path d="M224.7,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C224.2,413.4,224.5,413.3,224.7,413.3z"/>
		<path d="M235.4,408.6c0.8,0,1.6,0,2.4-0.2h0.1c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.4-0.1,0.8-0.1,1.1v3.5
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.6c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-3
			c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-4.1h-0.9c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.3
			c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.2-1.3,0.4-1.9c0.3-0.5,0.7-1,1.2-1.4c0.4-0.4,1-0.6,1.6-0.6c0.3,0,0.7,0.1,1,0.2
			c0.3,0.1,0.5,0.3,0.7,0.5c0.3,0.2,0.5,0.6,0.5,1c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6
			c0-0.1-0.1-0.1-0.1-0.2s-0.1-0.2-0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.4,0-0.8,0.3-1.1,0.9c-0.3,0.6-0.5,1.3-0.5,2v0.8L235.4,408.6z"/>
		<path d="M243.9,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.3-0.1,0.7
			s-0.1,0.6-0.1,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C243.1,408.6,243.5,408.4,243.9,408.4z"/>
		<path d="M246.9,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L246.9,411.3z
			 M246.9,410.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C246.9,410.7,246.9,410.7,246.9,410.7L246.9,410.7z"/>
      </g>
      <g id='po' style={{ opacity: selected === 'PO' ? 1 : 0 }}>
      <path d="M222.8,98.9c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,1.3,0.8,2.5,2.1,2.6c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-1.1,0-2.2-0.6-2.8-1.6c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2C222.7,98.7,222.8,98.8,222.8,98.9z"/>
		<path d="M227.4,98.1c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C225.7,98.4,226.5,98,227.4,98.1z M227.3,98.6
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C228.1,98.8,227.7,98.6,227.3,98.6z"/>
		<path d="M237.4,98.9c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			c0.1-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4
			c0.1,0.1,0.1,0.1,0.1,0.2C237.3,98.8,237.3,98.8,237.4,98.9z"/>
		<path d="M240.6,103.1c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6
			c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4l0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1
			l-0.1-0.1H243h-0.1h-0.1h-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2
			c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4c0,0-0.1-0.1-0.1-0.1l-0.1-0.1l0,0L240.6,103.1
			z"/>
		<path d="M250.9,98.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s-0.1,0.7-0.1,0.7
			c0,0,0,0,0,0.1l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C250,98.4,250.4,98.1,250.9,98.1z"/>
		<path d="M256,98.1c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C254.2,98.4,255.1,98,256,98.1z M255.8,98.6
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.2,0.5,0.4,0.9,0.7,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.3-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C256.7,98.7,256.3,98.6,255.8,98.6L255.8,98.6z"/>
		<path d="M263.8,98.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0l0,0
			c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C262.6,98.4,263.2,98.2,263.8,98.1z M262.9,104.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.4,0.4-0.3,1.1,0,1.5C262.4,104.1,262.6,104.2,262.9,104.2z"/>
		<path d="M272.7,98.9c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			c0.1-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4
			C272.5,98.6,272.6,98.8,272.7,98.9L272.7,98.9z"/>
		<path d="M278.1,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H279h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0l0,0c0.3-0.3,0.6-0.5,1-0.7
			C277.3,98.2,277.7,98.1,278.1,98.1z"/>
		<path d="M283.1,101.1c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L283.1,101.1z M283.2,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.3-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.1,0.5C283.2,100.4,283.2,100.5,283.2,100.5z"/>
		<path d="M291.2,98.1c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.7-0.4-0.9
			c-0.1-0.1-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4
			C290,98.2,290.6,98.1,291.2,98.1z"/>
		<path d="M317.7,96.5c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1l0,0
			c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.5
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C316.6,96.6,317.1,96.5,317.7,96.5z M316.8,102.6c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C316.3,102.5,316.5,102.6,316.8,102.6z"/>
		<path d="M328.6,103.4c-0.4,0-0.8-0.1-1.2-0.2h-0.2c-0.1,0-0.1,0-0.2-0.1H327c-0.1,0-0.1,0-0.2,0h-0.2h-0.2
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3
			c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.8-0.8,1.2
			c-0.4,0.3-0.8,0.6-1.2,0.8C329.6,103.3,329.1,103.4,328.6,103.4z M328.9,97.4c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3
			c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1
			c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C330.1,97.6,329.5,97.4,328.9,97.4L328.9,97.4z"/>
		<path d="M336.9,96.5c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1l0,0l0,0
			c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C335.6,96.7,336.2,96.5,336.9,96.5z M336,102.6c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C335.5,102.4,335.7,102.6,336,102.6L336,102.6z"/>
		<path d="M343.1,103.4c-0.4,0-0.8-0.1-1.2-0.2h-0.2l-0.2-0.1h-0.2c-0.1,0-0.1,0-0.2,0h-0.2H341c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			s0,0,0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C344.2,103.3,343.7,103.4,343.1,103.4z M343.4,97.4c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.1-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C344.6,97.7,344,97.4,343.4,97.4L343.4,97.4z"/>
		<path d="M349.1,96.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.6c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0,0,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c-0.3,0-0.3-0.1,0-0.2c0-0.1,0-0.2,0-0.2C348,96.6,348.5,96.6,349.1,96.6z"/>
		<path d="M355.9,101.4c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C355.4,101.4,355.7,101.4,355.9,101.4z"/>
		<path d="M365.4,103.4c-0.4,0-0.8-0.1-1.2-0.2h-0.2l-0.2-0.1h-0.2c-0.1,0-0.1,0-0.2,0h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			s0,0,0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C366.4,103.3,365.9,103.4,365.4,103.4z M365.6,97.4c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.1-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C366.9,97.7,366.3,97.4,365.6,97.4L365.6,97.4z"/>
		<path class="st6" d="M371.6,99.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2l-2,0.1
			L371.6,99.4z M371.7,98.8h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.2,0.5C371.7,98.8,371.7,98.8,371.7,98.8z"/>
		<path class="st6" d="M379.4,98.2v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c-0.1-0.1-0.1-0.3,0-0.5c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L379.4,98.2z
			 M378.1,94.6c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.5,0.2c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
			c-0.1,0.1-0.3,0.2-0.5,0.2C378.5,94.8,378.3,94.7,378.1,94.6z"/>
		<path class="st6" d="M385.8,96.4c0.3,0,0.6,0,0.9,0.1c0.5,0.1,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.4
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.1,0.3,0,0.5l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0c0.3-0.3,0.6-0.5,1-0.8
			C385,96.6,385.4,96.5,385.8,96.4z"/>
		<path class="st6" d="M393.8,102.2c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.8,0.6-1.8,1-2.9,1c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.4,0.2-0.5
			c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.4-0.6-0.4-0.9
			c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7
			c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1c0.1,0.1,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.3,0-0.4-0.1
			c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2L393.8,102.2z M393.1,103.3
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.5,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.4-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C393.7,103.4,393.4,103.3,393.1,103.3z M392.5,96.9c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C393.3,97.1,392.9,96.9,392.5,96.9z"/>
		<path class="st6" d="M315.7,112.4c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1
			c0,0.5-0.1,1-0.3,1.4c-0.4,0.9-1.1,1.6-2,2c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c-0.1,0.1-0.1,0.1-0.1,0.2v1.6
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H312h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.4
			c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.3c0,0.1-0.1,0.2-0.1,0.4v0.1h0
			c0.3-0.2,0.6-0.4,0.9-0.6C315,112.6,315.3,112.5,315.7,112.4z M315,113.4c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.4,0.3-0.4,0.4v3.8
			c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2
			c0-0.8-0.2-1.5-0.6-2.1C316.2,113.6,315.6,113.4,315,113.4z"/>
		<path class="st6" d="M323.8,112.5c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1s-0.7,0-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			s0,0.7,0,0.7c0,0,0,0,0,0.1l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C323,112.7,323.4,112.5,323.8,112.5z"/>
		<path class="st6" d="M326.8,115.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2l-2,0.1
			L326.8,115.4z M326.8,114.8h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C326.8,114.8,326.8,114.8,326.8,114.8z"/>
		<path class="st6" d="M336.4,118.2c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5-0.1
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2
			L336.4,118.2z M335.7,119.3c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.7-0.3C336.4,119.4,336,119.3,335.7,119.3z M335.1,112.9c-0.4,0-0.8,0.2-1,0.6
			c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C336,113.1,335.6,112.9,335.1,112.9z"/>
		<path class="st6" d="M344.1,112.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.8C343.2,112.5,343.6,112.4,344.1,112.4z"/>
		<path class="st6" d="M351.1,112.5c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1
			c0,0,0.1,0,0.1,0h0.1c0,0,0,0,0,0l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5
			c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l0.9-0.3l1.1-0.4c0.2-0.1,0.4-0.2,0.7-0.3
			c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9C349.9,112.8,350.5,112.5,351.1,112.5z M350.2,118.6c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7
			c0,0.3,0.1,0.6,0.3,0.8C349.7,118.4,350,118.6,350.2,118.6z"/>
		<path class="st6" d="M359.1,112.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H360h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1
			h0c0.3-0.3,0.6-0.6,1-0.8C358.3,112.5,358.7,112.4,359.1,112.4z"/>
		<path class="st6" d="M364.9,112.5h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8
			v-4.5h-0.9c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2
			c0.1-0.1,0.1-0.1,0.2-0.1h0L364.9,112.5z"/>
		<path class="st6" d="M368.9,117.4c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C368.4,117.5,368.6,117.4,368.9,117.4z"/>
		<path class="st6" d="M379.6,112.7c0.8,0,1.6,0,2.4-0.2h0.1c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.2v3.5
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.1,0.3,0,0.5l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-3
			c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.6v4.1c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-1.1-0.1-1.6-0.1-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-4.1h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3
			c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-1.3,0.6-2.6,1.6-3.4c0.4-0.4,1-0.6,1.6-0.7c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.5
			c0.3,0.3,0.5,0.6,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0,0,0-0.1-0.1-0.2
			c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9
			c-0.3,0.6-0.5,1.3-0.5,2v0.8L379.6,112.7z"/>
		<path class="st6" d="M388.1,112.5c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1-0.1-1.7-0.1-1.9-0.1s-0.7,0-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			s0,0.7,0,0.7c0,0,0,0,0,0.1l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C387.3,112.7,387.7,112.5,388.1,112.5z"/>
		<path class="st6" d="M391,115.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2l-2,0.1
			L391,115.4z M391.1,114.8h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C391.1,114.8,391.1,114.8,391.1,114.8L391.1,114.8z"/>
		<path class="st6" d="M397.7,117.4c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C397.2,117.5,397.4,117.4,397.7,117.4z"/>
		<path class="st6" d="M313.2,128.4c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.6,0.6,1c0.6-0.7,1.4-1.1,2.3-1.3c0.3,0,0.6,0,0.9,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H317c0-0.1-0.1-0.2,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.7c0-0.3,0-0.6,0-0.9
			c0-0.2-0.1-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4-0.2-1-0.2-1.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.1,0.1-0.2,0.2-0.2,0.3v0.1c0,0,0,0.1,0,0.1c0,0.4,0.1,0.7,0.1,1.1v1.9c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2
			c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6
			c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1H310h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.1,0.7-0.1,0.8
			s0,0.1,0,0.1l0,0c0.4-0.3,0.8-0.6,1.2-0.8C312.6,128.4,312.9,128.4,313.2,128.4z"/>
		<path class="st6" d="M324.6,128.4c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C322.8,128.8,323.7,128.4,324.6,128.4z M324.4,128.9
			c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.2-0.5-0.4-0.9-0.8-1.2C325.3,129.1,324.8,129,324.4,128.9L324.4,128.9z"/>
		<path class="st6" d="M333.6,128.4c0.3,0,0.6,0,0.9,0.1c0.5,0.1,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.1,0.3,0,0.5l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H329c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0c0.3-0.3,0.6-0.5,1-0.8
			C332.8,128.5,333.2,128.4,333.6,128.4z"/>
		<path class="st6" d="M338.6,131.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2l-2,0.1
			L338.6,131.4z M338.7,130.8h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.2,0.5C338.7,130.8,338.7,130.8,338.7,130.8z"/>
		<path class="st6" d="M345.2,128.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.6c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C344.1,128.6,344.6,128.6,345.2,128.6z"/>
		<path class="st6" d="M352,133.4c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C351.5,133.4,351.8,133.4,352,133.4z"/>
		<path class="st6" d="M361.2,128.5c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0.1,0,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0,0,0.2,0.1,0.3,0.2l0.4,0.2
			l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C360.1,128.6,360.7,128.4,361.2,128.5z"/>
		<path class="st6" d="M369,128.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.6,1-0.8C368.2,128.5,368.6,128.4,369,128.4z"/>
		<path class="st6" d="M376,128.5c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l0.9-0.3l1.1-0.4c0.2-0.1,0.4-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C374.8,128.7,375.4,128.5,376,128.5z M375.2,134.6c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C374.6,134.5,374.9,134.6,375.2,134.6L375.2,134.6z"/>
		<path class="st6" d="M381.6,133.5c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3v-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6
			c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4l0.4-0.3l0.3-0.2l0.2-0.2
			c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1-0.1l-0.1-0.1h-0.1h-0.1h-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2
			c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.4,0.1-0.6,0.3
			c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3
			c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
			c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4l-0.1-0.1l-0.1-0.1l0,0L381.6,133.5z"/>
		<path class="st6" d="M388.1,131.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2l-2,0.1
			L388.1,131.4z M388.2,130.8h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C388.2,130.8,388.2,130.8,388.2,130.8z"/>
		<path class="st6" d="M396.2,128.5c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C395,128.6,395.6,128.4,396.2,128.5z"/>
		<path class="st6" d="M400.5,133.4c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C400.1,133.4,400.3,133.4,400.5,133.4z"/>
		<path class="st6" d="M339,144.6h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2l0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8l0.4-1.1c0.2-0.4,0.3-0.7,0.4-0.9l0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5
			c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2h0.6c0.3,0,0.5,0,0.8,0l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
			c0,0.1,0.1,0.2,0.1,0.3l0.2,0.5c0.1,0.2,0.2,0.5,0.3,0.7l0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
			c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2h0.6c0.3,0,0.5,0,0.8,0h0.5h0.5h0.5c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1
			c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5
			l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.4-0.8-0.4
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2h0.5h0.5L339,144.6z"/>
		<path class="st6" d="M352.3,144.5c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1
			c0,0,0.1,0,0.1,0h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5
			c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.4l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3
			c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9C351.2,144.7,351.7,144.5,352.3,144.5z M351.5,150.6c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7
			c0,0.3,0.1,0.6,0.3,0.8C350.9,150.4,351.2,150.6,351.5,150.6L351.5,150.6z"/>
		<path class="st6" d="M357.8,144.5h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.1h0
			L357.8,144.5z"/>
		<path class="st6" d="M361.9,147.4c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2l-2,0.1
			L361.9,147.4z M362,146.8h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C362,146.8,362,146.8,362,146.8L362,146.8z"/>
		<path class="st6" d="M371.6,144.5c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1-0.1-1.7-0.1-1.9-0.1s-0.7,0-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7c0,0,0,0,0,0.1l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C370.8,144.7,371.2,144.5,371.6,144.5z"/>
		<path class="st6" d="M97.7,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1
			c0,0,0.1,0,0.1,0h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5
			c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2C96.1,81.5,96,81.7,96,82
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C96.5,80.5,97.1,80.3,97.7,80.2z M96.8,86.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C96.3,86.2,96.6,86.3,96.8,86.3z"/>
		<path class="st6" d="M108.7,87.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1h-0.2H107h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1V77
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4V81c0,0,0,0,0,0.1
			l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8C109.6,87,109.2,87.1,108.7,87.2
			z M108.9,81.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8
			c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1
			C110.2,81.4,109.6,81.1,108.9,81.2L108.9,81.2z"/>
		<path class="st6" d="M116.9,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0.1
			l0,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.2,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C115.7,80.5,116.3,80.3,116.9,80.2z M116.1,86.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C115.5,86.2,115.8,86.3,116.1,86.3z"/>
		<path class="st6" d="M123.2,87.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2H121c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4V81c0,0,0,0,0,0.1
			l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.6c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8C124.1,87,123.7,87.1,123.2,87.2z M123.4,81.2
			c-0.2,0-0.4,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C124.7,81.4,124.1,81.1,123.4,81.2
			L123.4,81.2z"/>
		<path class="st6" d="M129.1,80.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.4,5.6
			c0,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5
			c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8
			c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			C128,80.4,128.6,80.4,129.1,80.4z"/>
		<path class="st6" d="M136,85.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5C135.5,85.2,135.8,85.1,136,85.1z
			"/>
		<path class="st6" d="M145.4,87.2c-0.4,0-0.8-0.1-1.1-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			V81c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.3,0.8,0.2,1.7-0.1,2.6c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C146.5,87,145.9,87.2,145.4,87.2z M145.7,81.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.3
			c0-0.8-0.2-1.5-0.6-2.1C147,81.4,146.3,81.1,145.7,81.2L145.7,81.2z"/>
		<path class="st6" d="M151.6,83.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L151.6,83.2z
			 M151.7,82.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.4,1
			C151.7,82.5,151.7,82.6,151.7,82.6z"/>
		<path class="st6" d="M158.4,83.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L158.4,83.2z
			 M158.4,82.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C158.4,82.5,158.4,82.6,158.4,82.6L158.4,82.6z"/>
		<path class="st6" d="M166.4,80.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8
			c0-0.3-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
			c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4
			C165.3,80.3,165.8,80.2,166.4,80.2z"/>
		<path class="st6" d="M170.8,85.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C170.3,85.2,170.5,85.1,170.8,85.1z"/>
		<path class="st6" d="M180.2,87.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1V77
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4V81c0,0,0,0,0,0.1
			l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.6c-0.2,0.4-0.5,0.9-0.8,1.2C182.2,86.7,181.2,87.1,180.2,87.2L180.2,87.2z M180.5,81.2
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C181.7,81.4,181.1,81.1,180.5,81.2
			L180.5,81.2z"/>
		<path class="st6" d="M189.5,80.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.8,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-2-0.1l-1.8,0.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7s0,0,0,0l0,0
			l0,0c0.2-0.4,0.5-0.8,0.8-1.1C188.7,80.4,189,80.2,189.5,80.2z"/>
		<path class="st6" d="M192.4,83.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L192.4,83.2z M192.4,82.6h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C192.4,82.5,192.4,82.6,192.4,82.6z"/>
		<path class="st6" d="M201.1,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1
			c0,0,0.1,0,0.1,0h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5
			c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C199.9,80.5,200.5,80.3,201.1,80.2z M200.3,86.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C199.7,86.2,200,86.3,200.3,86.3z"/>
		<path class="st6" d="M208.4,80.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1
			c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.1l-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1l-0.5,0.1l-0.4,0.1h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.6c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.8,0-1.5-0.4-1.9-1
			c-0.6-0.7-0.9-1.5-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C207.6,80.2,208,80.2,208.4,80.2z
			 M207.9,80.9c-0.6,0-1.1,0.3-1.4,0.8c-0.7,1.2-0.7,2.6,0.1,3.8c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C208.7,80.9,208.3,80.8,207.9,80.9z"/>
		<path class="st6" d="M213.8,85.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C213.3,85.2,213.5,85.1,213.8,85.1z"/>
		<path class="st6" d="M125.5,97c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,1.3,0.8,2.5,2.1,2.6c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.7-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C125.5,96.8,125.5,96.9,125.5,97z"/>
		<path class="st6" d="M130.9,96.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.9-0.1l-1.8,0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7s0,0,0,0l0,0
			l0,0c0.2-0.4,0.5-0.8,0.8-1.1C130.1,96.4,130.5,96.3,130.9,96.2z"/>
		<path class="st6" d="M135.9,96.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1l0,0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.2,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C134.7,96.5,135.3,96.3,135.9,96.2z M135.1,102.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			L135,100c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8C134.5,102.2,134.8,102.3,135.1,102.3z"/>
		<path class="st6" d="M142.2,103.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2H140c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			V97c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.3,0.8,0.2,1.7-0.1,2.6c-0.2,0.4-0.5,0.9-0.8,1.2C144.2,102.7,143.2,103.1,142.2,103.2L142.2,103.2z
			 M142.5,97.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.4,0.8
			c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1
			C143.7,97.4,143.1,97.1,142.5,97.2L142.5,97.2z"/>
		<path class="st6" d="M149.7,96.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0.1,0.4,0.2,0.6
			c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3s-0.3-0.2-0.6-0.3s-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C148.6,96.4,149.2,96.2,149.7,96.2z"/>
		<path class="st6" d="M154.1,101.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C153.6,101.2,153.9,101.1,154.1,101.1z"/>
		<path class="st6" d="M161.7,94.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L161.7,94.3z
			"/>
		<path class="st6" d="M167.1,98v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H166h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1
			L167.1,98z M165.8,94.4c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
			c-0.1,0.1-0.3,0.2-0.6,0.2C166.2,94.6,166,94.5,165.8,94.4L165.8,94.4z"/>
		<path class="st6" d="M174.4,97c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1l-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.2,1.3,0.8,2.5,2.1,2.6c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-1.1,0-2.2-0.6-2.8-1.6c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2C174.4,96.8,174.4,96.9,174.4,97z"/>
		<path class="st6" d="M176.8,99.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-1,0.4-1.9,1-2.7
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L176.8,99.2z M176.9,98.6h2.6
			c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.1,0.5
			C176.9,98.5,176.9,98.6,176.9,98.6L176.9,98.6z"/>
		<path class="st6" d="M183.5,101.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C183,101.2,183.2,101.1,183.5,101.1z"/>
		<path class="st6" d="M105.7,112.2c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.5,0.6,0.6,1c0.6-0.7,1.4-1.1,2.3-1.3c0.3,0,0.6,0,0.9,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H110h-0.6c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.8
			c0-0.2-0.1-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4-0.2-1-0.2-1.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.4,0.1,0.7,0.1,1.1v1.9c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7,0h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6
			c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.6c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.5-0.1,0.8v0.1
			l0,0c0.4-0.3,0.8-0.6,1.2-0.8C105,112.3,105.3,112.2,105.7,112.2z"/>
		<path class="st6" d="M117,112.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-0.9-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C115.2,112.5,116.1,112.2,117,112.2z M116.8,112.7
			c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.2-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C117.7,112.9,117.3,112.7,116.8,112.7L116.8,112.7z"/>
		<path class="st6" d="M126,112.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H122h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C125.1,112.4,125.5,112.2,126,112.2z"/>
		<path class="st6" d="M131,115.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L131,115.2z M131.1,114.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.4,1
			C131.1,114.5,131.1,114.6,131.1,114.6L131.1,114.6z"/>
		<path class="st6" d="M137.6,112.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.4,5.6
			c0,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5
			c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8
			c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			C136.5,112.4,137.1,112.4,137.6,112.4z"/>
		<path class="st6" d="M144.4,117.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C144,117.2,144.2,117.1,144.4,117.1z"/>
		<path class="st6" d="M153.7,112.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.2-0.5-0.2-0.8
			c-0.1-0.3-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
			c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4
			C152.5,112.3,153.1,112.2,153.7,112.2z"/>
		<path class="st6" d="M162.4,113c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,1.3,0.8,2.5,2.1,2.6c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.2-0.5-0.3-1-0.3-1.6c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4C162.3,112.7,162.3,112.8,162.4,113
			L162.4,113z"/>
		<path class="st6" d="M167,112.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-0.9-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C165.3,112.5,166.1,112.2,167,112.2z M166.8,112.7
			c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.2-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C167.7,112.9,167.3,112.7,166.8,112.7L166.8,112.7z"/>
		<path class="st6" d="M175.7,112.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s-0.1,0.7-0.1,0.7l0,0l0,0
			l0,0c0.2-0.4,0.5-0.8,0.8-1.1C174.9,112.4,175.3,112.2,175.7,112.2z"/>
		<path class="st6" d="M181.5,112.2c0.9,0,1.7,0.4,2.1,1.1c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.4
			c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2l-0.1,0.3c0,0.2,0,0.3,0,0.4s0,0.1,0,0.1l0,0
			c0.3-0.2,0.6-0.4,0.9-0.6C180.8,112.3,181.1,112.2,181.5,112.2z M180.8,113.1c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.4,0.3-0.4,0.4
			v3.8c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1c0.4-1.1,0.3-2.4-0.4-3.3
			C182,113.4,181.4,113.1,180.8,113.1z"/>
		<path class="st6" d="M187.7,114v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H186h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L187.7,114z
			 M186.5,110.4c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.2,0.3-0.2,0.5-0.3c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
			c-0.1,0.1-0.3,0.2-0.6,0.2C186.8,110.6,186.6,110.5,186.5,110.4L186.5,110.4z"/>
		<path class="st6" d="M193.1,112.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C191.3,112.5,192.2,112.2,193.1,112.2z M192.9,112.7
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.3,0.9-0.2,1.9,0.1,2.8c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.1-0.5-0.4-0.9-0.8-1.2
			C193.8,112.9,193.4,112.7,192.9,112.7z"/>
		<path class="st6" d="M202.1,112.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H203h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7,0h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1
			l0,0c0.3-0.3,0.6-0.5,1-0.7C201.3,112.4,201.7,112.2,202.1,112.2z"/>
		<path class="st6" d="M207,117.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C206.5,117.2,206.8,117.1,207,117.1z"/>
		<path class="st6" d="M105.5,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0l-0.7-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C104.4,128.4,104.9,128.2,105.5,128.2z"/>
		<path class="st6" d="M113.3,128.2c0.3,0,0.6,0,0.9,0.1c0.5,0.2,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H113
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.7
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0.1c0.3-0.3,0.6-0.5,1-0.7
			C112.4,128.4,112.8,128.2,113.3,128.2z"/>
		<path class="st6" d="M120.3,128.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C119.1,128.5,119.7,128.3,120.3,128.2z M119.5,134.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C118.9,134.2,119.2,134.3,119.5,134.3z"/>
		<path class="st6" d="M125.9,133.2c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-0.9l0.4-0.4
			l0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l-0.1-0.1h-0.1h-0.1H128h-0.2
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0.1,0.1,0.1,0.3,0,0.4c-0.2,0-0.4,0-0.6,0.1
			c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.3-2.5c0,0,0,0-0.1-0.1l-0.1-0.1l0,0L125.9,133.2z"/>
		<path class="st6" d="M132.4,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L132.4,131.2z M132.5,130.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C132.5,130.5,132.5,130.6,132.5,130.6L132.5,130.6z"/>
		<path class="st6" d="M140.4,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.4,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3s-0.3-0.2-0.6-0.3s-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C139.3,128.4,139.9,128.2,140.4,128.2z"/>
		<path class="st6" d="M144.8,133.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C144.3,133.2,144.6,133.1,144.8,133.1z"/>
		<path class="st6" d="M154,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C152.9,128.4,153.4,128.2,154,128.2z"/>
		<path class="st6" d="M160.7,128.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C159,128.5,159.8,128.2,160.7,128.2z M160.6,128.7
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2
			C161.4,128.9,161,128.7,160.6,128.7z"/>
		<path class="st6" d="M169.7,128.2c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.5,0.6,0.6,1c0.6-0.7,1.4-1.1,2.3-1.3c0.3,0,0.6,0,0.9,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.4,0,0.8,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H174h-0.5
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.7
			c0-0.3,0-0.6,0-0.9c0-0.2-0.1-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1,0,0.1v0.1c0.1,0.4,0.1,0.7,0.1,1.1v1.9
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2L171,135h-0.5h-0.6
			h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.6
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8s0,0.1,0,0.1l0,0
			c0.4-0.3,0.8-0.6,1.2-0.8C169,128.3,169.3,128.2,169.7,128.2z"/>
		<path class="st6" d="M178.8,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L178.8,131.2z
			 M178.9,130.6h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C178.9,130.5,178.9,130.6,178.9,130.6L178.9,130.6z"/>
		<path class="st6" d="M187.7,128.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C186,128.5,186.8,128.2,187.7,128.2z M187.6,128.7
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.3-0.9,0.2-1.9-0.1-2.8c-0.1-0.5-0.4-0.9-0.8-1.2
			C188.4,128.9,188,128.7,187.6,128.7L187.6,128.7z"/>
		<path class="st6" d="M196.8,128.2c0.3,0,0.6,0,0.9,0.1c0.5,0.2,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0.1,0.7,0.1,1.2v1.7
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.6,0h-0.5h-0.6h-0.6
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.7
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0.1c0.3-0.3,0.6-0.5,1-0.7
			C196,128.4,196.4,128.3,196.8,128.2z"/>
		<path class="st6" d="M201.8,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L201.8,131.2z M201.9,130.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.3-0.4,0.6-0.4,1C201.9,130.5,201.8,130.6,201.9,130.6L201.9,130.6z"/>
		<path class="st6" d="M109.5,144.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c0,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.1c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C108.4,144.4,109,144.4,109.5,144.4z"/>
		<path class="st6" d="M119,144.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-0.9-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C117.2,144.5,118.1,144.2,119,144.2z M118.8,144.7
			c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.2-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C119.7,144.9,119.3,144.7,118.8,144.7L118.8,144.7z"/>
		<path class="st6" d="M128.4,146c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.7,0,1.4-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1v3.3c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1l-0.5,0.1
			l-0.6,0.1c-0.2,0-0.3,0.1-0.5,0.1l-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.7V150c-0.3,0.3-0.6,0.5-1,0.7
			c-0.4,0.2-0.8,0.3-1.2,0.3c-0.6,0-1.1-0.2-1.4-0.7c-0.2-0.2-0.3-0.5-0.3-0.7c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3,0-0.6,0-0.8v-2
			c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.7,0,1.4-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,1.1v2.3c0,0.6,0.1,1.3,0.4,1.8c0.3,0.4,0.7,0.6,1.2,0.6c0.4,0,0.7-0.1,1-0.3
			c0.3-0.2,0.5-0.4,0.5-0.6L128.4,146z"/>
		<path class="st6" d="M137.1,142.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6H136
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L137.1,142.3z"/>
		<path class="st6" d="M142.5,146v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V146
			z M141.3,142.4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.6,0.2
			C141.6,142.6,141.4,142.5,141.3,142.4L141.3,142.4z"/>
		<path class="st6" d="M146.5,149.2c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v5.6c0.5-0.3,0.9-0.6,1.3-0.9l0.4-0.4l0.4-0.3l0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l-0.1-0.1H149
			h-0.1h-0.1h-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.1-0.1,0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
			s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.5c0,0,0,0-0.1-0.1l-0.1-0.1l0,0L146.5,149.2z"/>
		<path class="st6" d="M153.1,147.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L153.1,147.2z M153.1,146.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.3-0.4,0.6-0.4,1C153.1,146.5,153.1,146.6,153.1,146.6L153.1,146.6z"/>
		<path class="st6" d="M159.7,149.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C159.2,149.2,159.5,149.1,159.7,149.1z"/>
		<path class="st6" d="M167.4,144.4h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6
			l0.2-0.5c0-0.1,0-0.1,0.1-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2h0.6h0.8l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0.1,0,0.1,0,0.2
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5l0.3,0.7c0.1,0.2,0.2,0.6,0.4,1l0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0-0.1,0.1-0.2,0.1-0.4
			c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2h0.6h0.8h0.5h0.5h0.5
			c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0-0.2,0-0.3,0-0.5h0.5h0.5
			L167.4,144.4z"/>
		<path class="st6" d="M180.8,144.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C179.6,144.5,180.2,144.3,180.8,144.2z M179.9,150.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C179.4,150.2,179.6,150.3,179.9,150.3z"/>
		<path class="st6" d="M186.2,144.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3
			c0.4,0,0.7-0.1,1-0.4c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.6,0.3
			c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5
			h-0.9c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2
			c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0l0,0L186.2,144.3z"/>
		<path class="st6" d="M190.3,147.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L190.3,147.2z M190.4,146.6h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5C190.4,146.5,190.4,146.6,190.4,146.6L190.4,146.6z"/>
		<path class="st6" d="M200.1,144.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.9-0.1l-1.8,0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s-0.1,0.7-0.1,0.7s0,0,0,0
			h0.1c0.2-0.4,0.5-0.8,0.8-1.1C199.3,144.5,199.7,144.3,200.1,144.2z"/>
		<path class="st6" d="M360.5,319c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			l-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.7-0.4-0.9
			c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
			c0-0.5,0.2-1.1,0.7-1.4C359.4,319.1,359.9,319,360.5,319z"/>
		<path class="st6" d="M368.3,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6H368c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.6-0.3,2.3-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C367.4,319.2,367.8,319,368.3,319z"/>
		<path class="st6" d="M375.3,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C374.2,319.3,374.7,319.1,375.3,319.1z M374.5,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C373.9,325,374.2,325.2,374.5,325.1z"/>
		<path class="st6" d="M380.9,324.1c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1-0.1l-0.1,0h-0.1
			h-0.1h-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0L380.9,324.1z"/>
		<path class="st6" d="M387.4,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.2,0.8,1.9,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L387.4,322z M387.5,321.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C387.5,321.4,387.5,321.4,387.5,321.4z"/>
		<path class="st6" d="M395.4,319c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.2,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.7-0.4-0.9
			c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
			c0-0.5,0.2-1,0.7-1.4C394.3,319.1,394.9,319,395.4,319z"/>
		<path class="st6" d="M399.8,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C399.3,324.1,399.6,324,399.8,324z"/>
		<path class="st6" d="M363.2,335.2h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6
			l0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2l0.6,0h0.8l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0.1,0,0.1,0,0.2
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5l0.3,0.7c0.1,0.2,0.2,0.6,0.4,1l0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0-0.1,0.1-0.2,0.1-0.4
			c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1h-0.3c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2l0.6,0h0.8h0.5h0.5h0.5
			c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.2,0-0.2h0.5h0.5L363.2,335.2z"/>
		<path class="st6" d="M376.6,335.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C375.4,335.3,376,335.1,376.6,335.1z M375.7,341.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C375.2,341,375.4,341.1,375.7,341.1z"/>
		<path class="st6" d="M382,335.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5H382v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0l0,0L382,335.1z"/>
		<path class="st6" d="M386.1,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.3,0.3,0.5,0.7,0.6,1.1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L386.1,338z
			 M386.2,337.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C386.2,337.4,386.2,337.4,386.2,337.4z"/>
		<path class="st6" d="M395.9,335c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2s0.6,0.1,0.8,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2
			v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0h0.1
			c0.2-0.4,0.5-0.8,0.8-1.1C395.1,335.3,395.4,335.1,395.9,335z"/>
		<path class="st6" d="M112.2,319.1c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.2s0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C111.1,319.3,111.6,319.1,112.2,319.1z M111.4,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C110.8,325,111.1,325.1,111.4,325.1z"/>
		<path class="st6" d="M123.2,326c-0.4,0-0.8-0.1-1.2-0.2l-0.2,0l-0.2,0h-0.2h-0.2h-0.2H121c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8C124.2,325.9,123.7,326,123.2,326z M123.4,320
			c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C124.7,320.3,124.1,320,123.4,320z"/>
		<path class="st6" d="M131.4,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1l0,0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.6-0.6l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C130.3,319.2,130.9,319.1,131.4,319.1z M130.6,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C130,325,130.3,325.1,130.6,325.1z"/>
		<path class="st6" d="M137.7,326c-0.4,0-0.8-0.1-1.2-0.2l-0.2,0l-0.2,0h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.8,0,1.6,0.4,2.1,1.1c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C138.7,325.9,138.2,326,137.7,326z M138,320c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C139.2,320.3,138.6,320,138,320L138,320z"/>
		<path class="st6" d="M143.6,319.2c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.2c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6l-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.1c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.3,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C142.6,319.2,143.1,319.2,143.6,319.2z"/>
		<path class="st6" d="M150.5,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C150,324.1,150.2,324,150.5,324z"/>
		<path class="st6" d="M86,342c-0.4,0-0.8-0.1-1.2-0.2l-0.2,0l-0.2,0h-0.2h-0.2H84h-0.2c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
			c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.6-0.3,2.3-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1v0.1l0,0h0.1
			c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.5c-0.2,0.4-0.5,0.9-0.8,1.2C88,341.5,87,342,86,342z M86.2,336c-0.2,0-0.5,0.1-0.7,0.1
			c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4
			c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C87.5,336.3,86.9,336,86.2,336L86.2,336z"/>
		<path class="st6" d="M92.2,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L92.2,338z M92.3,337.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C92.3,337.4,92.2,337.4,92.3,337.4z"/>
		<path class="st6" d="M100,336.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L100,336.8z
			 M98.7,333.2c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0
			C99.5,333.5,99,333.5,98.7,333.2C98.7,333.2,98.7,333.2,98.7,333.2z"/>
		<path class="st6" d="M106.4,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.3,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H103h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C105.5,335.2,106,335,106.4,335z"/>
		<path class="st6" d="M114.4,340.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.6-0.9-0.4-2.1,0.4-2.8
			c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.3,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L114.4,340.8z M113.7,341.9c-0.5,0-1,0-1.4,0.1
			c-0.2,0.1-0.5,0.3-0.7,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.4c-0.2-0.1-0.4-0.3-0.7-0.3
			C114.3,342,114,341.9,113.7,341.9z M113.1,335.5c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4
			c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C113.9,335.7,113.5,335.5,113.1,335.5
			L113.1,335.5z"/>
		<path class="st6" d="M126.2,335c0.9,0,1.7,0.4,2.1,1.1c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V337
			c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2l-0.1,0.3c0,0.2,0,0.3,0,0.4s0,0.1,0,0.1l0,0
			c0.3-0.2,0.6-0.4,0.9-0.6C125.5,335.1,125.9,335,126.2,335z M125.5,335.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
			c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1c0.4-1.1,0.3-2.4-0.4-3.3
			C126.7,336.2,126.1,336,125.5,335.9L125.5,335.9z"/>
		<path class="st6" d="M134.4,335c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.9-0.1l-1.8,0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0
			h0.1c0.2-0.4,0.5-0.8,0.8-1.1C133.6,335.3,134,335.1,134.4,335z"/>
		<path class="st6" d="M137.3,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.3,0.3,0.5,0.7,0.6,1.1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L137.3,338z
			 M137.4,337.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.3-0.4,0.6-0.4,1
			C137.4,337.4,137.4,337.4,137.4,337.4z"/>
		<path class="st6" d="M147,340.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L147,340.8z M146.3,341.9
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C146.9,342,146.6,341.9,146.3,341.9L146.3,341.9z M145.6,335.5c-0.4,0-0.8,0.2-1,0.6
			c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C146.5,335.7,146.1,335.5,145.6,335.5L145.6,335.5z"/>
		<path class="st6" d="M154.6,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0c0.3-0.3,0.6-0.5,1-0.7C153.8,335.2,154.2,335,154.6,335z"/>
		<path class="st6" d="M161.6,335.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.2h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4l0.7-0.4c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C160.5,335.2,161.1,335.1,161.6,335.1z M160.8,341.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			l-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8C160.2,341,160.5,341.1,160.8,341.1
			L160.8,341.1z"/>
		<path class="st6" d="M169.6,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0.1
			c0.3-0.3,0.6-0.5,1-0.7C168.8,335.2,169.2,335,169.6,335z"/>
		<path class="st6" d="M175.4,335.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0l0,0L175.4,335.1z"/>
		<path class="st6" d="M263.3,409.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.2,1.3,0.8,2.5,2.1,2.6c0.2,0,0.3,0,0.5,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C263.2,409,263.3,409.1,263.3,409.2z"/>
		<path class="st6" d="M267.9,408.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C266.1,408.7,267,408.3,267.9,408.3z M267.7,408.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C268.6,409,268.2,408.9,267.7,408.9L267.7,408.9z"/>
		<path class="st6" d="M277.9,409.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0.1,0.1,0.1,0.1,0.1,0.2
			C277.8,409,277.8,409.1,277.9,409.2z"/>
		<path class="st6" d="M281.1,413.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1H280h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V405c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1c0,0-0.1,0-0.1,0h-0.1h-0.2
			c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2l1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4l-0.1-0.1l-0.1-0.1l0,0L281.1,413.4z"/>
		<path class="st6" d="M291.3,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C290.5,408.6,290.9,408.4,291.3,408.4z"/>
		<path class="st6" d="M296.5,408.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.5-0.9-2.5c0-0.9,0.3-1.8,1-2.5C294.8,408.7,295.6,408.3,296.5,408.3z M296.3,408.9
			c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C297.2,409,296.7,408.8,296.3,408.9L296.3,408.9z"/>
		<path class="st6" d="M304.2,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C303,408.6,303.6,408.4,304.2,408.4z M303.4,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C302.8,414.4,303.1,414.5,303.4,414.5z"/>
		<path class="st6" d="M313.2,409.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C313.1,409,313.1,409.1,313.2,409.2z"/>
		<path class="st6" d="M318.6,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0h0c0.3-0.3,0.6-0.5,1-0.7
			C317.8,408.4,318.2,408.3,318.6,408.4z"/>
		<path class="st6" d="M323.6,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L323.6,411.3z M323.7,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C323.7,410.7,323.7,410.7,323.7,410.7L323.7,410.7z"/>
		<path class="st6" d="M331.6,408.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C330.5,408.5,331.1,408.3,331.6,408.4z"/>
		<path class="st6" d="M336,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C335.5,413.4,335.8,413.3,336,413.3z"/>
		<path class="st6" d="M346.7,408.6c0.8,0,1.6,0,2.3-0.2h0.1c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.4-0.1,0.8-0.1,1.1v3.5
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1H348h-0.6h-0.5
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-3
			c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-4.1h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2
			c0-0.6,0.2-1.3,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6c0.4-0.4,1-0.6,1.6-0.6c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.5
			c0.3,0.2,0.5,0.6,0.5,1c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
			c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9
			c-0.3,0.6-0.5,1.3-0.5,2v0.8L346.7,408.6z"/>
		<path class="st6" d="M355.2,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H351
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C354.4,408.6,354.8,408.4,355.2,408.4z"/>
		<path class="st6" d="M358.1,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L358.1,411.3z
			 M358.2,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C358.2,410.7,358.2,410.7,358.2,410.7L358.2,410.7z"/>
      </g>
      <g id='ru' style={{ opacity: selected === 'RU' ? 1 : 0 }}>
      <path class="st6" d="M337.2,112.8c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C336,113.1,336.6,112.8,337.2,112.8z M336.3,118.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.4,0.4-0.3,1.1,0,1.5C335.8,118.7,336,118.9,336.3,118.9z"/>
		<path class="st6" d="M348.1,119.7c-0.4,0-0.8-0.1-1.2-0.2h-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.2c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.8-0.8,1.2c-0.4,0.4-0.8,0.6-1.2,0.8
			C349.1,119.6,348.6,119.7,348.1,119.7z M348.4,113.7c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C349.6,113.9,349,113.6,348.4,113.7L348.4,113.7z"/>
		<path class="st6" d="M356.4,112.8c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1l0,0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C355.1,113,355.7,112.8,356.4,112.8z M355.5,118.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8C355,118.7,355.2,118.9,355.5,118.9z"/>
		<path class="st6" d="M362.6,119.7c-0.4,0-0.8-0.1-1.2-0.2h-0.2l-0.2-0.1H361h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.2c0,0,0,0,0,0.1
			l0,0h0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,1-0.4,1.9-1.1,2.6c-0.4,0.3-0.8,0.6-1.2,0.8C363.7,119.6,363.2,119.7,362.6,119.7z M362.9,113.7
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C364.1,113.9,363.5,113.6,362.9,113.7
			L362.9,113.7z"/>
		<path class="st6" d="M368.6,112.9c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.6c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C367.5,112.9,368,112.9,368.6,112.9z"/>
		<path class="st6" d="M375.4,117.6c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2l0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5C374.9,117.7,375.2,117.6,375.4,117.6z"/>
		<path class="st6" d="M309.3,135.7c-0.4,0-0.8-0.1-1.1-0.2H308l-0.2-0.1h-0.2h-0.2h-0.2l-0.2,0.1c0,0-0.1-0.1-0.1-0.1
			c0-0.1,0-0.2,0-0.3c0.1-0.4,0.2-0.8,0.2-1.1v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			l0,0h0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.4,0.9-1.1,1.6-2,2C310.4,135.6,309.9,135.7,309.3,135.7z M309.6,129.7
			c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C310.8,129.9,310.2,129.6,309.6,129.7
			L309.6,129.7z"/>
		<path class="st6" d="M315.5,131.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L315.5,131.7z
			 M315.6,131.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C315.6,131.1,315.6,131.1,315.6,131.1z"/>
		<path class="st6" d="M323.4,130.5v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1L323.4,130.5z
			 M322.1,126.9c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.1,0.1,0.2,0.3,0.2,0.6
			c0,0.2-0.1,0.4-0.2,0.6C322.9,127.2,322.4,127.2,322.1,126.9z"/>
		<path class="st6" d="M329.8,128.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C329,128.9,329.4,128.8,329.8,128.7z"/>
		<path class="st6" d="M337.8,134.5c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.2,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5-0.1
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L337.8,134.5z M337,135.6c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,1,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.3C337.7,135.7,337.3,135.6,337,135.6L337,135.6z M336.4,129.2
			c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.6
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C337.3,129.4,336.8,129.2,336.4,129.2z"/>
		<path class="st6" d="M349.6,128.7c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1
			c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
			c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2L347,139h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.4c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.4v0.1l0,0
			c0.3-0.2,0.6-0.4,0.9-0.6C348.9,128.8,349.2,128.7,349.6,128.7z M348.9,129.6c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.7c0,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2
			c0-0.8-0.2-1.5-0.6-2.1C350.1,129.9,349.5,129.6,348.9,129.6L348.9,129.6z"/>
		<path class="st6" d="M357.7,128.7c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7s0,0.6,0,0.7l0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C356.9,129,357.3,128.8,357.7,128.7z"
			/>
		<path class="st6" d="M360.6,131.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L360.6,131.7z
			 M360.7,131.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C360.7,131.1,360.7,131.1,360.7,131.1z"/>
		<path class="st6" d="M370.3,134.5c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5-0.1
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L370.3,134.5z M369.6,135.6c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,1,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.7-0.3C370.2,135.7,369.9,135.6,369.6,135.6z M369,129.2c-0.4,0-0.8,0.2-1,0.6
			c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.6c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C369.9,129.4,369.4,129.2,369,129.2L369,129.2z"/>
		<path class="st6" d="M378,128.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H374h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c-0.1-0.1-0.1-0.3,0-0.4
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0c0.3-0.3,0.6-0.5,1-0.7
			C377.1,128.9,377.6,128.7,378,128.7z"/>
		<path class="st6" d="M385,128.8c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1l0.1-0.1l0,0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3V131c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C383.8,129,384.4,128.8,385,128.8z M384.1,134.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.4,0.4-0.4,1.1,0,1.5C383.6,134.8,383.8,134.9,384.1,134.9L384.1,134.9z"/>
		<path class="st6" d="M393,128.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C392.2,128.9,392.6,128.8,393,128.7z"/>
		<path class="st6" d="M398.8,128.8h2c0.1,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1-0.1-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4l0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1h0.1l0,0
			L398.8,128.8z"/>
		<path class="st6" d="M402.8,133.6c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C402.3,133.7,402.5,133.7,402.8,133.6z"/>
		<path class="st6" d="M321.7,140.7c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2l-0.2,0.1H322c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v2h1.8c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.7v4.1c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H318c0-0.1-0.1-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-4.1h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.5v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3c0.2-0.3,0.5-0.6,0.9-0.8
			C321.1,140.8,321.4,140.7,321.7,140.7z"/>
		<path class="st6" d="M326.1,144.8c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1l0.1-0.1
			l0,0l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3V147c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C324.9,145,325.5,144.8,326.1,144.8z M325.3,150.9c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.4,0.4-0.4,1.1,0,1.5C324.7,150.8,325,150.9,325.3,150.9z"/>
		<path class="st6" d="M330.5,142.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L330.5,142.9z"/>
		<path class="st6" d="M334.5,142.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L334.5,142.9z"/>
		<path class="st6" d="M339.9,146.5v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1V146.5z M338.6,142.9
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.4-0.2,0.6-0.2c0.4,0,0.8,0.4,0.8,0.8c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.3,0.2-0.5,0.2C339,143.1,338.7,143,338.6,142.9z"/>
		<path class="st6" d="M346.3,144.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6H346c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2
			v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3
			v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2l-1.7-0.1H343h-0.6
			h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8
			c0-0.3,0-0.6-0.1-0.9c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c-0.1-0.1-0.1-0.3,0-0.4c0.8-0.1,1.5-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.6-0.1,0.9v0.1h0c0.3-0.3,0.6-0.5,1-0.7
			C345.5,144.9,345.9,144.8,346.3,144.7z"/>
		<path class="st6" d="M354.3,150.5c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.8,0.6-1.8,1-2.8,1c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.4,0.2-0.5
			c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.4-0.6-0.4-0.9
			c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7
			c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L354.3,150.5z M353.6,151.6
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,1,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C354.2,151.7,353.9,151.6,353.6,151.6z M352.9,145.2c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.6c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C353.8,145.4,353.4,145.2,352.9,145.2z"/>
		<path class="st6" d="M364,144.8h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H364v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0.1,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.2-0.5-0.1-0.8v-4.5h-0.9
			c0,0-0.1-0.1-0.1-0.1s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0
			h0L364,144.8z"/>
		<path class="st6" d="M368.1,147.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L368.1,147.7z
			 M368.2,147.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C368.2,147.1,368.2,147.1,368.2,147.1L368.2,147.1z"/>
		<path class="st6" d="M374.9,147.7c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.4c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L374.9,147.7z
			 M374.9,147.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C374.9,147.1,374.9,147.1,374.9,147.1z"/>
		<path class="st6" d="M382.3,144.8h2c0.1,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4l0.4-0.4l0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.1l0,0L382.3,144.8z"/>
		<path class="st6" d="M389.5,144.7c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H385c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5c0,0,0,0,0,0l0,0
			c0.3-0.3,0.6-0.5,1-0.7C388.7,144.9,389.1,144.7,389.5,144.7z"/>
		<path class="st6" d="M137.9,144.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C136.8,144.5,137.3,144.3,137.9,144.2z M137.1,150.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C136.5,150.2,136.8,150.3,137.1,150.3L137.1,150.3z"/>
		<path class="st6" d="M148.9,151.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V141
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.6c-0.2,0.4-0.5,0.9-0.8,1.2C150.8,150.7,149.8,151.1,148.9,151.2L148.9,151.2z M149.1,145.2
			c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C150.4,145.4,149.8,145.1,149.1,145.2
			L149.1,145.2z"/>
		<path class="st6" d="M157.1,144.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C155.9,144.5,156.5,144.3,157.1,144.2z M156.3,150.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C155.7,150.2,156,150.3,156.3,150.3z"/>
		<path class="st6" d="M163.4,151.2c-0.4,0-0.8-0.1-1.1-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C164.4,151,163.9,151.1,163.4,151.2z M163.6,145.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C164.9,145.4,164.3,145.1,163.6,145.2L163.6,145.2z"/>
		<path class="st6" d="M169.3,144.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.4,5.6
			c0,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5
			c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.1c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8
			c0.2-0.4,0.3-0.6,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			C168.3,144.4,168.8,144.4,169.3,144.4z"/>
		<path class="st6" d="M346,315c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.9c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V320h-0.9
			c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6V319c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.2-0.3,0.5-0.6,0.9-0.9C345.4,315.1,345.7,315,346,315z"/>
		<path class="st6" d="M350.3,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C349.2,319.3,349.8,319.1,350.3,319.1z M349.5,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C348.9,325,349.2,325.2,349.5,325.1z"/>
		<path class="st6" d="M354.7,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L354.7,317.1z"/>
		<path class="st6" d="M358.7,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L358.7,317.1z"/>
		<path class="st6" d="M364.1,320.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H363h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L364.1,320.8z
			 M362.8,317.2c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.6,0.2
			C363.2,317.4,363,317.3,362.8,317.2z"/>
		<path class="st6" d="M370.5,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H366c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C369.7,319.2,370.1,319,370.5,319z"/>
		<path class="st6" d="M378.5,324.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.3-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1l0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L378.5,324.8z M377.8,325.9
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.4,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C378.4,326,378.1,325.9,377.8,325.9z M377.2,319.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C378,319.7,377.6,319.5,377.2,319.5L377.2,319.5z"/>
		<path class="st6" d="M388.3,319.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			l0,0L388.3,319.1z"/>
		<path class="st6" d="M392.4,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L392.4,322z M392.4,321.4h2.6c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.1,0.5C392.5,321.4,392.4,321.4,392.4,321.4L392.4,321.4z"/>
		<path class="st6" d="M399.1,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L399.1,322z M399.2,321.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C399.2,321.4,399.2,321.4,399.2,321.4L399.2,321.4z"/>
		<path class="st6" d="M406.6,319.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0
			L406.6,319.1z"/>
		<path class="st6" d="M413.7,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5H414h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5c0,0,0,0,0,0h0
			c0.3-0.3,0.6-0.5,1-0.7C412.9,319.1,413.3,319,413.7,319z"/>
		<path class="st6" d="M181,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C179.9,408.5,180.4,408.4,181,408.4z M180.2,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C179.6,414.3,179.8,414.5,180.2,414.5L180.2,414.5z"/>
		<path class="st6" d="M191.9,415.3c-0.4,0-0.8-0.1-1.2-0.2h-0.2c-0.1,0-0.1,0-0.2,0h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3
			c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3
			c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1.2
			c-0.4,0.4-0.8,0.6-1.2,0.8C192.9,415.2,192.4,415.3,191.9,415.3z M192.2,409.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3
			c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1
			c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C193.4,409.6,192.8,409.3,192.2,409.3L192.2,409.3z"/>
		<path class="st6" d="M200.1,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			l0,0l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C198.9,408.6,199.5,408.4,200.1,408.4z M199.3,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C198.8,414.4,199,414.5,199.3,414.5z"/>
		<path class="st6" d="M206.4,415.3c-0.4,0-0.8-0.1-1.2-0.2h-0.2h-0.2h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.2,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.8,0.6-1.2,0.8
			C207.5,415.2,207,415.3,206.4,415.3z M206.7,409.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C207.9,409.6,207.3,409.3,206.7,409.3L206.7,409.3z"/>
		<path class="st6" d="M212.4,408.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6
			c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5
			c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8
			c0.1-0.2,0.2-0.5,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			C211.3,408.5,211.8,408.6,212.4,408.6z"/>
		<path class="st6" d="M219.2,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2l0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5C218.7,413.4,219,413.3,219.2,413.3z"/>
		<path class="st6" d="M154.7,431.3c-0.4,0-0.8-0.1-1.1-0.2h-0.2h-0.2H153h-0.2h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C155.6,431.2,155.2,431.3,154.7,431.3z M154.9,425.3c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C156.2,425.6,155.6,425.3,154.9,425.3L154.9,425.3z"/>
		<path class="st6" d="M160.9,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L160.9,427.3z
			 M161,426.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C161,426.7,161,426.7,161,426.7L161,426.7z"/>
		<path class="st6" d="M168.7,426.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H167h-0.6c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c-0.1,0.3-0.1,0.7-0.1,1L168.7,426.1z M167.4,422.5
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.1,0.1,0.2,0.3,0.2,0.5
			c0,0.2-0.1,0.4-0.2,0.6C168.2,422.8,167.8,422.8,167.4,422.5z"/>
		<path class="st6" d="M175.1,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H176h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1h0.1
			c0.3-0.3,0.6-0.5,1-0.7C174.3,424.5,174.7,424.4,175.1,424.4z"/>
		<path class="st6" d="M183.1,430.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.2,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1H184
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L183.1,430.1z M182.4,431.3c-0.5,0-1,0-1.4,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.3C183,431.3,182.7,431.3,182.4,431.3L182.4,431.3z M181.8,424.8
			c-0.4,0-0.8,0.2-1,0.5c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C182.6,425,182.2,424.8,181.8,424.8L181.8,424.8z"/>
		<path class="st6" d="M194.9,424.4c0.9,0,1.7,0.4,2.1,1.1c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.4
			c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.4v0.1l0,0
			c0.3-0.2,0.6-0.4,0.9-0.6C194.2,424.4,194.6,424.3,194.9,424.4z M194.2,425.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2
			c0-0.8-0.2-1.5-0.6-2.1C195.4,425.5,194.9,425.3,194.2,425.3z"/>
		<path class="st6" d="M203.1,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C202.3,424.6,202.7,424.4,203.1,424.4z"/>
		<path class="st6" d="M206,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L206,427.3z
			 M206.1,426.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C206.1,426.7,206.1,426.7,206.1,426.7L206.1,426.7z"/>
		<path class="st6" d="M215.7,430.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.6-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L215.7,430.1z M215,431.3c-0.5,0-1,0-1.4,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.8,0,1.5-0.3,2-0.9c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C215.6,431.3,215.3,431.3,215,431.3L215,431.3z M214.4,424.8c-0.4,0-0.8,0.2-1,0.5
			c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4C215.2,425,214.8,424.8,214.4,424.8L214.4,424.8z"/>
		<path class="st6" d="M223.3,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6H223c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H220h-0.6h-0.6c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1
			l0,0c0.3-0.3,0.6-0.5,1-0.7C222.5,424.5,222.9,424.4,223.3,424.4z"/>
		<path class="st6" d="M230.4,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C229.2,424.6,229.8,424.4,230.4,424.4z M229.5,430.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C228.9,430.4,229.2,430.5,229.5,430.5z"/>
		<path class="st6" d="M238.4,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H235h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1
			h0.1c0.3-0.3,0.6-0.5,1-0.7C237.6,424.5,238,424.4,238.4,424.4z"/>
		<path class="st6" d="M244.1,424.5h2c0.1,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H242
			c-0.1,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1-0.1,0.1-0.1h0.1l0,0L244.1,424.5z"
			/>
		<path class="st6" d="M294.8,404.3c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1H295c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.9c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H291
			c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-4.1h-0.9
			c0,0-0.1,0-0.1-0.1s0-0.1,0.2-0.2l0.2-0.3h0.6v-0.3c0-1,0.4-2.1,1-2.9c0.2-0.3,0.5-0.6,0.9-0.8
			C294.2,404.4,294.5,404.3,294.8,404.3z"/>
		<path class="st6" d="M299.1,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C298,408.6,298.6,408.4,299.1,408.4z M298.3,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C297.7,414.4,298,414.5,298.3,414.5L298.3,414.5z"/>
		<path class="st6" d="M303.5,406.5c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H303h-0.5c0-0.1-0.1-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L303.5,406.5z"/>
		<path class="st6" d="M307.5,406.5c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3v-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L307.5,406.5z"/>
		<path class="st6" d="M312.9,410.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1V410.1z M311.6,406.5
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.1,0.1,0.2,0.3,0.2,0.5
			c0,0.2-0.1,0.4-0.2,0.6C312.4,406.8,312,406.8,311.6,406.5z"/>
		<path class="st6" d="M319.3,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H316h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C318.5,408.5,318.9,408.4,319.3,408.4z"/>
		<path class="st6" d="M327.3,414.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.6-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.2,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L327.3,414.1z M326.6,415.3c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.7-0.3C327.2,415.3,326.9,415.3,326.6,415.3L326.6,415.3z M326,408.8
			c-0.4,0-0.8,0.2-1,0.5c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C326.8,409,326.4,408.8,326,408.8L326,408.8z"/>
		<path class="st6" d="M297.1,424.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.4,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H295c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2
			c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4l0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0.1,0,0.1-0.1h0.1h0L297.1,424.5z"/>
		<path d="M301.2,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L301.2,427.3z M301.3,426.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C301.3,426.7,301.3,426.7,301.3,426.7L301.3,426.7z"/>
		<path d="M307.9,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
			c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L307.9,427.3z M308,426.7h2.5
			c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C308,426.7,308,426.7,308,426.7L308,426.7z"/>
		<path d="M315.4,424.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0.1,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2l-0.3,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1s0-0.1,0.2-0.2l0.2-0.3
			h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.1l0,0L315.4,424.5z"/>
		<path d="M322.6,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3v-0.1V421c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5 M320.4,425.4L320.4,425.4c0.3-0.3,0.7-0.5,1-0.7
			c0.4-0.2,0.8-0.3,1.2-0.3L320.4,425.4z"/>
      </g>
      <g id='sp' style={{ opacity: selected === 'SP' ? 1 : 0 }}>
      <path d="M292.8,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1
			c0,0,0.1,0,0.1,0c0,0,0,0,0,0l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5
			c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2
			c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9C291.6,80.5,292.2,80.3,292.8,80.2z M291.9,86.3c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7
			c0,0.3,0.1,0.6,0.3,0.8C291.4,86.2,291.6,86.3,291.9,86.3z"/>
		<path d="M303.7,87.2c-0.4,0-0.8-0.1-1.2-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V77
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4V81c0,0,0,0,0,0.1
			l0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.6c-0.2,0.4-0.5,0.9-0.8,1.2C305.7,86.7,304.7,87.1,303.7,87.2L303.7,87.2z M304,81.2
			c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
			c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C305.2,81.4,304.6,81.1,304,81.2
			L304,81.2z"/>
		<path d="M312,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1
			c0,0,0.1,0,0.1,0h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2
			c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5
			c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C310.8,80.5,311.4,80.3,312,80.2z M311.2,86.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C310.6,86.2,310.9,86.3,311.2,86.3L311.2,86.3z"/>
		<path d="M318.2,87.2c-0.4,0-0.8-0.1-1.1-0.2l-0.2-0.1h-0.2h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			V81c0,0,0,0,0,0.1l0,0h0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C319.3,87,318.8,87.1,318.2,87.2z M318.5,81.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.3
			c0-0.8-0.2-1.5-0.6-2.1C319.8,81.4,319.1,81.1,318.5,81.2L318.5,81.2z"/>
		<path d="M324.2,80.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.2c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-1.1,2.7-1.9,4.5-2.4,5.6
			c0,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5
			c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8
			c0.1-0.2,0.2-0.5,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			C323.1,80.4,323.6,80.4,324.2,80.4z"/>
		<path d="M331,85.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C330.5,85.2,330.8,85.1,331,85.1z"/>
		<path d="M340.5,87.2c-0.4,0-0.8-0.1-1.1-0.2l-0.2-0.1H339h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			V81c0,0,0,0,0,0.1l0,0h0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C341.5,87,341,87.1,340.5,87.2z M340.8,81.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C342,81.4,341.4,81.1,340.8,81.2L340.8,81.2z"/>
		<path d="M346.7,83.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L346.7,83.2z
			 M346.8,82.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.1,0.3-0.2,0.5
			C346.8,82.5,346.8,82.6,346.8,82.6L346.8,82.6z"/>
		<path d="M354.5,82v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L354.5,82z M353.3,78.4
			c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.5,0.2C353.6,78.6,353.4,78.5,353.3,78.4
			L353.3,78.4z"/>
		<path d="M361,80.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5H357h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C360.2,80.4,360.6,80.2,361,80.2z"/>
		<path d="M368.9,86c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,0.9
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.3-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.2,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2L368.9,86z M368.2,87.1
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.4-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.6-0.3C368.9,87.1,368.5,87.1,368.2,87.1z M367.6,80.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C368.5,80.9,368,80.6,367.6,80.7z"/>
		<path d="M382.2,81c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1l-0.3-0.3l-0.3-0.2
			c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3,0-0.5,0c-0.5,0-1,0.3-1.2,0.7c-0.4,0.5-0.6,1.2-0.6,1.8c-0.2,1.3,0.8,2.5,2.1,2.6
			c0.1,0,0.3,0,0.4,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.1-0.2-1.6-0.5
			c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2C382.1,80.8,382.1,80.9,382.2,81z"/>
		<path d="M387.6,80.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			L389,87h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0l0,0c0.3-0.3,0.6-0.5,1-0.7
			C386.7,80.4,387.2,80.2,387.6,80.2z"/>
		<path d="M394.6,80.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.2h0.1l0.1-0.1h0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C393.5,80.5,394.1,80.3,394.6,80.2z M393.8,86.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C393.2,86.2,393.5,86.3,393.8,86.3z"/>
		<path d="M400.6,80.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0,0.4c0,0.6-0.2,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			s-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C399.5,80.4,400.1,80.2,400.6,80.2z"/>
		<path d="M405.1,83.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L405.1,83.2z
			 M405.2,82.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.5,1
			C405.2,82.5,405.2,82.6,405.2,82.6z"/>
		<path d="M414.4,80.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1
			c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1l-0.5,0.1c-0.2,0-0.4,0.1-0.5,0.1l-0.5,0.1l-0.4,0.1h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.6c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.8,0-1.5-0.4-1.9-1
			c-0.6-0.7-0.9-1.5-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,1-1.2c0.4-0.3,0.8-0.6,1.2-0.8C413.6,80.2,414,80.2,414.4,80.2z
			 M413.9,80.9c-0.6,0-1.1,0.3-1.4,0.8c-0.7,1.2-0.7,2.6,0.1,3.8c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C414.7,80.9,414.3,80.8,413.9,80.9z"/>
		<path d="M419.8,85.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C419.3,85.2,419.5,85.1,419.8,85.1z"/>
		<path d="M298.4,92.2c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v2h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-4h-0.9
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.3,0.6-0.6,0.9-0.8C297.8,92.3,298.1,92.2,298.4,92.2z"/>
		<path d="M302.8,96.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C301.6,96.5,302.2,96.3,302.8,96.2z M301.9,102.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.4
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C301.4,102.2,301.6,102.3,301.9,102.3L301.9,102.3z"/>
		<path d="M307.1,94.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H306
			c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L307.1,94.3z"/>
		<path d="M311.1,94.3c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H310c0-0.1-0.1-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L311.1,94.3z"/>
		<path d="M316.5,98v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V98z M315.3,94.4
			c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c-0.1,0.1-0.3,0.2-0.6,0.2C315.6,94.6,315.4,94.5,315.3,94.4L315.3,94.4z"/>
		<path d="M323,96.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C322.2,96.4,322.6,96.2,323,96.2z"/>
		<path d="M330.9,102c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,0.9
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2L330.9,102z M330.2,103.1
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C330.9,103.1,330.5,103.1,330.2,103.1z M329.6,96.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C330.5,96.9,330.1,96.6,329.6,96.7z"/>
		<path d="M340.7,96.3h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.8,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0L340.7,96.3z
			"/>
		<path d="M344.8,99.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L344.8,99.2z M344.9,98.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C344.9,98.5,344.9,98.6,344.9,98.6L344.9,98.6z"/>
		<path d="M351.5,99.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L351.5,99.2z M351.6,98.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5C351.6,98.5,351.6,98.6,351.6,98.6L351.6,98.6z"/>
		<path d="M359,96.3h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H359v4.1c0,0.8,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0
			L359,96.3z"/>
		<path d="M366.2,96.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H367h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5c0,0,0,0,0,0h0
			c0.3-0.3,0.6-0.5,1-0.7C365.4,96.4,365.8,96.2,366.2,96.2z"/>
		<path d="M371.1,101.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C370.6,101.2,370.8,101.1,371.1,101.1z"/>
		<path d="M378.8,101.2c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1h-0.8c0,0,0,0,0-0.1c0-0.1,0-0.3,0.1-0.4
			c0.1-0.2,0.1-0.2,0.1-0.2h0.6c0,0,0.1,0,0.1-0.1v-0.2c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3c0.3-0.3,0.6-0.6,1-0.8
			c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.4-0.2l0.2-0.1l0,0
			c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.1c0,0.5,0,0.9,0.1,1.4c0,0.1,0.2,0.2,0.4,0.2
			c0.2,0.1,0.3,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3L384,103l-1.7,0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.7,0.1-1.1v-6c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.4-0.1-0.6
			c-0.2-0.3-0.4-0.6-0.7-0.8c-0.3-0.2-0.6-0.4-1-0.4c-0.3,0-0.6,0.2-0.8,0.4c-0.2,0.3-0.4,0.6-0.5,1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8
			l0,0c0,0,0,0,0,0h1.7c0,0,0,0,0.1,0c0,0,0,0,0,0.1c0,0.2-0.1,0.4-0.2,0.6h-1.6c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6
			c0,0.5,0,0.9,0.1,1.4c0,0.1,0.1,0.2,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3l-1.7-0.1l-1.7,0.1
			c0,0-0.1-0.1-0.1-0.3s0-0.2,0.1-0.2c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.4-0.1,0.4-0.2C378.8,101.9,378.8,101.5,378.8,101.2z"/>
		<path d="M387.4,96.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.2c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-1.1,2.7-1.9,4.5-2.3,5.6
			c0,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0.1-0.6-0.2-0.7-0.5
			c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8
			c0.1-0.2,0.2-0.5,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			C386.3,96.4,386.9,96.4,387.4,96.4z"/>
		<path d="M396,98v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V98z M394.7,94.4
			c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c-0.1,0.1-0.4,0.2-0.6,0.2C395.1,94.6,394.9,94.5,394.7,94.4L394.7,94.4z"/>
		<path d="M402.4,96.2c0.3,0,0.6,0,0.9,0.1c0.5,0.2,0.8,0.5,0.9,1c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.4
			c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.6-0.1,0.9v0.1c0,0,0,0,0,0c0.3-0.3,0.6-0.5,1-0.7
			C401.6,96.4,402,96.2,402.4,96.2z"/>
		<path d="M410.4,102c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.2c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,0.9
			c-0.8,0.6-1.8,1-2.8,1c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.4,0.2-0.5
			c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.4-0.6-0.4-0.9
			c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7
			c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1c-0.1,0.1-0.3,0.2-0.4,0.3
			c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.4,0.2,0.7,0.2L410.4,102z M409.7,103.1c-0.5,0-1,0-1.5,0.1
			c-0.2,0.1-0.5,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			c0.3-0.1,0.7-0.3,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.3
			C410.3,103.1,410,103.1,409.7,103.1z M409,96.7c-0.4,0-0.8,0.2-1,0.6c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4
			c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C409.9,96.9,409.4,96.6,409,96.7z"/>
		<path d="M414.6,101.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C414.1,101.2,414.4,101.1,414.6,101.1z"/>
		<path d="M313.2,112.2c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.5,0.6,0.6,1c0.6-0.7,1.4-1.1,2.3-1.3c0.3,0,0.6,0,0.9,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H317c0-0.1-0.1-0.2,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.8
			c0-0.2,0-0.4-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4-0.2-1-0.2-1.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.1,0.1-0.2,0.2-0.2,0.3v0.1c0,0,0,0.1,0,0.1c0,0.4,0.1,0.7,0.1,1.1v1.9c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2
			c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H313c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6
			c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1H310h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.6c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
			s0,0.1,0,0.1l0,0c0.4-0.3,0.8-0.6,1.2-0.8C312.6,112.3,312.9,112.2,313.2,112.2z"/>
		<path d="M324.6,112.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C322.8,112.5,323.7,112.2,324.6,112.2z M324.4,112.7
			c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.3-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C325.3,112.9,324.8,112.7,324.4,112.7z"/>
		<path d="M333.6,112.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			L335,119h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2
			v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3
			v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.7
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0c0.3-0.3,0.6-0.5,1-0.7
			C332.8,112.4,333.2,112.2,333.6,112.2z"/>
		<path d="M338.6,115.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L338.6,115.2z M338.7,114.6h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4
			c-0.2,0.3-0.4,0.6-0.5,1C338.7,114.5,338.7,114.6,338.7,114.6z"/>
		<path d="M345.2,112.4c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.9,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-1.1,2.7-1.9,4.5-2.4,5.6c0,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.4c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C344.1,112.4,344.6,112.4,345.2,112.4z"/>
		<path d="M352,117.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.7c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C351.5,117.2,351.8,117.1,352,117.1z"/>
		<path d="M361.2,112.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			s-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C360.1,112.4,360.7,112.2,361.2,112.2z"/>
		<path d="M369,112.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C368.2,112.4,368.6,112.2,369,112.2z"/>
		<path d="M376,112.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1-0.1h0.1h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C374.8,112.5,375.4,112.3,376,112.2z M375.2,118.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C374.6,118.2,374.9,118.3,375.2,118.3L375.2,118.3z"/>
		<path d="M381.6,117.2c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2L381,119h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-0.9l0.5-0.4
			l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l-0.1-0.1h-0.1H384h-0.1h-0.2c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-0.2,0-0.4,0-0.6,0.1
			c-0.2,0-0.4,0.1-0.6,0.2c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2c0.2,0.2,0.4,0.4,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.3-2.4l-0.1-0.1l-0.1-0.1l0,0L381.6,117.2z"/>
		<path d="M388.1,115.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L388.1,115.2z
			 M388.2,114.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C388.2,114.5,388.2,114.6,388.2,114.6z"/>
		<path d="M396.2,112.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1L394,119c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4
			C395,112.4,395.6,112.2,396.2,112.2z"/>
		<path d="M400.5,117.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.7
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C400.1,117.2,400.3,117.1,400.5,117.1z"/>
		<path d="M299.3,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			s-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C298.1,128.3,298.7,128.2,299.3,128.2z"/>
		<path d="M306.6,128.2c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1
			c0,0.5-0.1,1-0.3,1.4c-0.4,0.9-1.1,1.6-2,2c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7,0h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.4
			c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.4v0.1h0
			c0.3-0.2,0.6-0.4,0.9-0.6C305.9,128.3,306.2,128.2,306.6,128.2z M305.9,129.1c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2
			c0-0.8-0.2-1.5-0.6-2.1C307.1,129.4,306.5,129.1,305.9,129.1z"/>
		<path d="M312.8,130v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1V130z
			 M311.6,126.4c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c-0.1,0.1-0.3,0.2-0.6,0.2C311.9,126.6,311.7,126.5,311.6,126.4L311.6,126.4z"/>
		<path d="M318.5,128.2c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.5,0.1c0,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1
			c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1l-0.5,0.1l-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1L320,135h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.6c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.4c-0.8,0-1.5-0.4-1.9-1
			c-0.6-0.7-0.9-1.5-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.4,0.6-0.8,1-1.2c0.4-0.3,0.8-0.6,1.2-0.8
			C317.8,128.2,318.2,128.2,318.5,128.2z M318,128.9c-0.6,0-1.1,0.3-1.4,0.8c-0.4,0.5-0.5,1.2-0.5,1.8c0,0.7,0.2,1.4,0.6,1.9
			c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
			C318.8,128.9,318.4,128.8,318,128.9L318,128.9z"/>
		<path d="M324,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L324,131.2z
			 M324.1,130.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.5,1
			C324.1,130.5,324.1,130.6,324.1,130.6L324.1,130.6z"/>
		<path d="M333.7,128.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.3,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7s0,0,0,0h0
			c0.2-0.4,0.5-0.8,0.8-1.1C333,128.4,333.3,128.3,333.7,128.2z"/>
		<path d="M338,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0,0.4c0,0.6-0.2,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.4,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.4-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C336.8,128.4,337.4,128.2,338,128.2z"/>
		<path d="M342.4,133.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5
			C341.9,133.2,342.1,133.1,342.4,133.1z"/>
		<path d="M351.1,128.3h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5H351v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5H349c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			h0L351.1,128.3z"/>
		<path d="M357.4,128.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C355.6,128.5,356.5,128.2,357.4,128.2z M357.2,128.7
			c-0.4,0-0.7,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.1,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.3-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C358.1,128.9,357.7,128.7,357.2,128.7L357.2,128.7z"/>
		<path d="M366.1,128.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.3,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.5-0.1,0.7c0,0.4,0,0.7,0,0.7
			s0,0,0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C365.3,128.5,365.7,128.3,366.1,128.2z"/>
		<path d="M372.4,128.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.6c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H369h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.7c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C371.6,128.4,372,128.2,372.4,128.2z"/>
		<path d="M379.4,128.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0l0,0
			c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C378.3,128.5,378.8,128.3,379.4,128.2z M378.6,134.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			l-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8C378,134.2,378.3,134.3,378.6,134.3z"/>
		<path d="M386.7,128.2c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0.1,0.5,0.1c0,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1
			c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1l-0.5,0.1l-0.5,0.1l-0.5,0.1l-0.4,0.1H388c-0.1,0-0.1-0.1-0.1-0.3v-0.6
			c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.4c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.9-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,1-1.2c0.4-0.3,0.8-0.6,1.2-0.8C385.9,128.2,386.3,128.2,386.7,128.2z M386.2,128.9
			c-0.6,0-1.1,0.3-1.4,0.8c-0.7,1.2-0.7,2.6,0.1,3.8c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5C387,128.9,386.6,128.8,386.2,128.9z"/>
		<path d="M394.4,128.2c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C392.7,128.5,393.5,128.2,394.4,128.2z M394.2,128.7
			c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.2,0.9-0.2,1.9,0.1,2.8c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.3-0.9,0.2-1.9-0.1-2.8c-0.1-0.5-0.4-0.9-0.8-1.2
			C395.1,128.9,394.7,128.7,394.2,128.7L394.2,128.7z"/>
		<path d="M400.1,131.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L400.1,131.2z M400.2,130.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.5,1
			C400.2,130.5,400.2,130.6,400.2,130.6L400.2,130.6z"/>
		<path d="M408.2,128.2c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1L406,135c-0.1-0.3-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4
			C407,128.4,407.6,128.2,408.2,128.2z"/>
		<path d="M412.5,133.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C412.1,133.2,412.3,133.1,412.5,133.1z"/>
		<path d="M339,144.4h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2l0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9l0.2-0.6
			c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.4-0.3-0.7-0.3
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6h0.8l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5
			c0,0,0,0.1,0,0.2l0.1,0.3c0,0.1,0.1,0.3,0.2,0.5l0.3,0.7l0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
			c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1h-0.3c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2h0.6
			h0.8h0.5h0.5h0.5c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c-0.1-0.1-0.1-0.3,0-0.5h0.5h0.5
			L339,144.4z"/>
		<path d="M352.3,144.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C351.2,144.5,351.7,144.3,352.3,144.2z M351.5,150.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.4c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C350.9,150.2,351.2,150.3,351.5,150.3z"/>
		<path d="M357.8,144.3h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0h0L357.8,144.3
			z"/>
		<path d="M361.9,147.2c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,0.9
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L361.9,147.2z
			 M362,146.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C362,146.5,362,146.6,362,146.6L362,146.6z"/>
		<path d="M371.6,144.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0
			c0.2-0.4,0.5-0.8,0.8-1.1C370.8,144.4,371.2,144.2,371.6,144.2z"/>
		<path d="M344.4,315c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.3-0.3,0.7-0.2,1.1v1.9h1.9c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2V320h-0.9
			c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6V319c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.3,0.6-0.6,0.9-0.9C343.8,315.1,344.1,315,344.4,315z"/>
		<path d="M348.8,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C347.6,319.3,348.2,319.1,348.8,319.1z M347.9,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C347.4,325,347.6,325.2,347.9,325.1z"/>
		<path d="M353.1,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H352
			c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L353.1,317.1z"/>
		<path d="M357.1,317.1c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6H356c0-0.1-0.1-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2L357.1,317.1z"/>
		<path d="M362.5,320.8v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1L362.5,320.8z
			 M361.3,317.2c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1
			c-0.1,0.1-0.3,0.2-0.6,0.2C361.6,317.4,361.4,317.3,361.3,317.2L361.3,317.2z"/>
		<path d="M369,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1
			l0,0c0.3-0.3,0.6-0.5,1-0.7C368.2,319.2,368.6,319,369,319z"/>
		<path d="M376.9,324.8c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.7,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7h0.4c0.1,0,0.2,0,0.4,0.1l0.3,0.1c0.1,0,0.2,0.1,0.3,0.1l0.2,0.1l0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3,0-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L376.9,324.8z M376.2,325.9
			c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.4,1,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.3-0.7-0.3C376.9,326,376.5,325.9,376.2,325.9z M375.6,319.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3
			c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C376.5,319.7,376.1,319.5,375.6,319.5L375.6,319.5z"/>
		<path d="M386.7,319.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.4-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1l0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			l0,0L386.7,319.1z"/>
		<path d="M390.8,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L390.8,322z M390.9,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.1,0.5C390.9,321.4,390.9,321.4,390.9,321.4L390.9,321.4z"/>
		<path d="M397.5,322c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.5c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L397.5,322z M397.6,321.4h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C397.6,321.4,397.6,321.4,397.6,321.4L397.6,321.4z"/>
		<path d="M405,319.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H405v4.1c0,0.9,0.4,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0,0
			L405,319.1z"/>
		<path d="M412.2,319c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H413h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.6-0.3,2.3-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5c0,0,0,0,0,0h0
			c0.3-0.3,0.6-0.5,1-0.7C411.4,319.2,411.8,319,412.2,319z"/>
		<path d="M417.1,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C416.6,324.1,416.8,324,417.1,324z"/>
		<path d="M340.4,335c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3
			l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.2,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.7-0.4-0.9
			c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
			c0-0.5,0.2-1,0.7-1.4C339.3,335.1,339.9,335,340.4,335z"/>
		<path d="M348.2,335c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H349h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-1.5c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-2.8c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0,0.2c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C347.3,335.2,347.8,335,348.2,335z"/>
		<path d="M355.2,335.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C354.1,335.3,354.7,335.1,355.2,335.1z M354.4,341.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C353.8,341,354.1,341.1,354.4,341.1L354.4,341.1z"/>
		<path d="M360.8,340.1c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.2,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.2v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3l0.3-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l-0.1-0.1l-0.1,0h-0.1H363h-0.2c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.1,0,0.2c-0.2,0-0.4,0-0.6,0.1
			c-0.2,0-0.4,0.1-0.6,0.2c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.3-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0L360.8,340.1z"/>
		<path d="M367.4,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.3-0.9,0.9-1.6,1.7-2c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.3,0.3,0.5,0.7,0.6,1.1
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L367.4,338z M367.4,337.4h2.6
			c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.3-0.4,0.6-0.5,1
			C367.4,337.4,367.4,337.4,367.4,337.4z"/>
		<path d="M375.4,335c0.3,0,0.6,0,0.9,0.1l0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.2,0,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5c-0.5,0.4-1.1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.2-0.1,0.3-0.1
			c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8
			c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3
			c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1,0.7-1.4C374.2,335.1,374.8,335,375.4,335z"/>
		<path d="M379.8,340c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,0.9-1.2,1.2
			c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C379.3,340,379.5,340,379.8,340z"/>
		<path d="M387.5,335.2h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2l0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9l0.2-0.6
			c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.4-0.3-0.7-0.3
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2l0.6,0h0.8l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c-0.5,0-0.8,0.2-0.8,0.5
			c0,0,0,0.1,0,0.2s0.1,0.2,0.1,0.3l0.2,0.5c0.1,0.2,0.2,0.5,0.3,0.7l0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
			c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.3-0.1h-0.3c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2l0.6,0
			h0.8h0.5h0.5h0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4
			c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3
			c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.2h0.5h0.5L387.5,335.2z"/>
		<path d="M400.8,335.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C399.7,335.3,400.2,335.1,400.8,335.1z M400,341.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			l-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C399.4,341,399.7,341.2,400,341.1L400,341.1z"
			/>
		<path d="M406.3,335.1h2c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9c0,0-0.1,0-0.1-0.1
			c0-0.1,0.1-0.2,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0L406.3,335.1z"
			/>
		<path d="M410.4,338c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.5,0.2,0.9,0.5,1.1,1
			c0.1,0.2,0.2,0.4,0.2,0.5c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L410.4,338z
			 M410.5,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C410.5,337.4,410.5,337.4,410.5,337.4z"/>
		<path d="M420.1,335c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0l-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.2c0,0.1,0.2,0.1,0.5,0.2s0.6,0.1,0.8,0.1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2
			v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0l0,0
			c0.2-0.4,0.5-0.8,0.8-1.1C419.3,335.3,419.7,335,420.1,335z"/>
		<path d="M113.5,319.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.3-0.9
			C112.4,319.3,112.9,319.1,113.5,319.1z M112.7,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C112.1,325,112.4,325.2,112.7,325.1z"/>
		<path d="M124.5,326c-0.4,0-0.8-0.1-1.2-0.2l-0.2,0l-0.2,0h-0.2h-0.2h-0.2h-0.2c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.8,0,1.6,0.4,2.1,1.1c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8C125.5,325.9,125,326,124.5,326z
			 M124.7,320c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.4,0.8
			c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1
			C126,320.3,125.4,320,124.7,320z"/>
		<path d="M132.7,319.1c0.5,0,0.9,0.2,1.2,0.6c0.4,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0h0.1h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3c0.5-0.1,0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.2s0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C131.6,319.3,132.1,319.1,132.7,319.1z M131.9,325.1c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C131.3,325,131.6,325.1,131.9,325.1z"/>
		<path d="M139,326c-0.4,0-0.8-0.1-1.2-0.2l-0.2,0l-0.2,0h-0.2h-0.2H137h-0.2c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
			c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.6-0.3,2.3-0.7c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1l0,0h0.1
			c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.3,0.8,0.2,1.7-0.1,2.5c-0.2,0.4-0.5,0.9-0.8,1.2C141,325.5,140,326,139,326z M139.2,320c-0.2,0-0.5,0.1-0.7,0.1
			c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4
			c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.8-0.2-1.5-0.6-2.1C140.5,320.3,139.9,320,139.2,320L139.2,320z"/>
		<path d="M144.9,319.2c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.6,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.6-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0.1-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.1c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.3,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C143.9,319.2,144.4,319.2,144.9,319.2z"/>
		<path d="M297.6,415.3c-0.4,0-0.8-0.1-1.1-0.2h-0.2h-0.2H296h-0.2h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			l0,0h0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C298.7,415.2,298.2,415.3,297.6,415.3z M297.9,409.3c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C299.2,409.6,298.5,409.3,297.9,409.3L297.9,409.3z"/>
		<path d="M303.2,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L303.2,411.3z
			 M303.3,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C303.3,410.7,303.3,410.7,303.3,410.7L303.3,410.7z"/>
		<path d="M310.4,410.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c-0.1,0.3-0.1,0.7-0.1,1V410.1z M309.1,406.5
			c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.4,0,0.8,0.4,0.8,0.8c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.3,0.2-0.5,0.2C309.5,406.7,309.3,406.6,309.1,406.5z"/>
		<path d="M316.2,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1H317h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1h0
			c0.3-0.3,0.6-0.5,1-0.7C315.3,408.5,315.8,408.3,316.2,408.4z"/>
		<path d="M323.5,414.1c0.6,0,1.2,0.2,1.7,0.5c0.4,0.2,0.7,0.7,0.8,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.4-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4l0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.4-0.6-0.4-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.3-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.6c0.5-0.5,1.2-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1l0.3,0.1l0.3,0.1l0.2,0.1
			c0.1,0,0.2,0.1,0.4,0.1l1.7-0.2c0.1,0,0.1,0,0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3l-1-0.1h-0.1
			c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.2-0.8,1.6c-0.5,0.5-1.2,0.7-1.9,0.7c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.3,0-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
			L323.5,414.1z M322.8,415.3c-0.5,0-1,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.3,0.6c0,0.5,0.2,0.9,0.5,1.3
			c0.4,0.3,1,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.4-0.8c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.3C323.4,415.3,323.1,415.3,322.8,415.3L322.8,415.3z M322.2,408.8
			c-0.4,0-0.8,0.2-1,0.5c-0.3,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.2,0.4,0.6,0.6,1.1,0.6c0.4,0,0.8-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C323.1,409,322.6,408.8,322.2,408.8L322.2,408.8z"/>
		<path d="M296.8,425.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C296.8,425,296.8,425.1,296.8,425.2z"/>
		<path d="M301.6,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5l0,0l0,0c0.3-0.3,0.6-0.5,1-0.7
			C300.7,424.4,301.2,424.3,301.6,424.4z"/>
		<path d="M308,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0h0
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C306.8,424.6,307.4,424.4,308,424.4z M307.2,430.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C306.6,430.4,306.9,430.5,307.2,430.5L307.2,430.5z"/>
		<path d="M313.4,424.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.4,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C312.2,424.5,312.8,424.3,313.4,424.4z"/>
		<path d="M317.2,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L317.2,427.3z
			 M317.3,426.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C317.3,426.7,317.3,426.7,317.3,426.7L317.3,426.7z"/>
		<path d="M325.9,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3v-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2
			c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1l-0.5,0.1c-0.2,0-0.4,0.1-0.5,0.1l-0.5,0.1l-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.8-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.2-0.4,0.6-0.8,1-1.2c0.4-0.3,0.8-0.6,1.2-0.8C325.1,424.4,325.5,424.3,325.9,424.4z M325.4,425
			c-0.6,0-1.1,0.3-1.4,0.8c-0.4,0.5-0.5,1.2-0.5,1.8c0,0.7,0.2,1.4,0.6,2c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C326.2,425.1,325.8,425,325.4,425L325.4,425z"/>
		<path d="M117.2,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.3-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C116,408.6,116.6,408.4,117.2,408.4z M116.4,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C115.8,414.4,116.1,414.5,116.4,414.5z"/>
		<path d="M126.9,415.3c-0.4,0-0.8-0.1-1.2-0.2h-0.2h-0.2h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1v-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			s0,0,0,0h0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C127.9,415.2,127.4,415.3,126.9,415.3z M127.2,409.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C128.4,409.6,127.8,409.3,127.2,409.3L127.2,409.3z"/>
		<path d="M134.5,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C133.4,408.6,133.9,408.4,134.5,408.4z M133.7,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C133.1,414.4,133.4,414.5,133.7,414.5L133.7,414.5z"/>
		<path d="M140.1,415.3c-0.4,0-0.8-0.1-1.1-0.2h-0.2h-0.2h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C141.1,415.2,140.6,415.3,140.1,415.3z M140.4,409.3c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C141.7,409.6,141,409.3,140.4,409.3L140.4,409.3z"/>
		<path d="M145.4,408.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.2c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.4,5.6l-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C144.4,408.5,144.9,408.6,145.4,408.6z"/>
		<path d="M151.6,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C151.2,413.4,151.4,413.3,151.6,413.3z"/>
		<path d="M159.8,415.3c-0.4,0-0.8-0.1-1.1-0.2h-0.2h-0.2h-0.2H158h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.8-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C160.8,415.2,160.3,415.3,159.8,415.3z M160.1,409.3c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C161.3,409.6,160.7,409.3,160.1,409.3L160.1,409.3z"/>
		<path d="M165.4,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L165.4,411.3z
			 M165.5,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C165.5,410.7,165.5,410.7,165.5,410.7L165.5,410.7z"/>
		<path d="M171.5,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L171.5,411.3z
			 M171.6,410.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C171.6,410.7,171.6,410.7,171.6,410.7L171.6,410.7z"/>
		<path d="M178.9,408.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C177.8,408.5,178.3,408.3,178.9,408.4z"/>
		<path d="M182.6,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2l0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5C182.1,413.4,182.4,413.3,182.6,413.3z"/>
		<path d="M190.8,415.3c-0.4,0-0.8-0.1-1.2-0.2h-0.2h-0.2h-0.2H189h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.1c0,0,0,0,0,0.1
			l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.6,0.5,1
			c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C191.8,415.2,191.3,415.3,190.8,415.3z M191.1,409.3c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C192.3,409.6,191.7,409.3,191.1,409.3L191.1,409.3z"/>
		<path d="M199.4,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C198.6,408.6,199,408.4,199.4,408.4z"/>
		<path d="M201.7,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L201.7,411.3z
			 M201.8,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C201.7,410.7,201.7,410.7,201.8,410.7L201.8,410.7z"/>
		<path d="M209.8,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C208.6,408.6,209.2,408.4,209.8,408.4z M208.9,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C208.4,414.4,208.6,414.5,208.9,414.5z"/>
		<path d="M216.4,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.3,0-0.5-0.1-0.8
			c-0.1-0.2-0.4-0.3-0.9-0.3v-0.1v-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2
			c-0.1,0.4-0.2,0.9-0.2,1.4v6.7c0,0.5,0,1,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2
			c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1l-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1l-0.5,0.1l-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1-0.1-0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.8,0-1.5-0.4-1.9-1c-0.6-0.7-0.8-1.5-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,1-1.2c0.4-0.3,0.8-0.6,1.2-0.8C215.7,408.4,216,408.3,216.4,408.4z M215.9,409
			c-0.6,0-1.1,0.3-1.4,0.8c-0.4,0.5-0.5,1.2-0.5,1.8c0,0.7,0.2,1.4,0.6,2c0.4,0.5,1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C216.7,409.1,216.3,409,215.9,409L215.9,409z"/>
		<path d="M221.2,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C220.7,413.4,220.9,413.3,221.2,413.3z"/>
		<path d="M232,409.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9c-0.2,1.3,0.8,2.5,2.1,2.6
			c0.2,0,0.3,0,0.5,0c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.6-0.5
			c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0.1,0.1,0.1,0.1,0.1,0.2C231.9,409,232,409.1,232,409.2z"/>
		<path d="M236.8,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C236,408.6,236.4,408.4,236.8,408.4z"/>
		<path d="M241.1,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C240,408.6,240.6,408.4,241.1,408.4z M240.3,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8C239.7,414.4,240,414.5,240.3,414.5z"/>
		<path d="M246.8,415.3c-0.4,0-0.8-0.1-1.1-0.2h-0.2h-0.2h-0.2h-0.2h-0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
			c0-0.1-0.1-0.1-0.1-0.2c0.1-0.4,0.2-0.8,0.2-1.2v-7c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.1c0,0,0,0,0,0.1l0,0l0,0c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.8
			c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.5c-0.2,0.4-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.2,0.8
			C247.8,415.2,247.3,415.3,246.8,415.3z M247,409.3c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0-0.8-0.2-1.5-0.6-2.1C248.3,409.5,247.7,409.3,247,409.3L247,409.3z"/>
		<path d="M253.7,408.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2l0.4,0.2
			l0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.2-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C252.5,408.5,253.1,408.3,253.7,408.4z"/>
		<path d="M257.4,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C256.9,413.4,257.1,413.3,257.4,413.3z"/>
		<path d="M263.7,406.5c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L263.7,406.5z"/>
		<path d="M268.5,410.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1L268.5,410.1z
			 M267.2,406.5c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.4,0,0.8,0.4,0.8,0.8
			c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2C267.5,406.7,267.3,406.6,267.2,406.5z"/>
		<path d="M275.2,409.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-1,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.1-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C275.1,409,275.2,409.1,275.2,409.2z"/>
		<path d="M276.9,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L276.9,411.3z
			 M277,410.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5
			C277,410.7,277,410.7,277,410.7L277,410.7z"/>
		<path d="M282.9,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.5-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5C282.4,413.3,282.7,413.3,282.9,413.3z"/>
		<path d="M133.1,424.4c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.6,0.6,0.6,1c0.6-0.7,1.4-1.1,2.3-1.3c0.3,0,0.6,0,0.9,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H138h-0.6h-0.5
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.7
			c0-0.3,0-0.6,0-0.9c0-0.2-0.1-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3v0.2c0.1,0.4,0.1,0.7,0.1,1.1v1.9c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6
			c0.1-0.7,0-1.4-0.3-2c-0.2-0.4-0.7-0.6-1.1-0.5c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9
			c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.1,0.7-0.1,0.8s0,0.1,0,0.1l0,0
			c0.4-0.3,0.8-0.6,1.2-0.8C132.4,424.3,132.8,424.3,133.1,424.4z"/>
		<path d="M143.8,424.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C142,424.7,142.9,424.3,143.8,424.3z M143.6,424.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C144.5,425,144.1,424.9,143.6,424.9z"/>
		<path d="M152.2,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H153h-0.6h-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.1,0.7-0.1,0.9
			s0,0.1,0,0.1l0,0c0.3-0.3,0.6-0.5,1-0.7C151.3,424.5,151.7,424.3,152.2,424.4z"/>
		<path d="M156.5,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L156.5,427.3z M156.6,426.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C156.6,426.7,156.6,426.7,156.6,426.7L156.6,426.7z"/>
		<path d="M162.5,424.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.2c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			s0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c0,0.1-0.2,0.4-0.3,0.9
			s-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0.1-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c-0.3,0-0.3-0.1,0-0.2c0-0.2,0-0.2,0-0.2C161.4,424.5,161.9,424.6,162.5,424.6z"
			/>
		<path d="M168.7,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C168.2,429.4,168.4,429.3,168.7,429.3z"/>
		<path d="M176.6,424.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C175.4,424.5,176,424.3,176.6,424.4z"/>
		<path d="M184.7,425.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.2,1.3,0.8,2.5,2.1,2.6c0.2,0,0.3,0,0.5,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.1-0.1-1.6-0.5
			c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2C184.6,425,184.6,425.1,184.7,425.2z"/>
		<path d="M188.6,424.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C186.9,424.7,187.7,424.3,188.6,424.3z M188.5,424.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C189.3,425,188.9,424.9,188.5,424.9z"/>
		<path d="M196.7,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C195.9,424.6,196.3,424.4,196.7,424.4z"/>
		<path d="M201.9,424.4c0.9,0,1.7,0.4,2.1,1.1c0.2,0.3,0.4,0.6,0.5,1c0.1,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.4-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.4,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.2v-6.4
			c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.4v0.1l0,0
			c0.3-0.2,0.6-0.4,0.9-0.6C201.2,424.4,201.5,424.4,201.9,424.4z M201.2,425.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.3,0.2,0.7,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2
			c0-0.8-0.2-1.5-0.6-2.1C202.4,425.5,201.8,425.3,201.2,425.3L201.2,425.3z"/>
		<path d="M207.5,426.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1
			V426.1z M206.2,422.5c-0.1-0.1-0.2-0.4-0.2-0.6c0-0.4,0.4-0.8,0.8-0.8c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.5
			c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2C206.6,422.7,206.4,422.6,206.2,422.5L206.2,422.5z"/>
		<path d="M212.2,424.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C210.5,424.7,211.3,424.3,212.2,424.3z M212,424.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.2-0.5-0.4-0.9-0.8-1.2C212.9,425,212.5,424.8,212,424.9L212,424.9z"/>
		<path d="M220.6,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.4,0.1,0.8,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1h0.1c0.3-0.3,0.6-0.5,1-0.7C219.8,424.5,220.2,424.4,220.6,424.4z"/>
		<path d="M224.9,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C224.4,429.4,224.6,429.3,224.9,429.3z"/>
		<path d="M232.8,424.4c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C231.7,424.5,232.2,424.3,232.8,424.4z"/>
		<path d="M239.9,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0.1,0.7,0.1,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H236h-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0
			c0.3-0.3,0.6-0.5,1-0.7C239.1,424.5,239.5,424.4,239.9,424.4z"/>
		<path d="M246.3,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C245.2,424.6,245.7,424.4,246.3,424.4z M245.5,430.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C244.9,430.4,245.2,430.5,245.5,430.5L245.5,430.5z"/>
		<path d="M251.2,429.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6H249c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6
			c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1
			c0,0-0.1,0-0.1,0h-0.1h-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2l1.1,1.2
			c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4l-0.1-0.1l-0.1-0.1l0,0L251.2,429.4z"/>
		<path d="M257.1,427.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L257.1,427.3z M257.2,426.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.1,0.5C257.2,426.7,257.2,426.7,257.2,426.7L257.2,426.7z"/>
		<path d="M264.5,424.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C263.4,424.5,264,424.3,264.5,424.4z"/>
		<path d="M268.3,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c0,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.4,0.3-0.5
			C267.8,429.4,268,429.3,268.3,429.3z"/>
		<path d="M132.1,440.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.4,1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3
			L131,444c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C130.9,440.6,131.5,440.4,132.1,440.4z"/>
		<path d="M138.1,440.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C136.4,440.7,137.2,440.3,138.1,440.3z M138,440.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C138.8,441.1,138.4,440.9,138,440.9L138,440.9z"/>
		<path d="M146.5,440.4c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.6,0.6,1c0.6-0.7,1.4-1.1,2.3-1.3c0.3,0,0.6,0,0.9,0.1
			c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.7
			c0-0.3,0-0.6,0-0.9c0-0.2-0.1-0.5-0.1-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3v0.1c0,0,0,0.1,0,0.1c0,0.4,0.1,0.7,0.1,1.1v1.9
			c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6
			h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6
			c0.1-0.7,0-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6H142c0,0,0,0,0,0
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H142c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.4-0.1,0.7-0.1,0.8s0,0.1,0,0.1l0,0c0.4-0.3,0.8-0.6,1.2-0.8
			C145.8,440.4,146.1,440.3,146.5,440.4z"/>
		<path d="M154.9,443.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4
			c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1L154.9,443.3z M155,442.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5
			c-0.1,0.1-0.1,0.3-0.1,0.5C155,442.7,155,442.7,155,442.7L155,442.7z"/>
		<path d="M163.2,440.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-0.9-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C161.5,440.7,162.3,440.3,163.2,440.3z M163.1,440.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.2-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C163.9,441,163.5,440.8,163.1,440.9z"/>
		<path d="M171.6,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.6-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			c0,0,0.1,0.1,0.1,0.1c0,0.3-0.1,0.6-0.1,0.9v0.1l0,0c0.3-0.3,0.6-0.5,1-0.7C170.8,440.5,171.2,440.3,171.6,440.4z"/>
		<path d="M176,443.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L176,443.3z
			 M176.1,442.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C176,442.7,176,442.7,176.1,442.7L176.1,442.7z"/>
		<path d="M186,440.6c0.5,0,1,0,1.6-0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2h-0.2c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.6-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.5,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
			c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6L188,448
			c-0.2,0.5-0.3,0.8-0.4,1.1c-0.1,0.3-0.3,0.5-0.4,0.8c-0.3,0.5-0.8,0.8-1.4,0.9c-0.3,0-0.6-0.2-0.7-0.5c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.5c0.3,0,0.7,0,1-0.2c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.8
			c-1-2.7-1.9-4.6-2.4-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C184.9,440.5,185.4,440.6,186,440.6z"/>
		<path d="M194.8,440.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C193.1,440.7,193.9,440.3,194.8,440.3z M194.7,440.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C195.5,441,195.1,440.9,194.7,440.9z"/>
		<path d="M203.6,442.2c0-0.3,0-0.6-0.1-0.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
			c0.7,0,1.4-0.1,2.1-0.3c0,0,0.1,0,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1v3.3c0,0.4,0,0.8,0.1,1.2
			c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h0.3h0.1c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1l-0.5,0.1
			c-0.2,0-0.4,0.1-0.5,0.1l-0.5,0.1l-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.2v-0.7v-0.1c-0.3,0.3-0.6,0.5-1,0.7
			c-0.4,0.2-0.8,0.3-1.2,0.3c-0.6,0-1.1-0.3-1.4-0.7c-0.1-0.2-0.3-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3,0-0.6,0-0.8v-2
			c0-0.3,0-0.6-0.1-0.9c-0.1-0.2-0.4-0.3-0.9-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.7,0,1.4-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1
			c0,0.1,0,0.1,0.1,0.2c-0.1,0.3-0.1,0.7-0.1,1.1v2.4c0,0.6,0.1,1.3,0.4,1.8c0.3,0.4,0.7,0.6,1.2,0.6c0.4,0,0.7-0.1,1-0.3
			c0.3-0.2,0.5-0.4,0.5-0.6L203.6,442.2z"/>
		<path d="M211,438.5c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v7.4c0,0.4,0,0.8,0.1,1.1
			c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5
			c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1L211,438.5z"/>
		<path d="M215.8,442.1v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2l-1.7-0.1h-0.5h-0.6h-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c-0.1,0.3-0.1,0.7-0.1,1L215.8,442.1z
			 M214.5,438.5c-0.2-0.1-0.2-0.3-0.2-0.6c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
			c0.1,0.1,0.2,0.3,0.2,0.5c0,0.4-0.3,0.8-0.7,0.8c0,0,0,0-0.1,0C214.9,438.7,214.7,438.6,214.5,438.5z"/>
		<path d="M219.1,445.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1H218h-0.6h-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1V437c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.4-0.4
			l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1c0,0-0.1,0-0.1,0h-0.1h-0.2
			c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4l-0.1-0.1l-0.1-0.1l0,0L219.1,445.4z"/>
		<path d="M225.1,443.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L225.1,443.3z
			 M225.1,442.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C225.1,442.7,225.1,442.7,225.1,442.7L225.1,442.7z"/>
		<path d="M231.1,445.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.5-0.7,1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2l0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.2-0.4-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C230.6,445.4,230.8,445.3,231.1,445.3z"/>
		<path d="M237.5,440.6h0.5h0.5h0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.2h-0.3c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2l0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8l0.4-1.1l0.4-0.9l0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0-0.1,0-0.2
			c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6
			c0.3,0,0.5,0,0.8,0l1.6-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2s0.1,0.2,0.1,0.3l0.2,0.5
			c0.1,0.2,0.2,0.4,0.3,0.7l0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.1-0.1-0.2l-0.3-0.1h-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2h0.6c0.3,0,0.5,0,0.8,0h0.5h0.5h0.5
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0.1-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.4-0.8-0.4c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2h0.5h0.5L237.5,440.6z"/>
		<path d="M250.2,440.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			l0,0l0,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5l0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3v-0.4
			c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0.4-0.4,0.8-0.6,1.3-0.9
			C249,440.6,249.6,440.4,250.2,440.4z M249.4,446.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8C248.8,446.4,249.1,446.5,249.4,446.5L249.4,446.5z"/>
		<path d="M255,440.5h2c0,0,0.1,0,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H255v4.1c0,0.9,0.3,1.3,1,1.3c0.4,0,0.7-0.1,1-0.4
			c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.5-0.1-0.8v-4.5h-0.9
			c-0.1,0-0.1,0-0.1-0.1s0.1-0.1,0.2-0.2l0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4l0.4-0.4l0.2-0.2c0,0,0.1,0,0.1-0.1h0.1l0,0
			L255,440.5z"/>
		<path d="M258.5,443.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L258.5,443.3z
			 M258.6,442.7h2.5c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C258.6,442.7,258.6,442.7,258.6,442.7L258.6,442.7z"/>
		<path d="M267.6,440.4c0.2,0,0.4,0,0.6,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C266.7,440.6,267.1,440.4,267.6,440.4z"/>
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
      <path d="M166.4,409.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.5,0-1,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C166.3,409,166.4,409.1,166.4,409.2z"/>
		<path d="M171,408.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.5-0.9-2.5c0-0.9,0.3-1.8,1-2.5C169.2,408.7,170.1,408.3,171,408.3z M170.8,408.9
			c-0.4,0-0.7,0.2-1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C171.7,409,171.3,408.8,170.8,408.9z"/>
		<path d="M181,409.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9c-0.2,1.3,0.8,2.5,2.1,2.6
			c0.2,0,0.3,0,0.5,0c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.5-0.3,0.7-0.5l0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.6-0.5
			c-0.5-0.3-0.8-0.7-1.1-1.1c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2C180.9,409,181,409.1,181,409.2z"/>
		<path d="M184.2,413.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1
			c0,0-0.1,0-0.1,0h-0.1h-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2
			c0,0.1,0,0.1,0,0.2c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2l1.1,1.2
			c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
			c-0.3,0.1-0.7,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4l-0.1-0.1l-0.1-0.1l0,0L184.2,413.4z"/>
		<path d="M194.4,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.5-0.3-1.1-0.2-1.4,0.3c0,0,0,0,0,0.1
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C193.6,408.6,194,408.4,194.4,408.4z"/>
		<path d="M199.6,408.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.8-0.9,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-0.9-1.6-0.9-2.5c0-0.9,0.3-1.8,0.9-2.5C197.8,408.7,198.7,408.3,199.6,408.3z M199.4,408.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.2-0.9,0.2-1.9-0.1-2.8c-0.2-0.5-0.4-0.9-0.8-1.2
			C200.3,409,199.8,408.9,199.4,408.9L199.4,408.9z"/>
		<path d="M207.3,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0
			h0.1l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.6-0.5-0.9-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.4,0.8-0.6,1.3-0.9C206.2,408.6,206.7,408.4,207.3,408.4z M206.5,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			C205.9,414.4,206.2,414.5,206.5,414.5L206.5,414.5z"/>
		<path d="M216.3,409.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C216.2,409,216.2,409.1,216.3,409.2z"/>
		<path d="M221.7,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5H222h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
			c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3
			c0,0,0,0,0-0.1V405c0-0.1,0-0.1,0-0.1c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4
			v3.5 M219.5,409.4L219.5,409.4c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.3,1.2-0.3L219.5,409.4z"/>
		<path d="M226.7,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.3-0.5,0.6-0.8,0.8c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
			c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0.1,1,0.2c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.2,0.6
			c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L226.7,411.3z M226.8,410.7h2.5
			c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C226.8,410.7,226.8,410.7,226.8,410.7L226.8,410.7z"/>
		<path d="M234.7,408.4c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0,0,0.1-0.1,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.6-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C233.6,408.5,234.1,408.3,234.7,408.4z"/>
		<path d="M278,409.2c0,0.2,0,0.4-0.2,0.6c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9c-0.1,0.7,0.2,1.5,0.7,2
			c0.5,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.1-1.6-0.5
			c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8
			c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0.1,0.1,0.1,0.1,0.1,0.2C277.9,409,278,409.1,278,409.2z"/>
		<path d="M282.6,408.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C280.9,408.7,281.7,408.3,282.6,408.3z M282.5,408.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C283.3,409,282.9,408.9,282.5,408.9z"/>
		<path d="M292.6,409.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.4,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C292.5,409,292.6,409.1,292.6,409.2z"/>
		<path d="M295.8,413.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1h-0.5h-0.6h-0.5c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v5.6c0.5-0.3,0.9-0.6,1.3-1l0.5-0.4
			l0.4-0.3l0.3-0.2l0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1h-0.1c0,0-0.1,0-0.1,0H298h-0.2
			c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0,1,0.1,1.5,0.1c0.5,0,0.9,0,1.4-0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.3c-1.1,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0.1,0.5,0.1,0.8,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.3,0.1-0.7,0.1-1,0.1
			c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.5-0.3-0.8-0.5l-2.2-2.4c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1l0,0L295.8,413.4z"/>
		<path d="M306.1,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1l-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.2-0.9,0.5
			c-0.2,0.2-0.4,0.5-0.4,0.9v2.4c0,0.4,0,0.8,0.1,1.1c0,0.1,0.2,0.1,0.5,0.2c0.3,0,0.5,0.1,0.8,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2c-1.1,0-1.7-0.1-1.9-0.1l-1.8,0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.4,0.1-0.8,0.1-1.1v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.3
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0.8-0.1,1.5-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.3-0.1,0.7
			s0,0.6,0,0.7s0,0,0,0l0,0l0,0c0.2-0.4,0.5-0.8,0.8-1.1C305.2,408.6,305.6,408.4,306.1,408.4z"/>
		<path d="M311.2,408.3c0.9,0,1.8,0.3,2.4,1c0.6,0.7,0.9,1.5,0.9,2.4c0,0.9-0.3,1.9-1,2.5c-0.6,0.7-1.5,1-2.4,1
			c-0.9,0-1.8-0.3-2.4-1c-0.6-0.7-1-1.6-0.9-2.5c0-0.9,0.3-1.8,1-2.5C309.4,408.7,310.3,408.3,311.2,408.3z M311,408.9
			c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.9-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.4,0.9,0.8,1.2
			c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.9,0.2-1.3c0-0.5-0.1-1-0.2-1.5
			c-0.1-0.5-0.4-0.9-0.8-1.2C311.9,409,311.5,408.8,311,408.9L311,408.9z"/>
		<path d="M319,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,1,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1l0.1,0l0.1,0h0.1
			l0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.3,0.2-0.6,0.5-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.4c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3l1.1-0.4l0.7-0.3c0.1,0,0.1-0.1,0.1-0.1
			c0-0.1,0-0.2,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.2-0.3-0.6-0.5-1-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.5-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2
			c0.4-0.3,0.8-0.6,1.3-0.9C317.9,408.5,318.4,408.4,319,408.4z M318.1,414.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.3,0.1,0.6,0.3,0.8
			C317.6,414.3,317.8,414.5,318.1,414.5L318.1,414.5z"/>
		<path d="M327.9,409.2c0,0.2,0,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.5,0-1,0.3-1.2,0.7c-0.4,0.6-0.6,1.2-0.5,1.9
			c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.2,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.5l0,0l0.1,0.1c0,0,0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.6,0-1.2-0.1-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2c-0.3-0.5-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.7,0.6-1.1
			c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1-0.2c0.5,0,1,0.1,1.4,0.4c0,0.1,0.1,0.1,0.1,0.2
			C327.8,409,327.9,409.1,327.9,409.2z"/>
		<path d="M333.3,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.3,0,0.7,0,1.2v1.7c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2c0,0.1,0,0.2,0,0.2
			l-1.7-0.1h-0.5h-0.6h-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-1.6c0.1-0.7-0.1-1.4-0.3-2c-0.3-0.4-0.7-0.6-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.4,0,0.8,0.1,1.1c0,0.1,0.1,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1c0,0.1,0.1,0.2,0,0.2
			c0,0.1,0,0.2,0,0.2l-1.7-0.1H330h-0.6h-0.5c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.4,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
			c0.1-0.4,0.1-0.8,0.1-1.1v-6.9c0-0.3,0-0.5-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.8-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.2c-0.1,0.4-0.2,0.9-0.2,1.4v3.5c0,0,0,0,0,0l0,0
			c0.3-0.3,0.6-0.5,1-0.7C332.5,408.4,332.9,408.3,333.3,408.4z"/>
		<path d="M338.3,411.3c-0.1,0.8,0.2,1.6,0.7,2.2c0.5,0.5,1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.1,0.5-0.3,0.7-0.6
			l0,0l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.5-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.4-0.8,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.3,1.1-0.3c0.3,0,0.7,0,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c0,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1L338.3,411.3z
			 M338.4,410.7h2.6c0.2,0,0.3-0.1,0.4-0.3c0,0,0,0,0-0.1c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.5
			C338.4,410.7,338.4,410.7,338.4,410.7L338.4,410.7z"/>
		<path d="M346.4,408.4c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.6,0.1,0.7,0.1c0.1,0.6,0.2,1.1,0.2,1.7c0,0-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.4-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.4-0.8-0.5c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.2,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.3,0.2l0.4,0.2l0.3,0.2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.3,1.2-0.7,1.5
			c-0.5,0.4-1.1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0l-0.6-0.1l-0.6-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.3-0.1-0.6-0.1-0.9
			c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			l-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.5,0.2-1.1,0.7-1.4C345.2,408.5,345.8,408.3,346.4,408.4z"/>
      </g>
    </g>
  );
};

export default NetworkTexts;
