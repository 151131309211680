import React from 'react';
import '../Network.scss';

const NetworkTexts = ({ selected }) => {
  return (
    <g id="subjects" transform="translate(-42,-40)">      
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path d="M357.5,319.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C356.4,319.2,356.9,319.2,357.5,319.2z"/>
        <path d="M367,319c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S366,319,367,319z M366.8,319.5c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S367.2,319.5,366.8,319.5z"/>
        <path d="M376.4,320.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
          s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
          h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2V325v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
          s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.4H376.4z"/>
        <path d="M383.7,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S383.4,319,383.7,319z"/>
        <path d="M391.4,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S391.5,322,391.4,322z
          M391.5,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S391.5,321.4,391.5,321.4z"/>
        <path d="M398.3,319.2c0.5,0,1,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.4,0-0.6,0.1-0.6,0.3c0,0,0,0.1,0.1,0.2l1,1.6
          c0.1-0.1,0.2-0.4,0.4-0.6s0.4-0.5,0.5-0.7s0.2-0.3,0.2-0.4s0-0.1-0.1-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0s-0.2,0-0.2,0s0-0.1,0-0.2
          s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0s0.3,0,0.5,0c0.5,0,0.9,0,1.2-0.1c0,0,0,0.1,0,0.3s0,0.2,0,0.2c-0.7,0-1.1,0.2-1.2,0.3
          c-0.1,0.2-0.3,0.5-0.6,0.8s-0.5,0.7-0.7,0.9s-0.3,0.4-0.3,0.4l1.9,2.8c0.3,0.4,0.7,0.6,1.2,0.6c0,0,0,0,0,0.1s0,0.1,0,0.2
          s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.5,0s-0.3,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.6,0s-0.4,0-0.6,0c0-0.1,0-0.1,0-0.3
          s0-0.2,0-0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.2v-0.1c-0.5-0.9-1-1.5-1.2-2c-0.1,0.1-0.2,0.3-0.5,0.7s-0.5,0.7-0.6,1
          c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0,0.2,0s0.1,0,0.3,0h0.1c0,0,0,0,0,0.1s0,0.1,0,0.2
          s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2
          s0-0.2,0-0.2s0.1,0,0.2,0s0.2,0,0.2,0s0.1,0,0.2,0s0.2,0,0.2-0.1s0.1,0,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2
          c1-1.4,1.5-2.1,1.5-2.2l-1.8-2.7c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.2
          C397.3,319.2,397.9,319.2,398.3,319.2z"/>
      </g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path d="M109.1,135.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3
          c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.8,0.6-1.2,0.8
          C110.2,135.1,109.6,135.2,109.1,135.2z M109.4,129.2c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8
          c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          S110.1,129.2,109.4,129.2z"/>
        <path d="M115.3,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C116.1,131.2,115.5,131.2,115.3,131.2z M115.4,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C115.4,130.6,115.4,130.7,115.4,130.7z"/>
        <path d="M123.1,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L123.1,130L123.1,130z M121.9,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
          c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
          S122,126.6,121.9,126.4z"/>
        <path d="M129.6,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
          c-0.2,0.1-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.2,0,0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
          c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C128.8,128.4,129.2,128.3,129.6,128.3
          z"/>
        <path d="M137.5,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
          s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
          c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
          c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
          c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2L137.5,134.1L137.5,134.1z M136.8,135.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C137.5,135.2,137.2,135.2,136.8,135.2z M136.2,128.7c-0.4,0-0.8,0.2-1,0.6
          c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S136.6,128.7,136.2,128.7z"/>
        <path d="M149.3,128.3c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6C148.7,128.4,149.1,128.3,149.3,128.3z M148.7,129.2
          c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C149.8,129.4,149.3,129.2,148.7,129.2z"/>
        <path d="M157.5,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7
          l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C156.8,128.5,157.2,128.3,157.5,128.3z"/>
        <path d="M160.4,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C161.2,131.2,160.6,131.2,160.4,131.2z M160.5,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C160.5,130.6,160.5,130.7,160.5,130.7z"/>
        <path d="M170.1,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
          s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
          c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
          c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
          c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2L170.1,134.1L170.1,134.1z M169.4,135.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C170.1,135.2,169.7,135.2,169.4,135.2z M168.8,128.7c-0.4,0-0.8,0.2-1,0.6
          c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S169.2,128.7,168.8,128.7z"/>
        <path d="M177.7,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C177,128.4,177.4,128.3,177.7,128.3z"/>
        <path d="M184.8,128.3c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1
          c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2
          c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C183.7,128.4,184.3,128.3,184.8,128.3z
          M183.9,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
          s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S183.6,134.4,183.9,134.4z"/>
        <path d="M192.8,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C192.1,128.4,192.4,128.3,192.8,128.3z"/>
        <path d="M198.6,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0
          v2.1H198.6z"/>
        <path d="M202.6,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
          c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6
          c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C202.1,133.3,202.3,133.2,202.6,133.2z"/>
        <path d="M111.5,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
          c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
          c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3
          s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S110.9,144.3,111.5,144.3z"/>
        <path d="M119.2,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4S117,146,117,146v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7C118.5,144.4,118.9,144.3,119.2,144.3z"/>
        <path d="M126.3,144.3c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
          c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C125.2,144.4,125.8,144.3,126.3,144.3z M125.4,150.4
          c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7
          c0,0.3,0.1,0.5,0.3,0.8S125.1,150.4,125.4,150.4z"/>
        <path d="M131.8,149.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
          c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1
          v-0.1c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1H134c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          c0.4,0.5,0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H131.8
          z"/>
        <path d="M138.4,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C139.1,147.2,138.5,147.2,138.4,147.2z M138.5,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C138.5,146.6,138.4,146.7,138.5,146.7z"/>
        <path d="M146.4,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
          c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
          s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
          s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S145.8,144.3,146.4,144.3z"/>
        <path d="M150.8,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
          c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6
          c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C150.3,149.3,150.5,149.2,150.8,149.2z"/>
        <path d="M160,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
          c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
          s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
          s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S159.4,144.3,160,144.3z"/>
        <path d="M167.3,144.3c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6C166.7,144.4,167,144.3,167.3,144.3z M166.6,145.2
          c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C167.7,145.4,167.2,145.2,166.6,145.2z"/>
        <path d="M173.5,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H173.5z M172.3,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
          c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
          S172.4,142.6,172.3,142.4z"/>
        <path d="M179.2,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1
          h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S178.9,144.3,179.2,144.3z
          M178.7,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
          c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C179.5,145,179.2,144.9,178.7,144.9z"/>
        <path d="M184.7,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C185.5,147.2,184.8,147.2,184.7,147.2z M184.8,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C184.8,146.6,184.8,146.7,184.8,146.7z"/>
        <path d="M194.4,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7
          l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C193.7,144.5,194.1,144.3,194.4,144.3z"/>
        <path d="M198.7,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
          c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
          s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
          s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S198.1,144.3,198.7,144.3z"/>
      </g>

      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M185,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C183.9,408.5,184.4,408.4,185,408.4z"/>
        <path d="M192.8,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S192.5,408.4,192.8,408.4z"/>
        <path d="M199.8,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C198.8,408.5,199.3,408.4,199.8,408.4z M199,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S198.7,414.5,199,414.5z"/>
        <path d="M205.4,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1H208h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H205.4z"/>
        <path d="M211.9,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C212.7,411.3,212.1,411.3,211.9,411.3z M212,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S212,410.7,212,410.7z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M332.8,144.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C331.7,144.4,332.2,144.5,332.8,144.5z"/>
        <path d="M342.3,144.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S341.3,144.3,342.3,144.3z M342.1,144.8c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S342.5,144.8,342.1,144.8z"/>
        <path d="M351.7,146.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1
          s-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3
          c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H351.7z"/>
        <path d="M359,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S358.7,144.3,359,144.3
          z"/>
        <path d="M366.7,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S366.8,147.2,366.7,147.2z
          M366.8,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S366.8,146.7,366.8,146.7z"/>
        <path d="M373.6,144.5c0.5,0,1,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.4,0-0.6,0.1-0.6,0.3c0,0,0,0.1,0.1,0.2l1,1.6
          c0.1-0.1,0.2-0.4,0.4-0.6s0.4-0.5,0.5-0.7s0.2-0.3,0.2-0.4c0-0.1,0-0.1-0.1-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0s-0.2,0-0.2,0
          s0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0s0.3,0,0.5,0c0.5,0,0.9,0,1.2-0.1c0,0,0,0.1,0,0.3s0,0.2,0,0.2
          c-0.7,0-1.1,0.2-1.2,0.3c-0.1,0.2-0.3,0.5-0.6,0.8s-0.5,0.7-0.7,0.9s-0.3,0.4-0.3,0.4l1.9,2.8c0.3,0.4,0.7,0.6,1.2,0.6
          c0,0,0,0,0,0.1s0,0.1,0,0.2s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.5,0s-0.3,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.6,0s-0.4,0-0.6,0
          c0-0.1,0-0.1,0-0.3s0-0.2,0-0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.2v-0.1c-0.5-0.9-1-1.5-1.2-2c-0.1,0.1-0.2,0.3-0.5,0.7
          s-0.5,0.7-0.6,1c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.1,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0,0.2,0s0.1,0,0.3,0h0.1c0,0,0,0,0,0.1s0,0.1,0,0.2
          s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2
          s0-0.2,0-0.2s0.1,0,0.2,0s0.2,0,0.2,0s0.1,0,0.2,0s0.2,0,0.2-0.1s0.1,0,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2
          c1-1.4,1.5-2.1,1.5-2.2l-1.8-2.7c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.2
          C372.6,144.4,373.1,144.5,373.6,144.5z"/>
      </g>

      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M365.4,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S364.8,319,365.4,319z"/>
        <path d="M373.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
          S372.9,319,373.2,319z"/>
        <path d="M380.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S379.7,319.1,380.2,319.1z M379.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S379.1,325.2,379.4,325.2z"/>
        <path d="M385.8,324.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V320c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1H388c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H385.8z"/>
        <path d="M392.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S392.5,322,392.3,322z
          M392.4,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S392.4,321.4,392.4,321.4z"/>
      </g>

      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M101.9,128.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S101.3,128.3,101.9,128.3z M101,134.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S100.7,134.4,101,134.4z"/>
        <path d="M109.9,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
          S109.5,128.3,109.9,128.3z"/>
        <path d="M115.6,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H115.6z"/>
        <path d="M121,128.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S120.4,128.3,121,128.3z"/>
        <path d="M125.4,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          S124,137,124,137c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S125.2,133.2,125.4,133.2z"/>
        <path d="M137.6,129.1c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
          s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
          s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2S137.6,129,137.6,129.1z"
          />
        <path d="M142.2,128.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S141.2,128.3,142.2,128.3z M142,128.8c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S142.4,128.8,142,128.8z"/>
        <path d="M152.1,129.1c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
          s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
          s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2S152.1,129,152.1,129.1z"
          />
        <path d="M155.4,133.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H155.4z"/>
        <path d="M165.6,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S165.3,128.3,165.6,128.3z"/>
        <path d="M170.7,128.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S169.8,128.3,170.7,128.3z M170.6,128.8c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S171,128.8,170.6,128.8z"/>
        <path d="M178.5,128.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S178,128.3,178.5,128.3z M177.7,134.4c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S177.4,134.4,177.7,134.4z"/>
        <path d="M187.4,129.1c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
          s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
          s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2S187.4,129,187.4,129.1z"
          />
        <path d="M192.9,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S192.5,128.3,192.9,128.3z"/>
        <path d="M197.9,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S198,131.2,197.9,131.2z
          M197.9,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S197.9,130.7,197.9,130.7z"/>
        <path d="M205.9,128.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S205.3,128.3,205.9,128.3z"/>
        <path d="M210.3,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S210,133.2,210.3,133.2z"/>
        <path d="M119.3,144.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8s-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V145h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7
          V144.6z"/>
        <path d="M127.8,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S127.5,144.3,127.8,144.3z"/>
        <path d="M130.8,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S130.9,147.2,130.8,147.2z
          M130.8,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S130.8,146.7,130.8,146.7z"/>
        <path d="M137.4,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          S136,153,136,153c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S137.1,149.2,137.4,149.2z"/>
        <path d="M148.1,144.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V145h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7
          V144.6z"/>
        <path d="M154.9,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S154.3,144.3,154.9,144.3z"/>
        <path d="M162.4,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S162.1,144.3,162.4,144.3z"/>
        <path d="M167.4,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          S166,153,166,153c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S167.1,149.2,167.4,149.2z"/>
        <path d="M174.9,142.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1
          c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          L174.9,142.4L174.9,142.4z"/>
        <path d="M180.4,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H180.4z
          M179.1,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S179.2,142.6,179.1,142.4z"/>
        <path d="M187.7,145.1c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
          s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
          s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2S187.7,145,187.7,145.1z"
          />
        <path d="M190.1,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S190.2,147.2,190.1,147.2z
          M190.2,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S190.1,146.7,190.2,146.7z"/>
      </g>

      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M146.4,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C145.4,408.5,145.9,408.4,146.4,408.4z M145.6,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S145.3,414.5,145.6,414.5z"/>
        <path d="M154.4,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S154.1,408.4,154.4,408.4z"/>
        <path d="M160.2,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V409H158c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H160.2z"/>
        <path d="M165.6,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C164.4,408.5,165,408.4,165.6,408.4z"/>
        <path d="M170,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S169.7,413.3,170,413.3z"/>
        <path d="M182.1,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2
          c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C182.1,409,182.1,409.1,182.1,409.2z"/>
        <path d="M186.7,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C185,408.7,185.8,408.3,186.7,408.3z M186.5,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S187,408.9,186.5,408.9z"/>
        <path d="M196.7,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2
          c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C196.6,409,196.7,409.1,196.7,409.2z"/>
        <path d="M199.9,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H199.9z"/>
        <path d="M210.1,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C209.5,408.6,209.8,408.4,210.1,408.4z"/>
        <path d="M215.3,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C213.5,408.7,214.3,408.3,215.3,408.3z M215.1,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S215.6,408.9,215.1,408.9z"/>
        <path d="M223,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C222,408.5,222.5,408.4,223,408.4z M222.2,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S221.9,414.5,222.2,414.5z"/>
        <path d="M232,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2
          c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C232,409,232,409.1,232,409.2z"/>
        <path d="M237.4,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S237.1,408.4,237.4,408.4z"/>
        <path d="M242.4,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C243.2,411.3,242.5,411.3,242.4,411.3z M242.5,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S242.5,410.7,242.5,410.7z"/>
        <path d="M250.4,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C249.3,408.5,249.8,408.4,250.4,408.4z"/>
        <path d="M254.8,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S254.6,413.3,254.8,413.3z"/>
        <path d="M163.8,424.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3H162v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V425H160c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7V424.6z"/>
        <path d="M172.4,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C171.7,424.6,172.1,424.4,172.4,424.4z"/>
        <path d="M175.3,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C176.1,427.3,175.4,427.3,175.3,427.3z M175.4,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S175.4,426.7,175.4,426.7z"/>
        <path d="M181.9,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S181.7,429.3,181.9,429.3z"/>
        <path d="M192.6,424.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V425h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7V424.6z"/>
        <path d="M199.5,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C198.3,424.5,198.9,424.4,199.5,424.4z"/>
        <path d="M207,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S206.7,424.4,207,424.4z"/>
        <path d="M211.9,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S211.6,429.3,211.9,429.3z"/>
        <path d="M219.5,422.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1
          c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          L219.5,422.5L219.5,422.5z"/>
        <path d="M224.9,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H224.9z
          M223.6,422.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S223.8,422.6,223.6,422.5z"/>
        <path d="M232.2,425.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2
          c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C232.2,425,232.2,425.1,232.2,425.2z"/>
        <path d="M234.6,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C235.4,427.3,234.8,427.3,234.6,427.3z M234.7,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S234.7,426.7,234.7,426.7z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>

        <path d="M309.3,135.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3
          c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.8,0.6-1.2,0.8C310.4,135.1,309.9,135.2,309.3,135.2z M309.6,129.2c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2
          v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          S310.3,129.2,309.6,129.2z"/>
        <path d="M315.5,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C316.3,131.2,315.7,131.2,315.5,131.2z M315.6,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C315.6,130.6,315.6,130.7,315.6,130.7z"/>
        <path d="M323.4,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H323.4z M322.1,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
          c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
          S322.2,126.6,322.1,126.4z"/>
        <path d="M329.8,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3
          v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1
          l0,0c0.2-0.2,0.5-0.5,1-0.7C329.1,128.4,329.4,128.3,329.8,128.3z"/>
        <path d="M337.8,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L337.8,134.1L337.8,134.1z M337,135.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C337.7,135.2,337.4,135.2,337,135.2z
          M336.4,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
          c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S336.9,128.7,336.4,128.7z"/>
        <path d="M349.6,128.3c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3
          c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6C348.9,128.4,349.3,128.3,349.6,128.3z M348.9,129.2
          c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C350,129.4,349.5,129.2,348.9,129.2z"/>
        <path d="M357.7,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C357.1,128.5,357.4,128.3,357.7,128.3z"/>
        <path d="M360.6,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C361.4,131.2,360.8,131.2,360.6,131.2z M360.7,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C360.7,130.6,360.7,130.7,360.7,130.7z"/>
        <path d="M370.3,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L370.3,134.1L370.3,134.1z M369.6,135.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C370.3,135.2,370,135.2,369.6,135.2z
          M369,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
          c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S369.4,128.7,369,128.7z"/>
        <path d="M377.9,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3
          v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1
          l0,0c0.2-0.2,0.5-0.5,1-0.7C377.2,128.4,377.6,128.3,377.9,128.3z"/>
        <path d="M385,128.3c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
          c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
          c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C384,128.4,384.5,128.3,385,128.3z M384.1,134.4c0.3,0,0.5-0.1,0.9-0.3
          c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8
          S383.9,134.4,384.1,134.4z"/>
        <path d="M393,128.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3
          v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1
          l0,0c0.2-0.2,0.5-0.5,1-0.7C392.3,128.4,392.7,128.3,393,128.3z"/>
        <path d="M398.8,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          s-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0
          L398.8,128.4L398.8,128.4z"/>
        <path d="M402.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C402.3,133.3,402.5,133.2,402.8,133.2z"/>
        <path d="M311.7,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
          c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
          c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3
          s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S311.1,144.3,311.7,144.3z"/>
        <path d="M319.4,144.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3
          v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1
          l0,0c0.2-0.2,0.5-0.5,1-0.7C318.7,144.4,319.1,144.3,319.4,144.3z"/>
        <path d="M326.5,144.3c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
          c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C325.5,144.4,326,144.3,326.5,144.3z M325.6,150.4c0.3,0,0.5-0.1,0.9-0.3
          c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8
          S325.4,150.4,325.6,150.4z"/>
        <path d="M332.1,149.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
          c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1
          v-0.1c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4
          H332.1z"/>
        <path d="M338.6,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C339.4,147.2,338.7,147.2,338.6,147.2z M338.7,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C338.7,146.6,338.7,146.7,338.7,146.7z"/>
        <path d="M346.6,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
          c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
          c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3
          s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S346,144.3,346.6,144.3
          z"/>
        <path d="M351,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C350.5,149.3,350.7,149.2,351,149.2z"/>
        <path d="M360.2,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
          c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
          c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3
          s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S359.6,144.3,360.2,144.3z"/>
        <path d="M367.5,144.3c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3
          c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6C366.9,144.4,367.2,144.3,367.5,144.3z M366.8,145.2
          c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C367.9,145.4,367.4,145.2,366.8,145.2z"/>
        <path d="M373.7,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H373.7z M372.5,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
          c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
          S372.6,142.6,372.5,142.4z"/>
        <path d="M379.5,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
          c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0s0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1
          c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6
          c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2
          c0.4-0.3,0.8-0.6,1.2-0.8S379.1,144.3,379.5,144.3z M379,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
          C379.8,145,379.4,144.9,379,144.9z"/>
        <path d="M384.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C385.7,147.2,385,147.2,384.9,147.2z M385,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C385,146.6,385,146.7,385,146.7z"/>
        <path d="M394.6,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C394,144.5,394.3,144.3,394.6,144.3z"/>
        <path d="M398.9,144.3c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
          c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
          c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3
          s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S398.3,144.3,398.9,144.3z"/>
      </g>
    </g>
  );
};

export default NetworkTexts;
