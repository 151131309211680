import React from 'react';

const BluePetal60 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_6)' }} d="M.21,24.28c-3.5,2,37.28,19.52,80,18.51s77.69-18.39,79.19-22c2-6.13-11.64-3.75-11.64-3.75h0c-4.12.38-10,.75-15,.75-9.63,0-20.89-2-29.65-3.38l-2-.37c-6.51-.5-14.14-1.63-21.27-1.88C79.53,12.27,26.86,8,.21,24.28Z" />
        <path className="cls-2" d="M18,19.65a57.15,57.15,0,0,1,13.88-.5A79.05,79.05,0,0,1,45.37,21.4a49.28,49.28,0,0,1,6.51,2.25c2,.75,4.25,1.88,6.26,2.75s4.25,1.88,6.25,2.63A33.62,33.62,0,0,0,71,30.53a36.21,36.21,0,0,1-6.63-1.25,44.19,44.19,0,0,1-6.5-2.62L51.63,23.9a50.77,50.77,0,0,0-6.51-2.25A70.47,70.47,0,0,0,31.61,19.4a56,56,0,0,0-13.76.75Z" />
        <path className="cls-2" d="M18,19.65a55.94,55.94,0,0,1,13.76-.5A79.22,79.22,0,0,1,45.25,21.4a50.3,50.3,0,0,1,6.51,2.25L58,26.4,64.27,29a33.46,33.46,0,0,0,6.63,1.5v.13a37.08,37.08,0,0,1-6.63-1.25A27.47,27.47,0,0,1,58,26.78c-4.25-1.88-8.38-3.88-12.76-5a70.49,70.49,0,0,0-13.51-2.26A56.46,56.46,0,0,0,18,20.27h0Zm0,0V20h0a56.46,56.46,0,0,1,13.76-.75,61.06,61.06,0,0,1,13.51,2.26c4.5,1.12,8.51,3.12,12.76,5,2,.88,4.25,1.88,6.26,2.63a44.49,44.49,0,0,0,6.63,1.25v.12A23.12,23.12,0,0,1,64.27,29L58,26.4l-6.25-2.75C49.5,22.9,47.5,22,45.25,21.4a85.78,85.78,0,0,0-13.51-2.25A55.46,55.46,0,0,0,18,19.65Z" />
        <path className="cls-2" d="M81.91,19.9A9.86,9.86,0,0,1,86.41,21c1.5.75,2.63,1.5,4.13,2.25a15.33,15.33,0,0,0,4.13,1.87c1.5.38,3,.5,4.5.75a18.16,18.16,0,0,1-4.5-.5c-1.5-.37-2.75-1.12-4.26-1.62a21,21,0,0,0-4.12-2A13,13,0,0,0,82,20.9Z" /><path className="cls-2" d="M81.91,19.9A9.86,9.86,0,0,1,86.41,21l4.13,2.25a15.33,15.33,0,0,0,4.13,1.87l4.5.75V26a18.65,18.65,0,0,1-4.5-.5c-1.5-.38-2.75-1.13-4.26-1.63h0a28.51,28.51,0,0,0-3.87-2A11.81,11.81,0,0,0,82.28,21h0Zm0,0v.75h0a11.72,11.72,0,0,1,4.25.88,25.36,25.36,0,0,1,4.13,2h0c1.5.5,2.75,1.25,4.25,1.62a18.24,18.24,0,0,0,4.51.5v.13L94.54,25a17.83,17.83,0,0,1-4.13-1.88L86.29,20.9A10.62,10.62,0,0,0,81.91,19.9Z" />
        <path className="cls-2" d="M.21,24.65C31,13.39,71,19.65,100.8,22.78c14.26,1.5,32,4.25,48.17,1.12a58.1,58.1,0,0,0,7.75-2A8.36,8.36,0,0,0,159,21c-16.89,6.88-47.66,3.5-62.8.12-18.89-4.13-43-5.38-63.81-3.5-10.88,1.12-23.64,3.88-32.15,7" /><path className="cls-2" d="M.21,24.65a110.74,110.74,0,0,1,19.14-4.88A130.18,130.18,0,0,1,39,18.15c13.14-.38,26.28.75,39.54,2s26.15,3,39.28,4.25,26.53,1.5,39.16-2.75h0a8.07,8.07,0,0,0,2.25-.88l.13.38a65.59,65.59,0,0,1-19.64,3.75,152.06,152.06,0,0,1-20-.37c-6.88-.63-13.51-1.5-20-2.63a173.43,173.43,0,0,0-19.89-3.38,226.18,226.18,0,0,0-40-1.25,152.44,152.44,0,0,0-19.9,2.38,109.92,109.92,0,0,0-19.64,5,124.28,124.28,0,0,1,19.52-5.13,147.45,147.45,0,0,1,20-2.37,224.49,224.49,0,0,1,40,1.25c6.63.75,13.39,2,19.89,3.38s13.14,2,19.89,2.62a153.84,153.84,0,0,0,20,.38A70.2,70.2,0,0,0,159.22,21l.13.37a8.39,8.39,0,0,1-2.25.88h0C144.46,26.4,131,26.15,117.94,24.9s-26.15-3-39.29-4.25-26.27-2.38-39.53-2a130,130,0,0,0-19.64,1.62A74.87,74.87,0,0,0,.21,24.65Z" />
      </g>
    </g>
  );
};

export default BluePetal60;
