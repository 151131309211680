import React from 'react';

const BluePetalEmpty = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 0.7 : 0.5) : 0.7,
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="blue_no_data_0" d="M0.4,24.2c-2.8,1.7,30.4,16,65.3,15.1c34.9-0.7,63.5-14.9,64.6-18c1.7-5-9.5-3-9.5-3l0,0
      c-3.3,0.3-8.1,0.5-12.3,0.5c-7.9,0-17.1-1.7-24.3-2.7l-1.7-0.3c-5.3-0.5-11.6-1.5-17.4-1.6C65.1,14.2,22.2,10.8,0.4,24.2z"/>
        <path className="blue_no_data_1" d="M14.9,20.3c3.8-0.5,7.6-0.7,11.3-0.4c3.8,0.2,7.5,0.8,11.1,1.9c1.8,0.5,3.6,1.1,5.4,1.9s3.4,1.5,5.2,2.3
          c1.7,0.8,3.4,1.6,5.2,2.1c1.8,0.6,3.6,1,5.5,1.1c-1.9-0.1-3.7-0.5-5.5-1.1c-1.8-0.5-3.5-1.3-5.3-2.1c-1.7-0.8-3.4-1.6-5.2-2.3
          c-1.7-0.7-3.5-1.3-5.3-1.8c-3.6-1-7.3-1.6-11.1-1.8c-3.8-0.2-7.5,0-11.2,0.5L14.9,20.3z"/>
        <path className="blue_no_data_1" d="M14.9,20.3c3.7-0.5,7.5-0.7,11.3-0.4c3.8,0.2,7.5,0.8,11.1,1.9c3.7,0.9,7.1,2.7,10.5,4.2
          c1.7,0.8,3.4,1.6,5.2,2.1c1.8,0.6,3.6,1,5.5,1.1v0.1c-1.9-0.1-3.7-0.5-5.5-1.1c-1.8-0.5-3.5-1.3-5.2-2.1l-5.2-2.3
          c-1.7-0.7-3.5-1.3-5.3-1.8c-7.2-2.1-14.9-2.4-22.4-1.3l0,0L14.9,20.3z M14.9,20.3l0.1,0.3l0,0c7.4-1,15.1-0.7,22.4,1.3
          c1.8,0.5,3.6,1.1,5.3,1.8l5.2,2.3c1.7,0.8,3.4,1.6,5.2,2.1c1.8,0.6,3.6,1,5.5,1.1v0.1c-1.9-0.1-3.8-0.5-5.5-1.1
          c-1.8-0.5-3.5-1.3-5.2-2.1c-3.5-1.5-6.8-3.2-10.5-4.2c-3.6-1.1-7.4-1.7-11.1-1.9C22.4,19.6,18.6,19.8,14.9,20.3z"/>
        <path className="blue_no_data_1" d="M67.1,20.9c1.2,0,2.5,0.3,3.6,0.9c1.1,0.5,2.2,1.2,3.3,1.8c1.1,0.6,2.2,1.1,3.4,1.5c1.2,0.3,2.4,0.5,3.7,0.5
          c-1.2,0-2.5-0.1-3.7-0.4c-1.2-0.4-2.4-0.9-3.4-1.4c-1.1-0.6-2.1-1.2-3.3-1.7c-1.1-0.5-2.3-0.8-3.5-0.8L67.1,20.9z"/>
        <path className="blue_no_data_1" d="M67.1,20.9c1.2,0,2.5,0.3,3.6,0.9l0,0c1.1,0.5,2.2,1.2,3.3,1.8c1.1,0.6,2.2,1.1,3.4,1.5l0,0
          c1.2,0.3,2.4,0.5,3.7,0.5v0.1c-1.2,0-2.5-0.1-3.7-0.4l0,0c-0.9-0.3-1.7-0.6-2.6-1c-0.8-0.4-1.6-0.9-2.4-1.3s-1.6-0.8-2.5-1.1
          c-0.9-0.3-1.8-0.5-2.7-0.5l0,0L67.1,20.9z M67.1,20.9l0.1,0.4l0,0c0.9,0,1.8,0.2,2.7,0.5c0.9,0.3,1.7,0.7,2.5,1.1
          s1.6,0.9,2.4,1.3s1.7,0.7,2.6,1l0,0c1.2,0.3,2.5,0.4,3.7,0.4v0.1c-1.3,0-2.5-0.2-3.7-0.5l0,0c-1.2-0.4-2.3-0.9-3.4-1.5
          s-2.1-1.3-3.3-1.8l0,0C69.6,21.2,68.3,20.9,67.1,20.9z"/>
        <path className="blue_no_data_1" d="M0.4,24.4c25.1-9.2,57.8-4.1,82.2-1.5c11.6,1.2,26.3,3.4,39.3,0.9c2.2-0.4,4.3-0.9,6.4-1.6
          c0.6-0.2,1.2-0.4,1.9-0.7c-13.8,5.6-38.8,2.8-51.3,0.1c-15.6-3.4-35.2-4.5-52.3-2.9C17.7,19.5,7.3,21.8,0.4,24.4"/>
        <path className="blue_no_data_1" d="M0.4,24.4c5.1-1.8,10.5-3.1,15.9-3.9c5.4-0.9,10.9-1.2,16.3-1.3C43.5,19,54.4,19.8,65.3,21
          c10.9,1.1,21.7,2.5,32.6,3.5c5.4,0.4,10.9,0.6,16.4,0.3c2.7-0.2,5.4-0.5,8.1-1s5.3-1.2,7.8-2.2l0.1,0.2c-5.1,2-10.6,2.8-16.1,3.1
          c-5.5,0.3-11,0.1-16.4-0.3c-5.5-0.4-10.9-1.2-16.3-2.2c-2.7-0.5-5.4-1.1-8.1-1.6s-5.4-0.9-8.1-1.2c-10.9-1.3-21.8-1.7-32.8-1.1
          c-2.7,0.2-5.5,0.4-8.2,0.7c-2.7,0.3-5.4,0.7-8.1,1.2C10.9,21.2,5.5,22.5,0.4,24.4c5.1-1.9,10.5-3.2,15.8-4.2
          c2.7-0.5,5.4-0.9,8.1-1.2c2.7-0.3,5.4-0.5,8.2-0.7c10.9-0.6,21.9-0.3,32.8,1.1c2.7,0.3,5.4,0.7,8.1,1.2s5.4,1.1,8.1,1.6
          c5.4,1,10.8,1.7,16.3,2.2c5.5,0.4,10.9,0.6,16.4,0.3c5.4-0.3,10.9-1.1,16-3.1l0.1,0.2c-2.5,1-5.2,1.7-7.9,2.2
          c-2.7,0.5-5.4,0.9-8.1,1c-5.5,0.3-10.9,0.1-16.4-0.3c-10.9-1-21.7-2.3-32.6-3.5s-21.8-2-32.7-1.8c-5.5,0.1-10.9,0.4-16.3,1.3
          C10.9,21.3,5.5,22.6,0.4,24.4z"/>
      </g>
    </g>
  );
};

export default BluePetalEmpty;
