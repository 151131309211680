import React from 'react';

const BluePetal1 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1,
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_1)' }} d="M.14,24.54c-2.37,1.38,25.15,13.14,53.93,12.51s52.42-12.38,53.42-14.88c1.37-4.13-7.88-2.51-7.88-2.51h0c-2.76.25-6.76.5-10.14.5-6.5,0-14.14-1.37-20-2.25l-1.37-.25c-4.38-.37-9.51-1.12-14.39-1.25C53.69,16.41,18.16,13.53.14,24.54Z" />
        <path className="cls-2" d="M12.15,21.41A39.42,39.42,0,0,1,21.54,21a55.56,55.56,0,0,1,9.13,1.5c1.5.38,3,1,4.38,1.5s2.88,1.25,4.25,1.88,2.88,1.25,4.26,1.75a19.16,19.16,0,0,0,4.5,1,25.07,25.07,0,0,1-4.5-.88A37.65,37.65,0,0,1,39.18,26c-1.38-.62-2.88-1.25-4.26-1.87a24.75,24.75,0,0,0-4.37-1.5,44.74,44.74,0,0,0-9.14-1.51,38.09,38.09,0,0,0-9.26.5Z" />
        <path className="cls-2" d="M12.15,21.41A38.1,38.1,0,0,1,21.41,21a55.86,55.86,0,0,1,9.14,1.5A45.72,45.72,0,0,1,34.92,24l4.26,1.88,4.25,1.75a19.13,19.13,0,0,0,4.51,1v.13a26.09,26.09,0,0,1-4.51-.88,26,26,0,0,1-4.25-1.75,56.31,56.31,0,0,0-8.63-3.38,44.72,44.72,0,0,0-9.14-1.5,38.09,38.09,0,0,0-9.26.5h0Zm0,0v.25h0a38.09,38.09,0,0,1,9.26-.5,44.74,44.74,0,0,1,9.14,1.51A56.26,56.26,0,0,1,39.18,26c1.37.63,2.88,1.25,4.25,1.75a27.42,27.42,0,0,0,4.51.88v.13a17.91,17.91,0,0,1-4.51-1L39.18,26l-4.26-1.87c-1.5-.5-2.87-1.13-4.37-1.5a57.13,57.13,0,0,0-9.14-1.51A32.93,32.93,0,0,0,12.15,21.41Z" />
        <path className="cls-2" d="M55.32,21.66a6.54,6.54,0,0,1,3,.76c1,.5,1.75,1,2.75,1.5a11,11,0,0,0,2.75,1.25,26.6,26.6,0,0,0,3,.5,12.48,12.48,0,0,1-3-.38A28.32,28.32,0,0,1,61,24.17a19.21,19.21,0,0,0-2.76-1.38,8,8,0,0,0-2.87-.62Z" /><path className="cls-2" d="M55.32,21.66a6.54,6.54,0,0,1,3,.76l2.75,1.5a11,11,0,0,0,2.75,1.25l3,.5v.12a12.46,12.46,0,0,1-3-.37A26.24,26.24,0,0,1,61,24.29h0a25.44,25.44,0,0,0-2.63-1.37,7.37,7.37,0,0,0-2.88-.63h0Zm0,0v.51h0a7.46,7.46,0,0,1,2.87.62A19.21,19.21,0,0,1,61,24.17h0a28.32,28.32,0,0,0,2.87,1.12,12.48,12.48,0,0,0,3,.38v.12l-3-.5A11,11,0,0,1,61.07,24l-2.75-1.5A5.49,5.49,0,0,0,55.32,21.66Z" />
        <path className="cls-2" d="M.14,24.79c20.77-7.63,47.8-3.38,67.94-1.25,9.63,1,21.64,2.88,32.53.75a40.61,40.61,0,0,0,5.25-1.37,8,8,0,0,0,1.5-.63C96,26.92,75.21,24.67,65,22.42a152.79,152.79,0,0,0-43-2.38A98.42,98.42,0,0,0,.14,24.79" /><path className="cls-2" d="M.14,24.79A74.87,74.87,0,0,1,13,21.54a95.68,95.68,0,0,1,13.26-1.13,210.56,210.56,0,0,1,26.65,1.38c8.88.88,17.64,2,26.52,2.88s17.89,1,26.4-1.88h0a7.33,7.33,0,0,0,1.5-.62l.13.25a43.9,43.9,0,0,1-13.26,2.5,98.44,98.44,0,0,1-13.52-.25c-4.5-.38-9-1-13.38-1.75a117.66,117.66,0,0,0-13.39-2.26,150.79,150.79,0,0,0-27-.87,96.2,96.2,0,0,0-13.39,1.62A76.05,76.05,0,0,0,.14,24.79a93.39,93.39,0,0,1,13.14-3.5,94.29,94.29,0,0,1,13.51-1.63,149.6,149.6,0,0,1,27,.88c4.5.5,9,1.37,13.38,2.25a116.42,116.42,0,0,0,13.39,1.75,96.74,96.74,0,0,0,13.51.25,47.85,47.85,0,0,0,13.26-2.5l.13.25a8,8,0,0,1-1.5.63h0c-8.51,2.75-17.64,2.62-26.4,1.75S62,22.92,53.06,22a210.4,210.4,0,0,0-26.64-1.38,95.82,95.82,0,0,0-13.27,1.13A56.38,56.38,0,0,0,.14,24.79Z" />
      </g>
    </g>
  );
};

export default BluePetal1;
