import React from 'react';

const BluePetal40 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_4)' }} d="M.18,24.41c-3,1.75,32.53,16.89,69.68,16.14s67.69-16,68.94-19.27C140.55,15.9,128.67,18,128.67,18h0c-3.51.38-8.76.63-13.14.63-8.38,0-18.27-1.75-25.9-2.88l-1.75-.38c-5.63-.5-12.26-1.5-18.52-1.62C69.36,13.9,23.45,10.15.18,24.41Z" />
        <path className="cls-2" d="M15.69,20.41a56.11,56.11,0,0,1,12.14-.5,75,75,0,0,1,11.76,1.87c1.87.5,3.88,1.26,5.63,1.88S49,25.29,50.72,26s3.76,1.62,5.51,2.25A23.25,23.25,0,0,0,62,29.54a29,29,0,0,1-5.75-1.12c-1.75-.51-3.76-1.51-5.63-2.26l-5.51-2.37a29.32,29.32,0,0,0-5.63-1.88A53.32,53.32,0,0,0,27.7,20a48.16,48.16,0,0,0-12,.63Z" />
        <path className="cls-2" d="M15.69,20.41a54.3,54.3,0,0,1,12-.5,72.66,72.66,0,0,1,11.76,1.87c1.88.5,3.88,1.26,5.63,1.88L50.6,26l5.5,2.25a23.32,23.32,0,0,0,5.76,1.25v.13a29.66,29.66,0,0,1-5.76-1.13,42,42,0,0,1-5.5-2.25c-3.76-1.63-7.26-3.38-11.14-4.38A53.32,53.32,0,0,0,27.7,20a48.16,48.16,0,0,0-12,.63h0Zm0,0v.37h0a47.84,47.84,0,0,1,12-.62A60.74,60.74,0,0,1,39.46,22c3.88,1,7.38,2.76,11.14,4.38,1.75.75,3.75,1.63,5.5,2.26a30.84,30.84,0,0,0,5.76,1.12v.13a23,23,0,0,1-5.76-1.25l-5.5-2.38-5.51-2.38A50.92,50.92,0,0,0,39.46,22,64,64,0,0,0,27.7,20.16,35,35,0,0,0,15.69,20.41Z" />
        <path className="cls-2" d="M71.49,20.53a9,9,0,0,1,3.88,1c1.25.63,2.25,1.26,3.5,1.88A18,18,0,0,0,82.37,25a21,21,0,0,0,3.88.62,13.65,13.65,0,0,1-3.88-.5c-1.25-.37-2.37-1-3.75-1.5a30.87,30.87,0,0,0-3.5-1.75,9.6,9.6,0,0,0-3.76-.75Z" /><path className="cls-2" d="M71.49,20.53a9,9,0,0,1,3.88,1l3.5,1.88A18,18,0,0,0,82.37,25l3.88.62v.13a14,14,0,0,1-3.88-.5c-1.25-.38-2.37-1-3.75-1.5h0c-1.12-.63-2.25-1.25-3.38-1.76a9.1,9.1,0,0,0-3.75-.75h0Zm0,0v.63h0a12.32,12.32,0,0,1,3.75.75,31.89,31.89,0,0,1,3.51,1.75h0a32.2,32.2,0,0,0,3.75,1.5,13.61,13.61,0,0,0,3.88.5v.13l-3.88-.63A17.67,17.67,0,0,1,79,23.54l-3.51-1.88A7.49,7.49,0,0,0,71.49,20.53Z" />
        <path className="cls-2" d="M.18,24.66C27,14.78,61.86,20.28,87.88,23c12.39,1.25,27.9,3.75,42,1a64.51,64.51,0,0,0,6.75-1.76,11.33,11.33,0,0,0,1.88-.75c-14.64,6-41.54,3.13-54.8.13C67.24,18.16,46.34,17,28.2,18.53a130.09,130.09,0,0,0-28,6.13" /><path className="cls-2" d="M.18,24.66a108.83,108.83,0,0,1,16.64-4.25A132.21,132.21,0,0,1,34,18.91c11.51-.38,22.89.62,34.4,1.75s22.77,2.63,34.28,3.75c11.26,1,23.15,1.25,34-2.38h0a11.33,11.33,0,0,0,1.88-.75l.12.38c-5.5,2.13-11.26,2.88-17.14,3.25a114.82,114.82,0,0,1-17.39-.37c-5.75-.5-11.63-1.25-17.26-2.26a159.06,159.06,0,0,0-17.27-2.87,192.21,192.21,0,0,0-34.9-1.13,132.9,132.9,0,0,0-17.27,2.13A94.09,94.09,0,0,0,.18,24.66a125.65,125.65,0,0,1,17-4.5A132.19,132.19,0,0,1,34.58,18a191.94,191.94,0,0,1,34.91,1.13c5.75.62,11.63,1.75,17.26,2.87A157.74,157.74,0,0,0,104,24.29a134.6,134.6,0,0,0,17.39.37,61.93,61.93,0,0,0,17.14-3.25l.12.37a5.9,5.9,0,0,1-1.87.76h0c-11,3.5-22.77,3.37-34,2.25C91.26,23.66,80,22.16,68.49,21s-22.9-2.12-34.41-1.75a114,114,0,0,0-17.14,1.5A84.21,84.21,0,0,0,.18,24.66Z" />
      </g>
    </g>
  );
};

export default BluePetal40;
