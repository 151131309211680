import React from 'react';

const BluePetal3 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_3)' }} d="M.18,24.43c-2.88,1.62,30,15.64,64.3,14.89S127,24.55,128.16,21.55c1.63-4.88-9.38-3-9.38-3h0c-3.25.25-8,.62-12.14.62-7.75,0-16.89-1.62-23.89-2.62l-1.63-.25c-5.25-.51-11.38-1.38-17.14-1.51C64.11,14.79,21.69,11.29.18,24.43Z" />
        <path className="cls-2" d="M14.56,20.8a47.62,47.62,0,0,1,11.14-.5,63.26,63.26,0,0,1,10.88,1.75c1.75.5,3.63,1.25,5.26,1.75s3.38,1.5,5.13,2.25,3.37,1.51,5.13,2.13a27.37,27.37,0,0,0,5.38,1.25,35.54,35.54,0,0,1-5.38-1c-1.63-.5-3.38-1.38-5.26-2.13-1.62-.75-3.38-1.5-5.13-2.25a23.81,23.81,0,0,0-5.25-1.75,53.42,53.42,0,0,0-10.89-1.75,46.11,46.11,0,0,0-11,.62Z" />
        <path className="cls-2" d="M14.56,20.8a45.94,45.94,0,0,1,11-.5,63.22,63.22,0,0,1,10.89,1.75c1.75.5,3.63,1.25,5.25,1.75l5.13,2.25L52,28.18a27.54,27.54,0,0,0,5.38,1.25v.13a38.75,38.75,0,0,1-5.38-1,44.58,44.58,0,0,1-5.13-2.13,63.85,63.85,0,0,0-10.26-4A52.77,52.77,0,0,0,25.7,20.67a45.53,45.53,0,0,0-11,.63h0Zm0,0v.25h0a46,46,0,0,1,11-.63,52.76,52.76,0,0,1,10.89,1.76,59.77,59.77,0,0,1,10.26,4c1.62.75,3.37,1.5,5.13,2.13a27.37,27.37,0,0,0,5.38,1v.12a19.34,19.34,0,0,1-5.38-1.25l-5.13-2.13L41.59,23.8a38.16,38.16,0,0,0-5.26-1.75A65.19,65.19,0,0,0,25.45,20.3,45,45,0,0,0,14.56,20.8Z" />
        <path className="cls-2" d="M66,21.17a7.7,7.7,0,0,1,3.63.88c1.25.63,2.13,1.25,3.25,1.75a10.93,10.93,0,0,0,3.26,1.5c1.25.25,2.37.5,3.63.63a12.23,12.23,0,0,1-3.63-.5,16.1,16.1,0,0,1-3.38-1.38,15.9,15.9,0,0,0-3.25-1.62,8.75,8.75,0,0,0-3.38-.75Z" /><path className="cls-2" d="M66,21.17a7.7,7.7,0,0,1,3.63.88l3.25,1.75a10.93,10.93,0,0,0,3.26,1.5l3.63.63v.12a11.92,11.92,0,0,1-3.63-.5,16.07,16.07,0,0,1-3.38-1.37h0a18.75,18.75,0,0,0-3.13-1.63,7.84,7.84,0,0,0-3.38-.75h0Zm0,0v.63h0a7.46,7.46,0,0,1,3.38.75,19.32,19.32,0,0,1,3.25,1.63h0c1.26.5,2.26,1,3.38,1.37a11.47,11.47,0,0,0,3.63.5v.13L76,25.55a9.58,9.58,0,0,1-3.25-1.5L69.49,22.3A6.18,6.18,0,0,0,66,21.17Z" />
        <path className="cls-2" d="M.18,24.8c24.77-9.13,57.05-4,81.07-1.5,11.51,1.25,25.77,3.38,38.78.88a57.7,57.7,0,0,0,6.26-1.63A7.27,7.27,0,0,0,128,21.8c-13.51,5.51-38.29,2.88-50.55.13A183,183,0,0,0,26.2,19.05a124.92,124.92,0,0,0-26,5.75" /><path className="cls-2" d="M.18,24.8a90,90,0,0,1,15.38-3.88,113.62,113.62,0,0,1,15.77-1.37,270.72,270.72,0,0,1,31.78,1.62c10.63,1,21.14,2.38,31.65,3.51,10.38.87,21.39,1.25,31.53-2.25h0a7.69,7.69,0,0,0,1.75-.75l.12.25c-5.13,2-10.38,2.62-15.76,3a140.22,140.22,0,0,1-16.14-.25c-5.38-.5-10.76-1.25-16-2.13a115.81,115.81,0,0,0-16-2.63,175.16,175.16,0,0,0-32.28-1,98.84,98.84,0,0,0-16,2A77.4,77.4,0,0,0,.18,24.8a111.93,111.93,0,0,1,15.63-4.13,136.85,136.85,0,0,1,16.14-2,186,186,0,0,1,32.28,1c5.38.63,10.76,1.63,16,2.63s10.63,1.63,16,2.13a100,100,0,0,0,16.14.25,55.83,55.83,0,0,0,15.76-3l.13.25c-.63.25-1.25.62-1.75.75h0c-10.14,3.25-21,3.12-31.53,2.12s-21-2.37-31.65-3.38-21.15-2-31.78-1.62a110.27,110.27,0,0,0-15.77,1.37A65.21,65.21,0,0,0,.18,24.8Z" />
      </g>
    </g>
  );
};

export default BluePetal3;
