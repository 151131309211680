import React from 'react';

const BluePetal100 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_10)' }} d="M.26,24.08c-4.38,2.5,46.79,24.52,100.47,23.27s97.58-23,99.46-27.78c2.5-7.63-14.64-4.63-14.64-4.63h0c-5.13.5-12.64.88-18.89.88-12.14,0-26.28-2.5-37.28-4.25l-2.51-.51c-8.25-.5-17.76-1.87-26.9-2.12C100,8.94,33.79,3.56.26,24.08Z" />
        <path className="cls-2" d="M22.66,18.32a82.88,82.88,0,0,1,17.51-.75,97.59,97.59,0,0,1,17,2.75c2.75.75,5.63,1.88,8.13,2.76s5.38,2.37,7.88,3.5,5.38,2.38,7.88,3.25a40.19,40.19,0,0,0,8.39,1.88,45.31,45.31,0,0,1-8.39-1.63C78.58,29.33,75.7,28,73,26.83c-2.5-1.13-5.38-2.38-7.88-3.5a55.9,55.9,0,0,0-8.13-2.76,78.62,78.62,0,0,0-17-2.75,79.18,79.18,0,0,0-17.26.88Z" />
        <path className="cls-2" d="M22.66,18.32a79.84,79.84,0,0,1,17.26-.75,97.59,97.59,0,0,1,17,2.75c2.75.75,5.63,1.88,8.13,2.76L73,26.58l7.88,3.25a40.25,40.25,0,0,0,8.38,1.88V32a45.2,45.2,0,0,1-8.38-1.63A55.45,55.45,0,0,1,73,27.08c-5.38-2.38-10.51-4.88-16.14-6.26a78.53,78.53,0,0,0-17-2.75A79.27,79.27,0,0,0,22.53,19h0Zm0,0v.5h0A72.35,72.35,0,0,1,39.92,18a86,86,0,0,1,17,2.75c5.63,1.37,10.76,4,16.14,6.25,2.5,1.13,5.38,2.38,7.88,3.26a49.55,49.55,0,0,0,8.38,1.62v.25A31.39,31.39,0,0,1,81,30.21L73.08,27l-7.89-3.5c-3-1-5.5-2.13-8.25-2.88a101.56,101.56,0,0,0-17-2.75A56.78,56.78,0,0,0,22.66,18.32Z" />
        <path className="cls-2" d="M103,18.7a13.42,13.42,0,0,1,5.63,1.37c1.87.88,3.25,1.88,5.13,2.76a24,24,0,0,0,5.13,2.37,29.55,29.55,0,0,0,5.63.88,21.73,21.73,0,0,1-5.63-.75,47.3,47.3,0,0,1-5.38-2.13,32.09,32.09,0,0,0-5.13-2.5A13.89,13.89,0,0,0,103,19.57Z" />
        <path className="cls-2" d="M103,18.7a13.42,13.42,0,0,1,5.63,1.37l5.13,2.76a24,24,0,0,0,5.13,2.37l5.63.88v.25a21.73,21.73,0,0,1-5.63-.75,47.3,47.3,0,0,1-5.38-2.13h0a44.21,44.21,0,0,0-4.88-2.5,13,13,0,0,0-5.38-1.13h0Zm0,0v.87h0a15.25,15.25,0,0,1,5.38,1.13,48.92,48.92,0,0,1,5.13,2.5h0a47.3,47.3,0,0,0,5.38,2.13,21.73,21.73,0,0,0,5.63.75v.25l-5.63-.88a19,19,0,0,1-5.13-2.37l-5.13-2.76A10.84,10.84,0,0,0,103,18.7Z" />
        <path className="cls-2" d="M.26,24.58c38.66-14.27,88.95-6.26,126.49-2.38,17.89,1.88,40.28,5.38,60.55,1.38a92,92,0,0,0,9.76-2.51A25.6,25.6,0,0,0,199.81,20c-21.14,8.63-59.93,4.38-78.94.25-23.77-5.13-54.05-6.76-80.2-4.38C27,17.07,11,20.57.26,24.58" />
        <path className="cls-2" d="M.26,24.58a140.23,140.23,0,0,1,24-6,183.22,183.22,0,0,1,24.65-2.13c16.51-.5,33,.88,49.67,2.51S131.5,22.7,148,24.33c16.26,1.37,33.28,1.87,49.16-3.51h0a26.61,26.61,0,0,0,2.76-1.12l.25.5a79.11,79.11,0,0,1-24.65,4.63,169,169,0,0,1-25.15-.5c-8.38-.75-16.76-1.88-24.89-3.26a237,237,0,0,0-24.9-4.25,282,282,0,0,0-50.29-1.63,182.07,182.07,0,0,0-24.9,3A149.89,149.89,0,0,0,.26,24.58a176,176,0,0,1,24.52-6.51,181.41,181.41,0,0,1,25.15-3,281.23,281.23,0,0,1,50.29,1.62A236.16,236.16,0,0,1,125.12,21,235,235,0,0,0,150,24.2a186.87,186.87,0,0,0,25.15.5,91,91,0,0,0,24.64-4.63l.25.5a12.57,12.57,0,0,1-2.75,1.13h0c-15.89,5.13-32.9,4.88-49.17,3.25-16.51-1.62-32.9-3.75-49.42-5.38s-33-3-49.67-2.5A181.32,181.32,0,0,0,24.41,19.2,97.61,97.61,0,0,0,.26,24.58Z" />
      </g>
    </g>
  );
};

export default BluePetal100;
