import React from 'react';

const BluePetal80 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_8)' }} d="M.24,24.18c-4,2.25,42,21.89,90.08,20.89s87.58-20.64,89.21-24.9c2.25-6.88-13.14-4.12-13.14-4.12h0c-4.63.37-11.26.87-16.89.87-10.89,0-23.65-2.25-33.41-3.75l-2.25-.38c-7.25-.62-15.89-1.87-24-2.12C89.7,10.54,30.27,5.79.24,24.18Z" />
        <path className="cls-2" d="M20.26,18.92A65.88,65.88,0,0,1,35.9,18.3a89.78,89.78,0,0,1,15.26,2.5,63.12,63.12,0,0,1,7.26,2.5c2.25.88,4.75,2.13,7.13,3.13s4.75,2.13,7.13,2.88a27,27,0,0,0,7.51,1.62,46.44,46.44,0,0,1-7.51-1.5,75,75,0,0,1-7.25-2.88c-2.26-1-4.76-2.12-7.14-3.12A44.56,44.56,0,0,0,51,20.93a69.29,69.29,0,0,0-15.27-2.51,64,64,0,0,0-15.51.88Z" />
        <path className="cls-2" d="M20.26,18.92a64,64,0,0,1,15.51-.62A88,88,0,0,1,51,20.8a63.61,63.61,0,0,1,7.25,2.5l7.14,3.13,7.13,2.88a26.9,26.9,0,0,0,7.5,1.62v.25a46.31,46.31,0,0,1-7.5-1.5,48.72,48.72,0,0,1-7.13-2.87C60.67,24.68,56,22.43,51,21.18a69.29,69.29,0,0,0-15.27-2.51,64,64,0,0,0-15.51.88h0Zm0,0v.38h0a64,64,0,0,1,15.51-.88A69.29,69.29,0,0,1,51,20.93c5,1.25,9.63,3.5,14.39,5.62a68.08,68.08,0,0,0,7.13,2.88,52.4,52.4,0,0,0,7.5,1.5v.25a36.34,36.34,0,0,1-7.5-1.62l-7.13-2.88-7.14-3.13c-2.5-.87-4.75-1.87-7.25-2.5a92.06,92.06,0,0,0-15.27-2.5A49.67,49.67,0,0,0,20.26,18.92Z" />
        <path className="cls-2" d="M92.32,18.8a10.56,10.56,0,0,1,5,1.25c1.62.88,2.88,1.63,4.63,2.5a19.16,19.16,0,0,0,4.63,2.13c1.62.37,3.37.62,5,.87a23,23,0,0,1-5-.62,37.58,37.58,0,0,1-4.76-1.88A27.22,27.22,0,0,0,97.2,20.8a12.81,12.81,0,0,0-4.75-1Z" /><path className="cls-2" d="M92.32,18.8a10.56,10.56,0,0,1,5,1.25l4.63,2.5a19.16,19.16,0,0,0,4.63,2.13l5,.87v.25a23,23,0,0,1-5-.62,37.58,37.58,0,0,1-4.76-1.88h0a37.89,37.89,0,0,0-4.38-2.25,12.33,12.33,0,0,0-4.75-1h0Zm0,0v.87h0a12.1,12.1,0,0,1,4.76,1c1.62.63,3.13,1.51,4.63,2.26h0a36.8,36.8,0,0,0,4.75,1.87,22.61,22.61,0,0,0,5,.63v.25l-5-.88a17.08,17.08,0,0,1-4.63-2.12L97.2,20.17A8.48,8.48,0,0,0,92.32,18.8Z" />
        <path className="cls-2" d="M.24,24.55c34.66-12.76,79.82-5.63,113.48-2.12,16.14,1.62,36.15,4.75,54.3,1.25a76,76,0,0,0,8.75-2.25,19.52,19.52,0,0,0,2.5-1c-19,7.76-53.67,4-70.81.25-21.27-4.62-48.41-6-71.93-4A160.2,160.2,0,0,0,.24,24.55" /><path className="cls-2" d="M.24,24.55a126.18,126.18,0,0,1,21.52-5.38A165.48,165.48,0,0,1,43.91,17.3c14.88-.38,29.65.87,44.54,2.25s29.4,3.38,44.28,4.75c14.64,1.25,29.91,1.63,44-3.12h0a19.52,19.52,0,0,0,2.5-1l.26.38c-7.14,2.75-14.64,3.75-22.15,4.13a169.31,169.31,0,0,1-22.52-.38c-7.51-.62-15-1.62-22.39-2.87a220.32,220.32,0,0,0-22.4-3.76,260,260,0,0,0-45.16-1.5,150.79,150.79,0,0,0-22.4,2.75A126.26,126.26,0,0,0,.24,24.55a161.23,161.23,0,0,1,21.9-5.88,165.36,165.36,0,0,1,22.52-2.75,247.77,247.77,0,0,1,45.16,1.5c7.51.88,15,2.25,22.4,3.76a187.66,187.66,0,0,0,22.39,2.87,149.88,149.88,0,0,0,22.52.38,78.91,78.91,0,0,0,22.14-4.13l.26.37a10.56,10.56,0,0,1-2.51,1h0c-14.26,4.62-29.4,4.37-44,2.87-14.88-1.5-29.4-3.37-44.28-4.75S58.92,17.17,44,17.67a165.35,165.35,0,0,0-22.14,1.88A95.45,95.45,0,0,0,.24,24.55Z" />
      </g>
    </g>
  );
};

export default BluePetal80;
