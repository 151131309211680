import React from 'react';

const BluePetal70 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_7)' }} d="M.23,24.23C-3.53,26.35,39.89,45,85.3,44S168,24.48,169.63,20.47c2.12-6.5-12.39-4-12.39-4h0c-4.38.38-10.64.75-16,.75-10.26,0-22.27-2.13-31.53-3.5l-2.13-.38c-6.88-.62-15-1.75-22.64-2C84.68,11.34,28.63,6.84.23,24.23Z" />
        <path className="cls-2" d="M19.12,19.22a64.65,64.65,0,0,1,14.76-.62A80,80,0,0,1,48.27,21c2.38.63,4.75,1.63,6.88,2.38s4.5,2,6.76,3,4.5,2,6.75,2.76a36.8,36.8,0,0,0,7.13,1.62,45.59,45.59,0,0,1-7.13-1.37,73.12,73.12,0,0,1-6.88-2.76c-2.13-1-4.5-2-6.76-3a40.72,40.72,0,0,0-6.88-2.38,70.44,70.44,0,0,0-14.38-2.37,63.31,63.31,0,0,0-14.64.75Z" />
        <path className="cls-2" d="M19.12,19.22a63,63,0,0,1,14.64-.62A78.06,78.06,0,0,1,48.14,21c2.38.63,4.76,1.63,6.88,2.38l6.76,3,6.76,2.76a36.47,36.47,0,0,0,7.13,1.62V31a45.37,45.37,0,0,1-7.13-1.37,45.45,45.45,0,0,1-6.76-2.76c-4.5-2-8.88-4.12-13.64-5.38A70.44,70.44,0,0,0,33.76,19.1a63.31,63.31,0,0,0-14.64.75h0Zm0,0v.38h0a62.94,62.94,0,0,1,14.64-.75,69.11,69.11,0,0,1,14.38,2.37c4.76,1.13,9.14,3.38,13.64,5.38a63.39,63.39,0,0,0,6.76,2.76,48.18,48.18,0,0,0,7.13,1.37V31a28.25,28.25,0,0,1-7.13-1.62L61.78,26.6,55,23.6c-2.37-.75-4.5-1.75-6.88-2.38a95.81,95.81,0,0,0-14.38-2.37A57.14,57.14,0,0,0,19.12,19.22Z" />
        <path className="cls-2" d="M87.18,19.35a11,11,0,0,1,4.75,1.12c1.63.75,2.75,1.63,4.38,2.38a17.27,17.27,0,0,0,4.38,2,30.24,30.24,0,0,0,4.75.75,21,21,0,0,1-4.75-.62,28.75,28.75,0,0,1-4.5-1.75,24,24,0,0,0-4.38-2.13,12.21,12.21,0,0,0-4.51-1Z" /><path className="cls-2" d="M87.18,19.35a11,11,0,0,1,4.75,1.12l4.38,2.38a17.27,17.27,0,0,0,4.38,2l4.75.75v.25a21,21,0,0,1-4.75-.62,28.75,28.75,0,0,1-4.5-1.75h0a33.11,33.11,0,0,0-4.13-2.13,11.22,11.22,0,0,0-4.51-1h0Zm0,0v.75h0a10.71,10.71,0,0,1,4.5,1,35,35,0,0,1,4.38,2.13h0a44,44,0,0,0,4.5,1.75,21,21,0,0,0,4.76.62v.25l-4.76-.75a13.67,13.67,0,0,1-4.37-2l-4.38-2.38A7.76,7.76,0,0,0,87.18,19.35Z" />
        <path className="cls-2" d="M.23,24.6c32.78-12,75.44-5.38,107.22-2,15.13,1.63,34.15,4.5,51.29,1.13A67.76,67.76,0,0,0,167,21.6a20,20,0,0,0,2.37-1c-18,7.25-50.66,3.75-66.93.25C82.3,16.47,56.65,15.09,34.51,17.1,22.87,18.35,9.23,21.22.23,24.6" /><path className="cls-2" d="M.23,24.6a110.49,110.49,0,0,1,20.39-5.13,148.67,148.67,0,0,1,20.89-1.75c14-.37,28,.75,42,2.13s27.77,3.13,41.79,4.5c13.76,1.13,28.27,1.63,41.66-3h0a20,20,0,0,0,2.37-1l.26.37c-6.76,2.63-13.77,3.51-20.9,4a150.74,150.74,0,0,1-21.27-.38c-7.13-.62-14.26-1.62-21.14-2.75a204.07,204.07,0,0,0-21.27-3.5,242.53,242.53,0,0,0-42.66-1.38,132.64,132.64,0,0,0-21.14,2.63,111.48,111.48,0,0,0-21,5.25A143.19,143.19,0,0,1,21,19.1a166.65,166.65,0,0,1,21.26-2.63,241.87,241.87,0,0,1,42.67,1.38c7.13.75,14.26,2.12,21.14,3.5a192.9,192.9,0,0,0,21.14,2.75,151.41,151.41,0,0,0,21.27.38,73.66,73.66,0,0,0,20.89-4l.26.38a20.82,20.82,0,0,1-2.38,1h0c-13.39,4.38-27.78,4.13-41.66,2.75S97.81,21.47,83.8,20.1s-28.15-2.38-42.16-2a167.52,167.52,0,0,0-20.9,1.75A91.88,91.88,0,0,0,.23,24.6Z" />
      </g>
    </g>
  );
};

export default BluePetal70;
