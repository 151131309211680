import React from 'react';

const BluePetal50 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_5)' }} d="M.19,24.3C-3.06,26.18,35,42.44,74.88,41.69s72.57-17.14,73.94-20.64c1.88-5.76-10.88-3.51-10.88-3.51h0c-3.75.38-9.38.75-14,.75-9,0-19.64-1.87-27.78-3.12l-1.87-.38c-6-.5-13.14-1.5-19.9-1.75C74.38,13,25.22,9,.19,24.3Z" />
        <path className="cls-2" d="M16.83,19.92a50.77,50.77,0,0,1,13-.5,70.3,70.3,0,0,1,12.63,2.13,42.5,42.5,0,0,1,6,2.12c1.87.76,4,1.76,5.88,2.63a57,57,0,0,0,5.88,2.38,22.66,22.66,0,0,0,6.25,1.38,32.94,32.94,0,0,1-6.25-1.26,46.36,46.36,0,0,1-6-2.37c-1.87-.88-4-1.75-5.88-2.63a48.47,48.47,0,0,0-6-2.13,60.39,60.39,0,0,0-12.64-2.12,49.17,49.17,0,0,0-12.89.75Z" />
        <path className="cls-2" d="M16.83,19.92a49.34,49.34,0,0,1,12.89-.5,70.26,70.26,0,0,1,12.64,2.13,42.36,42.36,0,0,1,6,2.12l5.88,2.63,5.88,2.38a22.73,22.73,0,0,0,6.26,1.38v.12a32.28,32.28,0,0,1-6.26-1.25,30.41,30.41,0,0,1-5.88-2.38c-4-1.75-7.75-3.63-12-4.63A60.41,60.41,0,0,0,29.6,19.8a49.15,49.15,0,0,0-12.89.75h0Zm0,0v.38h0a55.05,55.05,0,0,1,12.89-.75,60.39,60.39,0,0,1,12.64,2.12c4.13,1,8,3,12,4.63a57,57,0,0,0,5.88,2.38,55.28,55.28,0,0,0,6.25,1.25v.13a25.74,25.74,0,0,1-6.25-1.38L54.37,26.3l-5.88-2.5c-2.13-.75-4-1.5-6-2.13a88.34,88.34,0,0,0-12.63-2.12A56.36,56.36,0,0,0,16.83,19.92Z" />
        <path className="cls-2" d="M76.64,20.05a10,10,0,0,1,4.13,1c1.37.75,2.37,1.37,3.75,2.12a14.89,14.89,0,0,0,3.75,1.76c1.38.37,2.75.5,4.13.75a15.77,15.77,0,0,1-4.13-.5c-1.37-.38-2.63-1-4-1.51a20.92,20.92,0,0,0-3.76-1.87,11.49,11.49,0,0,0-4-.88Z" /><path className="cls-2" d="M76.64,20.05a10,10,0,0,1,4.13,1l3.75,2.12a14.89,14.89,0,0,0,3.75,1.76l4.13.75v.12a15.36,15.36,0,0,1-4.13-.5c-1.37-.37-2.63-1-4-1.5h0a25.12,25.12,0,0,0-3.63-1.88,11,11,0,0,0-4-.87h0Zm0,0v.75h0a11,11,0,0,1,4,.87,25.22,25.22,0,0,1,3.75,1.88h0a26.13,26.13,0,0,0,4,1.5,15.31,15.31,0,0,0,4.13.5v.13l-4-.63a12.77,12.77,0,0,1-3.75-1.75L81,21.17A10.36,10.36,0,0,0,76.64,20.05Z" />
        <path className="cls-2" d="M.19,24.68C29,14.17,66.38,20.05,94.28,22.92c13.38,1.38,30,4,45,1a52.22,52.22,0,0,0,7.25-1.87,7.7,7.7,0,0,0,2.13-.88c-15.76,6.38-44.54,3.26-58.8.13-17.64-3.76-40.16-5-59.68-3.26-10,1.13-22,3.63-30,6.64" /><path className="cls-2" d="M.19,24.68a95.78,95.78,0,0,1,17.9-4.51,129.46,129.46,0,0,1,18.39-1.5c12.26-.38,24.64.75,36.9,1.88s24.4,2.75,36.79,4c12.13,1,24.77,1.38,36.53-2.63h0a7.6,7.6,0,0,0,2.12-.87l.13.37a61.65,61.65,0,0,1-18.39,3.51,135.23,135.23,0,0,1-18.77-.38c-6.25-.5-12.51-1.38-18.51-2.38a150.86,150.86,0,0,0-18.52-3.25,219.78,219.78,0,0,0-37.41-1.25,128.4,128.4,0,0,0-18.51,2.25A110.23,110.23,0,0,0,.19,24.68,128.89,128.89,0,0,1,18.34,19.8,129,129,0,0,1,37.1,17.54,196.1,196.1,0,0,1,74.51,18.8C80.77,19.55,87,20.67,93,21.92a149.79,149.79,0,0,0,18.51,2.38,135.23,135.23,0,0,0,18.77.38,65.67,65.67,0,0,0,18.39-3.51l.12.38a7.6,7.6,0,0,1-2.12.87h0c-11.76,3.76-24.4,3.63-36.53,2.38s-24.4-2.75-36.79-4-24.64-2.25-36.9-1.88a132.51,132.51,0,0,0-18.39,1.5A80.85,80.85,0,0,0,.19,24.68Z" />
      </g>
    </g>
  );
};

export default BluePetal50;
