import React from 'react';
import '../Network.scss';



const NetworkTexts = ({ selected }) => {
  return (
    <g id="subjects" transform="translate(-42,-40)">            
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path d="M246.3,98.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C245.3,98.3,245.8,98.1,246.3,98.1z M245.4,104.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S245.2,104.2,245.4,104.2z"/>
		<path d="M254.3,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S254,98.1,254.3,98.1z"/>
		<path d="M260.1,98.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H258c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L260.1,98.2L260.1,98.2z"/>
		<path d="M265.5,98.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
			c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
			c0-0.6,0.2-1,0.7-1.4C264.3,98.3,264.9,98.1,265.5,98.1z"/>
	</g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path d="M334,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S334.6,326,334,326z M334.3,320c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S335,320,334.3,320z"/>
        <path d="M340.2,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S340.4,322,340.2,322z
          M340.3,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S340.3,321.4,340.3,321.4z"/>
        <path d="M348.1,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H348.1z M346.8,317.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S346.9,317.3,346.8,317.2z"/>
        <path d="M354.5,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S354.1,319,354.5,319z"/>
        <path d="M362.5,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M361.7,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S362.1,325.9,361.7,325.9z M361.1,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S361.6,319.5,361.1,319.5z"/>
        <path d="M374.3,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S374,319,374.3,319z M373.6,320
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S374.2,320,373.6,320z"/>
        <path d="M382.4,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S382.1,319,382.4,319z"/>
        <path d="M385.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S385.5,322,385.3,322z
          M385.4,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S385.4,321.4,385.4,321.4z"/>
        <path d="M395,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M394.3,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S394.7,325.9,394.3,325.9z M393.7,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S394.1,319.5,393.7,319.5z"/>
        <path d="M402.7,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S402.3,319,402.7,319z"/>
        <path d="M409.7,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S409.2,319.1,409.7,319.1z M408.8,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S408.6,325.2,408.8,325.2z"/>
        <path d="M417.7,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S417.4,319,417.7,319z"/>
        <path d="M423.5,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L423.5,319.1L423.5,319.1z"/>
        <path d="M427.5,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S427.2,324,427.5,324z"/>
        <path d="M335.4,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V340.8z M334.7,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S335.1,341.9,334.7,341.9z M334.1,335.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S334.5,335.5,334.1,335.5z"/>
        <path d="M339.7,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S339.9,338,339.7,338z
          M339.8,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S339.8,337.4,339.8,337.4z"/>
        <path d="M347.2,335.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H345c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L347.2,335.1L347.2,335.1z"/>
        <path d="M352.1,335.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H350c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L352.1,335.1L352.1,335.1z"/>
        <path d="M357.3,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H357.3z M356,333.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S356.2,333.3,356,333.2z"/>
        <path d="M363.7,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S363.4,335,363.7,335z"/>
        <path d="M371.7,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1H373c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V340.8z M371,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S371.3,341.9,371,341.9z M370.3,335.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S370.8,335.5,370.3,335.5z"/>
        <path d="M383.9,335c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C383.4,335.1,383.7,335,383.9,335z"/>
        <path d="M395.1,335.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S394.5,335.1,395.1,335.1z M394.2,341.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S393.9,341.2,394.2,341.2z"/>
        <path d="M402.8,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S402.4,335,402.8,335z"/>
        <path d="M408.7,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S408.4,335,408.7,335z"/>
        <path d="M412.8,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H412.8z M411.6,333.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S411.7,333.3,411.6,333.2z"/>
        <path d="M415.9,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S416.1,338,415.9,338z
          M416,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
          s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S416,337.4,416,337.4z"/>
        <path d="M425.3,335c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S424.9,335,425.3,335z M424.8,335.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S425.2,335.7,424.8,335.7z"/>
      </g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path d="M274.2,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C273.5,408.4,273.8,408.4,274.2,408.4z M273.7,409
          c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
          c0-0.2-0.2-0.4-0.5-0.5S274.1,409,273.7,409z"/>
        <path d="M281.9,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C280.1,408.7,280.9,408.3,281.9,408.3z M281.7,408.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S282.2,408.9,281.7,408.9z"/>
        <path d="M290.6,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V414.2z M289.9,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C290.5,415.3,290.2,415.3,289.9,415.3z M289.2,408.8
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C290,409,289.7,408.8,289.2,408.8z"/>
        <path d="M294.8,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S294.5,413.3,294.8,413.3z"/>
        <path d="M303.5,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L303.5,408.4L303.5,408.4z"/>
        <path d="M308.9,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C307.8,408.5,308.3,408.4,308.9,408.4z"/>
        <path d="M317.1,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7
          c-0.1-0.3-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.5-0.6v-3.5H317.1z"/>
        <path d="M324.7,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S324.4,408.4,324.7,408.4z"/>
        <path d="M331.7,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C330.7,408.5,331.2,408.4,331.7,408.4z M330.9,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S330.6,414.5,330.9,414.5z"/>
        <path d="M339.7,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C339.1,408.4,339.4,408.4,339.7,408.4z"/>
        <path d="M349.9,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H349.9z M348.6,406.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S348.8,406.6,348.6,406.5z"/>
      </g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path d="M153.6,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C154.7,415.2,154.2,415.3,153.6,415.3z M153.9,409.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
          v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C155.2,409.5,154.6,409.3,153.9,409.3z"/>
        <path d="M159.9,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C160.6,411.3,160,411.3,159.9,411.3z M159.9,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S159.9,410.7,159.9,410.7z"/>
        <path d="M167.7,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H167.7z M166.4,406.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S166.6,406.6,166.4,406.5z"/>
        <path d="M174.1,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S173.8,408.4,174.1,408.4z"/>
        <path d="M182.1,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V414.2z M181.4,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C182,415.3,181.7,415.3,181.4,415.3z M180.7,408.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C181.5,409,181.2,408.8,180.7,408.8z"/>
        <path d="M193.9,408.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C193.3,408.5,193.6,408.4,193.9,408.4z M193.2,409.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C194.3,409.5,193.8,409.3,193.2,409.3z"/>
        <path d="M202.1,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C201.4,408.6,201.7,408.4,202.1,408.4z"/>
        <path d="M205,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C205.7,411.3,205.1,411.3,205,411.3z M205,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S205,410.7,205,410.7z"/>
        <path d="M214.7,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H216c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V414.2z M213.9,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C214.6,415.3,214.3,415.3,213.9,415.3z M213.3,408.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C214.1,409,213.8,408.8,213.3,408.8z"/>
        <path d="M222.3,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S221.9,408.4,222.3,408.4z"/>
        <path d="M229.3,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C228.3,408.5,228.8,408.4,229.3,408.4z M228.5,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S228.2,414.5,228.5,414.5z"/>
        <path d="M237.3,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S237,408.4,237.3,408.4z"/>
        <path d="M243.1,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V409H241c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L243.1,408.4
          L243.1,408.4z"/>
        <path d="M247.1,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S246.8,413.3,247.1,413.3z"/>
        <path d="M161.7,425.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C161.6,425,161.7,425.1,161.7,425.2z"/>
        <path d="M166.1,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C165,424.5,165.6,424.4,166.1,424.4z M165.2,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S164.9,430.5,165.2,430.5z"/>
        <path d="M171.5,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V425h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H171.5z"/>
        <path d="M176.9,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C175.8,424.5,176.3,424.4,176.9,424.4z"/>
        <path d="M181.3,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S181,429.3,181.3,429.3z"/>
        <path d="M190.5,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C189.4,424.5,189.9,424.4,190.5,424.4z"/>
        <path d="M197.2,424.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C195.5,424.7,196.3,424.3,197.2,424.3z M197,424.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S197.5,424.9,197,424.9z"/>
        <path d="M206.2,424.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C205.6,424.4,205.9,424.4,206.2,424.4z"/>
        <path d="M215.3,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C216,427.3,215.4,427.3,215.3,427.3z M215.4,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S215.3,426.7,215.4,426.7z"/>
        <path d="M224.2,424.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C222.5,424.7,223.3,424.3,224.2,424.3z M224.1,424.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S224.5,424.9,224.1,424.9z"/>
        <path d="M233.3,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S232.9,424.4,233.3,424.4z"/>
        <path d="M238.3,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C239,427.3,238.4,427.3,238.3,427.3z M238.3,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S238.3,426.7,238.3,426.7z"/>
        <path d="M145.2,440.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
          c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
          l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
          c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S145,440.6,145.2,440.6z"/>
        <path d="M159.6,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S159.2,440.4,159.6,440.4z"/>
        <path d="M166.8,440.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C165.1,440.7,165.8,440.3,166.8,440.3z M166.6,440.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S167.1,440.9,166.6,440.9z"/>
        <path d="M180,440.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C179.4,440.5,179.7,440.4,180,440.4z M179.3,441.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
          c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C180.5,441.5,180,441.3,179.3,441.3z"/>
        <path d="M187.2,440.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C186.2,440.5,186.7,440.4,187.2,440.4z M186.4,446.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S186.1,446.5,186.4,446.5z"/>
        <path d="M193.2,440.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C192.1,440.5,192.6,440.4,193.2,440.4z"/>
        <path d="M199,440.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C197.8,440.5,198.4,440.4,199,440.4z"/>
        <path d="M203.4,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C204.2,443.3,203.6,443.3,203.4,443.3z M203.5,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S203.5,442.7,203.5,442.7z"/>
        <path d="M212.7,440.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C212,440.4,212.4,440.4,212.7,440.4z M212.2,441
          c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
          c0-0.2-0.2-0.4-0.5-0.5S212.7,441,212.2,441z"/>
        <path d="M224.9,440.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C223.9,440.5,224.4,440.4,224.9,440.4z M224.1,446.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S223.8,446.5,224.1,446.5z"/>
        <path d="M229.3,440.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
          c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
          l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
          c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S229.1,440.6,229.3,440.6z"/>
        <path d="M242.6,440.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C241.6,440.5,242.1,440.4,242.6,440.4z M241.8,446.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S241.5,446.5,241.8,446.5z"/>
        <path d="M247,440.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2
          s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8
          c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
          c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          C245.9,440.5,246.5,440.6,247,440.6z"/>
        <path d="M253.9,445.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S253.6,445.3,253.9,445.3z"/>
        <path d="M160,456.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C159.3,456.4,159.7,456.4,160,456.4z M159.5,457c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S159.9,457,159.5,457z"/>
        <path d="M167.7,456.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C166,456.7,166.8,456.3,167.7,456.3z M167.5,456.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S168,456.9,167.5,456.9z"/>
        <path d="M176.4,462.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V462.2z M175.7,463.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C176.3,463.3,176,463.3,175.7,463.3z M175.1,456.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C175.9,457,175.5,456.8,175.1,456.8z"/>
        <path d="M180.6,461.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S180.4,461.3,180.6,461.3z"/>
        <path d="M188.5,459.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C189.3,459.3,188.7,459.3,188.5,459.3z M188.6,458.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S188.6,458.7,188.6,458.7z"/>
        <path d="M194.9,454.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L194.9,454.5L194.9,454.5z"/>
        <path d="M199.2,459.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C200,459.3,199.4,459.3,199.2,459.3z M199.3,458.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S199.3,458.7,199.3,458.7z"/>
        <path d="M208.8,456.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C208.2,456.5,208.5,456.4,208.8,456.4z M208.1,457.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C209.2,457.5,208.7,457.3,208.1,457.3z"/>
        <path d="M216.9,456.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S216.6,456.4,216.9,456.4z"/>
        <path d="M224,456.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C222.9,456.5,223.5,456.4,224,456.4z M223.1,462.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S222.8,462.5,223.1,462.5z"/>
        <path d="M232,456.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S231.6,456.4,232,456.4z"/>
        <path d="M237.7,456.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V457h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H237.7z"/>
        <path d="M241.8,461.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S241.5,461.3,241.8,461.3z"/>
        <path d="M168.5,470.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L168.5,470.5L168.5,470.5z"/>
        <path d="M173.9,474.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H173.9z M172.6,470.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S172.8,470.6,172.6,470.5z"/>
        <path d="M181.2,473.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C181.2,473,181.2,473.1,181.2,473.2z"/>
        <path d="M183.6,475.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C184.4,475.3,183.7,475.3,183.6,475.3z M183.7,474.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S183.7,474.7,183.7,474.7z"/>
        <path d="M190.3,477.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S190,477.3,190.3,477.3z"/>
        <path d="M198,472.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
          c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
          l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
          c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S197.8,472.6,198,472.6z"/>
        <path d="M211.3,472.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C210.3,472.5,210.8,472.4,211.3,472.4z M210.5,478.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S210.2,478.5,210.5,478.5z"/>
        <path d="M216.8,472.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V473h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H216.8z"/>
        <path d="M220.9,475.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C221.7,475.3,221,475.3,220.9,475.3z M221,474.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S221,474.7,221,474.7z"/>
        <path d="M230.6,472.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C230,472.6,230.3,472.4,230.6,472.4z"/>
      </g>
      <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path d="M92.8,96.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C91.8,96.4,92.3,96.3,92.8,96.3z
			 M92,102.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
			s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S91.7,102.4,92,102.4z"/>
		<path d="M100.8,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2C99,96.8,99,97.1,99,97.3v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S100.5,96.3,100.8,96.3z"/>
		<path d="M106.6,96.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V97h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H106.6z"/>
		<path d="M112,96.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C110.9,96.5,111.4,96.3,112,96.3z"/>
		<path d="M116.4,101.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			S115,105,115,105c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S116.1,101.2,116.4,101.2z"/>
		<path d="M125.9,103.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
			C126.9,103.1,126.4,103.2,125.9,103.2z M126.1,97.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
			v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C127.4,97.5,126.8,97.2,126.1,97.2z"/>
		<path d="M132.1,99.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5S136.1,99,136,99c-0.2,0-0.9,0-2,0.1
			C132.8,99.2,132.2,99.2,132.1,99.2z M132.1,98.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S132.1,98.7,132.1,98.7z"/>
		<path d="M139.9,98v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V98H139.9z M138.6,94.4
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S138.8,94.6,138.6,94.4z"/>
		<path d="M146.3,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S146,96.3,146.3,96.3z"/>
		<path d="M154.3,102.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L154.3,102.1L154.3,102.1z M153.6,103.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C154.2,103.2,153.9,103.2,153.6,103.2z
			 M152.9,96.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C153.7,96.9,153.4,96.7,152.9,96.7z"/>
		<path d="M166.1,96.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C165.5,96.4,165.8,96.3,166.1,96.3z M165.4,97.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
			c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C166.6,97.5,166,97.2,165.4,97.2z"/>
		<path d="M174.3,96.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C173.6,96.5,173.9,96.3,174.3,96.3z"/>
		<path d="M177.2,99.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C177.9,99.2,177.3,99.2,177.2,99.2z M177.3,98.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S177.2,98.7,177.3,98.7z"/>
		<path d="M186.9,102.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L186.9,102.1L186.9,102.1z M186.1,103.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C186.8,103.2,186.5,103.2,186.1,103.2z
			 M185.5,96.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C186.3,96.9,186,96.7,185.5,96.7z"/>
		<path d="M194.5,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S194.1,96.3,194.5,96.3z"/>
		<path d="M201.5,96.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C200.5,96.4,201,96.3,201.5,96.3z M200.7,102.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S200.4,102.4,200.7,102.4z"/>
		<path d="M209.5,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S209.2,96.3,209.5,96.3z"/>
		<path d="M215.3,96.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V97h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
			s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H215.3z"/>
		<path d="M219.3,101.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S219,101.2,219.3,101.2z"/>
		<path d="M104.5,118.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L104.5,118.1L104.5,118.1z M103.8,119.2c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C104.4,119.2,104.1,119.2,103.8,119.2z M103.2,112.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C104,112.9,103.6,112.7,103.2,112.7z"/>
		<path d="M111.9,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S111.6,112.3,111.9,112.3z"/>
		<path d="M119.1,112.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C117.4,112.6,118.2,112.3,119.1,112.3z M118.9,112.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S119.4,112.8,118.9,112.8z"/>
		<path d="M126.1,112.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C125,112.5,125.5,112.3,126.1,112.3z"/>
		<path d="M131.4,112.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V113h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
			s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H131.4z"/>
		<path d="M136.8,112.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C135.6,112.5,136.2,112.3,136.8,112.3z"/>
		<path d="M141.1,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S140.9,117.2,141.1,117.2z"/>
		<path d="M148.7,110.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L148.7,110.4L148.7,110.4z"/>
		<path d="M154.1,114v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H154.1z M152.9,110.4
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S153,110.6,152.9,110.4z"/>
		<path d="M161.5,113.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C161.5,112.9,161.5,113,161.5,113.1z"/>
		<path d="M163.9,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C164.6,115.2,164,115.2,163.9,115.2z M163.9,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S163.9,114.7,163.9,114.7z"/>
		<path d="M170.5,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S170.2,117.2,170.5,117.2z"/>
		<path d="M178.1,110.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L178.1,110.4L178.1,110.4z"/>
		<path d="M183.5,114v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H183.5z M182.2,110.4
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S182.4,110.6,182.2,110.4z"/>
		<path d="M188.8,112.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C187.1,112.6,187.9,112.3,188.8,112.3z M188.7,112.8c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S189.1,112.8,188.7,112.8z"/>
		<path d="M197.9,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S197.5,112.3,197.9,112.3z"/>
		<path d="M204.2,112.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C203.1,112.5,203.6,112.3,204.2,112.3z"/>
		<path d="M208.6,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S208.3,117.2,208.6,117.2z"/>
		<path d="M90.4,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H90.4z"/>
		<path d="M97.6,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S97.2,128.3,97.6,128.3z"/>
		<path d="M102.6,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C103.3,131.2,102.7,131.2,102.6,131.2z M102.6,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S102.6,130.7,102.6,130.7z"/>
		<path d="M114,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C114.7,131.2,114.1,131.2,114,131.2z M114,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S114,130.7,114,130.7z"/>
		<path d="M124,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S123.7,128.3,124,128.3z"/>
		<path d="M131.6,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C130.8,128.4,131.2,128.3,131.6,128.3z
			 M131.1,128.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4
			v-4c0-0.2-0.2-0.4-0.5-0.5S131.5,128.9,131.1,128.9z"/>
		<path d="M144,128.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C142.2,128.6,143,128.3,144,128.3z M143.8,128.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S144.2,128.8,143.8,128.8z"/>
		<path d="M152.2,124.2c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
			c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.1-0.6,0.4s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1
			h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8
			C151.7,124.3,151.9,124.2,152.2,124.2z"/>
		<path d="M160,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H160v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H160z"/>
		<path d="M167.1,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S166.8,128.3,167.1,128.3z"/>
		<path d="M172.1,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1S172.2,131.2,172.1,131.2z M172.2,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S172.2,130.7,172.2,130.7z"/>
		<path d="M183.4,128.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0
			s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9
			c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2
			c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2
			c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0
			c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1
			c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0
			S183.2,128.5,183.4,128.5z"/>
		<path d="M196.8,128.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C195.1,128.6,195.9,128.3,196.8,128.3z M196.6,128.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S197.1,128.8,196.6,128.8z"/>
		<path d="M205.5,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C204.9,128.5,205.2,128.3,205.5,128.3z"/>
		<path d="M208.2,126.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L208.2,126.4L208.2,126.4z"/>
		<path d="M215.1,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C214.3,128.4,214.7,128.3,215.1,128.3z
			 M214.6,128.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4
			v-4c0-0.2-0.2-0.4-0.5-0.5S215,128.9,214.6,128.9z"/>
		<path d="M220.5,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S220.2,133.2,220.5,133.2z"/>
		<path d="M111,144.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0
			c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
			s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
			c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2
			s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
			c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3
			s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0
			c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4
			c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S110.8,144.5,111,144.5z"/>
		<path d="M124.3,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C123.3,144.4,123.8,144.3,124.3,144.3z M123.5,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S123.2,150.4,123.5,150.4z"/>
		<path d="M129.8,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H129.8z"/>
		<path d="M133.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C134.6,147.2,134,147.2,133.9,147.2z M134,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S133.9,146.7,134,146.7z"/>
		<path d="M143.6,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C142.9,144.5,143.3,144.3,143.6,144.3z"/>
		<path d="M146.3,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S146,149.2,146.3,149.2z"/>
		<path d="M154.1,144.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C153,144.5,153.5,144.5,154.1,144.5z"/>
		<path d="M163.6,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C161.8,144.6,162.6,144.3,163.6,144.3z M163.4,144.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S163.9,144.8,163.4,144.8z"/>
		<path d="M173,146.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
			c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
			c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7s-0.1-0.6-0.2-0.8
			s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
			s0.5-0.4,0.5-0.6v-3.5H173z"/>
		<path d="M180.4,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C179.7,144.5,180,144.3,180.4,144.3z"/>
		<path d="M188,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C188.8,147.2,188.2,147.2,188,147.2z M188.1,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S188.1,146.7,188.1,146.7z"/>
		<path d="M194.9,144.5c0.5,0,1,0,1.6-0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.4,0-0.6,0.1-0.6,0.3c0,0,0,0.1,0.1,0.2l1,1.6
			c0.1-0.1,0.2-0.4,0.4-0.6s0.4-0.5,0.5-0.7s0.2-0.3,0.2-0.4c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0s-0.2,0-0.2,0
			s0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0s0.3,0,0.5,0c0.5,0,0.9,0,1.2-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2,0,0.2c-0.7,0-1.1,0.2-1.2,0.3c-0.1,0.2-0.3,0.5-0.6,0.8c-0.3,0.4-0.5,0.7-0.7,0.9s-0.3,0.4-0.3,0.4l1.9,2.8
			c0.3,0.4,0.7,0.6,1.2,0.6c0,0,0,0,0,0.1s0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.5,0s-0.3,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.6,0s-0.4,0-0.6,0c0-0.1,0-0.1,0-0.3s0-0.2,0-0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.2v-0.1
			c-0.5-0.9-1-1.5-1.2-2c-0.1,0.1-0.2,0.3-0.5,0.7s-0.5,0.7-0.6,1c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.1,0.1,0.2c0,0,0.1,0.1,0.2,0.1
			s0.2,0,0.2,0s0.1,0,0.3,0h0.1c0,0,0,0,0,0.1s0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0
			s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2s0-0.2,0-0.2s0.1,0,0.2,0s0.2,0,0.2,0s0.1,0,0.2,0c0.1,0,0.2,0,0.2-0.1
			c0,0,0.1,0,0.2-0.1s0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2c1-1.4,1.5-2.1,1.5-2.2l-1.8-2.7
			c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2C193.9,144.5,194.5,144.5,194.9,144.5z"
			/>
	</g>
    
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M296.1,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C295,408.5,295.5,408.4,296.1,408.4z"/>
        <path d="M303.9,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S303.6,408.4,303.9,408.4z"/>
        <path d="M310.9,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C309.9,408.5,310.4,408.4,310.9,408.4z M310.1,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S309.8,414.5,310.1,414.5z"/>
        <path d="M316.5,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1H319h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H316.5z"/>
        <path d="M323.1,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C323.8,411.3,323.2,411.3,323.1,411.3z M323.1,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S323.1,410.7,323.1,410.7z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M186.7,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C187.8,415.2,187.3,415.3,186.7,415.3z M187,409.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8
          c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C188.2,409.5,187.7,409.3,187,409.3z"/>
        <path d="M195,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C193.9,408.5,194.5,408.4,195,408.4z M194.1,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S193.8,414.5,194.1,414.5z"/>
        <path d="M201.2,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C202.3,415.2,201.8,415.3,201.2,415.3z M201.5,409.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
          v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C202.8,409.5,202.2,409.3,201.5,409.3z"/>
        <path d="M207.2,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C206.1,408.5,206.6,408.6,207.2,408.6z"/>
        <path d="M214,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S213.8,413.3,214,413.3z"/>
        <path d="M153.6,431.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C154.7,431.2,154.2,431.3,153.6,431.3z M153.9,425.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
          v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C155.2,425.5,154.6,425.3,153.9,425.3z"/>
        <path d="M159.9,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C160.6,427.3,160,427.3,159.9,427.3z M159.9,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S159.9,426.7,159.9,426.7z"/>
        <path d="M167.7,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H167.7z M166.4,422.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S166.6,422.6,166.4,422.5z"/>
        <path d="M174.1,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S173.8,424.4,174.1,424.4z"/>
        <path d="M182.1,430.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.2z M181.4,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C182,431.3,181.7,431.3,181.4,431.3z M180.7,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C181.5,425,181.2,424.8,180.7,424.8z"/>
        <path d="M193.9,424.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C193.3,424.5,193.6,424.4,193.9,424.4z M193.2,425.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C194.3,425.5,193.8,425.3,193.2,425.3z"/>
        <path d="M202.1,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C201.4,424.6,201.7,424.4,202.1,424.4z"/>
        <path d="M205,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C205.7,427.3,205.1,427.3,205,427.3z M205,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S205,426.7,205,426.7z"/>
        <path d="M214.7,430.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H216c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.2z M213.9,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C214.6,431.3,214.3,431.3,213.9,431.3z M213.3,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C214.1,425,213.8,424.8,213.3,424.8z"/>
        <path d="M222.3,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S221.9,424.4,222.3,424.4z"/>
        <path d="M229.3,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C228.3,424.5,228.8,424.4,229.3,424.4z M228.5,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S228.2,430.5,228.5,430.5z"/>
        <path d="M237.3,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S237,424.4,237.3,424.4z"/>
        <path d="M243.1,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V425H241c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L243.1,424.4
          L243.1,424.4z"/>
        <path d="M247.1,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S246.8,429.3,247.1,429.3z"/>
        <path d="M162.8,441.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C162.7,441,162.8,441.1,162.8,441.2z"/>
        <path d="M168.1,440.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C167.5,440.6,167.8,440.4,168.1,440.4z"/>
        <path d="M171,440.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2
          s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8
          c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
          c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          C169.9,440.5,170.4,440.6,171,440.6z"/>
        <path d="M179.6,442.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H179.6z M178.3,438.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S178.4,438.6,178.3,438.5z"/>
        <path d="M186,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S185.6,440.4,186,440.4z"/>
        <path d="M193.9,446.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V446.2z M193.2,447.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C193.9,447.3,193.6,447.3,193.2,447.3z M192.6,440.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C193.4,441,193.1,440.8,192.6,440.8z"/>
        <path d="M198.2,445.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S197.9,445.3,198.2,445.3z"/>
        <path d="M207.4,440.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C206.3,440.5,206.8,440.4,207.4,440.4z"/>
        <path d="M214.9,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S214.6,440.4,214.9,440.4z"/>
        <path d="M221.9,440.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C220.9,440.5,221.4,440.4,221.9,440.4z M221.1,446.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S220.8,446.5,221.1,446.5z"/>
        <path d="M229.7,440.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C229,440.6,229.3,440.4,229.7,440.4z"/>
        <path d="M233.5,445.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1H236h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H233.5z"/>
        <path d="M240.7,445.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S240.4,445.3,240.7,445.3z"/>
        <path d="M185,456.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C183.9,456.5,184.4,456.4,185,456.4z"/>
        <path d="M192.8,456.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S192.5,456.4,192.8,456.4z"/>
        <path d="M199.8,456.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C198.8,456.5,199.3,456.4,199.8,456.4z M199,462.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S198.7,462.5,199,462.5z"/>
        <path d="M205.4,461.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1H208h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H205.4z"/>
        <path d="M211.9,459.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C212.7,459.3,212.1,459.3,211.9,459.3z M212,458.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S212,458.7,212,458.7z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M309.3,135.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C310.4,135.1,309.9,135.2,309.3,135.2z M309.6,129.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8
          c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C310.8,129.5,310.3,129.2,309.6,129.2z"/>
        <path d="M315.5,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C316.3,131.2,315.7,131.2,315.5,131.2z M315.6,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S315.6,130.7,315.6,130.7z"/>
        <path d="M323.4,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H323.4
          z M322.1,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S322.2,126.6,322.1,126.4z"/>
        <path d="M329.8,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S329.4,128.3,329.8,128.3z"/>
        <path d="M337.8,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L337.8,134.1L337.8,134.1z M337,135.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C337.7,135.2,337.4,135.2,337,135.2z M336.4,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
          c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C337.2,128.9,336.9,128.7,336.4,128.7z"/>
        <path d="M349.6,128.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C348.9,128.4,349.3,128.3,349.6,128.3z M348.9,129.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C350,129.5,349.5,129.2,348.9,129.2z"/>
        <path d="M357.7,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C357.1,128.5,357.4,128.3,357.7,128.3z"/>
        <path d="M360.6,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C361.4,131.2,360.8,131.2,360.6,131.2z M360.7,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S360.7,130.7,360.7,130.7z"/>
        <path d="M370.3,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L370.3,134.1L370.3,134.1z M369.6,135.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C370.3,135.2,370,135.2,369.6,135.2z M369,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
          c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C369.8,128.9,369.4,128.7,369,128.7z"/>
        <path d="M377.9,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S377.6,128.3,377.9,128.3z"/>
        <path d="M385,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C384,128.4,384.5,128.3,385,128.3z M384.1,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S383.9,134.4,384.1,134.4z"/>
        <path d="M393,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S392.7,128.3,393,128.3z"/>
        <path d="M398.8,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L398.8,128.4L398.8,128.4z"/>
        <path d="M402.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S402.5,133.2,402.8,133.2z"/>
        <path d="M310.7,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H312c0.1,0.1,0.2,0.3,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L310.7,150.1L310.7,150.1z M310,151.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C310.7,151.2,310.4,151.2,310,151.2z M309.4,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
          c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C310.2,144.9,309.8,144.7,309.4,144.7z"/>
        <path d="M315,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C315.8,147.2,315.2,147.2,315,147.2z M315.1,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S315.1,146.7,315.1,146.7z"/>
        <path d="M322.5,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L322.5,144.4L322.5,144.4z"/>
        <path d="M327.4,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L327.4,144.4L327.4,144.4z"/>
        <path d="M332.6,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H332.6
          z M331.3,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S331.5,142.6,331.3,142.4z"/>
        <path d="M339,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S338.7,144.3,339,144.3z"/>
        <path d="M347,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L347,150.1L347,150.1z M346.3,151.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C346.9,151.2,346.6,151.2,346.3,151.2z M345.6,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
          s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C346.4,144.9,346.1,144.7,345.6,144.7z"/>
        <path d="M359.2,144.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
          c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C358.7,144.3,359,144.3,359.2,144.3
          z"/>
        <path d="M370.3,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C369.3,144.4,369.8,144.3,370.3,144.3z M369.5,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S369.2,150.4,369.5,150.4z"/>
        <path d="M378.1,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C377.4,144.5,377.7,144.3,378.1,144.3z"/>
        <path d="M384,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C383.4,144.5,383.7,144.3,384,144.3z"/>
        <path d="M388.1,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H388.1
          z M386.8,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S387,142.6,386.8,142.4z"/>
        <path d="M391.2,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C392,147.2,391.4,147.2,391.2,147.2z M391.3,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S391.3,146.7,391.3,146.7z"/>
        <path d="M400.5,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C399.8,144.4,400.2,144.3,400.5,144.3z M400,144.9
          c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
          c0-0.2-0.2-0.4-0.5-0.5S400.5,144.9,400,144.9z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
		<path d="M110.7,151.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3
			c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
			C111.7,151.1,111.2,151.2,110.7,151.2z M110.9,145.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C112.2,145.5,111.6,145.2,110.9,145.2z"/>
		<path d="M116.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C117.7,147.2,117,147.2,116.9,147.2z M117,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S117,146.7,117,146.7z"/>
		<path d="M124.7,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L124.7,146
			L124.7,146z M123.4,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S123.6,142.6,123.4,142.4z"/>
		<path d="M131.1,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S130.8,144.3,131.1,144.3z"/>
		<path d="M139.1,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L139.1,150.1L139.1,150.1z M138.4,151.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C139,151.2,138.7,151.2,138.4,151.2z
			 M137.8,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C138.6,144.9,138.2,144.7,137.8,144.7z"/>
		<path d="M150.9,144.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C150.3,144.4,150.6,144.3,150.9,144.3z M150.2,145.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			S150.9,145.2,150.2,145.2z"/>
		<path d="M159.1,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C158.4,144.5,158.8,144.3,159.1,144.3z"/>
		<path d="M162,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C162.8,147.2,162.1,147.2,162,147.2z M162.1,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S162.1,146.7,162.1,146.7z"/>
		<path d="M171.7,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H173c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L171.7,150.1L171.7,150.1z M171,151.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C171.6,151.2,171.3,151.2,171,151.2z
			 M170.3,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C171.1,144.9,170.8,144.7,170.3,144.7z"/>
		<path d="M179.3,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S179,144.3,179.3,144.3z"/>
		<path d="M186.3,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C185.3,144.4,185.8,144.3,186.3,144.3z M185.5,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S185.2,150.4,185.5,150.4z"/>
		<path d="M194.3,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S194,144.3,194.3,144.3z"/>
		<path d="M200.1,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V145H198c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L200.1,144.4L200.1,144.4z"/>
	</g>
    
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M252.2,98.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C252.2,98.7,252.2,98.8,252.2,98.9z"/>
        <path d="M256.6,98.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
          c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
          c0.3-0.3,0.8-0.6,1.3-0.9C255.6,98.3,256.1,98.1,256.6,98.1z M255.8,104.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S255.5,104.2,255.8,104.2z"/>
        <path d="M262.1,98.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H260c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L262.1,98.2L262.1,98.2z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M365.4,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S364.8,319,365.4,319z"/>
        <path d="M373.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S372.9,319,373.2,319z"/>
        <path d="M380.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S379.7,319.1,380.2,319.1z M379.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S379.1,325.2,379.4,325.2z"/>
        <path d="M385.8,324.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V320c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
          h-0.1H388c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H385.8z"/>
        <path d="M392.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S392.5,322,392.3,322z
          M392.4,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S392.4,321.4,392.4,321.4z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M97.8,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S97.8,319.8,97.8,319.8z"/>
        <path d="M102.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S101.7,319.1,102.2,319.1z M101.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S101.1,325.2,101.4,325.2z"/>
        <path d="M107.7,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H107.7z"/>
        <path d="M111.7,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S111.4,324,111.7,324z"/>
        <path d="M122.9,319c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C122.3,319.1,122.6,319,122.9,319z"/>
        <path d="M134.2,319c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S133.2,319,134.2,319z M134,319.5c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S134.4,319.5,134,319.5z"/>
        <path d="M143.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S142.9,319,143.2,319z"/>
        <path d="M149.1,324.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V320c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
          h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H149.1z"/>
        <path d="M155.6,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S155.7,322,155.6,322z
          M155.7,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S155.7,321.4,155.7,321.4z"/>
        <path d="M162.2,319.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C161.1,319.2,161.6,319.2,162.2,319.2z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M161.9,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C161.2,408.4,161.6,408.4,161.9,408.4z M161.4,409
          c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
          c0-0.2-0.2-0.4-0.5-0.5S161.8,409,161.4,409z"/>
        <path d="M169.6,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C167.9,408.7,168.6,408.3,169.6,408.3z M169.4,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S169.9,408.9,169.4,408.9z"/>
        <path d="M178.3,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V414.2z M177.6,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C178.2,415.3,177.9,415.3,177.6,415.3z M176.9,408.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C177.7,409,177.4,408.8,176.9,408.8z"/>
        <path d="M182.5,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S182.3,413.3,182.5,413.3z"/>
        <path d="M193.7,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C193.1,408.4,193.4,408.4,193.7,408.4z"/>
        <path d="M205,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C203.3,408.7,204.1,408.3,205,408.3z M204.8,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S205.3,408.9,204.8,408.9z"/>
        <path d="M214,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S213.7,408.4,214,408.4z"/>
        <path d="M219.9,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H219.9z"/>
        <path d="M226.4,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C227.2,411.3,226.6,411.3,226.4,411.3z M226.5,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S226.5,410.7,226.5,410.7z"/>
        <path d="M233,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2
          s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8
          c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
          c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          C231.9,408.5,232.5,408.6,233,408.6z"/>
        <path d="M239.9,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S239.6,413.3,239.9,413.3z"/>
        <path d="M153.2,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C152.1,424.5,152.6,424.4,153.2,424.4z"/>
        <path d="M161,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S160.7,424.4,161,424.4z"/>
        <path d="M168,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C167,424.5,167.5,424.4,168,424.4z M167.2,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S166.9,430.5,167.2,430.5z"/>
        <path d="M173.6,429.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1H176h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1L174,428l0,0
          v1.4H173.6z"/>
        <path d="M180.2,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C180.9,427.3,180.3,427.3,180.2,427.3z M180.2,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S180.2,426.7,180.2,426.7z"/>
        <path d="M186.8,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S186.5,429.3,186.8,429.3z"/>
        <path d="M196,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C194.9,424.5,195.4,424.4,196,424.4z"/>
        <path d="M202.7,424.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C201,424.7,201.7,424.3,202.7,424.3z M202.5,424.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S203,424.9,202.5,424.9z"/>
        <path d="M211.7,424.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C211.1,424.4,211.4,424.4,211.7,424.4z"/>
        <path d="M220.8,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C221.5,427.3,220.9,427.3,220.8,427.3z M220.8,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S220.8,426.7,220.8,426.7z"/>
        <path d="M229.7,424.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C228,424.7,228.8,424.3,229.7,424.3z M229.5,424.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S230,424.9,229.5,424.9z"/>
        <path d="M238.7,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S238.4,424.4,238.7,424.4z"/>
        <path d="M243.7,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C244.5,427.3,243.9,427.3,243.7,427.3z M243.8,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S243.8,426.7,243.8,426.7z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M318.8,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1L322,151c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H320
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C318,144.4,318.4,144.3,318.8,144.3z M318.3,144.9
          c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
          c0-0.2-0.2-0.4-0.5-0.5S318.7,144.9,318.3,144.9z"/>
        <path d="M326.5,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C324.7,144.6,325.5,144.3,326.5,144.3z M326.3,144.8c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S326.7,144.8,326.3,144.8z"/>
        <path d="M335.2,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L335.2,150.1L335.2,150.1z M334.4,151.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C335.1,151.2,334.8,151.2,334.4,151.2z M333.8,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
          s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C334.6,144.9,334.3,144.7,333.8,144.7z"/>
        <path d="M339.4,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          S338,153,338,153c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S339.1,149.2,339.4,149.2z"/>
        <path d="M348.1,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L348.1,144.4L348.1,144.4z"/>
        <path d="M353.5,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          C352.3,144.5,352.9,144.3,353.5,144.3z"/>
        <path d="M361.6,146.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7
          c-0.1-0.3-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H361.6z"/>
        <path d="M369.3,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S368.9,144.3,369.3,144.3z"/>
        <path d="M376.3,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C375.3,144.4,375.8,144.3,376.3,144.3z M375.5,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S375.2,150.4,375.5,150.4z"/>
        <path d="M384.3,144.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
          c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C383.7,144.3,384,144.3,384.3,144.3
          z"/>
        <path d="M394.5,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H394.5
          z M393.2,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S393.3,142.6,393.2,142.4z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
		<path d="M120.6,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C121.3,131.2,120.7,131.2,120.6,131.2z M120.7,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S120.6,130.7,120.7,130.7z"/>
		<path d="M129.3,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C128.3,128.4,128.8,128.3,129.3,128.3z M128.5,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S128.2,134.4,128.5,134.4z"/>
		<path d="M137,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C136.4,128.5,136.7,128.3,137,128.3z"/>
		<path d="M140.8,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H140.8z"/>
		<path d="M147.9,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S147.6,128.3,147.9,128.3z"/>
		<path d="M155.7,128.3c0.2,0,0.5,0.1,0.9,0.2c0.5,0.1,0.8,0.2,0.9,0.2c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0.1s0.1,0.2,0.1,0.2
			c-0.1,0.3-0.2,0.7-0.2,1.2v6.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.3c0-0.1,0-0.1,0-0.1s0,0-0.1,0c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.1,0.3
			s-0.7-0.1-1-0.2s-0.6-0.3-0.8-0.5s-0.4-0.5-0.6-0.8s-0.3-0.6-0.4-0.9s-0.1-0.6-0.1-0.9c0-0.4,0.1-0.8,0.2-1.2
			c0.2-0.4,0.4-0.7,0.6-1s0.6-0.6,0.9-0.8c0.3-0.2,0.7-0.4,1-0.5C155,128.3,155.3,128.3,155.7,128.3z M155.2,128.8
			c-0.3,0-0.7,0.1-0.9,0.3s-0.5,0.4-0.7,0.7s-0.3,0.6-0.4,0.8c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.9,0.2,1.6,0.6,2.1s1,0.8,1.6,0.8
			c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.4-0.3,0.4-0.4V130c0-0.3-0.1-0.6-0.4-0.8C155.9,128.9,155.6,128.8,155.2,128.8z"/>
		<path d="M164.4,130.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
			c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
			c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7s-0.1-0.6-0.2-0.8
			s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
			s0.5-0.4,0.5-0.6v-3.5H164.4z"/>
		<path d="M170.8,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C169.8,128.4,170.3,128.3,170.8,128.3z M169.9,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S169.6,134.4,169.9,134.4z"/>
		<path d="M176.3,133.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
			c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
			c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
			c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
			s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
			v1.4H176.3z"/>
		<path d="M182.9,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C183.7,131.2,183,131.2,182.9,131.2z M183,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S183,130.7,183,130.7z"/>
		<path d="M189.5,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S189.3,133.2,189.5,133.2z"/>
		<path d="M104.8,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L104.8,146
			L104.8,146z M103.6,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S103.7,142.6,103.6,142.4z"/>
		<path d="M111.3,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S110.9,144.3,111.3,144.3z"/>
		<path d="M117.6,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
			c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C116.4,144.5,117,144.3,117.6,144.3z"/>
		<path d="M122,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C122.8,147.2,122.2,147.2,122,147.2z M122.1,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S122.1,146.7,122.1,146.7z"/>
		<path d="M133,145.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
			c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4
			c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C133,144.9,133,145,133,145.1z"/>
		<path d="M136.1,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V145H134c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L136.1,144.4L136.1,144.4z"/>
		<path d="M141.5,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C140.4,144.5,140.9,144.3,141.5,144.3z"/>
		<path d="M145.9,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S145.6,149.2,145.9,149.2z"/>
		<path d="M155.1,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C154,144.5,154.5,144.3,155.1,144.3z"/>
		<path d="M161.8,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C160.1,144.6,160.9,144.3,161.8,144.3z M161.6,144.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S162.1,144.8,161.6,144.8z"/>
		<path d="M170.8,144.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
			c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
			c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
			s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C170.2,144.3,170.5,144.3,170.8,144.3z"/>
		<path d="M179.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C180.6,147.2,180,147.2,179.9,147.2z M180,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S179.9,146.7,180,146.7z"/>
		<path d="M188.8,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C187.1,144.6,187.9,144.3,188.8,144.3z M188.7,144.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S189.1,144.8,188.7,144.8z"/>
		<path d="M197.9,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S197.5,144.3,197.9,144.3z"/>
		<path d="M202.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C203.6,147.2,203,147.2,202.9,147.2z M202.9,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S202.9,146.7,202.9,146.7z"/>
	</g>
    
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M249.4,98.4c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V99h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8L249.4,98.4L249.4,98.4z"/>
        <path d="M257.9,98.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C257.3,98.3,257.6,98.1,257.9,98.1z"/>
        <path d="M260.9,101.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C261.6,101,261,101.1,260.9,101.1z M260.9,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S260.9,100.5,260.9,100.5z"/>
      </g>
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M367.1,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S367.7,326,367.1,326z M367.4,320c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S368.1,320,367.4,320z"/>
        <path d="M375.4,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S374.9,319.1,375.4,319.1z M374.5,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S374.2,325.2,374.5,325.2z"/>
        <path d="M381.6,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S382.2,326,381.6,326z M381.9,320c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S382.6,320,381.9,320z"/>
        <path d="M387.6,319.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C386.5,319.2,387,319.2,387.6,319.2z"/>
        <path d="M394.4,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S394.1,324,394.4,324z"/>
        <path d="M334,342c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S334.6,342,334,342z M334.3,336c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S335,336,334.3,336z"/>
        <path d="M340.2,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S340.4,338,340.2,338z
          M340.3,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S340.3,337.4,340.3,337.4z"/>
        <path d="M348.1,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H348.1z M346.8,333.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S346.9,333.3,346.8,333.2z"/>
        <path d="M354.5,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S354.1,335,354.5,335z"/>
        <path d="M362.5,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V340.8z M361.7,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S362.1,341.9,361.7,341.9z M361.1,335.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S361.6,335.5,361.1,335.5z"/>
        <path d="M374.3,335c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S374,335,374.3,335z M373.6,336
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S374.2,336,373.6,336z"/>
        <path d="M382.4,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S382.1,335,382.4,335z"/>
        <path d="M385.3,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S385.5,338,385.3,338z
          M385.4,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S385.4,337.4,385.4,337.4z"/>
        <path d="M395,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V340.8z M394.3,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S394.7,341.9,394.3,341.9z M393.7,335.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S394.1,335.5,393.7,335.5z"/>
        <path d="M402.7,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S402.3,335,402.7,335z"/>
        <path d="M409.7,335.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S409.2,335.1,409.7,335.1z M408.8,341.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S408.6,341.2,408.8,341.2z"/>
        <path d="M417.7,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S417.4,335,417.7,335z"/>
        <path d="M423.5,335.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L423.5,335.1L423.5,335.1z"/>
        <path d="M427.5,340c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S427.2,340,427.5,340z"/>
        <path d="M343.1,351.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S343.1,351.8,343.1,351.8z"/>
        <path d="M348.5,351c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S348.2,351,348.5,351z"/>
        <path d="M351.4,351.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C350.3,351.2,350.8,351.2,351.4,351.2z"/>
        <path d="M359.9,352.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H359.9z M358.7,349.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S358.8,349.3,358.7,349.2z"/>
        <path d="M366.4,351c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S366,351,366.4,351z"/>
        <path d="M374.3,356.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V356.8z M373.6,357.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S374,357.9,373.6,357.9z M373,351.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S373.4,351.5,373,351.5
          z"/>
        <path d="M378.6,356c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S378.3,356,378.6,356z"/>
        <path d="M387.8,351c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S387.2,351,387.8,351z"/>
        <path d="M395.3,351c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V349
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S395,351,395.3,351z"/>
        <path d="M402.3,351.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S401.8,351.1,402.3,351.1z M401.5,357.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S401.2,357.2,401.5,357.2z"/>
        <path d="M410,351c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S409.7,351,410,351z"/>
        <path d="M413.9,356.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V352c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
          h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H413.9z"/>
        <path d="M421,356c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S420.8,356,421,356z"/>
        <path d="M365.4,367c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S364.8,367,365.4,367z"/>
        <path d="M373.2,367c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S372.9,367,373.2,367z"/>
        <path d="M380.2,367.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S379.7,367.1,380.2,367.1z M379.4,373.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S379.1,373.2,379.4,373.2z"/>
        <path d="M385.8,372.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V368c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
          h-0.1H388c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H385.8z"/>
        <path d="M392.3,370c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S392.5,370,392.3,370z
          M392.4,369.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S392.4,369.4,392.4,369.4z"/>
      </g>
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M122.3,319.3c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V320h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8
          L122.3,319.3L122.3,319.3z"/>
        <path d="M130.9,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S130.6,319,130.9,319z"/>
        <path d="M133.8,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S133.9,322,133.8,322z
          M133.9,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S133.9,321.4,133.9,321.4z"/>
        <path d="M140.4,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S140.2,324,140.4,324z"/>
        <path d="M109.8,335c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C109.3,335.1,109.6,335,109.8,335z"/>
        <path d="M121.2,335c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S120.2,335,121.2,335z M121,335.5c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S121.4,335.5,121,335.5z"/>
        <path d="M130.2,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S129.8,335,130.2,335z"/>
        <path d="M136.1,340.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V336c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
          h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H136.1z"/>
        <path d="M142.6,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S142.7,338,142.6,338z
          M142.7,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S142.7,337.4,142.7,337.4z"/>
        <path d="M149.2,335.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C148.1,335.2,148.6,335.2,149.2,335.2z"/>
      </g>
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M273,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C272.3,408.4,272.7,408.4,273,408.4z M272.5,409c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S272.9,409,272.5,409z"/>
        <path d="M280.7,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C279,408.7,279.8,408.3,280.7,408.3z M280.5,408.9c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S281,408.9,280.5,408.9z"/>
        <path d="M289.4,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V414.2z M288.7,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C289.3,415.3,289,415.3,288.7,415.3z M288,408.8
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C288.9,409,288.5,408.8,288,408.8z"/>
        <path d="M293.6,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S293.4,413.3,293.6,413.3z"/>
        <path d="M304.8,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C304.2,408.4,304.5,408.4,304.8,408.4z"/>
        <path d="M316.1,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C314.4,408.7,315.2,408.3,316.1,408.3z M315.9,408.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S316.4,408.9,315.9,408.9z"/>
        <path d="M325.1,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S324.8,408.4,325.1,408.4z"/>
        <path d="M331,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H331z"/>
        <path d="M337.5,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C338.3,411.3,337.7,411.3,337.5,411.3z M337.6,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S337.6,410.7,337.6,410.7z"/>
        <path d="M344.1,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C343,408.5,343.6,408.6,344.1,408.6z"/>
        <path d="M351,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S350.7,413.3,351,413.3z"/>
        <path d="M264.3,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C263.2,424.5,263.7,424.4,264.3,424.4z"/>
        <path d="M272.1,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S271.8,424.4,272.1,424.4z"/>
        <path d="M279.1,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C278.1,424.5,278.6,424.4,279.1,424.4z M278.3,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S278,430.5,278.3,430.5z"/>
        <path d="M284.7,429.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1H287h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H284.7z"/>
        <path d="M291.3,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C292,427.3,291.4,427.3,291.3,427.3z M291.3,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S291.3,426.7,291.3,426.7z"/>
        <path d="M297.9,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S297.6,429.3,297.9,429.3z"/>
        <path d="M307.1,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C306,424.5,306.5,424.4,307.1,424.4z"/>
        <path d="M313.8,424.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C312.1,424.7,312.9,424.3,313.8,424.3z M313.6,424.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S314.1,424.9,313.6,424.9z"/>
        <path d="M322.8,424.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C322.2,424.4,322.5,424.4,322.8,424.4z"/>
        <path d="M331.9,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C332.6,427.3,332,427.3,331.9,427.3z M331.9,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S331.9,426.7,331.9,426.7z"/>
        <path d="M340.8,424.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C339.1,424.7,339.9,424.3,340.8,424.3z M340.6,424.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S341.1,424.9,340.6,424.9z"/>
        <path d="M349.8,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S349.5,424.4,349.8,424.4z"/>
        <path d="M354.8,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C355.6,427.3,355,427.3,354.8,427.3z M354.9,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S354.9,426.7,354.9,426.7z"/>
      </g>
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path d="M311.6,87.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3v0.1
          c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C312.6,87.1,312.1,87.2,311.6,87.2z M311.8,81.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8
          c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C313.1,81.5,312.5,81.2,311.8,81.2z"/>
        <path d="M317.5,83.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C318.2,83.2,317.6,83.2,317.5,83.2z M317.5,82.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S317.5,82.7,317.5,82.7z"/>
        <path d="M325,82v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V82H325z M323.7,78.4
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S323.8,78.6,323.7,78.4z"/>
        <path d="M331.1,80.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S330.7,80.3,331.1,80.3z"/>
        <path d="M338.7,86.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H340c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L338.7,86.1L338.7,86.1z M338,87.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C338.7,87.2,338.3,87.2,338,87.2z M337.4,80.7
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C338.2,80.9,337.8,80.7,337.4,80.7z"/>
        <path d="M349.9,80.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6
          C349.3,80.4,349.6,80.3,349.9,80.3z M349.2,81.2c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
          c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C350.3,81.5,349.8,81.2,349.2,81.2z"/>
        <path d="M357.7,80.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C357.1,80.5,357.4,80.3,357.7,80.3z"/>
        <path d="M360.3,83.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C361.1,83.2,360.5,83.2,360.3,83.2z M360.4,82.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S360.4,82.7,360.4,82.7z"/>
        <path d="M369.7,86.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H371c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L369.7,86.1L369.7,86.1z M369,87.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C369.6,87.2,369.3,87.2,369,87.2z M368.3,80.7
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C369.2,80.9,368.8,80.7,368.3,80.7z"/>
        <path d="M377,80.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S376.7,80.3,377,80.3z"/>
        <path d="M383.7,80.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C382.7,80.4,383.2,80.3,383.7,80.3z M382.9,86.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S382.6,86.4,382.9,86.4z"/>
        <path d="M391.4,80.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S391.1,80.3,391.4,80.3z"/>
        <path d="M396.8,80.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V81h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L396.8,80.4L396.8,80.4z"/>
        <path d="M400.5,85.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S400.3,85.2,400.5,85.2z"/>
        <path d="M319.3,97.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C319.2,96.9,319.3,97,319.3,97.1z"/>
        <path d="M323.3,96.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C322.3,96.4,322.8,96.3,323.3,96.3z M322.5,102.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S322.2,102.4,322.5,102.4z"/>
        <path d="M328.5,96.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V97h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L328.5,96.4L328.5,96.4z"/>
        <path d="M333.6,96.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C332.4,96.5,333,96.3,333.6,96.3z"/>
        <path d="M337.6,101.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S337.4,101.2,337.6,101.2z"/>
        <path d="M346.2,96.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C345.1,96.5,345.6,96.3,346.2,96.3z"/>
        <path d="M352.6,96.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C350.8,96.6,351.6,96.3,352.6,96.3z M352.4,96.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S352.8,96.8,352.4,96.8z"/>
        <path d="M361.2,96.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          V97l0,0c0.5-0.4,0.9-0.7,1.2-0.8C360.7,96.3,361,96.3,361.2,96.3z"/>
        <path d="M370,99.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5S374,99,373.9,99
          c-0.2,0-0.9,0-2,0.1C370.8,99.2,370.1,99.2,370,99.2z M370.1,98.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S370.1,98.7,370.1,98.7z"/>
        <path d="M378.6,96.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C376.9,96.6,377.7,96.3,378.6,96.3z M378.5,96.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S378.9,96.8,378.5,96.8z"/>
        <path d="M387.3,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S387,96.3,387.3,96.3z"/>
        <path d="M392,99.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5S396,99,395.9,99
          c-0.2,0-0.9,0-2,0.1C392.8,99.2,392.2,99.2,392,99.2z M392.1,98.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S392.1,98.7,392.1,98.7z"/>
        <path d="M303.2,112.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0
          c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
          s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
          c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2
          s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
          c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2
          c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0
          s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
          c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1
          s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0
          S303.1,112.5,303.2,112.5z"/>
        <path d="M317.3,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S317,112.3,317.3,112.3z"/>
        <path d="M324.2,112.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C322.5,112.6,323.3,112.3,324.2,112.3z M324.1,112.8c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S324.5,112.8,324.1,112.8z"/>
        <path d="M336.8,112.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6
          C336.2,112.4,336.5,112.3,336.8,112.3z M336.1,113.2c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
          c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C337.3,113.5,336.8,113.2,336.1,113.2z"/>
        <path d="M343.7,112.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C342.7,112.4,343.2,112.3,343.7,112.3z M342.8,118.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S342.5,118.4,342.8,118.4z"/>
        <path d="M349.4,112.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C348.2,112.5,348.8,112.3,349.4,112.3z"/>
        <path d="M354.8,112.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C353.7,112.5,354.2,112.3,354.8,112.3z"/>
        <path d="M359,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C359.7,115.2,359.1,115.2,359,115.2z M359,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S359,114.7,359,114.7z"/>
        <path d="M367.9,112.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C367.2,112.4,367.6,112.3,367.9,112.3z
          M367.4,112.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4
          v-4c0-0.2-0.2-0.4-0.5-0.5S367.9,112.9,367.4,112.9z"/>
        <path d="M379.5,112.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C378.5,112.4,379,112.3,379.5,112.3z M378.6,118.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S378.3,118.4,378.6,118.4z"/>
        <path d="M383.5,112.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0
          c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
          s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
          c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2
          s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
          c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2
          c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0
          s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
          c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1
          s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0
          S383.3,112.5,383.5,112.5z"/>
        <path d="M396.5,112.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C395.5,112.4,396,112.3,396.5,112.3z M395.7,118.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S395.4,118.4,395.7,118.4z"/>
        <path d="M400.6,112.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C399.5,112.5,400.1,112.5,400.6,112.5z"/>
        <path d="M407.1,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S406.9,117.2,407.1,117.2z"/>
        <path d="M317.8,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1L321,135c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H319
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C317,128.4,317.4,128.3,317.8,128.3z M317.3,128.9
          c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
          c0-0.2-0.2-0.4-0.5-0.5S317.7,128.9,317.3,128.9z"/>
        <path d="M325.1,128.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C323.4,128.6,324.2,128.3,325.1,128.3z M325,128.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S325.4,128.8,325,128.8z"/>
        <path d="M333.5,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L333.5,134.1L333.5,134.1z M332.8,135.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C333.5,135.2,333.1,135.2,332.8,135.2z M332.2,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
          s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C333,128.9,332.6,128.7,332.2,128.7z"/>
        <path d="M337.4,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          S336,137,336,137c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S337.2,133.2,337.4,133.2z"/>
        <path d="M344.7,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C345.5,131.2,344.8,131.2,344.7,131.2z M344.8,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S344.8,130.7,344.8,130.7z"/>
        <path d="M350.8,126.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V126.4z"/>
        <path d="M354.8,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C355.5,131.2,354.9,131.2,354.8,131.2z M354.8,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S354.8,130.7,354.8,130.7z"/>
        <path d="M364,128.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C363.4,128.4,363.7,128.3,364,128.3z M363.3,129.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
          c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C364.4,129.5,363.9,129.2,363.3,129.2z"/>
        <path d="M371.8,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S371.5,128.3,371.8,128.3z"/>
        <path d="M378.5,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C377.5,128.4,378,128.3,378.5,128.3z M377.7,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S377.4,134.4,377.7,134.4z"/>
        <path d="M386.2,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S385.9,128.3,386.2,128.3z"/>
        <path d="M391.7,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L391.7,128.4L391.7,128.4z"/>
        <path d="M395.4,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          S394,137,394,137c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S395.1,133.2,395.4,133.2z"/>
        <path d="M325.7,142.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
          c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
          V142.4z"/>
        <path d="M330.8,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H330.8
          z M329.6,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S329.7,142.6,329.6,142.4z"/>
        <path d="M337.9,145.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C337.9,144.9,337.9,145,337.9,145.1z"/>
        <path d="M339.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C340.7,147.2,340.1,147.2,339.9,147.2z M340,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S340,146.7,340,146.7z"/>
        <path d="M346.3,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S346,149.2,346.3,149.2z"/>
        <path d="M353.4,144.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0
          c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
          s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
          c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2
          s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
          c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3
          s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0
          c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4
          c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2
          c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
          c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S353.2,144.5,353.4,144.5z"/>
        <path d="M366.4,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C365.3,144.4,365.9,144.3,366.4,144.3z M365.5,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S365.2,150.4,365.5,150.4z"/>
        <path d="M371.5,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L371.5,144.4L371.5,144.4z"/>
        <path d="M375.3,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C376.1,147.2,375.4,147.2,375.3,147.2z M375.4,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S375.4,146.7,375.4,146.7z"/>
        <path d="M384.7,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C384,144.5,384.4,144.3,384.7,144.3z"/>
      </g>
      <g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
		<path d="M94.2,80.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C93.1,80.5,93.6,80.3,94.2,80.3z"/>
		<path d="M100.6,80.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C98.8,80.6,99.6,80.3,100.6,80.3z M100.4,80.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S100.8,80.8,100.4,80.8z"/>
		<path d="M109.2,80.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
			c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5
			c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5V85c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
			V81l0,0c0.5-0.4,0.9-0.7,1.2-0.8C108.7,80.3,109,80.3,109.2,80.3z"/>
		<path d="M118,83.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5S122,83,121.9,83
			c-0.2,0-0.9,0-2,0.1C118.8,83.2,118.1,83.2,118,83.2z M118.1,82.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S118.1,82.7,118.1,82.7z"/>
		<path d="M126.6,80.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C124.9,80.6,125.7,80.3,126.6,80.3z M126.5,80.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S126.9,80.8,126.5,80.8z"/>
		<path d="M135.3,80.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S135,80.3,135.3,80.3z"/>
		<path d="M140,83.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5S144,83,143.9,83c-0.2,0-0.9,0-2,0.1
			C140.8,83.2,140.2,83.2,140,83.2z M140.1,82.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S140.1,82.7,140.1,82.7z"/>
		<path d="M150.6,80.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
			c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
			s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
			c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
			s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
			c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
			l-1.7-4.5L153,87c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S150.4,80.5,150.6,80.5z"/>
		<path d="M164.7,80.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S164.4,80.3,164.7,80.3z"/>
		<path d="M171.6,80.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C169.9,80.6,170.7,80.3,171.6,80.3z M171.4,80.8c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S171.9,80.8,171.4,80.8z"/>
		<path d="M184.2,80.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C183.6,80.4,183.9,80.3,184.2,80.3z M183.5,81.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
			c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C184.7,81.5,184.1,81.2,183.5,81.2z"/>
		<path d="M191.1,80.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C190,80.4,190.6,80.3,191.1,80.3z M190.2,86.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S189.9,86.4,190.2,86.4z"/>
		<path d="M196.8,80.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C195.6,80.5,196.2,80.3,196.8,80.3z"/>
		<path d="M202.2,80.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C201.1,80.5,201.6,80.3,202.2,80.3z"/>
		<path d="M206.3,83.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C207.1,83.2,206.5,83.2,206.3,83.2z M206.4,82.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S206.4,82.7,206.4,82.7z"/>
		<path d="M215.3,80.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
			c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C214.6,80.4,215,80.3,215.3,80.3z M214.8,80.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
			c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S215.3,80.9,214.8,80.9z"/>
		<path d="M93.5,96.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1H96h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5S92.1,97,92,97.1c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C92.5,96.4,93,96.3,93.5,96.3z
			 M92.7,102.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
			s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S92.4,102.4,92.7,102.4z"/>
		<path d="M97.6,96.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9
			c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
			c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2
			s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
			c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2
			c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0
			s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1
			s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0
			S97.4,96.5,97.6,96.5z"/>
		<path d="M110.6,96.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C109.5,96.4,110.1,96.3,110.6,96.3z M109.7,102.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S109.4,102.4,109.7,102.4z"/>
		<path d="M114.6,96.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C113.6,96.5,114.1,96.5,114.6,96.5z"/>
		<path d="M121.2,101.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S120.9,101.2,121.2,101.2z"/>
		<path d="M130,103.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
			C131.1,103.1,130.5,103.2,130,103.2z M130.3,97.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8
			c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C131.5,97.5,131,97.2,130.3,97.2z"/>
		<path d="M135.9,99.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C136.7,99.2,136,99.2,135.9,99.2z M136,98.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S136,98.7,136,98.7z"/>
		<path d="M143.4,98v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V98H143.4z M142.1,94.4
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S142.3,94.6,142.1,94.4z"/>
		<path d="M149.5,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S149.2,96.3,149.5,96.3z"/>
		<path d="M157.2,102.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L157.2,102.1L157.2,102.1z M156.4,103.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C157.1,103.2,156.8,103.2,156.4,103.2z
			 M155.8,96.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C156.6,96.9,156.3,96.7,155.8,96.7z"/>
		<path d="M168.3,96.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C167.7,96.4,168,96.3,168.3,96.3z M167.6,97.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
			c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C168.8,97.5,168.3,97.2,167.6,97.2z"/>
		<path d="M176.2,96.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C175.5,96.5,175.8,96.3,176.2,96.3z"/>
		<path d="M178.8,99.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C179.5,99.2,178.9,99.2,178.8,99.2z M178.8,98.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S178.8,98.7,178.8,98.7z"/>
		<path d="M188.1,102.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L188.1,102.1L188.1,102.1z M187.4,103.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C188.1,103.2,187.8,103.2,187.4,103.2z
			 M186.8,96.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C187.6,96.9,187.2,96.7,186.8,96.7z"/>
		<path d="M195.4,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S195.1,96.3,195.4,96.3z"/>
		<path d="M202.1,96.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C201.1,96.4,201.6,96.3,202.1,96.3z M201.3,102.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S201,102.4,201.3,102.4z"/>
		<path d="M209.8,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S209.5,96.3,209.8,96.3z"/>
		<path d="M215.3,96.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V97h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
			s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H215.3z"/>
		<path d="M219,101.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S218.7,101.2,219,101.2z"/>
		<path d="M111.4,113.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
			c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
			s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C111.3,112.9,111.4,113,111.4,113.1z"/>
		<path d="M113.1,110.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V110.4z"/>
		<path d="M117.1,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C117.9,115.2,117.2,115.2,117.1,115.2z M117.2,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S117.1,114.7,117.2,114.7z"/>
		<path d="M125.5,112.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C124.5,112.4,125,112.3,125.5,112.3z M124.7,118.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S124.4,118.4,124.7,118.4z"/>
		<path d="M133.2,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S132.9,112.3,133.2,112.3z"/>
		<path d="M142.1,112.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
			c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
			s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
			c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
			s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
			c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
			l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
			c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S141.9,112.5,142.1,112.5z"/>
		<path d="M155.1,112.3c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C154.1,112.4,154.6,112.3,155.1,112.3z M154.3,118.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S154,118.4,154.3,118.4z"/>
		<path d="M160.3,112.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V113h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
			s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H160.3z"/>
		<path d="M164,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C164.8,115.2,164.2,115.2,164,115.2z M164.1,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S164.1,114.7,164.1,114.7z"/>
		<path d="M173.4,112.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C172.8,112.5,173.1,112.3,173.4,112.3z"/>
		<path d="M175.8,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S175.6,117.2,175.8,117.2z"/>
		<path d="M183.1,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C183.9,115.2,183.2,115.2,183.1,115.2z M183.2,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S183.2,114.7,183.2,114.7z"/>
		<path d="M192.5,118.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L192.5,118.1L192.5,118.1z M191.7,119.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C192.4,119.2,192.1,119.2,191.7,119.2z
			 M191.1,112.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C191.9,112.9,191.6,112.7,191.1,112.7z"/>
		<path d="M199.4,118.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L199.4,118.1L199.4,118.1z M198.7,119.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C199.4,119.2,199.1,119.2,198.7,119.2z
			 M198.1,112.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C198.9,112.9,198.5,112.7,198.1,112.7z"/>
		<path d="M203.3,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S203.1,117.2,203.3,117.2z"/>
		<path d="M121,126.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V126.4z"/>
		<path d="M126.1,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L126.1,130
			L126.1,130z M124.8,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S124.9,126.6,124.8,126.4z"/>
		<path d="M133.1,129.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
			c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4
			c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C133.1,128.9,133.1,129,133.1,129.1z"/>
		<path d="M135.1,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1S135.3,131.2,135.1,131.2z M135.2,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S135.2,130.7,135.2,130.7z"/>
		<path d="M141.5,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S141.2,133.2,141.5,133.2z"/>
		<path d="M152,128.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
			c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
			c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
			s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C151.4,128.3,151.7,128.3,152,128.3z"/>
		<path d="M163,128.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C161.3,128.6,162,128.3,163,128.3z M162.8,128.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S163.3,128.8,162.8,128.8z"/>
		<path d="M171.7,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S171.4,128.3,171.7,128.3z"/>
		<path d="M176.4,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C177.2,131.2,176.5,131.2,176.4,131.2z M176.5,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S176.5,130.7,176.5,130.7z"/>
		<path d="M182.6,128.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C181.6,128.5,182.1,128.5,182.6,128.5z"/>
		<path d="M189.2,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S188.9,133.2,189.2,133.2z"/>
		<path d="M110.7,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
			c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C109.6,144.5,110.1,144.3,110.7,144.3z"/>
		<path d="M117.1,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C115.3,144.6,116.1,144.3,117.1,144.3z M116.9,144.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S117.4,144.8,116.9,144.8z"/>
		<path d="M125.7,144.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
			c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
			c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
			c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8
			C125.2,144.3,125.5,144.3,125.7,144.3z"/>
		<path d="M134.5,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C135.3,147.2,134.6,147.2,134.5,147.2z M134.6,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S134.6,146.7,134.6,146.7z"/>
		<path d="M143.1,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C141.4,144.6,142.2,144.3,143.1,144.3z M143,144.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S143.4,144.8,143,144.8z"/>
		<path d="M151.8,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S151.5,144.3,151.8,144.3z"/>
		<path d="M156.5,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C157.3,147.2,156.7,147.2,156.5,147.2z M156.6,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S156.6,146.7,156.6,146.7z"/>
		<path d="M162.9,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S162.6,149.2,162.9,149.2z"/>
		<path d="M170,144.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
			c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5
			c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
			c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2
			s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2
			s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
			c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S169.8,144.5,170,144.5z"/>
		<path d="M183,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C181.9,144.4,182.5,144.3,183,144.3z M182.1,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S181.8,150.4,182.1,150.4z"/>
		<path d="M188.1,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V145H186c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L188.1,144.4L188.1,144.4z"/>
		<path d="M191.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C192.7,147.2,192,147.2,191.9,147.2z M192,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S192,146.7,192,146.7z"/>
		<path d="M201.3,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C200.6,144.5,201,144.3,201.3,144.3z"/>
	</g>
    
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path d="M236.3,82.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C236.3,82.7,236.3,82.8,236.3,82.9z"/>
        <path d="M240.7,82.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
          c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
          c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
          c0.3-0.3,0.8-0.6,1.3-0.9C239.7,82.3,240.2,82.1,240.7,82.1z M239.8,88.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S239.5,88.2,239.8,88.2z"/>
        <path d="M246.1,82.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9V87c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4V88
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5H244c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0
          L246.1,82.2L246.1,82.2z"/>
        <path d="M250.2,87c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C249.7,87.1,249.9,87,250.2,87z"/>
        <path d="M260.8,82.4c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          C262,88,262,87.6,262,87v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3H259V87c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          c0.3,0,0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0
          s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V83H257
          c0,0,0,0,0-0.1s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9c-0.3,0.6-0.5,1.2-0.5,2v0.8L260.8,82.4L260.8,82.4z"/>
        <path d="M269.4,82.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
          c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0
          l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C268.7,82.3,269.1,82.1,269.4,82.1z"/>
        <path d="M272.3,85.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C273.1,85,272.4,85.1,272.3,85.1z M272.4,84.5h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C272.4,84.4,272.4,84.5,272.4,84.5z"/>
        <path d="M278.9,87c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          S278,88,278,87.8s0.1-0.4,0.3-0.5C278.5,87.1,278.7,87,278.9,87z"/>
        <path d="M247.5,98.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C246.8,98.3,247.2,98.1,247.5,98.1z"/>
        <path d="M252.5,98.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
          c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
          c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
          c0.3-0.3,0.8-0.6,1.3-0.9C251.5,98.3,252,98.1,252.5,98.1z M251.6,104.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S251.4,104.2,251.6,104.2z"/>
        <path d="M258.3,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          v0.6H258.3z M257,96.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S257.2,96.4,257,96.2z"/>
        <path d="M264.7,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C264,98.2,264.4,98.1,264.7,98.1z"/>
      </g>
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path d="M278.5,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1
          c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          C278.5,409,278.5,409.1,278.5,409.2z"/>
        <path d="M282.9,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C281.9,408.5,282.4,408.4,282.9,408.4z M282.1,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S281.8,414.5,282.1,414.5z"/>
        <path d="M288.4,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          s-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3
          h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L288.4,408.4L288.4,408.4z"/>
        <path d="M292.4,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C291.9,413.4,292.1,413.3,292.4,413.3z"/>
        <path d="M303.5,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9
          c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2
          c-0.3,0.1-0.4,0.3-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C303,408.4,303.3,408.4,303.5,408.4z"/>
        <path d="M314.9,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C313.1,408.7,313.9,408.3,314.9,408.3z M314.7,408.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2C315.5,409,315.1,408.9,314.7,408.9z"/>
        <path d="M323.9,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C323.2,408.5,323.6,408.4,323.9,408.4z"/>
        <path d="M329.8,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
          c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2
          c0-0.1,0.1-0.1,0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1H332c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2
          c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3
          c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2c0.5,0.5,0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3
          c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5
          c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H329.8z"/>
        <path d="M336.3,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C337.1,411.3,336.4,411.3,336.3,411.3z M336.4,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C336.4,410.7,336.4,410.7,336.4,410.7z"/>
        <path d="M342.9,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C341.8,408.5,342.3,408.6,342.9,408.6z"/>
      </g>
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path d="M191.9,408.7c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-1c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.8V408.7z"/>
        <path d="M200.5,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C199.8,408.6,200.1,408.4,200.5,408.4z"/>
        <path d="M203.4,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C204.2,411.3,203.5,411.3,203.4,411.3z M203.5,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S203.5,410.7,203.5,410.7z"/>
        <path d="M210,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S209.8,413.3,210,413.3z"/>
        <path d="M179.4,424.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C178.9,424.4,179.2,424.4,179.4,424.4z"/>
        <path d="M190.7,424.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C189,424.7,189.8,424.3,190.7,424.3z M190.6,424.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S191,424.9,190.6,424.9z"/>
        <path d="M199.8,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S199.4,424.4,199.8,424.4z"/>
        <path d="M205.6,429.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1H208h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1L206,428l0,0
          v1.4H205.6z"/>
        <path d="M212.2,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C213,427.3,212.3,427.3,212.2,427.3z M212.3,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S212.3,426.7,212.3,426.7z"/>
        <path d="M218.8,424.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C217.7,424.5,218.2,424.6,218.8,424.6z"/>
      </g>
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
		<path d="M121.3,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L121.3,134.1L121.3,134.1z M120.6,135.2c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C121.2,135.2,120.9,135.2,120.6,135.2z M119.9,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
			s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C120.8,128.9,120.4,128.7,119.9,128.7z"/>
		<path d="M128.6,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C127.9,128.5,128.3,128.3,128.6,128.3z"/>
		<path d="M133.6,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
			s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
			c0.3-0.3,0.8-0.6,1.3-0.9C132.6,128.4,133.1,128.3,133.6,128.3z M132.8,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S132.5,134.4,132.8,134.4z"/>
		<path d="M141.1,128.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
			c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6C140.5,128.4,140.8,128.3,141.1,128.3z M140.5,129.2
			c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
			c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C141.6,129.5,141.1,129.2,140.5,129.2z"/>
		<path d="M146.3,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
			s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C147.1,131.2,146.4,131.2,146.3,131.2z M146.4,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4
			c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
			s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C146.4,130.6,146.4,130.7,146.4,130.7z"/>
		<path d="M154.3,128.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1
			c0-0.6,0.2-1,0.7-1.4C153.2,128.5,153.7,128.3,154.3,128.3z"/>
		<path d="M158.7,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C158.2,133.3,158.4,133.2,158.7,133.2z"/>
		<path d="M169.6,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C168.9,128.5,169.3,128.3,169.6,128.3z"/>
		<path d="M174.6,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
			s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
			c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
			c0.3-0.3,0.8-0.6,1.3-0.9C173.6,128.4,174.1,128.3,174.6,128.3z M173.8,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S173.5,134.4,173.8,134.4z"/>
		<path d="M180.4,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H180.4z M179.1,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
			c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
			S179.3,126.6,179.1,126.4z"/>
		<path d="M186.8,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C186.1,128.4,186.5,128.3,186.8,128.3
			z"/>
		<path d="M191.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6
			c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C191.3,133.3,191.5,133.2,191.8,133.2z"/>
		<path d="M111.2,144.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0
			c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
			c0.2-0.4,0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
			c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0c0.3,0,0.6,0,0.8,0c0,0,0.6,0,1.6-0.1
			c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
			c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3
			s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0
			c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4
			c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S111,144.5,111.2,144.5z"/>
		<path d="M122.4,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C123.2,147.2,122.6,147.2,122.4,147.2z M122.5,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C122.5,146.6,122.5,146.7,122.5,146.7z"/>
		<path d="M131.7,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1
			h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8
			C131,144.4,131.4,144.3,131.7,144.3z M131.2,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
			c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
			C132,145,131.7,144.9,131.2,144.9z"/>
		<path d="M139.8,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1
			H141c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8
			C139,144.4,139.4,144.3,139.8,144.3z M139.3,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
			c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
			C140.1,145,139.7,144.9,139.3,144.9z"/>
		<path d="M146.3,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H146.3z M145.1,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
			c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
			S145.2,142.6,145.1,142.4z"/>
		<path d="M152.8,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C152,144.4,152.4,144.3,152.8,144.3z"
			/>
		<path d="M160.7,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H162c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L160.7,150.1L160.7,150.1z M160,151.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C160.7,151.2,160.4,151.2,160,151.2z
			 M159.4,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C160.2,144.9,159.8,144.7,159.4,144.7z"/>
		<path d="M172.3,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1
			h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8
			C171.6,144.4,172,144.3,172.3,144.3z M171.8,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
			c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
			C172.6,145,172.2,144.9,171.8,144.9z"/>
		<path d="M180.8,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C180.1,144.5,180.5,144.3,180.8,144.3z"/>
		<path d="M183.7,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
			s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C184.5,147.2,183.8,147.2,183.7,147.2z M183.8,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4
			c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
			s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C183.8,146.6,183.8,146.7,183.8,146.7z"/>
		<path d="M191.7,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1
			c0-0.6,0.2-1,0.7-1.4C190.6,144.5,191.1,144.3,191.7,144.3z"/>
		<path d="M197.5,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1
			c0-0.6,0.2-1,0.7-1.4C196.4,144.5,196.9,144.3,197.5,144.3z"/>
	</g>
    
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M244.7,66.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
          c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
          c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
          c0.3-0.3,0.8-0.6,1.3-0.9C243.7,66.3,244.2,66.1,244.7,66.1z M243.9,72.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S243.6,72.2,243.9,72.2z"/>
        <path d="M252.7,66.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C252,66.2,252.4,66.1,252.7,66.1z"/>
        <path d="M258.5,66.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9V71c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4V72
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          s-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L258.5,66.2
          L258.5,66.2z"/>
        <path d="M263.9,66.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C262.8,66.3,263.3,66.1,263.9,66.1z"/>
        <path d="M268.3,71c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C267.8,71.1,268,71,268.3,71z"/>
        <path d="M230.8,82.1c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9
          c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2
          c-0.3,0.1-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C230.2,82.2,230.5,82.1,230.8,82.1z"/>
        <path d="M241.9,82.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
          c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
          c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
          c0.3-0.3,0.8-0.6,1.3-0.9C240.9,82.3,241.4,82.1,241.9,82.1z M241.1,88.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S240.8,88.2,241.1,88.2z"/>
        <path d="M249.6,82.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C248.9,82.3,249.3,82.1,249.6,82.1z"/>
        <path d="M255.6,82.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
          c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0
          l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C254.9,82.3,255.3,82.1,255.6,82.1z"/>
        <path d="M259.7,83.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          v0.6H259.7z M258.4,80.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S258.6,80.4,258.4,80.2z"/>
        <path d="M264.8,82.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
          c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C263.8,82.3,264.3,82.1,264.8,82.1z M264,88.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S263.7,88.2,264,88.2z"/>
        <path d="M272.5,87.9c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L272.5,87.9L272.5,87.9z M271.8,89c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C272.4,89,272.1,89,271.8,89z M271.2,82.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C272,82.7,271.6,82.5,271.2,82.5z"/>
        <path d="M276.8,85.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C277.6,85,276.9,85.1,276.8,85.1z M276.9,84.5h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C276.9,84.4,276.9,84.5,276.9,84.5z"/>
        <path d="M283.5,87c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C283,87.1,283.2,87,283.5,87z"/>
        <path d="M247.5,98.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C246.8,98.3,247.2,98.1,247.5,98.1z"/>
        <path d="M252.5,98.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
          c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7
          c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2
          c0.3-0.3,0.8-0.6,1.3-0.9C251.5,98.3,252,98.1,252.5,98.1z M251.6,104.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S251.4,104.2,251.6,104.2z"/>
        <path d="M258.3,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          v0.6H258.3z M257,96.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S257.2,96.4,257,96.2z"/>
        <path d="M264.7,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C264,98.2,264.4,98.1,264.7,98.1z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M336.1,319.2c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0
          c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
          c0.2-0.4,0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4
          s-0.2-0.3-0.3-0.5c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0c0.3,0,0.6,0,0.8,0c0,0,0.6,0,1.6-0.1
          c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2s0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
          s0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2
          c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0
          c0.1,0,0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1s-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
          c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1L340,321l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1
          c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0
          s0.4,0,0.5,0S336,319.2,336.1,319.2z"/>
        <path d="M347.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C348.1,322,347.5,322,347.3,322z M347.4,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C347.4,321.4,347.4,321.4,347.4,321.4z"/>
        <path d="M356.7,319c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0s0,0.1,0,0.2v0.1
          c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S356.3,319,356.7,319z
          M356.2,319.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
          s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C357,319.8,356.6,319.7,356.2,319.7z"/>
        <path d="M364.7,319c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0s0,0.1,0,0.2v0.1
          c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S364.3,319,364.7,319z
          M364.2,319.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
          s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C365,319.8,364.6,319.7,364.2,319.7z"/>
        <path d="M371.3,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          v0.6H371.3z M370,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S370.1,317.3,370,317.2z"/>
        <path d="M377.7,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
          C377,319.2,377.3,319,377.7,319z"/>
        <path d="M385.7,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H387c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M384.9,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C385.6,326,385.3,325.9,384.9,325.9z M384.3,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S384.8,319.5,384.3,319.5z"/>
        <path d="M397.2,319c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0s0,0.1,0,0.2v0.1
          c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S396.9,319,397.2,319z
          M396.7,319.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
          s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C397.6,319.8,397.2,319.7,396.7,319.7z"/>
        <path d="M405.7,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C405,319.2,405.4,319,405.7,319z"/>
        <path d="M408.6,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C409.4,322,408.8,322,408.6,322z M408.7,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          c-0.1,0.2-0.1,0.3-0.2,0.5C408.7,321.4,408.7,321.4,408.7,321.4z"/>
        <path d="M416.7,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
          c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
          s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
          c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S416,319,416.7,319z"/>
        <path d="M422.4,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
          s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
          c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
          s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
          c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S421.8,319,422.4,319z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M96.2,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M95.5,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S95.9,325.9,95.5,325.9z M94.9,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S95.4,319.5,94.9,319.5
          z"/>
        <path d="M103.6,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S103.2,319,103.6,319z"/>
        <path d="M108.6,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S108.1,319.1,108.6,319.1z M107.7,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S107.4,325.2,107.7,325.2z"/>
        <path d="M116.1,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S115.8,319,116.1,319z M115.4,320
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S116,320,115.4,320z"/>
        <path d="M121.2,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S121.4,322,121.2,322z
          M121.3,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S121.3,321.4,121.3,321.4z"/>
        <path d="M129.3,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          C128.1,319.2,128.7,319,129.3,319z"/>
        <path d="M133.6,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S133.4,324,133.6,324z"/>
        <path d="M144.6,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S144.2,319,144.6,319z"/>
        <path d="M149.6,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S149.1,319.1,149.6,319.1z M148.7,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S148.4,325.2,148.7,325.2z"/>
        <path d="M155.4,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H155.4z M154.1,317.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S154.3,317.3,154.1,317.2z"/>
        <path d="M161.8,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S161.5,319,161.8,319z"/>
        <path d="M166.7,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S166.5,324,166.7,324z"/>
        <path d="M86.2,335.2c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6
          s0.1-0.4,0.2-0.5s0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          s-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2s-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3
          c0,0-0.1,0.1-0.1,0.2s-0.2,0.1-0.2,0.1L88,342c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S86,335.2,86.2,335.2z"/>
        <path d="M97.4,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S97.5,338,97.4,338z
          M97.4,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S97.4,337.4,97.4,337.4z"/>
        <path d="M106.7,335c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S106.3,335,106.7,335z M106.2,335.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S106.6,335.7,106.2,335.7z"/>
        <path d="M114.7,335c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S114.4,335,114.7,335z M114.2,335.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S114.7,335.7,114.2,335.7z"/>
        <path d="M121.3,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L121.3,336.8L121.3,336.8z
          M120,333.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S120.2,333.3,120,333.2z"/>
        <path d="M127.7,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0c-0.2,0-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S127.4,335,127.7,335z"/>
        <path d="M135.7,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1H137c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V340.8z M135,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S135.3,341.9,135,341.9z M134.3,335.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S134.8,335.5,134.3,335.5z"/>
        <path d="M147.3,335c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S146.9,335,147.3,335z M146.8,335.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S147.2,335.7,146.8,335.7z"/>
        <path d="M155.7,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S155.4,335,155.7,335z"/>
        <path d="M158.6,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S158.8,338,158.6,338z
          M158.7,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S158.7,337.4,158.7,337.4z"/>
        <path d="M166.7,335c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S166.1,335,166.7,335z"/>
        <path d="M172.4,335c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S171.8,335,172.4,335z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M276.2,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C277,411.3,276.4,411.3,276.2,411.3z M276.3,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C276.3,410.7,276.3,410.7,276.3,410.7z"/>
        <path d="M285,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
          c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C283.9,408.5,284.5,408.4,285,408.4z M284.1,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S283.8,414.5,284.1,414.5z"/>
        <path d="M292.7,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
          c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0
          l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C292,408.6,292.4,408.4,292.7,408.4z"/>
        <path d="M296.4,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          s-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3
          h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L296.4,408.4L296.4,408.4z"/>
        <path d="M303.6,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7C302.9,408.5,303.2,408.4,303.6,408.4z"/>
        <path d="M311.3,408.4c0.2,0,0.5,0.1,0.9,0.2c0.5,0.1,0.8,0.2,0.9,0.2s0.2,0,0.3,0c0,0,0.1,0,0.1,0.1s0.1,0.2,0.1,0.2
          c-0.1,0.3-0.2,0.7-0.2,1.2v6.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.3c0-0.1,0-0.1,0-0.1s0,0-0.1,0c-0.2,0.2-0.5,0.4-0.9,0.6
          s-0.8,0.3-1.1,0.3c-0.3,0-0.7-0.1-1-0.2s-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.6-0.8s-0.3-0.6-0.4-0.9s-0.1-0.6-0.1-0.9
          c0-0.4,0.1-0.8,0.2-1.2c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.7-0.4,1-0.5C310.6,408.4,311,408.4,311.3,408.4z
          M310.8,408.9c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2,0.3-0.3,0.6-0.4,0.8c-0.1,0.3-0.1,0.6-0.1,0.8
          c0,0.9,0.2,1.6,0.6,2.1s1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.4-0.3,0.4-0.4V410c0-0.3-0.1-0.6-0.4-0.8
          C311.5,409,311.2,408.9,310.8,408.9z"/>
        <path d="M320.1,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.2-0.4-0.3-0.7
          c-0.1-0.3-0.1-0.6-0.2-0.8c0-0.3,0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.5-0.6v-3.5H320.1z"/>
        <path d="M326.4,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1
          s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C325.4,408.5,325.9,408.4,326.4,408.4z M325.6,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S325.3,414.5,325.6,414.5z"/>
        <path d="M332,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
          c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2
          c0-0.1,0.1-0.1,0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2
          c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3
          c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2c0.5,0.5,0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3
          c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5
          c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H332z"/>
        <path d="M338.5,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C339.3,411.3,338.7,411.3,338.5,411.3z M338.6,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C338.6,410.7,338.6,410.7,338.6,410.7z"/>
        <path d="M345.2,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C344.7,413.4,344.9,413.3,345.2,413.3z"/>
        <path d="M290.7,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          v0.6H290.7z M289.4,422.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S289.6,422.6,289.4,422.5z"/>
        <path d="M297.1,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C296.4,424.5,296.8,424.4,297.1,424.4z"/>
        <path d="M303.4,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C302.3,424.5,302.8,424.4,303.4,424.4z"/>
        <path d="M307.9,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C308.7,427.3,308,427.3,307.9,427.3z M308,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C308,426.7,308,426.7,308,426.7z"/>
        <path d="M318.9,425.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1
          c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          C318.8,425,318.9,425.1,318.9,425.2z"/>
        <path d="M322,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H322v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          s-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V425h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3
          h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L322,424.4L322,424.4z"/>
        <path d="M327.4,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C326.3,424.5,326.8,424.4,327.4,424.4z"/>
        <path d="M331.8,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C331.3,429.4,331.5,429.3,331.8,429.3z"/>
        <path d="M285.7,440.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C284.6,440.5,285.1,440.4,285.7,440.4z"/>
        <path d="M292.4,440.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C290.7,440.7,291.5,440.3,292.4,440.3z M292.2,440.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2C293.1,441,292.7,440.9,292.2,440.9z"/>
        <path d="M301.4,440.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9
          c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2
          c-0.3,0.1-0.4,0.3-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C300.8,440.4,301.1,440.4,301.4,440.4z"/>
        <path d="M310.5,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C311.3,443.3,310.6,443.3,310.5,443.3z M310.6,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C310.6,442.7,310.5,442.7,310.6,442.7z"/>
        <path d="M319.4,440.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C317.7,440.7,318.5,440.3,319.4,440.3z M319.3,440.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2C320.1,441,319.7,440.9,319.3,440.9z"/>
        <path d="M328.5,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
          c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7C327.7,440.5,328.1,440.4,328.5,440.4z"/>
        <path d="M333.5,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
          c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
          s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C334.2,443.3,333.6,443.3,333.5,443.3z M333.5,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4
          c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
          s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C333.5,442.7,333.5,442.7,333.5,442.7z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path d="M138.6,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C137.4,408.5,138,408.4,138.6,408.4z"/>
        <path d="M144.9,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C143.2,408.7,144,408.3,144.9,408.3z M144.8,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S145.2,408.9,144.8,408.9z"/>
        <path d="M153.6,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C153,408.4,153.3,408.4,153.6,408.4z"/>
        <path d="M162.4,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C163.1,411.3,162.5,411.3,162.4,411.3z M162.4,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S162.4,410.7,162.4,410.7z"/>
        <path d="M171,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C169.3,408.7,170,408.3,171,408.3z M170.8,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S171.3,408.9,170.8,408.9z"/>
        <path d="M179.7,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S179.4,408.4,179.7,408.4z"/>
        <path d="M184.4,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C185.2,411.3,184.5,411.3,184.4,411.3z M184.5,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S184.4,410.7,184.5,410.7z"/>
        <path d="M195,408.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
          c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
          l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
          c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S194.8,408.6,195,408.6z"/>
        <path d="M209.1,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S208.7,408.4,209.1,408.4z"/>
        <path d="M216,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C214.2,408.7,215,408.3,216,408.3z M215.8,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S216.2,408.9,215.8,408.9z"/>
        <path d="M228.5,408.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C227.9,408.5,228.3,408.4,228.5,408.4z M227.9,409.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C229,409.5,228.5,409.3,227.9,409.3z"/>
        <path d="M235.4,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C234.4,408.5,234.9,408.4,235.4,408.4z M234.6,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S234.3,414.5,234.6,414.5z"/>
        <path d="M241.1,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C240,408.5,240.5,408.4,241.1,408.4z"/>
        <path d="M246.6,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C245.4,408.5,246,408.4,246.6,408.4z"/>
        <path d="M250.7,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C251.5,411.3,250.8,411.3,250.7,411.3z M250.8,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S250.8,410.7,250.8,410.7z"/>
        <path d="M259.7,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C258.9,408.4,259.3,408.4,259.7,408.4z M259.2,409
          c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
          c0-0.2-0.2-0.4-0.5-0.5S259.6,409,259.2,409z"/>
        <path d="M137.9,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C136.8,424.5,137.4,424.4,137.9,424.4z M137,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S136.7,430.5,137,430.5z"/>
        <path d="M141.9,424.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
          c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
          l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
          c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S141.7,424.6,141.9,424.6z"/>
        <path d="M154.9,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C153.9,424.5,154.4,424.4,154.9,424.4z M154.1,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S153.8,430.5,154.1,430.5z"/>
        <path d="M159,424.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2
          s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8
          c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
          c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          C157.9,424.5,158.4,424.6,159,424.6z"/>
        <path d="M165.5,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S165.3,429.3,165.5,429.3z"/>
        <path d="M174.4,431.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C175.4,431.2,174.9,431.3,174.4,431.3z M174.6,425.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
          v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C175.9,425.5,175.3,425.3,174.6,425.3z"/>
        <path d="M180.3,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C181,427.3,180.4,427.3,180.3,427.3z M180.3,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S180.3,426.7,180.3,426.7z"/>
        <path d="M187.8,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H187.8z M186.5,422.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S186.6,422.6,186.5,422.5z"/>
        <path d="M193.9,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S193.5,424.4,193.9,424.4z"/>
        <path d="M201.5,430.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.2z M200.8,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C201.5,431.3,201.1,431.3,200.8,431.3z M200.2,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C201,425,200.6,424.8,200.2,424.8z"/>
        <path d="M212.7,424.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C212.1,424.5,212.4,424.4,212.7,424.4z M212,425.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
          c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C213.1,425.5,212.6,425.3,212,425.3z"/>
        <path d="M220.5,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C219.9,424.6,220.2,424.4,220.5,424.4z"/>
        <path d="M223.1,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C223.9,427.3,223.3,427.3,223.1,427.3z M223.2,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S223.2,426.7,223.2,426.7z"/>
        <path d="M232.5,430.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.2z M231.8,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C232.4,431.3,232.1,431.3,231.8,431.3z M231.1,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C232,425,231.6,424.8,231.1,424.8z"/>
        <path d="M239.8,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S239.5,424.4,239.8,424.4z"/>
        <path d="M246.5,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C245.5,424.5,246,424.4,246.5,424.4z M245.7,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S245.4,430.5,245.7,430.5z"/>
        <path d="M254.2,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.1,0.6,0.2,0.9
          c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
          s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9
          v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S253.9,424.4,254.2,424.4z"/>
        <path d="M259.6,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V425h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L259.6,424.4L259.6,424.4z"/>
        <path d="M263.3,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S263.1,429.3,263.3,429.3z"/>
        <path d="M155.7,441.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C155.7,441,155.7,441.1,155.7,441.2z"/>
        <path d="M157.4,438.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L157.4,438.5L157.4,438.5z"/>
        <path d="M161.4,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C162.2,443.3,161.6,443.3,161.4,443.3z M161.5,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S161.5,442.7,161.5,442.7z"/>
        <path d="M169.9,440.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C168.8,440.5,169.4,440.4,169.9,440.4z M169,446.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S168.7,446.5,169,446.5z"/>
        <path d="M177.6,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S177.2,440.4,177.6,440.4z"/>
        <path d="M186.5,440.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
          c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
          l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
          c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S186.3,440.6,186.5,440.6z"/>
        <path d="M199.5,440.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C198.4,440.5,199,440.4,199.5,440.4z M198.6,446.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S198.3,446.5,198.6,446.5z"/>
        <path d="M204.6,440.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V441h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H204.6z"/>
        <path d="M208.4,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C209.2,443.3,208.5,443.3,208.4,443.3z M208.5,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S208.5,442.7,208.5,442.7z"/>
        <path d="M217.8,440.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C217.1,440.6,217.5,440.4,217.8,440.4z"/>
        <path d="M220.2,445.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S219.9,445.3,220.2,445.3z"/>
        <path d="M227.4,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C228.2,443.3,227.6,443.3,227.4,443.3z M227.5,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S227.5,442.7,227.5,442.7z"/>
        <path d="M236.8,446.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V446.2z M236.1,447.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C236.8,447.3,236.4,447.3,236.1,447.3z M235.5,440.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C236.3,441,235.9,440.8,235.5,440.8z"/>
        <path d="M243.8,446.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V446.2z M243.1,447.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C243.7,447.3,243.4,447.3,243.1,447.3z M242.4,440.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C243.2,441,242.9,440.8,242.4,440.8z"/>
        <path d="M247.7,445.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S247.4,445.3,247.7,445.3z"/>
        <path d="M165.3,454.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L165.3,454.5L165.3,454.5z"/>
        <path d="M170.4,458.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H170.4z M169.1,454.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S169.3,454.6,169.1,454.5z"/>
        <path d="M177.5,457.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C177.4,457,177.5,457.1,177.5,457.2z"/>
        <path d="M179.5,459.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C180.3,459.3,179.6,459.3,179.5,459.3z M179.6,458.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S179.6,458.7,179.6,458.7z"/>
        <path d="M185.8,461.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S185.6,461.3,185.8,461.3z"/>
        <path d="M196.4,456.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C195.8,456.4,196.1,456.4,196.4,456.4z"/>
        <path d="M207.4,456.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C205.6,456.7,206.4,456.3,207.4,456.3z M207.2,456.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S207.6,456.9,207.2,456.9z"/>
        <path d="M216.1,456.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S215.7,456.4,216.1,456.4z"/>
        <path d="M220.7,459.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C221.5,459.3,220.9,459.3,220.7,459.3z M220.8,458.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S220.8,458.7,220.8,458.7z"/>
        <path d="M227,456.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2
          s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8
          c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
          c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          C225.9,456.5,226.4,456.6,227,456.6z"/>
        <path d="M233.5,461.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S233.3,461.3,233.5,461.3z"/>
        <path d="M155.1,472.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C153.9,472.5,154.5,472.4,155.1,472.4z"/>
        <path d="M161.4,472.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C159.7,472.7,160.5,472.3,161.4,472.3z M161.3,472.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S161.7,472.9,161.3,472.9z"/>
        <path d="M170.1,472.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C169.5,472.4,169.8,472.4,170.1,472.4z"/>
        <path d="M178.9,475.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C179.6,475.3,179,475.3,178.9,475.3z M178.9,474.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S178.9,474.7,178.9,474.7z"/>
        <path d="M187.5,472.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C185.8,472.7,186.5,472.3,187.5,472.3z M187.3,472.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S187.8,472.9,187.3,472.9z"/>
        <path d="M196.2,472.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S195.9,472.4,196.2,472.4z"/>
        <path d="M200.9,475.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C201.7,475.3,201,475.3,200.9,475.3z M201,474.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S201,474.7,201,474.7z"/>
        <path d="M207.2,477.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S206.9,477.3,207.2,477.3z"/>
        <path d="M214.3,472.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6
          c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
          l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
          c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S214.1,472.6,214.3,472.6z"/>
        <path d="M227.3,472.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C226.3,472.5,226.8,472.4,227.3,472.4z M226.5,478.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S226.2,478.5,226.5,478.5z"/>
        <path d="M232.5,472.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V473h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H232.5z"/>
        <path d="M236.3,475.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C237,475.3,236.4,475.3,236.3,475.3z M236.3,474.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S236.3,474.7,236.3,474.7z"/>
        <path d="M245.7,472.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C245,472.6,245.3,472.4,245.7,472.4z"/>
      </g>
      <g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
		<path d="M293,96.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C292,96.4,292.5,96.3,293,96.3z
			 M292.2,102.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
			s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S291.9,102.4,292.2,102.4z"/>
		<path d="M301,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S300.7,96.3,301,96.3z"/>
		<path d="M306.8,96.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V97h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L306.8,96.4L306.8,96.4z"/>
		<path d="M312.2,96.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C311.1,96.5,311.6,96.3,312.2,96.3z"/>
		<path d="M316.6,101.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S316.3,101.2,316.6,101.2z"/>
		<path d="M326.1,103.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
			C327.1,103.1,326.6,103.2,326.1,103.2z M326.3,97.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
			v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C327.6,97.5,327,97.2,326.3,97.2z"/>
		<path d="M332.3,99.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C333.1,99.2,332.4,99.2,332.3,99.2z M332.4,98.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S332.4,98.7,332.4,98.7z"/>
		<path d="M340.1,98v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V98H340.1z M338.8,94.4
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S339,94.6,338.8,94.4z"/>
		<path d="M346.5,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S346.2,96.3,346.5,96.3z"/>
		<path d="M354.5,102.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L354.5,102.1L354.5,102.1z M353.8,103.2c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C354.4,103.2,354.1,103.2,353.8,103.2z M353.2,96.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C354,96.9,353.6,96.7,353.2,96.7z"/>
		<path d="M366.3,96.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6
			C365.7,96.4,366,96.3,366.3,96.3z M365.6,97.2c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
			c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C366.8,97.5,366.3,97.2,365.6,97.2z"/>
		<path d="M374.5,96.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
			c0.2-0.4,0.4-0.8,0.8-1.1C373.8,96.5,374.2,96.3,374.5,96.3z"/>
		<path d="M377.4,99.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C378.2,99.2,377.5,99.2,377.4,99.2z M377.5,98.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S377.5,98.7,377.5,98.7z"/>
		<path d="M387.1,102.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L387.1,102.1L387.1,102.1z M386.4,103.2c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C387,103.2,386.7,103.2,386.4,103.2z M385.7,96.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C386.5,96.9,386.2,96.7,385.7,96.7z"/>
		<path d="M394.7,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S394.4,96.3,394.7,96.3z"/>
		<path d="M401.7,96.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C400.7,96.4,401.2,96.3,401.7,96.3z M400.9,102.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S400.6,102.4,400.9,102.4z"/>
		<path d="M409.7,96.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S409.4,96.3,409.7,96.3z"/>
		<path d="M415.5,96.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V97h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L415.5,96.4L415.5,96.4z"/>
		<path d="M419.5,101.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S419.3,101.2,419.5,101.2z"/>
		<path d="M304.7,118.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H306c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L304.7,118.1L304.7,118.1z M304,119.2c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C304.7,119.2,304.4,119.2,304,119.2z M303.4,112.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C304.2,112.9,303.8,112.7,303.4,112.7z"/>
		<path d="M312.1,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S311.8,112.3,312.1,112.3z"/>
		<path d="M319.3,112.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C317.6,112.6,318.4,112.3,319.3,112.3z M319.2,112.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S319.6,112.8,319.2,112.8z"/>
		<path d="M326.4,112.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C325.2,112.5,325.8,112.3,326.4,112.3z"/>
		<path d="M331.6,112.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V113h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L331.6,112.4L331.6,112.4z"/>
		<path d="M337,112.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C335.9,112.5,336.4,112.3,337,112.3z"/>
		<path d="M341.4,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			S340,121,340,121c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S341.1,117.2,341.4,117.2z"/>
		<path d="M348.9,110.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V110.4z"/>
		<path d="M354.4,114v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H354.4z M353.1,110.4
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S353.2,110.6,353.1,110.4z"/>
		<path d="M361.7,113.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
			c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
			s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C361.7,112.9,361.7,113,361.7,113.1z"/>
		<path d="M364.1,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C364.9,115.2,364.2,115.2,364.1,115.2z M364.2,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S364.1,114.7,364.2,114.7z"/>
		<path d="M370.7,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S370.5,117.2,370.7,117.2z"/>
		<path d="M378.3,110.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V110.4z"/>
		<path d="M383.7,114v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H383.7z M382.4,110.4
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S382.6,110.6,382.4,110.4z"/>
		<path d="M389.1,112.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C387.3,112.6,388.1,112.3,389.1,112.3z M388.9,112.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S389.3,112.8,388.9,112.8z"/>
		<path d="M398.1,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S397.8,112.3,398.1,112.3z"/>
		<path d="M404.4,112.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C403.3,112.5,403.8,112.3,404.4,112.3z"/>
		<path d="M408.8,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S408.5,117.2,408.8,117.2z"/>
		<path d="M290.6,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L290.6,128.4L290.6,128.4z"/>
		<path d="M297.8,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S297.5,128.3,297.8,128.3z"/>
		<path d="M302.8,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C303.6,131.2,302.9,131.2,302.8,131.2z M302.9,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S302.9,130.7,302.9,130.7z"/>
		<path d="M314.2,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C315,131.2,314.3,131.2,314.2,131.2z M314.3,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S314.3,130.7,314.3,130.7z"/>
		<path d="M324.2,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S323.9,128.3,324.2,128.3z"/>
		<path d="M331.8,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1L335,135c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H333
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C331.1,128.4,331.5,128.3,331.8,128.3z
			 M331.3,128.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4
			v-4c0-0.2-0.2-0.4-0.5-0.5S331.7,128.9,331.3,128.9z"/>
		<path d="M344.2,128.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C342.5,128.6,343.2,128.3,344.2,128.3z M344,128.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S344.5,128.8,344,128.8z"/>
		<path d="M352.4,124.2c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
			c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
			s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-1,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.8
			C351.9,124.3,352.1,124.2,352.4,124.2z"/>
		<path d="M360.2,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129H358c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L360.2,128.4L360.2,128.4z"/>
		<path d="M367.3,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S367,128.3,367.3,128.3z"/>
		<path d="M372.3,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C373.1,131.2,372.5,131.2,372.3,131.2z M372.4,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S372.4,130.7,372.4,130.7z"/>
		<path d="M383.6,128.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0
			c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
			s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
			c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2
			s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
			c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3
			s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0
			c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4
			c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S383.4,128.5,383.6,128.5z"/>
		<path d="M397,128.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C395.3,128.6,396.1,128.3,397,128.3z M396.8,128.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S397.3,128.8,396.8,128.8z"/>
		<path d="M405.7,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
			l0,0c0.2-0.4,0.4-0.8,0.8-1.1C405.1,128.5,405.4,128.3,405.7,128.3z"/>
		<path d="M408.4,126.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V126.4z"/>
		<path d="M415.3,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C414.6,128.4,414.9,128.3,415.3,128.3z
			 M414.8,128.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4
			v-4c0-0.2-0.2-0.4-0.5-0.5S415.2,128.9,414.8,128.9z"/>
		<path d="M420.7,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S420.4,133.2,420.7,133.2z"/>
		<path d="M311.2,144.5c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0
			c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
			s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
			c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2
			s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7
			c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3
			s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2s0.3,0,0.6,0s0.5,0,0.8,0
			c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4
			c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S311,144.5,311.2,144.5z"/>
		<path d="M324.5,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C323.5,144.4,324,144.3,324.5,144.3z M323.7,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S323.4,150.4,323.7,150.4z"/>
		<path d="M330,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H330v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L330,144.4L330,144.4z"/>
		<path d="M334.1,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C334.9,147.2,334.2,147.2,334.1,147.2z M334.2,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S334.2,146.7,334.2,146.7z"/>
		<path d="M343.8,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
			l0,0c0.2-0.4,0.4-0.8,0.8-1.1C343.2,144.5,343.5,144.3,343.8,144.3z"/>
		<path d="M346.5,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S346.3,149.2,346.5,149.2z"/>
		<path d="M354.3,144.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C353.2,144.5,353.7,144.5,354.3,144.5z"/>
		<path d="M363.8,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C362.1,144.6,362.9,144.3,363.8,144.3z M363.6,144.8c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S364.1,144.8,363.6,144.8z"/>
		<path d="M373.2,146.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
			c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
			c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7
			c-0.1-0.3-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
			s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H373.2z"/>
		<path d="M380.6,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
			l0,0c0.2-0.4,0.4-0.8,0.8-1.1C379.9,144.5,380.2,144.3,380.6,144.3z"/>
		<path d="M388.2,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C389,147.2,388.4,147.2,388.2,147.2z M388.3,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S388.3,146.7,388.3,146.7z"/>
		<path d="M395.1,144.5c0.5,0,1,0,1.6-0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.4,0-0.6,0.1-0.6,0.3c0,0,0,0.1,0.1,0.2l1,1.6
			c0.1-0.1,0.2-0.4,0.4-0.6s0.4-0.5,0.5-0.7s0.2-0.3,0.2-0.4c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0
			c-0.1,0-0.2,0-0.2,0s0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0s0.3,0,0.5,0c0.5,0,0.9,0,1.2-0.1
			c0,0,0,0.1,0,0.3c0,0.1,0,0.2,0,0.2c-0.7,0-1.1,0.2-1.2,0.3c-0.1,0.2-0.3,0.5-0.6,0.8c-0.3,0.4-0.5,0.7-0.7,0.9s-0.3,0.4-0.3,0.4
			l1.9,2.8c0.3,0.4,0.7,0.6,1.2,0.6c0,0,0,0,0,0.1s0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.5,0s-0.3,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.6,0s-0.4,0-0.6,0c0-0.1,0-0.1,0-0.3s0-0.2,0-0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.2v-0.1
			c-0.5-0.9-1-1.5-1.2-2c-0.1,0.1-0.2,0.3-0.5,0.7s-0.5,0.7-0.6,1c-0.1,0.1-0.1,0.2-0.1,0.3s0,0.1,0.1,0.2c0,0,0.1,0.1,0.2,0.1
			s0.2,0,0.2,0s0.1,0,0.3,0h0.1c0,0,0,0,0,0.1s0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0
			s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2s0-0.2,0-0.2s0.1,0,0.2,0s0.2,0,0.2,0s0.1,0,0.2,0s0.2,0,0.2-0.1
			c0,0,0.1,0,0.2-0.1s0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2c1-1.4,1.5-2.1,1.5-2.2l-1.8-2.7
			c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2C394.2,144.5,394.7,144.5,395.1,144.5z"
			/>
	</g>
    
    
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M110.8,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S110.8,319.8,110.8,319.8z"/>
        <path d="M115.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S114.7,319.1,115.2,319.1z M114.3,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S114.1,325.2,114.3,325.2z"/>
        <path d="M120.7,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H120.7z"/>
        <path d="M124.7,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S124.4,324,124.7,324z"/>
        <path d="M135.4,319.3c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V320h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8
          L135.4,319.3L135.4,319.3z"/>
        <path d="M143.9,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S143.6,319,143.9,319z"/>
        <path d="M146.8,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S146.9,322,146.8,322z
          M146.9,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S146.9,321.4,146.9,321.4z"/>
        <path d="M153.5,324c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S153.2,324,153.5,324z"/>
        <path d="M122,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S121.7,335,122,335z"/>
        <path d="M127,335.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
          c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2
          s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S126.5,335.1,127,335.1z M126.2,341.2
          c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7
          c0,0.3,0.1,0.5,0.3,0.8S125.9,341.2,126.2,341.2z"/>
        <path d="M132.8,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H132.8z M131.5,333.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S131.7,333.3,131.5,333.2z"/>
        <path d="M139.2,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S138.9,335,139.2,335z"/>
      </g>
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M307.4,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C307.4,409,307.4,409.1,307.4,409.2z"/>
        <path d="M311.8,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C310.8,408.5,311.3,408.4,311.8,408.4z M311,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S310.7,414.5,311,414.5z"/>
        <path d="M317.3,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L317.3,408.4L317.3,408.4z"/>
      </g>
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M193.5,408.7c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
          c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2
          c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
          c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7V408.7z"/>
        <path d="M202,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C201.4,408.6,201.7,408.4,202,408.4z"/>
        <path d="M205,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C205.7,411.3,205.1,411.3,205,411.3z M205,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S205,410.7,205,410.7z"/>
      </g>
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M346.1,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C345,144.4,345.6,144.3,346.1,144.3z M345.2,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S344.9,150.4,345.2,150.4z"/>
        <path d="M354.1,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S353.7,144.3,354.1,144.3z"/>
        <path d="M359.9,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L359.9,144.4L359.9,144.4z"/>
        <path d="M365.3,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          C364.1,144.5,364.7,144.3,365.3,144.3z"/>
      </g>
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
		<path d="M112.4,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C111.3,128.4,111.9,128.3,112.4,128.3z M111.5,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S111.2,134.4,111.5,134.4z"/>
		<path d="M120.4,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S120,128.3,120.4,128.3z"/>
		<path d="M126.1,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129H124c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0C126.1,126.3,126.1,128.4,126.1,128.4z"
			/>
		<path d="M131.6,128.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C130.4,128.5,131,128.3,131.6,128.3z"/>
		<path d="M135.9,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S135.7,133.2,135.9,133.2z"/>
		<path d="M147.1,128.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
			c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
			c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
			s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C146.5,128.3,146.8,128.3,147.1,128.3z"/>
		<path d="M158.2,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C157.2,128.4,157.7,128.3,158.2,128.3z M157.4,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S157.1,134.4,157.4,134.4z"/>
		<path d="M165.9,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C165.3,128.5,165.6,128.3,165.9,128.3z"/>
		<path d="M171.9,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C171.2,128.5,171.6,128.3,171.9,128.3z"/>
		<path d="M176,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H176z
			 M174.7,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S174.9,126.6,174.7,126.4z"/>
		<path d="M181.1,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C180.1,128.4,180.6,128.3,181.1,128.3z M180.3,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S180,134.4,180.3,134.4z"/>
		<path d="M188.8,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L188.8,134.1L188.8,134.1z M188.1,135.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C188.8,135.2,188.4,135.2,188.1,135.2z
			 M187.5,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C188.3,128.9,187.9,128.7,187.5,128.7z"/>
		<path d="M193.1,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1S193.3,131.2,193.1,131.2z M193.2,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S193.2,130.7,193.2,130.7z"/>
		<path d="M199.8,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S199.5,133.2,199.8,133.2z"/>
		<path d="M147,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C146.4,144.5,146.7,144.3,147,144.3z"/>
		<path d="M152,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C151,144.4,151.5,144.3,152,144.3z M151.2,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S150.9,150.4,151.2,150.4z"/>
		<path d="M157.9,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H157.9
			z M156.6,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S156.7,142.6,156.6,142.4z"/>
		<path d="M164.3,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S163.9,144.3,164.3,144.3z"/>
	</g>
    </g>
  );
};

export default NetworkTexts;
