import React from 'react';

const BluePetal90 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_9)' }} d="M.26,24.1C-4,26.47,44.67,47.37,95.47,46.24S188.05,24.35,189.8,20c2.38-7.26-13.89-4.38-13.89-4.38h0c-4.88.5-11.88.88-17.89.88-11.51,0-25-2.38-35.4-4l-2.38-.5c-7.76-.62-16.76-2-25.4-2.25C94.84,9.71,32,4.71.26,24.1Z" />
        <path className="cls-2" d="M21.4,18.59A66.8,66.8,0,0,1,37.92,18a114.58,114.58,0,0,1,16.14,2.62c2.62.63,5.25,1.76,7.75,2.63s5.13,2.25,7.51,3.25,5.13,2.26,7.51,3.13a34.87,34.87,0,0,0,8,1.75,55.89,55.89,0,0,1-8-1.5,61.49,61.49,0,0,1-7.76-3.13c-2.38-1.12-5.13-2.25-7.51-3.25a41.11,41.11,0,0,0-7.75-2.63,77.45,77.45,0,0,0-16.14-2.62,71.78,71.78,0,0,0-16.39.87Z" />
        <path className="cls-2" d="M21.4,18.59A65.32,65.32,0,0,1,37.79,18a114.11,114.11,0,0,1,16.14,2.62c2.63.63,5.26,1.76,7.76,2.63l7.51,3.25,7.5,3.13a35.07,35.07,0,0,0,8,1.75v.25a55.77,55.77,0,0,1-8-1.5A39.28,39.28,0,0,1,69.2,27c-5.13-2.26-9.89-4.63-15.27-6a77.91,77.91,0,0,0-16.14-2.63,71.81,71.81,0,0,0-16.39.88h0Zm0,0v.5h0a65.29,65.29,0,0,1,16.39-.87,86.82,86.82,0,0,1,16.14,2.62c5.26,1.38,10.14,3.76,15.27,6C71.57,28,74.32,29.1,76.7,30a44,44,0,0,0,8,1.5v.25a35.49,35.49,0,0,1-8-1.75l-7.5-3.13L61.69,23.6c-2.63-.88-5.13-2-7.76-2.63a90.35,90.35,0,0,0-16.14-2.63A51.75,51.75,0,0,0,21.4,18.59Z" />
        <path className="cls-2" d="M97.72,19A11.8,11.8,0,0,1,103,20.34c1.76.88,3.13,1.76,4.88,2.63a21.68,21.68,0,0,0,4.88,2.25,38.49,38.49,0,0,0,5.26.88,24.86,24.86,0,0,1-5.26-.63c-1.75-.5-3.25-1.37-5.13-2a29.86,29.86,0,0,0-4.88-2.38A14.63,14.63,0,0,0,97.59,20Z" />
        <path className="cls-2" d="M97.72,19A11.8,11.8,0,0,1,103,20.34L107.85,23a21.68,21.68,0,0,0,4.88,2.25l5.26.88v.25a24.86,24.86,0,0,1-5.26-.63c-1.75-.5-3.25-1.37-5.13-2h0A39.74,39.74,0,0,0,103,21.35a9.86,9.86,0,0,0-5-1.26h0Zm0,0v.87h0A14.47,14.47,0,0,1,102.85,21a35.49,35.49,0,0,1,4.88,2.38h0c1.75.62,3.25,1.5,5.13,2a18.85,18.85,0,0,0,5.25.62v.25l-5.25-.87A25,25,0,0,1,108,23.1l-4.88-2.63A10,10,0,0,0,97.72,19Z" />
        <path className="cls-2" d="M.26,24.6c36.66-13.51,84.45-6,120-2.25,17,1.75,38.28,5.13,57.42,1.37a82.24,82.24,0,0,0,9.26-2.37,12.42,12.42,0,0,0,2.63-1.13c-20.14,8.13-56.8,4.25-74.94.25-22.52-4.88-51.3-6.38-76.07-4.25C25.66,17.47,10.39,20.84.26,24.6" />
        <path className="cls-2" d="M.26,24.6A138,138,0,0,1,23,18.84a165,165,0,0,1,23.4-2c15.63-.5,31.4.88,47,2.38s31.15,3.5,46.79,5.13c15.51,1.37,31.65,1.75,46.66-3.26h0A11.88,11.88,0,0,0,189.55,20l.25.5c-7.51,2.88-15.51,4-23.39,4.38a152.66,152.66,0,0,1-23.9-.5c-8-.63-15.89-1.75-23.65-3.13a210.88,210.88,0,0,0-23.64-4,264,264,0,0,0-47.79-1.5,185.15,185.15,0,0,0-23.65,2.87,120.15,120.15,0,0,0-23.52,6,160.92,160.92,0,0,1,23.15-6.13A183.32,183.32,0,0,1,47.3,15.59a264.9,264.9,0,0,1,47.79,1.5c8,.88,15.89,2.38,23.65,4s15.64,2.38,23.64,3.13a188.83,188.83,0,0,0,23.9.5,86.84,86.84,0,0,0,23.4-4.38l.25.5A11.66,11.66,0,0,1,187.3,22h0c-15,4.88-31.15,4.63-46.67,3.13S109.48,21.6,93.84,20s-31.4-2.88-47-2.38a164.7,164.7,0,0,0-23.39,2A78.84,78.84,0,0,0,.26,24.6Z" />
      </g>
    </g>
  );
};

export default BluePetal90;
