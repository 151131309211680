import React from 'react';
import '../Network.scss';

const NetworkTexts = ({ selected }) => {
  return (
    <g id='subjects' transform="translate(-42,-40)">
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M146.8,144.2c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
			c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H148c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S146.5,144.2,146.8,144.2z M146.3,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
			c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S146.8,144.9,146.3,144.9z'
        />
        <path
          d='M154.5,144.2c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
			s0.3-1.8,1-2.5S153.6,144.2,154.5,144.2z M154.4,144.7c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
			s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
			S154.8,144.7,154.4,144.7z'
        />
        <path
          d='M163.2,150c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1
			c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2L163.2,150L163.2,150z M162.5,151.1
			c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S162.9,151.1,162.5,151.1z M161.9,144.7
			c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S162.3,144.7,161.9,144.7z'
        />
        <path
          d='M168.8,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S168.2,144.2,168.8,144.2z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M322.8,145c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
			c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4
			s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
			c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C322.8,144.9,322.8,144.9,322.8,145z'
        />
        <path
          d='M328.2,144.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
			c0.2-0.4,0.4-0.8,0.8-1.1C327.5,144.4,327.9,144.2,328.2,144.2z'
        />
        <path
          d='M331,144.4c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1
			s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8
			c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
			c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			C330,144.4,330.5,144.4,331,144.4z'
        />
        <path
          d='M339.6,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H339.6z M338.3,142.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
			c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
			S338.5,142.5,338.3,142.3z'
        />
        <path
          d='M346,144.2c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7C345.3,144.3,345.7,144.2,346,144.2z'
        />
        <path
          d='M354,150c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L354,150L354,150z M353.3,151.1c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C353.9,151.2,353.6,151.1,353.3,151.1z M352.7,144.7c-0.4,0-0.8,0.2-1,0.6
			c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S353.1,144.7,352.7,144.7z'
        />
        <path
          d='M358.2,149.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C357.7,149.2,358,149.1,358.2,149.1z'
        />
        <path
          d='M368.7,144.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0
			c0.1,0,0.1,0,0.1,0s0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1
			c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3
			c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8
			S368.4,144.2,368.7,144.2z M368.2,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8
			c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C369,144.9,368.7,144.9,368.2,144.9z'
        />
        <path
          d='M376.4,144.2c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C374.7,144.5,375.5,144.2,376.4,144.2z M376.2,144.7c-0.4,0-0.8,0.1-1.1,0.4
			s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2C377.1,144.9,376.7,144.7,376.2,144.7z'
        />
        <path
          d='M385.1,150c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L385.1,150L385.1,150z M384.4,151.1c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C385,151.2,384.7,151.1,384.4,151.1z
			 M383.8,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S384.2,144.7,383.8,144.7z'
        />
        <path
          d='M390.7,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
			s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
			s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
			c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
			c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3
			s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S390.1,144.2,390.7,144.2z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M365.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V317
			c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S365.3,319,365.6,319z'
        />
        <path
          d='M370.6,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S370.7,322,370.6,322z
			 M370.7,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S370.6,321.4,370.7,321.4z'
        />
        <path
          d='M380.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S380.3,319,380.6,319z'
        />
        <path
          d='M388.9,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S388.6,319,388.9,319z'
        />
        <path
          d='M396,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S395.4,319.1,396,319.1z M395.1,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S394.8,325.2,395.1,325.2z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M98.9,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
			s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4S97,326,96.7,326c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
			c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			S98.9,319.8,98.9,319.8z'
        />
        <path
          d='M105,320.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
			s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
			h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2V325v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
			s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
			s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.4H105z'
        />
        <path
          d='M110.1,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H108c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0C110.1,317,110.1,319.1,110.1,319.1z'
        />
        <path
          d='M115,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H115v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H115z'
        />
        <path
          d='M120.2,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L120.2,320.8L120.2,320.8z
			 M118.9,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			s-0.3,0.2-0.6,0.2S119,317.3,118.9,317.2z'
        />
        <path
          d='M126.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
			S126.2,319,126.6,319z'
        />
        <path
          d='M134.5,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
			s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M133.8,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S134.2,325.9,133.8,325.9z M133.2,319.5c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S133.7,319.5,133.2,319.5z'
        />
        <path
          d='M146.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V317
			c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S146.3,319,146.6,319z'
        />
        <path
          d='M153.6,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S153.1,319.1,153.6,319.1z M152.8,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S152.5,325.2,152.8,325.2z'
        />
        <path
          d='M159.4,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H159.4z M158.2,317.2
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
			S158.3,317.3,158.2,317.2z'
        />
        <path
          d='M165.6,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S165.2,319,165.6,319z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M159.8,409.2c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
			s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
			c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			S159.8,409.1,159.8,409.2z'
        />
        <path
          d='M165.2,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S164.9,408.4,165.2,408.4z'
        />
        <path
          d='M168,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
			c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
			c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C166.9,408.5,167.5,408.6,168,408.6z'
        />
        <path
          d='M176.6,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H176.6z M175.3,406.5
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
			S175.5,406.6,175.3,406.5z'
        />
        <path
          d='M183,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S182.7,408.4,183,408.4z'
        />
        <path
          d='M191,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
			s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V414.2z M190.3,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S190.6,415.3,190.3,415.3z M189.6,408.8c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S190.1,408.8,189.6,408.8z'
        />
        <path
          d='M195.2,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S194.9,413.3,195.2,413.3z'
        />
        <path
          d='M206.4,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
			s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
			s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
			c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
			c0.5-0.4,0.9-0.7,1.2-0.8C205.8,408.4,206.1,408.4,206.4,408.4z'
        />
        <path
          d='M217.7,408.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
			s0.3-1.8,1-2.5S216.8,408.3,217.7,408.3z M217.5,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
			s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
			S218,408.9,217.5,408.9z'
        />
        <path
          d='M226.7,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S226.4,408.4,226.7,408.4z'
        />
        <path
          d='M231.7,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S231.9,411.3,231.7,411.3z
			 M231.8,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S231.8,410.7,231.8,410.7z'
        />
        <path
          d='M238.3,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
			c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
			c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C237.2,408.5,237.8,408.6,238.3,408.6z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M146.8,144.2c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
			c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H148c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S146.5,144.2,146.8,144.2z M146.3,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
			c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S146.8,144.9,146.3,144.9z'
        />
        <path
          d='M154.5,144.2c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
			s0.3-1.8,1-2.5S153.6,144.2,154.5,144.2z M154.4,144.7c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
			s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
			S154.8,144.7,154.4,144.7z'
        />
        <path
          d='M163.2,150c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1
			c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
			c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2L163.2,150L163.2,150z M162.5,151.1
			c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S162.9,151.1,162.5,151.1z M161.9,144.7
			c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S162.3,144.7,161.9,144.7z'
        />
        <path
          d='M168.8,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S168.2,144.2,168.8,144.2z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M322.8,145c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
			c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4
			s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
			c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C322.8,144.9,322.8,144.9,322.8,145z'
        />
        <path
          d='M328.2,144.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
			c0.2-0.4,0.4-0.8,0.8-1.1C327.5,144.4,327.9,144.2,328.2,144.2z'
        />
        <path
          d='M331,144.4c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1
			s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8
			c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
			c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			C330,144.4,330.5,144.4,331,144.4z'
        />
        <path
          d='M339.6,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H339.6z M338.3,142.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
			c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
			S338.5,142.5,338.3,142.3z'
        />
        <path
          d='M346,144.2c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7C345.3,144.3,345.7,144.2,346,144.2z'
        />
        <path
          d='M354,150c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L354,150L354,150z M353.3,151.1c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C353.9,151.2,353.6,151.1,353.3,151.1z M352.7,144.7c-0.4,0-0.8,0.2-1,0.6
			c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S353.1,144.7,352.7,144.7z'
        />
        <path
          d='M358.2,149.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C357.7,149.2,358,149.1,358.2,149.1z'
        />
        <path
          d='M368.7,144.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0
			c0.1,0,0.1,0,0.1,0s0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1
			c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3
			c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8
			S368.4,144.2,368.7,144.2z M368.2,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8
			c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C369,144.9,368.7,144.9,368.2,144.9z'
        />
        <path
          d='M376.4,144.2c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C374.7,144.5,375.5,144.2,376.4,144.2z M376.2,144.7c-0.4,0-0.8,0.1-1.1,0.4
			s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
			s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2C377.1,144.9,376.7,144.7,376.2,144.7z'
        />
        <path
          d='M385.1,150c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L385.1,150L385.1,150z M384.4,151.1c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C385,151.2,384.7,151.1,384.4,151.1z
			 M383.8,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S384.2,144.7,383.8,144.7z'
        />
        <path
          d='M390.7,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
			s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
			s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8
			c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5
			c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3
			s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S390.1,144.2,390.7,144.2z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M365.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V317
			c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S365.3,319,365.6,319z'
        />
        <path
          d='M370.6,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S370.7,322,370.6,322z
			 M370.7,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S370.6,321.4,370.7,321.4z'
        />
        <path
          d='M380.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S380.3,319,380.6,319z'
        />
        <path
          d='M388.9,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S388.6,319,388.9,319z'
        />
        <path
          d='M396,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S395.4,319.1,396,319.1z M395.1,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S394.8,325.2,395.1,325.2z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M98.9,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
			s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4S97,326,96.7,326c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
			c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			S98.9,319.8,98.9,319.8z'
        />
        <path
          d='M105,320.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
			s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
			h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2V325v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
			s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
			s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.4H105z'
        />
        <path
          d='M110.1,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H108c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0C110.1,317,110.1,319.1,110.1,319.1z'
        />
        <path
          d='M115,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H115v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H115z'
        />
        <path
          d='M120.2,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1L120.2,320.8L120.2,320.8z
			 M118.9,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			s-0.3,0.2-0.6,0.2S119,317.3,118.9,317.2z'
        />
        <path
          d='M126.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
			S126.2,319,126.6,319z'
        />
        <path
          d='M134.5,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
			s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M133.8,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S134.2,325.9,133.8,325.9z M133.2,319.5c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S133.7,319.5,133.2,319.5z'
        />
        <path
          d='M146.6,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V317
			c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S146.3,319,146.6,319z'
        />
        <path
          d='M153.6,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S153.1,319.1,153.6,319.1z M152.8,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S152.5,325.2,152.8,325.2z'
        />
        <path
          d='M159.4,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H159.4z M158.2,317.2
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
			S158.3,317.3,158.2,317.2z'
        />
        <path
          d='M165.6,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S165.2,319,165.6,319z'
        />
      </g>
      <g id='ar' style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path
          d='M159.8,409.2c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
			s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
			c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			S159.8,409.1,159.8,409.2z'
        />
        <path
          d='M165.2,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S164.9,408.4,165.2,408.4z'
        />
        <path
          d='M168,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
			c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
			c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C166.9,408.5,167.5,408.6,168,408.6z'
        />
        <path
          d='M176.6,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H176.6z M175.3,406.5
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
			S175.5,406.6,175.3,406.5z'
        />
        <path
          d='M183,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S182.7,408.4,183,408.4z'
        />
        <path
          d='M191,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
			s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V414.2z M190.3,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S190.6,415.3,190.3,415.3z M189.6,408.8c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S190.1,408.8,189.6,408.8z'
        />
        <path
          d='M195.2,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S194.9,413.3,195.2,413.3z'
        />
        <path
          d='M206.4,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
			s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
			s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
			c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
			c0.5-0.4,0.9-0.7,1.2-0.8C205.8,408.4,206.1,408.4,206.4,408.4z'
        />
        <path
          d='M217.7,408.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
			s0.3-1.8,1-2.5S216.8,408.3,217.7,408.3z M217.5,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
			s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
			S218,408.9,217.5,408.9z'
        />
        <path
          d='M226.7,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S226.4,408.4,226.7,408.4z'
        />
        <path
          d='M231.7,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S231.9,411.3,231.7,411.3z
			 M231.8,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S231.8,410.7,231.8,410.7z'
        />
        <path
          d='M238.3,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
			c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
			c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C237.2,408.5,237.8,408.6,238.3,408.6z'
        />
      </g>

      <g id='en' style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path
          d='M170.6,409.2c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
			s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
			c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			S170.6,409.1,170.6,409.2z'
        />
        <path
          d='M176,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S175.7,408.4,176,408.4z'
        />
        <path
          d='M178.9,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
			c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
			c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
			c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C177.8,408.5,178.3,408.6,178.9,408.6z'
        />
        <path
          d='M187.4,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H187.4z M186.2,406.5
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
			S186.3,406.6,186.2,406.5z'
        />
        <path
          d='M193.9,408.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S193.5,408.4,193.9,408.4z'
        />
        <path
          d='M201.8,414.2c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
			c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
			s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V414.2z M201.1,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S201.5,415.3,201.1,415.3z M200.5,408.8c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S200.9,408.8,200.5,408.8z'
        />
        <path
          d='M206.1,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S205.8,413.3,206.1,413.3z'
        />
        <path
          d='M216.7,408.7c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c0,0,0,0,0-0.1
			s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
			s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
			s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7
			V408.7z'
        />
        <path
          d='M225.3,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S225,408.4,225.3,408.4z'
        />
        <path
          d='M228.2,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S228.3,411.3,228.2,411.3z
			 M228.3,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S228.3,410.7,228.3,410.7z'
        />
        <path
          d='M174.7,424.7c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c0,0,0,0,0-0.1
			s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
			s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
			s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7
			V424.7z'
        />
        <path
          d='M181.5,424.4c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S180.9,424.4,181.5,424.4z'
        />
        <path
          d='M189,424.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
			c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S188.7,424.4,189,424.4z'
        />
        <path
          d='M193.9,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S193.7,429.3,193.9,429.3z'
        />
        <path
          d='M201.5,422.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L201.5,422.5L201.5,422.5z'
        />
        <path
          d='M206.9,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H206.9z M205.7,422.5
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
			S205.8,422.6,205.7,422.5z'
        />
        <path
          d='M212.3,424.3c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
			s0.3-1.8,1-2.5S211.3,424.3,212.3,424.3z M212.1,424.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
			s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
			S212.6,424.9,212.1,424.9z'
        />
        <path
          d='M221.3,424.4c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S221,424.4,221.3,424.4z'
        />
        <path
          d='M226.2,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S226,429.3,226.2,429.3z'
        />
        <path
          d='M179.7,440.4c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S179.1,440.4,179.7,440.4z'
        />
        <path
          d='M187,440.4c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
			s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
			c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S186.7,440.4,187,440.4z M186.3,441.3
			c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
			s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S187,441.3,186.3,441.3z'
        />
        <path
          d='M193.3,442.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H193.3z M192,438.5
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
			S192.1,438.6,192,438.5z'
        />
        <path
          d='M199,440.4c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
			c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S198.6,440.4,199,440.4z M198.5,441c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
			s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S198.9,441,198.5,441z'
        />
        <path
          d='M204.4,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S204.6,443.3,204.4,443.3z
			 M204.5,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S204.5,442.7,204.5,442.7z'
        />
        <path
          d='M214.2,440.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S213.8,440.4,214.2,440.4z'
        />
        <path
          d='M218.4,440.4c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S217.8,440.4,218.4,440.4z'
        />
      </g>
      <g id='en' style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path
          d='M131,87.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S131.5,87.2,131,87.2z M131.2,81.1
			c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4
			s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1C132.5,81.4,131.9,81.1,131.2,81.1z'
        />
        <path
          d='M137.2,83.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C137.9,83.2,137.3,83.2,137.2,83.2z M137.2,82.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S137.2,82.6,137.2,82.6z'
        />
        <path
          d='M143.9,83.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C144.7,83.2,144,83.2,143.9,83.2z M144,82.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S144,82.6,144,82.6z'
        />
        <path
          d='M151.9,80.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C150.8,80.4,151.3,80.2,151.9,80.2z'
        />
        <path
          d='M156.3,85.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S156,85.1,156.3,85.1z'
        />
        <path
          d='M168.4,81c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C168.4,80.9,168.4,80.9,168.4,81z'
        />
        <path
          d='M172.8,80.2c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C171.8,80.4,172.3,80.2,172.8,80.2z M172,86.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S171.7,86.3,172,86.3z'
        />
        <path
          d='M178.3,80.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4V86
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.2H178.3z'
        />
        <path
          d='M183.7,80.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C182.6,80.4,183.1,80.2,183.7,80.2z'
        />
        <path
          d='M125.1,96.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
			c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C124.3,96.3,124.7,96.2,125.1,96.2z M124.6,96.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
			c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S125,96.9,124.6,96.9z'
        />
        <path
          d='M132.8,96.2c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C131,96.5,131.8,96.2,132.8,96.2z M132.6,96.7c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S133,96.7,132.6,96.7z'
        />
        <path
          d='M141.5,102c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
			s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1
			h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0
			s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L141.5,102
			L141.5,102z M140.7,103.1c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3
			S141.1,103.1,140.7,103.1z M140.1,96.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C140.9,96.9,140.6,96.7,140.1,96.7z'
        />
        <path
          d='M147.1,96.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C145.9,96.4,146.5,96.2,147.1,96.2z'
        />
        <path
          d='M151.5,101.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S151.2,101.1,151.5,101.1z'
        />
        <path
          d='M159.1,101.2c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1h-0.8c0,0,0,0,0-0.1s0-0.2,0.1-0.4c0.1-0.2,0.1-0.2,0.1-0.2h0.6
			c0.1,0,0.1,0,0.1-0.1v-0.2c0-0.5,0.1-1.1,0.3-1.6s0.4-0.9,0.7-1.3s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.2,0,0.4,0.1,0.8,0.2
			c0.3,0.1,0.6,0.2,0.7,0.2c0.1,0,0.3,0,0.5-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.2-0.1l0,0c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v7.1c0,0.6,0,1.1,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3
			c-1-0.1-1.6-0.1-1.7-0.1c-0.1,0-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
			c0.1-0.3,0.1-0.7,0.1-1.1v-6c0-0.1,0-0.3-0.1-0.6s-0.1-0.5-0.1-0.6c-0.1-0.2-0.3-0.5-0.7-0.8c-0.3-0.3-0.7-0.4-1.1-0.4
			c-0.3,0-0.6,0.1-0.8,0.4s-0.4,0.6-0.5,1.1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8c0,0,0,0,0,0.1c0,0,0,0,0.1,0h1.7h0.1c0,0,0,0,0,0.1
			c0,0.2-0.1,0.4-0.2,0.6h-1.6c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6c0,0.7,0.1,1.2,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.5,0.1
			c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1c-0.1,0-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2
			s0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2C159.1,102,159.1,101.7,159.1,101.2z'
        />
        <path
          d='M167.8,96.4c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1
			s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
			c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			C166.7,96.4,167.2,96.4,167.8,96.4z'
        />
        <path
          d='M176.4,98v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V98H176.4z M175.1,94.3
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S175.2,94.5,175.1,94.3z'
        />
        <path
          d='M182.8,96.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S182.4,96.2,182.8,96.2z'
        />
        <path
          d='M190.8,102c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
			s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1
			h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0
			s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L190.8,102
			L190.8,102z M190,103.1c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3
			S190.4,103.1,190,103.1z M189.4,96.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C190.2,96.9,189.9,96.7,189.4,96.7z'
        />
        <path
          d='M125.8,112.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
			c0.2-0.4,0.4-0.8,0.8-1.1C125.2,112.4,125.5,112.2,125.8,112.2z'
        />
        <path
          d='M130.9,112.2c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C129.8,112.4,130.4,112.2,130.9,112.2z M130,118.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S129.7,118.3,130,118.3z'
        />
        <path
          d='M136.3,112.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H136.3z'
        />
        <path
          d='M141.7,112.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C140.6,112.4,141.1,112.2,141.7,112.2z'
        />
        <path
          d='M146.1,117.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S145.8,117.1,146.1,117.1z'
        />
        <path
          d='M155.3,112.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C154.2,112.4,154.7,112.2,155.3,112.2z'
        />
        <path
          d='M163.1,112.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S162.7,112.2,163.1,112.2z'
        />
        <path
          d='M170.1,112.2c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C169.1,112.4,169.6,112.2,170.1,112.2z M169.3,118.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S169,118.3,169.3,118.3z'
        />
        <path
          d='M175.7,117.2c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
			c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
			c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1H178h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
			c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
			s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
			c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
			v1.4H175.7z'
        />
        <path
          d='M182.2,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C183,115.2,182.4,115.2,182.2,115.2z M182.3,114.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S182.3,114.6,182.3,114.6z'
        />
        <path
          d='M190.3,112.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C189.1,112.4,189.7,112.2,190.3,112.2z'
        />
        <path
          d='M119.4,128.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
			c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C118.3,128.4,118.8,128.2,119.4,128.2z'
        />
        <path
          d='M126.6,128.2c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C126,128.3,126.4,128.2,126.6,128.2z M126,129.1c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
			c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C127.1,129.4,126.6,129.1,126,129.1z'
        />
        <path
          d='M132.9,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H132.9
			z M131.6,126.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S131.8,126.5,131.6,126.3z'
        />
        <path
          d='M138.6,128.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C137.9,128.3,138.3,128.2,138.6,128.2z
			 M138.1,128.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4
			v-4c0-0.2-0.2-0.4-0.5-0.5S138.5,128.9,138.1,128.9z'
        />
        <path
          d='M144.1,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C144.8,131.2,144.2,131.2,144.1,131.2z M144.2,130.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S144.1,130.6,144.2,130.6z'
        />
        <path
          d='M153.8,128.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C153.1,128.4,153.5,128.2,153.8,128.2z'
        />
        <path
          d='M158.1,128.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5
			c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C157,128.4,157.5,128.2,158.1,128.2z'
        />
        <path
          d='M162.5,133.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S162.2,133.1,162.5,133.1z'
        />
        <path
          d='M170.2,128.4c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1
			s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
			c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			C169.2,128.4,169.7,128.4,170.2,128.4z'
        />
        <path
          d='M179.7,128.2c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C178,128.5,178.8,128.2,179.7,128.2z M179.6,128.7c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S180,128.7,179.6,128.7z'
        />
        <path
          d='M189.2,130.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
			c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
			c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.5-0.3-0.7
			c-0.1-0.3-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
			s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H189.2z'
        />
        <path
          d='M196.5,128.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C195.8,128.4,196.2,128.2,196.5,128.2z'
        />
        <path
          d='M109.6,144.2c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C109,144.3,109.3,144.2,109.6,144.2z M108.9,145.1c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
			c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C110,145.4,109.5,145.1,108.9,145.1z'
        />
        <path
          d='M116.8,144.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C115.7,144.4,116.2,144.2,116.8,144.2z M115.9,150.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S115.6,150.3,115.9,150.3z'
        />
        <path
          d='M124.5,144.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
			l0,0c0.2-0.4,0.4-0.8,0.8-1.1C123.8,144.4,124.1,144.2,124.5,144.2z'
        />
        <path
          d='M128.2,144.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H126c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H128.2z'
        />
        <path
          d='M135.6,144.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S135.3,144.2,135.6,144.2z'
        />
        <path
          d='M140.6,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1S140.7,147.2,140.6,147.2z M140.7,146.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S140.7,146.6,140.7,146.6z'
        />
        <path
          d='M150.3,144.2c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C149.7,144.4,150,144.2,150.3,144.2z'
        />
        <path
          d='M162.2,145c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
			c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4
			c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C162.2,144.9,162.2,144.9,162.2,145z'
        />
        <path
          d='M167.7,144.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S167.4,144.2,167.7,144.2z'
        />
        <path
          d='M172.7,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C173.4,147.2,172.8,147.2,172.7,147.2z M172.8,146.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S172.7,146.6,172.8,146.6z'
        />
        <path
          d='M181.4,144.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C180.4,144.4,180.9,144.2,181.4,144.2z M180.6,150.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S180.3,150.3,180.6,150.3z'
        />
        <path
          d='M186.9,144.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H186.9z'
        />
        <path
          d='M192.1,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H192.1
			z M190.8,142.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S191,142.5,190.8,142.3z'
        />
        <path
          d='M198.5,144.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S198.2,144.2,198.5,144.2z'
        />
        <path
          d='M206.5,150c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
			s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3
			l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0
			s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L206.5,150
			L206.5,150z M205.8,151.1c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5
			c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3
			S206.1,151.1,205.8,151.1z M205.1,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C206,144.9,205.6,144.7,205.1,144.7z'
        />
      </g>
      <g id='en' style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path
          d='M223,98.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
			c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2
			c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C223,98.7,223,98.8,223,98.9z'
        />
        <path
          d='M228.4,98.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
			c0.2-0.4,0.4-0.8,0.8-1.1C227.7,98.3,228.1,98.1,228.4,98.1z'
        />
        <path
          d='M231.2,98.3c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1
			c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2
			s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2C230.2,98.3,230.7,98.3,231.2,98.3z'
        />
        <path
          d='M239.8,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
			c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.5-0.1,0.9-0.1,1v0.6H239.8z M238.5,96.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
			s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S238.7,96.4,238.5,96.2z'
        />
        <path
          d='M246.2,98.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7C245.5,98.2,245.9,98.1,246.2,98.1z'
        />
        <path
          d='M254.2,103.9c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L254.2,103.9L254.2,103.9z M253.5,105c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C254.2,105,253.8,105,253.5,105z M252.9,98.5
			c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
			c0-0.5-0.1-1-0.4-1.4S253.3,98.5,252.9,98.5z'
        />
        <path
          d='M258.5,103c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C258,103.1,258.2,103,258.5,103z'
        />
        <path
          d='M269,98.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0s0,0.1,0,0.2v0.1
			c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S268.6,98.1,269,98.1z
			 M268.5,98.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C269.3,98.8,268.9,98.7,268.5,98.7z'
        />
        <path
          d='M276.6,98.1c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C274.9,98.4,275.7,98.1,276.6,98.1z M276.5,98.6c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2C277.3,98.8,276.9,98.6,276.5,98.6z'
        />
        <path
          d='M285.3,103.9c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L285.3,103.9L285.3,103.9z M284.6,105c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C285.3,105,285,105,284.6,105z M284,98.5c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S284.4,98.5,284,98.5z'
        />
        <path
          d='M291,98.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
			s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
			s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S290.3,98.1,291,98.1z'
        />
      </g>
      <g id='en' style={{ opacity: selected === 'EN' ? 1 : 0 }}>
        <path
          d='M342.4,317.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V317.2z'
        />
        <path
          d='M347.8,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
			v0.6H347.8z M346.5,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S346.7,317.3,346.5,317.2z'
        />
        <path
          d='M353.2,319c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C351.4,319.3,352.2,319,353.2,319z M353,319.5c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2C353.8,319.7,353.4,319.5,353,319.5z'
        />
        <path
          d='M362.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
			C361.5,319.2,361.9,319,362.2,319z'
        />
        <path
          d='M367.1,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C366.6,324,366.9,323.9,367.1,323.9z'
        />
        <path
          d='M376.3,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
			s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
			s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S375.7,319,376.3,319z'
        />
        <path
          d='M383.6,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C383,319.1,383.3,319,383.6,319z M382.9,319.9c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8
			c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C384.1,320.2,383.5,319.9,382.9,319.9z'
        />
        <path
          d='M389.9,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
			v0.6H389.9z M388.6,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S388.7,317.3,388.6,317.2z'
        />
        <path
          d='M395.6,319c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0s0,0.1,0,0.2v0.1
			c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S395.2,319,395.6,319z
			 M395.1,319.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2
			s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5C395.9,319.8,395.5,319.7,395.1,319.7z'
        />
        <path
          d='M401,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C401.8,322,401.2,322,401,322z M401.1,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
			C401.1,321.4,401.1,321.4,401.1,321.4z'
        />
        <path
          d='M410.8,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
			c0.2-0.4,0.4-0.8,0.8-1.1C410.1,319.2,410.4,319,410.8,319z'
        />
        <path
          d='M415,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2
			s0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5
			s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S414.4,319,415,319z'
        />
      </g>

      <g id='fr' style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path
          d='M317.8,142.3c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V142.3z'
        />
        <path
          d='M323.2,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H323.2z
			 M322,142.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			s-0.3,0.2-0.6,0.2S322.1,142.5,322,142.3z'
        />
        <path
          d='M328.6,144.2c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
			s0.3-1.8,1-2.5S327.6,144.2,328.6,144.2z M328.4,144.7c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
			s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
			S328.9,144.7,328.4,144.7z'
        />
        <path
          d='M337.6,144.2c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S337.3,144.2,337.6,144.2z'
        />
        <path
          d='M342.5,149.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S342.3,149.1,342.5,149.1z'
        />
        <path
          d='M351.8,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S351.2,144.2,351.8,144.2z'
        />
        <path
          d='M359,144.2c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
			s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
			c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S358.7,144.2,359,144.2z M358.3,145.1
			c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
			s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S359,145.1,358.3,145.1z'
        />
        <path
          d='M365.3,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H365.3z
			 M364,142.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			s-0.3,0.2-0.6,0.2S364.2,142.5,364,142.3z'
        />
        <path
          d='M371,144.2c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
			c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S370.6,144.2,371,144.2z M370.5,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
			c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S370.9,144.9,370.5,144.9z'
        />
        <path
          d='M376.5,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S376.6,147.2,376.5,147.2z
			 M376.5,146.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S376.5,146.6,376.5,146.6z'
        />
        <path
          d='M386.2,144.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
			S385.9,144.2,386.2,144.2z'
        />
        <path
          d='M390.5,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S389.9,144.2,390.5,144.2z'
        />
      </g>
      <g id='fr' style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path
          d='M113.6,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S113,144.2,113.6,144.2z'
        />
        <path
          d='M121.3,144.2c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S121,144.2,121.3,144.2z'
        />
        <path
          d='M128.4,144.2c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6c-0.4,0.3-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          s0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4c0.3-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2
          s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S127.9,144.2,128.4,144.2z M127.5,150.3
          c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7
          c0,0.3,0.1,0.5,0.3,0.8S127.3,150.3,127.5,150.3z'
        />
        <path
          d='M134,149.2c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H134z'
        />
        <path
          d='M140.5,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S140.6,147.2,140.5,147.2z
          M140.6,146.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S140.6,146.6,140.6,146.6z'
        />
        <path
          d='M148.5,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S147.9,144.2,148.5,144.2z'
        />
        <path
          d='M152.9,149.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S152.6,149.1,152.9,149.1z'
        />
        <path
          d='M162.1,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S161.5,144.2,162.1,144.2z'
        />
        <path
          d='M169.4,144.2c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S169.1,144.2,169.4,144.2z M168.7,145.1
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S169.3,145.1,168.7,145.1z'
        />
        <path
          d='M175.6,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H175.6z
          M174.4,142.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S174.5,142.5,174.4,142.3z'
        />
        <path
          d='M181.4,144.2c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S181,144.2,181.4,144.2z M180.9,144.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S181.3,144.9,180.9,144.9z'
        />
        <path
          d='M186.8,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S186.9,147.2,186.8,147.2z
          M186.9,146.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S186.9,146.6,186.9,146.6z'
        />
        <path
          d='M196.5,144.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S196.2,144.2,196.5,144.2z'
        />
        <path
          d='M200.8,144.2c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S200.2,144.2,200.8,144.2z'
        />
      </g>
      <g id='fr' style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path
          d='M297,407.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V410c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S296.5,407.9,297,407.9z M296.2,413.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S295.9,413.9,296.2,413.9z'
        />
        <path
          d='M305.1,407.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V410
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S304.7,407.8,305.1,407.8z'
        />
        <path
          d='M314.8,410.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S314.9,410.8,314.8,410.8z
          M314.8,410.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S314.8,410.2,314.8,410.2z'
        />
        <path
          d='M321.7,408c0.5,0,1,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.4,0-0.6,0.1-0.6,0.3c0,0,0,0.1,0.1,0.2l1,1.6
          c0.1-0.1,0.2-0.4,0.4-0.6s0.4-0.5,0.5-0.7s0.2-0.3,0.2-0.4s0-0.1-0.1-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0s-0.2,0-0.2,0s0-0.1,0-0.2
          s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0s0.3,0,0.5,0c0.5,0,0.9,0,1.2-0.1c0,0,0,0.1,0,0.3s0,0.2,0,0.2c-0.7,0-1.1,0.2-1.2,0.3
          c-0.1,0.2-0.3,0.5-0.6,0.8s-0.5,0.7-0.7,0.9s-0.3,0.4-0.3,0.4l1.9,2.8c0.3,0.4,0.7,0.6,1.2,0.6c0,0,0,0,0,0.1s0,0.1,0,0.2
          s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.5,0s-0.3,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.6,0s-0.4,0-0.6,0c0-0.1,0-0.1,0-0.3
          s0-0.2,0-0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.2v-0.1c-0.5-0.9-1-1.5-1.2-2c-0.1,0.1-0.2,0.3-0.5,0.7s-0.5,0.7-0.6,1
          c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0,0.2,0s0.1,0,0.3,0h0.1c0,0,0,0,0,0.1s0,0.1,0,0.2
          s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2
          s0-0.2,0-0.2s0.1,0,0.2,0s0.2,0,0.2,0s0.1,0,0.2,0s0.2,0,0.2-0.1s0.1,0,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2
          c1-1.4,1.5-2.1,1.5-2.2l-1.8-2.7c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.2
          C320.7,408,321.2,408,321.7,408z'
        />
      </g>
      <g id='fr' style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path
          d='M155.5,414.8c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S156,414.8,155.5,414.8z M155.7,408.7
          c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4
          s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S156.4,408.7,155.7,408.7z'
        />
        <path
          d='M161.7,410.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S161.8,410.8,161.7,410.8z
          M161.8,410.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S161.8,410.2,161.8,410.2z'
        />
        <path
          d='M169.5,409.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H169.5z M168.2,406
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S168.4,406.1,168.2,406z'
        />
        <path
          d='M175.9,407.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V410
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S175.6,407.8,175.9,407.8z'
        />
        <path
          d='M183.9,413.6c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V413.6z M183.2,414.7c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S183.5,414.7,183.2,414.7z M182.6,408.3c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S183,408.3,182.6,408.3
          z'
        />
        <path
          d='M195.7,407.8c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S195.4,407.8,195.7,407.8z M195,408.7
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S195.7,408.7,195,408.7z'
        />
        <path
          d='M203.9,407.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S203.6,407.8,203.9,407.8z'
        />
        <path
          d='M206.8,410.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S206.9,410.8,206.8,410.8z
          M206.9,410.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S206.9,410.2,206.9,410.2z'
        />
        <path
          d='M216.5,413.6c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V413.6z M215.8,414.7c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S216.1,414.7,215.8,414.7z M215.1,408.3c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S215.6,408.3,215.1,408.3z'
        />
        <path
          d='M224.1,407.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V410
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S223.8,407.8,224.1,407.8z'
        />
        <path
          d='M231.1,407.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V410c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S230.6,407.9,231.1,407.9z M230.3,413.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S230,413.9,230.3,413.9z'
        />
        <path
          d='M239.2,407.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V410
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S238.8,407.8,239.2,407.8z'
        />
        <path
          d='M244.9,407.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H244.9z'
        />
        <path
          d='M248.9,412.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S248.7,412.7,248.9,412.7z'
        />
        <path
          d='M161.3,430.8c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S161.9,430.8,161.3,430.8z M161.6,424.7
          c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4
          s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S162.3,424.7,161.6,424.7z'
        />
        <path
          d='M170.5,423.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S170.2,423.8,170.5,423.8z'
        />
        <path
          d='M173.5,426.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S173.6,426.8,173.5,426.8z
          M173.5,426.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S173.5,426.2,173.5,426.2z'
        />
        <path
          d='M182.2,423.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V426c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S181.7,423.9,182.2,423.9z M181.4,429.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S181.1,429.9,181.4,429.9z'
        />
        <path
          d='M189.5,423.8c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3V422c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S189.1,423.8,189.5,423.8z M189,424.5c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S189.4,424.5,189,424.5z'
        />
        <path
          d='M194.9,428.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S194.6,428.7,194.9,428.7z'
        />
        <path
          d='M205.8,429.6c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V429.6z M205,430.7c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S205.4,430.7,205,430.7z M204.4,424.3c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S204.9,424.3,204.4,424.3z'
        />
        <path
          d='M210.1,426.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S210.2,426.8,210.1,426.8z
          M210.1,426.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S210.1,426.2,210.1,426.2z'
        />
        <path
          d='M217.6,423.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H217.6z'
        />
        <path
          d='M222.4,423.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H222.4z'
        />
        <path
          d='M227.6,425.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H227.6z M226.3,422
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S226.5,422.1,226.3,422z'
        />
        <path
          d='M234,423.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V426
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S233.7,423.8,234,423.8z'
        />
        <path
          d='M242,429.6c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V429.6z M241.3,430.7c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S241.6,430.7,241.3,430.7z M240.7,424.3c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S241.1,424.3,240.7,424.3z'
        />
        <path
          d='M163.6,439.8c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V443c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2V442c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C163,439.9,163.3,439.8,163.6,439.8z'
        />
        <path
          d='M174.7,439.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V442c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S174.2,439.9,174.7,439.9z M173.8,445.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S173.5,445.9,173.8,445.9z'
        />
        <path
          d='M182.4,439.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S182.1,439.8,182.4,439.8z'
        />
        <path
          d='M188.4,439.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S188,439.8,188.4,439.8z'
        />
        <path
          d='M192.4,441.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H192.4z M191.2,438
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S191.3,438.1,191.2,438z'
        />
        <path
          d='M195.6,442.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S195.7,442.8,195.6,442.8z
          M195.6,442.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S195.6,442.2,195.6,442.2z'
        />
        <path
          d='M204.9,439.8c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3V438c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S204.5,439.8,204.9,439.8z M204.4,440.5c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S204.8,440.5,204.4,440.5z'
        />
        <path
          d='M210.3,444.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S210,444.7,210.3,444.7z'
        />
        <path
          d='M217.8,437.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L217.8,437.9L217.8,437.9z'
        />
        <path
          d='M223.2,441.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H223.2z M222,438
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S222.1,438.1,222,438z'
        />
        <path
          d='M228.6,439.8c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S227.6,439.8,228.6,439.8z M228.4,440.3c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S228.9,440.3,228.4,440.3z'
        />
        <path
          d='M237.6,439.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V442
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S237.3,439.8,237.6,439.8z'
        />
        <path
          d='M242.6,444.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S242.3,444.7,242.6,444.7z'
        />
        <path
          d='M186.9,455.8c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S186.3,455.8,186.9,455.8z'
        />
        <path
          d='M194.6,455.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V458
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S194.3,455.8,194.6,455.8z'
        />
        <path
          d='M201.7,455.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V458c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S201.2,455.9,201.7,455.9z M200.8,461.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S200.5,461.9,200.8,461.9z'
        />
        <path
          d='M207.2,460.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H207.2z'
        />
        <path
          d='M213.8,458.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S213.9,458.8,213.8,458.8z
          M213.9,458.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S213.9,458.2,213.9,458.2z'
        />
      </g>
      <g id='fr' style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path
          d='M125,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
          c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8S125.5,326,125,326z
          M125.3,319.9c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3
          s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S126,319.9,125.3,319.9z'
        />
        <path
          d='M133.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S132.7,319.1,133.2,319.1z M132.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S132.1,325.2,132.4,325.2z'
        />
        <path
          d='M138.7,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H138.7z'
        />
      </g>
      <g id='fr' style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path
          d='M241.1,98.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2S241,99,240.4,99c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
			c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S240.8,98.1,241.1,98.1z'
        />
        <path
          d='M246.1,101.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S246.2,101.1,246.1,101.1z
			 M246.2,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S246.2,100.5,246.2,100.5z'
        />
        <path
          d='M256.1,98.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2S256,99,255.4,99c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S255.8,98.1,256.1,98.1z'
        />
        <path
          d='M264.4,98.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S264.1,98.1,264.4,98.1z'
        />
        <path
          d='M271.5,98.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S271,98.1,271.5,98.1z M270.6,104.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S270.3,104.2,270.6,104.2z'
        />
      </g>

      <g id='jp' style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path
          d='M366.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S365.7,319.1,366.2,319.1z M365.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S365.1,325.2,365.4,325.2z'
        />
        <path
          d='M374.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S373.9,319,374.2,319z'
        />
        <path
          d='M383.9,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S384,322,383.9,322z
          M384,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
          s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S384,321.4,384,321.4z'
        />
        <path
          d='M390.8,319.2c0.5,0,1,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.4,0-0.6,0.1-0.6,0.3c0,0,0,0.1,0.1,0.2l1,1.6
          c0.1-0.1,0.2-0.4,0.4-0.6s0.4-0.5,0.5-0.7s0.2-0.3,0.2-0.4s0-0.1-0.1-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0s-0.2,0-0.2,0s0-0.1,0-0.2
          s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0s0.3,0,0.5,0c0.5,0,0.9,0,1.2-0.1c0,0,0,0.1,0,0.3s0,0.2,0,0.2c-0.7,0-1.1,0.2-1.2,0.3
          c-0.1,0.2-0.3,0.5-0.6,0.8s-0.5,0.7-0.7,0.9S393,322,393,322l1.9,2.8c0.3,0.4,0.7,0.6,1.2,0.6c0,0,0,0,0,0.1s0,0.1,0,0.2
          s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.4,0-0.5,0s-0.3,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.6,0s-0.4,0-0.6,0c0-0.1,0-0.1,0-0.3
          s0-0.2,0-0.2c0.2,0,0.3,0,0.4-0.1s0.2-0.1,0.2-0.2v-0.1c-0.5-0.9-1-1.5-1.2-2c-0.1,0.1-0.2,0.3-0.5,0.7s-0.5,0.7-0.6,1
          c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.2s0.1,0.1,0.2,0.1s0.2,0,0.2,0s0.1,0,0.3,0h0.1c0,0,0,0,0,0.1s0,0.1,0,0.2
          s0,0.1,0,0.1c-0.1,0-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0s-0.3,0-0.5,0c0-0.1,0-0.1,0-0.2
          s0-0.2,0-0.2s0.1,0,0.2,0s0.2,0,0.2,0s0.1,0,0.2,0s0.2,0,0.2-0.1s0.1,0,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2
          c1-1.4,1.5-2.1,1.5-2.2l-1.8-2.7c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.2
          C389.8,319.2,390.4,319.2,390.8,319.2z'
        />
      </g>
      <g id='jp' style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path
          d='M106.1,135.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S106.6,135.3,106.1,135.3z
			 M106.3,129.3c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3s-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3
			s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1C107.6,129.5,107,129.3,106.3,129.3z'
        />
        <path
          d='M113.4,130.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
			L113.4,130.1L113.4,130.1z M112.1,126.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S112.3,126.6,112.1,126.5z'
        />
        <path
          d='M119.5,128.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
			l0,0c0.2-0.4,0.4-0.8,0.8-1.1C118.9,128.5,119.2,128.4,119.5,128.4z'
        />
        <path
          d='M125,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
			c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4
			c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C124.2,128.4,124.6,128.3,125,128.3z M124.5,129
			c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5S124.9,129,124.5,129z'
        />
        <path
          d='M131.7,128.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5
			c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C130.6,128.5,131.1,128.4,131.7,128.4z'
        />
        <path
          d='M136.1,133.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S135.8,133.3,136.1,133.3z'
        />
        <path
          d='M144,131.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C144.8,131.3,144.1,131.3,144,131.3z M144.1,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S144.1,130.7,144.1,130.7z'
        />
        <path
          d='M152.8,128.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C151.7,128.5,152.3,128.4,152.8,128.4z M151.9,134.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S151.6,134.5,151.9,134.5z'
        />
        <path
          d='M160.5,128.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C159.8,128.5,160.2,128.4,160.5,128.4z'
        />
        <path
          d='M164.2,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V129H162c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H164.2z'
        />
        <path
          d='M171.4,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S171,128.3,171.4,128.3z'
        />
        <path
          d='M179.1,128.3c0.2,0,0.5,0.1,0.9,0.2c0.5,0.1,0.8,0.2,0.9,0.2c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0.1s0.1,0.2,0.1,0.2
			c-0.1,0.3-0.2,0.7-0.2,1.2v6.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.3c0-0.1,0-0.1,0-0.1s0,0-0.1,0c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.1,0.3
			s-0.7-0.1-1-0.2s-0.6-0.3-0.8-0.5s-0.4-0.5-0.6-0.8s-0.3-0.6-0.4-0.9s-0.1-0.6-0.1-0.9c0-0.4,0.1-0.8,0.2-1.2
			c0.2-0.4,0.4-0.7,0.6-1s0.6-0.6,0.9-0.8c0.3-0.2,0.7-0.4,1-0.5C178.4,128.4,178.8,128.3,179.1,128.3z M178.6,128.9
			c-0.3,0-0.7,0.1-0.9,0.3s-0.5,0.4-0.7,0.7s-0.3,0.6-0.4,0.8c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.9,0.2,1.6,0.6,2.1s1,0.8,1.6,0.8
			c0.3,0,0.6-0.1,0.8-0.2c0.3-0.1,0.4-0.3,0.4-0.4V130c0-0.3-0.1-0.6-0.4-0.8C179.3,129,179,128.9,178.6,128.9z'
        />
        <path
          d='M187.9,130.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
			c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
			c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.5-0.3-0.7
			c-0.1-0.3-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
			s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5H187.9z'
        />
        <path
          d='M194.2,128.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C193.2,128.5,193.7,128.4,194.2,128.4z M193.4,134.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S193.1,134.5,193.4,134.5z'
        />
        <path
          d='M199.8,133.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
			c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
			c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1H202c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
			c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
			s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
			c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
			v1.4H199.8z'
        />
        <path
          d='M206.3,131.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C207.1,131.3,206.5,131.3,206.3,131.3z M206.4,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S206.4,130.7,206.4,130.7z'
        />
        <path
          d='M122.4,140.3c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
			c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
			s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-1,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.8
			C121.9,140.4,122.2,140.3,122.4,140.3z'
        />
        <path
          d='M126.8,144.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C125.7,144.5,126.3,144.4,126.8,144.4z M125.9,150.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S125.6,150.5,125.9,150.5z'
        />
        <path
          d='M131.1,142.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
			L131.1,142.5L131.1,142.5z'
        />
        <path
          d='M135.1,142.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
			L135.1,142.5L135.1,142.5z'
        />
        <path
          d='M140.5,146.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H140.5
			z M139.3,142.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S139.4,142.6,139.3,142.5z'
        />
        <path
          d='M147,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S146.6,144.3,147,144.3z'
        />
        <path
          d='M154.9,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
			c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
			s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3
			l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0
			s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L154.9,150.1
			L154.9,150.1z M154.2,151.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
			s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3
			S154.6,151.2,154.2,151.2z M153.6,144.8c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C154.4,145,154.1,144.8,153.6,144.8z'
        />
        <path
          d='M164.7,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H164.7z'
        />
        <path
          d='M171,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C169.3,144.7,170.1,144.3,171,144.3z M170.9,144.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S171.3,144.8,170.9,144.8z'
        />
        <path
          d='M179,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C177.2,144.7,178,144.3,179,144.3z M178.8,144.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S179.3,144.8,178.8,144.8z'
        />
        <path
          d='M185.5,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H185.5z'
        />
        <path
          d='M192.6,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S192.3,144.3,192.6,144.3z'
        />
      </g>

      <g id='po' style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path
          d='M117.9,112c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S117.4,112,117.9,112z M117.1,118.1c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S116.8,118.1,117.1,118.1z'
        />
        <path
          d='M127,110.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L127,110.1L127,110.1z'
        />
        <path
          d='M133.5,111.9c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S132.6,111.9,133.5,111.9z M133.3,112.4c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S133.8,112.4,133.3,112.4z'
        />
        <path
          d='M140,112h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H140v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H140z'
        />
        <path
          d='M151,111.9c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S150.1,111.9,151,111.9z M150.8,112.4c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S151.3,112.4,150.8,112.4z'
        />
        <path
          d='M159.2,107.9c0.2,0,0.4,0,0.4,0s0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
          s-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
          s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1
          c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8S159,107.9,159.2,107.9z'
        />
        <path
          d='M166.1,112.1c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6
          s0.1-0.4,0.2-0.5s0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          s-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5
          l-1.7,4.3c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S165.9,112.1,166.1,112.1z'
        />
        <path
          d='M179.4,112c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S178.9,112,179.4,112z M178.6,118.1c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S178.3,118.1,178.6,118.1z'
        />
        <path
          d='M184.9,112h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H184.9z'
        />
        <path
          d='M189,114.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S189.1,114.9,189,114.9z
          M189.1,114.3h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S189,114.3,189.1,114.3z'
        />
        <path
          d='M198.7,111.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2V114
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S198.4,111.9,198.7,111.9z'
        />
        <path
          d='M123.4,126.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V126.1z'
        />
        <path
          d='M128.8,129.7v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2V130c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H128.8
          z M127.6,126.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2C127.9,126.3,127.7,126.2,127.6,126.1z'
        />
        <path
          d='M136.2,128.7c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4
          s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
          s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5c0.4-0.1,0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S136.2,128.7,136.2,128.7z'
        />
        <path
          d='M138.6,130.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          S138.7,130.9,138.6,130.9z M138.6,130.3h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4
          s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S138.6,130.3,138.6,130.3z'
        />
        <path
          d='M145.2,132.8c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S144.9,132.8,145.2,132.8z'
        />
        <path
          d='M154.4,127.9c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6
          c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
          c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8
          c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S153.8,127.9,154.4,127.9z'
        />
        <path
          d='M162.2,127.9c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2V130c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
          S161.8,127.9,162.2,127.9z'
        />
        <path
          d='M169.2,128c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S168.7,128,169.2,128z M168.4,134.1c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S168.1,134.1,168.4,134.1z'
        />
        <path
          d='M174.8,133c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V126c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1
          c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1H177c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H174.8z'
        />
        <path
          d='M181.3,130.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          c0.3,0.1,0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          S181.5,130.9,181.3,130.9z M181.4,130.3h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4
          s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S181.4,130.3,181.4,130.3z'
        />
        <path
          d='M189.4,127.9c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6
          c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8
          c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8
          c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S188.8,127.9,189.4,127.9z'
        />
        <path
          d='M115.5,143.9c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          s-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4S114.9,143.9,115.5,143.9z'
        />
        <path
          d='M122.7,143.9c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S122.5,143.9,122.7,143.9z M122.1,144.8
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S122.7,144.8,122.1,144.8z'
        />
        <path
          d='M129,145.7v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2V146c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
          v0.6H129z M127.7,142.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2S127.9,142.2,127.7,142.1z'
        />
        <path
          d='M134.7,143.9c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4
          c0-0.5,0.1-1,0.4-1.4s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S134.4,143.9,134.7,143.9z M134.2,144.6c-0.6,0-1,0.3-1.4,0.8
          s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
          S134.7,144.6,134.2,144.6z'
        />
        <path
          d='M140.2,146.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S140.3,146.9,140.2,146.9z
          M140.3,146.3h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S140.2,146.3,140.3,146.3z'
        />
        <path
          d='M149.9,143.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2V146
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S149.6,143.9,149.9,143.9z'
        />
        <path
          d='M154.2,143.9c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S153.6,143.9,154.2,143.9z'
        />
        <path
          d='M158.6,148.8c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S158.3,148.8,158.6,148.8z'
        />
        <path
          d='M167.8,143.9c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          s0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S167.2,143.9,167.8,143.9z'
        />
        <path
          d='M173,144h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H173v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H173z'
        />
        <path
          d='M179.3,143.9c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S178.4,143.9,179.3,143.9z M179.2,144.4c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S179.6,144.4,179.2,144.4z'
        />
        <path
          d='M188.1,143.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2V146
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1
          S187.7,143.9,188.1,143.9z'
        />
        <path
          d='M194.3,143.9c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V147c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C193.7,144,194,143.9,194.3,143.9z'
        />
      </g>
      <g id='po' style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path
          d='M326.9,112.7c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S326.9,112.7,326.9,112.7z'
        />
        <path
          d='M332.2,111.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2V114
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S331.9,111.9,332.2,111.9z'
        />
        <path
          d='M335.1,112.1c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8
          c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          C334,112.1,334.5,112.1,335.1,112.1z'
        />
        <path
          d='M343.6,113.7v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2V114c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H343.6z M342.4,110.1
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S342.5,110.2,342.4,110.1z'
        />
        <path
          d='M350.1,111.9c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V114
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S349.7,111.9,350.1,111.9z'
        />
        <path
          d='M358,117.7c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5
          c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1
          c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3
          s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
          c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2
          L358,117.7L358,117.7z M357.3,118.8c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
          c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4
          c-0.2-0.1-0.4-0.2-0.7-0.3C358,118.9,357.7,118.8,357.3,118.8z M356.7,112.4c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
          s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S357.2,112.4,356.7,112.4z'
        />
        <path
          d='M362.3,116.8c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S362,116.8,362.3,116.8z'
        />
        <path
          d='M373,112.2c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-3
          c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.5,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1H369c0,0,0,0,0-0.1s0.1-0.2,0.2-0.3
          s0.2-0.2,0.3-0.2h0.5v0c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2s0.6,0.3,0.7,0.5
          c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2s-0.1-0.2-0.1-0.2
          s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8L373,112.2L373,112.2z'
        />
        <path
          d='M381.5,111.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2V114
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S381.2,111.9,381.5,111.9z'
        />
        <path
          d='M384.4,114.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S384.6,114.9,384.4,114.9z
          M384.5,114.3h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S384.5,114.3,384.5,114.3z'
        />
        <path
          d='M330.9,128.2c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8s-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-1c0,0,0,0,0-0.1
          s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v0c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
          s0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
          s-0.1-0.2-0.1-0.2s-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.7h1.8
          V128.2z'
        />
        <path
          d='M337.7,127.9c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          s-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4S337.1,127.9,337.7,127.9z'
        />
        <path
          d='M345.2,127.9c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1
          c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7
          S344.9,127.9,345.2,127.9z'
        />
        <path
          d='M350.2,132.8c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S349.9,132.8,350.2,132.8z'
        />
        <path
          d='M357.7,126.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V126.1z'
        />
        <path
          d='M363.1,129.7v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2V130c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H363.1
          z M361.9,126.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S362,126.2,361.9,126.1z'
        />
        <path
          d='M368.5,127.9c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S367.5,127.9,368.5,127.9z M368.3,128.4c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S368.8,128.4,368.3,128.4z'
        />
        <path
          d='M377.5,127.9c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2V130c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
          S377.2,127.9,377.5,127.9z'
        />
        <path
          d='M382.4,132.8c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S382.2,132.8,382.4,132.8z'
        />
        <path
          d='M336,143.9c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          s-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4S335.4,143.9,336,143.9z'
        />
        <path
          d='M343.2,143.9c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S342.9,143.9,343.2,143.9z M342.5,144.8
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S343.2,144.8,342.5,144.8z'
        />
        <path
          d='M349.5,145.7v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2V146c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H349.5z
          M348.2,142.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S348.4,142.2,348.2,142.1z'
        />
        <path
          d='M355.2,143.9c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4
          c0-0.5,0.1-1,0.4-1.4s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S354.8,143.9,355.2,143.9z M354.7,144.6c-0.6,0-1,0.3-1.4,0.8
          s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
          S355.1,144.6,354.7,144.6z'
        />
        <path
          d='M360.6,146.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S360.8,146.9,360.6,146.9z
          M360.7,146.3h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S360.7,146.3,360.7,146.3z'
        />
        <path
          d='M370.4,143.9c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2V146
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S370,143.9,370.4,143.9z
          '
        />
        <path
          d='M374.7,143.9c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          s-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4S374.1,143.9,374.7,143.9z'
        />
      </g>
      <g id='po' style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path
          d='M333.6,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S334.1,326,333.6,326z M333.8,319.9c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S334.5,319.9,333.8,319.9z'
        />
        <path
          d='M339.8,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S339.9,322,339.8,322z
          M339.8,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S339.8,321.4,339.8,321.4z'
        />
        <path
          d='M347.6,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H347.6z M346.3,317.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S346.5,317.3,346.3,317.2z'
        />
        <path
          d='M354,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S353.7,319,354,319z'
        />
        <path
          d='M362,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3S364,320,364,320l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2H362z M361.3,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S361.6,325.9,361.3,325.9z M360.6,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S361.1,319.5,360.6,319.5z'
        />
        <path
          d='M373.8,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S373.5,319,373.8,319z M373.1,319.9
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S373.7,319.9,373.1,319.9z'
        />
        <path
          d='M382,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S381.6,319,382,319z'
        />
        <path
          d='M384.9,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S385,322,384.9,322z
          M385,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
          s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S384.9,321.4,385,321.4z'
        />
        <path
          d='M394.6,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M393.8,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S394.2,325.9,393.8,325.9z M393.2,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S393.7,319.5,393.2,319.5z'
        />
        <path
          d='M402.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S401.9,319,402.2,319z'
        />
        <path
          d='M409.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S408.7,319.1,409.2,319.1z M408.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S408.1,325.2,408.4,325.2z'
        />
        <path
          d='M417.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S416.9,319,417.2,319z'
        />
        <path
          d='M423,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H423v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L423,319.1L423,319.1z'
        />
        <path
          d='M427,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S426.8,323.9,427,323.9z'
        />
        <path
          d='M339.4,342c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S339.9,342,339.4,342z M339.7,335.9c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S340.4,335.9,339.7,335.9z'
        />
        <path
          d='M348.6,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S348.3,335,348.6,335z'
        />
        <path
          d='M351.5,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S351.7,338,351.5,338z
          M351.6,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S351.6,337.4,351.6,337.4z'
        />
        <path
          d='M360.3,335.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S359.8,335.1,360.3,335.1z M359.4,341.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S359.1,341.2,359.4,341.2z'
        />
        <path
          d='M367.6,335c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S367.2,335,367.6,335z M367.1,335.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S367.5,335.7,367.1,335.7z'
        />
        <path
          d='M373,339.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S372.7,339.9,373,339.9z'
        />
        <path
          d='M383.8,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V340.8z M383.1,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S383.5,341.9,383.1,341.9z M382.5,335.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S382.9,335.5,382.5,335.5z'
        />
        <path
          d='M388.1,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S388.3,338,388.1,338z
          M388.2,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S388.2,337.4,388.2,337.4z'
        />
        <path
          d='M395.6,335.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L395.6,335.1L395.6,335.1z'
        />
        <path
          d='M400.5,335.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L400.5,335.1L400.5,335.1z'
        />
        <path
          d='M405.7,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H405.7z M404.4,333.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S404.6,333.3,404.4,333.2z'
        />
        <path
          d='M412.1,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S411.8,335,412.1,335z'
        />
        <path
          d='M420.1,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V340.8z M419.4,341.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S419.7,341.9,419.4,341.9z M418.7,335.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S419.2,335.5,418.7,335.5z'
        />
        <path
          d='M341.6,351c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C341.1,351.1,341.4,351,341.6,351z'
        />
        <path
          d='M352.8,351.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S352.2,351.1,352.8,351.1z M351.9,357.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S351.6,357.2,351.9,357.2z'
        />
        <path
          d='M360.5,351c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S360.1,351,360.5,351z'
        />
        <path
          d='M366.4,351c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S366.1,351,366.4,351z'
        />
        <path
          d='M370.5,352.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H370.5z M369.3,349.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S369.4,349.3,369.3,349.2z'
        />
        <path
          d='M373.6,354c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S373.8,354,373.6,354z
          M373.7,353.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S373.7,353.4,373.7,353.4z'
        />
        <path
          d='M383,351c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S382.6,351,383,351z M382.5,351.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S382.9,351.7,382.5,351.7z'
        />
        <path
          d='M388.3,355.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S388.1,355.9,388.3,355.9z'
        />
        <path
          d='M395.9,349.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V349.2z'
        />
        <path
          d='M401.3,352.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H401.3z M400.1,349.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S400.2,349.3,400.1,349.2z'
        />
        <path
          d='M406.7,351c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S405.7,351,406.7,351z M406.5,351.5c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S406.9,351.5,406.5,351.5z'
        />
        <path
          d='M415.7,351c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S415.4,351,415.7,351z'
        />
        <path
          d='M420.6,355.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S420.4,355.9,420.6,355.9z'
        />
        <path
          d='M365,367c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S364.4,367,365,367z'
        />
        <path
          d='M372.7,367c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S372.4,367,372.7,367z'
        />
        <path
          d='M379.8,367.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S379.2,367.1,379.8,367.1z M378.9,373.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S378.6,373.2,378.9,373.2z'
        />
        <path
          d='M385.3,372.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V368c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
          h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H385.3z'
        />
        <path
          d='M391.9,370c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S392,370,391.9,370z
          M391.9,369.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S391.9,369.4,391.9,369.4z'
        />
      </g>
      <g id='po' style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path
          d='M120.6,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S121.1,326,120.6,326z M120.8,319.9c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S121.5,319.9,120.8,319.9z'
        />
        <path
          d='M128.8,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S128.3,319.1,128.8,319.1z M127.9,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S127.7,325.2,127.9,325.2z'
        />
        <path
          d='M135.1,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S135.6,326,135.1,326z M135.3,319.9c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S136,319.9,135.3,319.9z'
        />
        <path
          d='M141,319.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C139.9,319.2,140.4,319.2,141,319.2z'
        />
      </g>
      <g id='po' style={{ opacity: selected === 'PO' ? 1 : 0 }}>
        <path
          d='M216.2,98.9c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
			c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
			s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
			c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			S216.2,98.8,216.2,98.9z'
        />
        <path
          d='M221.6,98.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S221.3,98.1,221.6,98.1z'
        />
        <path
          d='M224.4,98.3c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
			c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
			c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8
			c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			C223.4,98.3,223.9,98.3,224.4,98.3z'
        />
        <path
          d='M233,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H233z M231.7,96.2
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
			S231.9,96.4,231.7,96.2z'
        />
        <path
          d='M239.4,98.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S239.1,98.1,239.4,98.1z'
        />
        <path
          d='M247.4,103.9c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3
			s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2
			L247.4,103.9L247.4,103.9z M246.7,105c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
			c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C247.4,105,247,105,246.7,105z M246.1,98.5c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S246.5,98.5,246.1,98.5
			z'
        />
        <path
          d='M251.6,103c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S251.4,103,251.6,103z'
        />
        <path
          d='M262.8,98.1c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
			s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
			s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
			c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
			c0.5-0.4,0.9-0.7,1.2-0.8C262.2,98.2,262.5,98.1,262.8,98.1z'
        />
        <path
          d='M274.1,98.1c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
			s0.3-1.8,1-2.5S273.2,98.1,274.1,98.1z M274,98.6c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
			s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
			S274.4,98.6,274,98.6z'
        />
        <path
          d='M283.2,98.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2S283,99,282.4,99c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S282.8,98.1,283.2,98.1z'
        />
        <path
          d='M288.2,101.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S288.3,101.1,288.2,101.1z
			 M288.2,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S288.2,100.5,288.2,100.5z'
        />
        <path
          d='M294.7,98.3c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
			c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
			c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
			c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8
			c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			C293.7,98.3,294.2,98.3,294.7,98.3z'
        />
      </g>

      <g id='ru' style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path
          d='M373.5,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S374,326,373.5,326z M373.7,319.9c-0.2,0-0.4,0-0.7,0.1
          s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1
          s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S374.4,319.9,373.7,319.9z'
        />
        <path
          d='M381.7,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S381.2,319.1,381.7,319.1z M380.9,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S380.6,325.2,380.9,325.2z'
        />
        <path
          d='M387.2,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H385c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L387.2,319.1L387.2,319.1z'
        />
      </g>
      <g id='ru' style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path
          d='M187.5,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S188,415.3,187.5,415.3z M187.7,409.3
          c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4
          s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S188.4,409.3,187.7,409.3z'
        />
        <path
          d='M195.7,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S195.2,408.4,195.7,408.4z M194.9,414.5c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S194.6,414.5,194.9,414.5z'
        />
        <path
          d='M202,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1
          s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S202.6,415.3,202,415.3z M202.3,409.3
          c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4
          s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S203,409.3,202.3,409.3z'
        />
        <path
          d='M207.9,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C206.9,408.5,207.4,408.6,207.9,408.6z'
        />
      </g>
      <g id='ru' style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path
          d='M224.4,98.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
			c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2
			c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C224.4,98.7,224.4,98.8,224.4,98.9z'
        />
        <path
          d='M230.5,99.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
			c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7V104
			c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5c-0.1-0.2-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8
			c0-0.3,0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
			s0.5-0.4,0.5-0.6v-3.5H230.5z'
        />
        <path
          d='M235.6,98.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2
			s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H235.6z'
        />
        <path
          d='M240.4,98.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2
			s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H240.4z'
        />
        <path
          d='M245.6,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
			c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.5-0.1,0.9-0.1,1v0.6H245.6z M244.4,96.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
			s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S244.5,96.4,244.4,96.2z'
        />
        <path
          d='M252.1,98.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7C251.3,98.2,251.7,98.1,252.1,98.1z'
        />
        <path
          d='M260,103.9c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L260,103.9L260,103.9z M259.3,105c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C260,105,259.7,105,259.3,105z M258.7,98.5c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			S259.1,98.5,258.7,98.5z'
        />
        <path
          d='M272.1,98.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2S272,99,271.4,99c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1
			c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7
			C271.4,98.2,271.8,98.1,272.1,98.1z'
        />
        <path
          d='M279.1,98.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
			c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
			c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C278.1,98.3,278.6,98.1,279.1,98.1z M278.3,104.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S278,104.2,278.3,104.2z'
        />
        <path
          d='M284.9,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1
			v0.6H284.9z M283.7,96.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S283.8,96.4,283.7,96.2z'
        />
        <path
          d='M291.1,98.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
			c0.2-0.4,0.4-0.8,0.8-1.1C290.4,98.3,290.7,98.1,291.1,98.1z'
        />
      </g>

      <g id='sp' style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path
          d='M159.8,407.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V410c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S159.3,407.9,159.8,407.9z M159,413.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S158.7,413.9,159,413.9z'
        />
        <path
          d='M168.9,405.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L168.9,405.9L168.9,405.9z'
        />
        <path
          d='M175.4,407.8c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S174.5,407.8,175.4,407.8z M175.2,408.3c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S175.7,408.3,175.2,408.3z'
        />
        <path
          d='M181.9,407.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1L181.9,407.9L181.9,407.9z'
        />
        <path
          d='M192.9,407.8c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S192,407.8,192.9,407.8z M192.7,408.3c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S193.2,408.3,192.7,408.3z'
        />
        <path
          d='M201.1,403.8c0.2,0,0.4,0,0.4,0s0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
          s-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
          s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1
          c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8S200.9,403.8,201.1,403.8z'
        />
        <path
          d='M208,408c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          s-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1s0.3-0.7,0.4-0.9s0.2-0.4,0.2-0.6
          s0.1-0.4,0.2-0.5s0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5s-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
          s0.1,0.2,0.1,0.3s0.1,0.3,0.2,0.5s0.2,0.5,0.3,0.7s0.2,0.6,0.4,1s0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5
          c0.1-0.2,0.1-0.3,0.1-0.4s0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2s-0.2-0.1-0.3-0.1s-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
          s0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2s0,0.2-0.1,0.2s-0.3,0-0.6,0.1
          s-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3
          c0,0-0.1,0.1-0.1,0.2s-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S207.8,408,208,408z'
        />
        <path
          d='M221.3,407.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V410c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S220.8,407.9,221.3,407.9z M220.5,413.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S220.2,413.9,220.5,413.9z'
        />
        <path
          d='M226.8,407.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1L226.8,407.9L226.8,407.9z'
        />
        <path
          d='M230.9,410.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S231,410.8,230.9,410.8z
          M230.9,410.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S230.9,410.2,230.9,410.2z'
        />
        <path
          d='M240.6,407.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S240.3,407.8,240.6,407.8z'
        />
        <path
          d='M165.3,421.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L165.3,421.9L165.3,421.9z'
        />
        <path
          d='M170.7,425.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L170.7,425.6L170.7,425.6z
          M169.4,422c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S169.6,422.1,169.4,422z'
        />
        <path
          d='M178.1,424.6c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S178.1,424.6,178.1,424.6z'
        />
        <path
          d='M180.4,426.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S180.6,426.8,180.4,426.8z
          M180.5,426.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S180.5,426.2,180.5,426.2z'
        />
        <path
          d='M187.1,428.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S186.8,428.7,187.1,428.7z'
        />
        <path
          d='M196.3,423.8c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S195.7,423.8,196.3,423.8z'
        />
        <path
          d='M204.1,423.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V426
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S203.7,423.8,204.1,423.8z'
        />
        <path
          d='M211.1,423.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V426c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S210.6,423.9,211.1,423.9z M210.3,429.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S210,429.9,210.3,429.9z'
        />
        <path
          d='M216.7,428.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1H219h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4L216.7,428.9L216.7,428.9z'
        />
        <path
          d='M223.2,426.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S223.4,426.8,223.2,426.8z
          M223.3,426.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S223.3,426.2,223.3,426.2z'
        />
        <path
          d='M231.2,423.8c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S230.6,423.8,231.2,423.8z'
        />
        <path
          d='M157.4,439.8c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S156.8,439.8,157.4,439.8z'
        />
        <path
          d='M164.6,439.8c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S164.4,439.8,164.6,439.8z M164,440.7
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S164.6,440.7,164,440.7z'
        />
        <path
          d='M170.9,441.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L170.9,441.6L170.9,441.6z
          M169.6,438c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S169.8,438.1,169.6,438z'
        />
        <path
          d='M176.6,439.8c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3V438c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S176.3,439.8,176.6,439.8z M176.1,440.5c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S176.5,440.5,176.1,440.5z'
        />
        <path
          d='M182.1,442.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S182.2,442.8,182.1,442.8z
          M182.1,442.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S182.1,442.2,182.1,442.2z'
        />
        <path
          d='M191.8,439.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S191.5,439.8,191.8,439.8z'
        />
        <path
          d='M196.1,439.8c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S195.5,439.8,196.1,439.8z'
        />
        <path
          d='M200.5,444.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S200.2,444.7,200.5,444.7z'
        />
        <path
          d='M209.7,439.8c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S209.1,439.8,209.7,439.8z'
        />
        <path
          d='M214.9,439.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1L214.9,439.9L214.9,439.9z'
        />
        <path
          d='M221.2,439.8c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S220.3,439.8,221.2,439.8z M221.1,440.3c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S221.5,440.3,221.1,440.3z'
        />
        <path
          d='M229.9,439.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          s-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S229.6,439.8,229.9,439.8z'
        />
        <path
          d='M236.2,439.8c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V443c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2V442c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C235.6,439.9,235.9,439.8,236.2,439.8z'
        />
      </g>
      <g id='sp' style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path
          d='M259.3,414.8c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S259.8,414.8,259.3,414.8z M259.5,408.7
          c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4
          s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S260.2,408.7,259.5,408.7z'
        />
        <path
          d='M266.6,409.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L266.6,409.6L266.6,409.6z
          M265.3,406c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S265.5,406.1,265.3,406z'
        />
        <path
          d='M272.7,407.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S272.4,407.8,272.7,407.8z'
        />
        <path
          d='M278.1,407.8c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3V406c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S277.8,407.8,278.1,407.8z M277.6,408.5c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S278.1,408.5,277.6,408.5z'
        />
        <path
          d='M284.9,407.8c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S284.3,407.8,284.9,407.8z'
        />
        <path
          d='M289.3,412.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S289,412.7,289.3,412.7z'
        />
        <path
          d='M297.2,410.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S297.3,410.8,297.2,410.8z
          M297.3,410.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S297.3,410.2,297.3,410.2z'
        />
        <path
          d='M305.9,407.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V410c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S305.4,407.9,305.9,407.9z M305.1,413.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S304.8,413.9,305.1,413.9z'
        />
        <path
          d='M313.7,407.8c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S313.3,407.8,313.7,407.8z'
        />
        <path
          d='M317.4,407.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L317.4,407.9L317.4,407.9z'
        />
        <path
          d='M324.6,407.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S324.2,407.8,324.6,407.8z'
        />
        <path
          d='M332.3,407.8c0.2,0,0.5,0.1,0.9,0.2s0.8,0.2,0.9,0.2s0.2,0,0.3,0c0,0,0.1,0,0.1,0.1s0.1,0.2,0.1,0.2
          c-0.1,0.3-0.2,0.7-0.2,1.2v6.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V414c0-0.1,0-0.1,0-0.1s0,0-0.1,0c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.1,0.3
          c-0.3,0-0.7-0.1-1-0.2s-0.6-0.3-0.8-0.5s-0.4-0.5-0.6-0.8s-0.3-0.6-0.4-0.9s-0.1-0.6-0.1-0.9c0-0.4,0.1-0.8,0.2-1.2s0.4-0.7,0.6-1
          s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5S332,407.8,332.3,407.8z M331.8,408.4c-0.3,0-0.7,0.1-0.9,0.3s-0.5,0.4-0.7,0.7s-0.3,0.6-0.4,0.8
          s-0.1,0.6-0.1,0.8c0,0.9,0.2,1.6,0.6,2.1s1,0.8,1.6,0.8c0.3,0,0.6-0.1,0.8-0.2s0.4-0.3,0.4-0.4v-3.7c0-0.3-0.1-0.6-0.4-0.8
          S332.2,408.4,331.8,408.4z'
        />
        <path
          d='M341,409.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
          s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
          h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
          s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5L341,409.7L341,409.7z'
        />
        <path
          d='M347.4,407.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V410c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S346.9,407.9,347.4,407.9z M346.6,413.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S346.3,413.9,346.6,413.9z'
        />
        <path
          d='M353,412.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1
          h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4L353,412.9L353,412.9z'
        />
        <path
          d='M359.5,410.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S359.6,410.8,359.5,410.8z
          M359.6,410.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S359.6,410.2,359.6,410.2z'
        />
        <path
          d='M275.6,419.8c0.2,0,0.4,0,0.4,0s0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
          s-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
          s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H274v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1H272c-0.1,0-0.1,0-0.1-0.1
          c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8S275.3,419.8,275.6,419.8z'
        />
        <path
          d='M280,423.9c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3V426c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S279.4,423.9,280,423.9z M279.1,429.9c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S278.8,429.9,279.1,429.9z'
        />
        <path
          d='M284.3,421.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V421.9z'
        />
        <path
          d='M288.3,421.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V421.9z'
        />
        <path
          d='M293.7,425.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L293.7,425.6L293.7,425.6z
          M292.5,422c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          s-0.3,0.2-0.6,0.2S292.6,422.1,292.5,422z'
        />
        <path
          d='M300.1,423.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V426
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S299.8,423.8,300.1,423.8z'
        />
        <path
          d='M308.1,429.6c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4L308.1,429.6L308.1,429.6z M307.4,430.7c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
          s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S307.8,430.7,307.4,430.7z M306.8,424.3
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S307.2,424.3,306.8,424.3z'
        />
        <path
          d='M317.9,423.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L317.9,423.9L317.9,423.9z'
        />
        <path
          d='M324.2,423.8c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S323.3,423.8,324.2,423.8z M324,424.3c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S324.5,424.3,324,424.3z'
        />
        <path
          d='M332.2,423.8c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S331.2,423.8,332.2,423.8z M332,424.3c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S332.4,424.3,332,424.3z'
        />
        <path
          d='M338.7,423.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L338.7,423.9L338.7,423.9z'
        />
        <path
          d='M345.8,423.8c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9
          c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S345.5,423.8,345.8,423.8z'
        />
      </g>
      <g id='sp' style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path
          d='M327.6,88.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
          c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
          s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1
          c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8S328.1,88.2,327.6,88.2z
          M327.9,82.1c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3
          s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1C329.1,82.4,328.6,82.1,327.9,82.1z'
        />
        <path
          d='M333.8,84.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C334.6,84.2,333.9,84.2,333.8,84.2z M333.9,83.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S333.9,83.6,333.9,83.6z'
        />
        <path
          d='M340.5,84.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C341.3,84.2,340.7,84.2,340.5,84.2z M340.6,83.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S340.6,83.6,340.6,83.6z'
        />
        <path
          d='M348.6,81.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4C347.4,81.4,348,81.2,348.6,81.2z'
        />
        <path
          d='M352.9,86.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S352.7,86.1,352.9,86.1z'
        />
        <path
          d='M365.1,82c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C365.1,81.9,365.1,81.9,365.1,82z'
        />
        <path
          d='M369.5,81.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C368.5,81.4,369,81.2,369.5,81.2
          z M368.6,87.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
          s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S368.3,87.3,368.6,87.3z'
        />
        <path
          d='M375,81.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H375v4c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4V87
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L375,81.3L375,81.3z'
        />
        <path
          d='M380.4,81.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4C379.2,81.4,379.8,81.2,380.4,81.2z'
        />
        <path
          d='M321.7,97.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C321,97.3,321.4,97.2,321.7,97.2z M321.2,97.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S321.7,97.9,321.2,97.9z'
        />
        <path
          d='M329.4,97.2c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C327.7,97.5,328.5,97.2,329.4,97.2z M329.2,97.7c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S329.7,97.7,329.2,97.7z'
        />
        <path
          d='M338.1,103c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
          c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
          c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3
          l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0
          s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L338.1,103
          L338.1,103z M337.4,104.1c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5
          c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3
          S337.7,104.1,337.4,104.1z M336.8,97.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
          c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C337.6,97.9,337.2,97.7,336.8,97.7z'
        />
        <path
          d='M343.7,97.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4C342.6,97.4,343.1,97.2,343.7,97.2z'
        />
        <path
          d='M348.1,102.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S347.8,102.1,348.1,102.1z'
        />
        <path
          d='M355.8,102.2c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1h-0.8c0,0,0,0,0-0.1s0-0.2,0.1-0.4c0.1-0.2,0.1-0.2,0.1-0.2h0.6
          c0.1,0,0.1,0,0.1-0.1v-0.2c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
          c0.2,0,0.4,0.1,0.8,0.2c0.3,0.1,0.6,0.2,0.7,0.2s0.3,0,0.5-0.1s0.3-0.1,0.4-0.2s0.2-0.1,0.2-0.1l0,0c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.1c0,0.6,0,1.1,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.1
          c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1s-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6-0.1
          c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.3,0.1-0.7,0.1-1.1v-6c0-0.1,0-0.3-0.1-0.6s-0.1-0.5-0.1-0.6c-0.1-0.2-0.3-0.5-0.7-0.8
          c-0.3-0.3-0.7-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.3-0.4,0.6-0.5,1.1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8c0,0,0,0,0,0.1
          c0,0,0,0,0.1,0h1.7h0.1c0,0,0,0,0,0.1c0,0.2-0.1,0.4-0.2,0.6h-1.6c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6c0,0.7,0.1,1.2,0.1,1.4
          c0,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1s-0.6,0-1.7,0.1
          c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2C355.8,103,355.8,102.7,355.8,102.2z'
        />
        <path
          d='M364.4,97.4c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1
          s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
          c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          C363.4,97.4,363.9,97.4,364.4,97.4z'
        />
        <path
          d='M373,99v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V99H373z M371.7,95.3
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S371.9,95.5,371.7,95.3z'
        />
        <path
          d='M379.4,97.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S379.1,97.2,379.4,97.2z'
        />
        <path
          d='M387.4,103c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
          c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
          c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3
          l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0
          s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L387.4,103
          L387.4,103z M386.7,104.1c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5
          c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3
          S387,104.1,386.7,104.1z M386.1,97.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
          c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C386.9,97.9,386.5,97.7,386.1,97.7z'
        />
        <path
          d='M322.5,113.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C321.8,113.4,322.2,113.2,322.5,113.2z'
        />
        <path
          d='M327.5,113.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C326.5,113.4,327,113.2,327.5,113.2z M326.7,119.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S326.4,119.3,326.7,119.3z'
        />
        <path
          d='M333,113.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5H333v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L333,113.3L333,113.3z'
        />
        <path
          d='M338.4,113.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4C337.2,113.4,337.8,113.2,338.4,113.2z'
        />
        <path
          d='M342.7,118.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S342.5,118.1,342.7,118.1z'
        />
        <path
          d='M352,113.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4C350.8,113.4,351.4,113.2,352,113.2z'
        />
        <path
          d='M359.7,113.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S359.4,113.2,359.7,113.2z'
        />
        <path
          d='M366.8,113.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C365.7,113.4,366.3,113.2,366.8,113.2z M365.9,119.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S365.6,119.3,365.9,119.3z'
        />
        <path
          d='M372.3,118.2c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4C372.7,118.2,372.3,118.2,372.3,118.2z'
        />
        <path
          d='M378.9,116.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C379.6,116.2,379,116.2,378.9,116.2z M379,115.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S378.9,115.6,379,115.6z'
        />
        <path
          d='M386.9,113.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4C385.8,113.4,386.3,113.2,386.9,113.2z'
        />
        <path
          d='M316,129.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          C314.9,129.4,315.4,129.2,316,129.2z'
        />
        <path
          d='M323.3,129.2c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C322.7,129.3,323,129.2,323.3,129.2z M322.6,130.1c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
          c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C323.8,130.4,323.2,130.1,322.6,130.1z'
        />
        <path
          d='M329.6,131v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6
          L329.6,131L329.6,131z M328.3,127.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S328.4,127.5,328.3,127.3z'
        />
        <path
          d='M335.3,129.2c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C334.5,129.3,334.9,129.2,335.3,129.2z
          M334.8,129.9c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4
          v-4c0-0.2-0.2-0.4-0.5-0.5S335.2,129.9,334.8,129.9z'
        />
        <path
          d='M340.7,132.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C341.5,132.2,340.9,132.2,340.7,132.2z M340.8,131.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S340.8,131.6,340.8,131.6z'
        />
        <path
          d='M350.4,129.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C349.8,129.4,350.1,129.2,350.4,129.2z'
        />
        <path
          d='M354.7,129.2c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          C353.6,129.4,354.1,129.2,354.7,129.2z'
        />
        <path
          d='M359.1,134.1c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S358.8,134.1,359.1,134.1z'
        />
        <path
          d='M366.9,129.4c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1
          s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7
          c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
          C365.8,129.4,366.3,129.4,366.9,129.4z'
        />
        <path
          d='M376.4,129.2c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C374.6,129.5,375.4,129.2,376.4,129.2z M376.2,129.7c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S376.6,129.7,376.2,129.7z'
        />
        <path
          d='M385.8,131.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H386c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.5-0.3-0.7
          c-0.1-0.3-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.5L385.8,131.1L385.8,131.1z'
        />
        <path
          d='M393.1,129.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C392.5,129.4,392.8,129.2,393.1,129.2z'
        />
        <path
          d='M306.2,145.2c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C305.6,145.3,305.9,145.2,306.2,145.2z M305.5,146.1c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C306.7,146.4,306.1,146.1,305.5,146.1z'
        />
        <path
          d='M313.4,145.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C312.4,145.4,312.9,145.2,313.4,145.2z M312.6,151.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S312.3,151.3,312.6,151.3z'
        />
        <path
          d='M321.1,145.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C320.4,145.4,320.8,145.2,321.1,145.2z'
        />
        <path
          d='M324.9,145.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L324.9,145.3L324.9,145.3z'
        />
        <path
          d='M332.2,145.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S331.9,145.2,332.2,145.2z'
        />
        <path
          d='M337.3,148.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C338,148.2,337.4,148.2,337.3,148.2z M337.3,147.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S337.3,147.6,337.3,147.6z'
        />
        <path
          d='M347,145.2c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C346.3,145.4,346.7,145.2,347,145.2z'
        />
        <path
          d='M358.9,146c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C358.9,145.9,358.9,145.9,358.9,146z'
        />
        <path
          d='M364.3,145.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S364,145.2,364.3,145.2z'
        />
        <path
          d='M369.3,148.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C370.1,148.2,369.5,148.2,369.3,148.2z M369.4,147.6h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S369.4,147.6,369.4,147.6z'
        />
        <path
          d='M378.1,145.2c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C377,145.4,377.6,145.2,378.1,145.2z M377.2,151.3c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S376.9,151.3,377.2,151.3z'
        />
        <path
          d='M383.5,145.3h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L383.5,145.3L383.5,145.3z'
        />
        <path
          d='M388.7,147v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6
          L388.7,147L388.7,147z M387.5,143.3c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
          c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S387.6,143.5,387.5,143.3z'
        />
        <path
          d='M395.2,145.2c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S394.8,145.2,395.2,145.2z'
        />
        <path
          d='M403.1,151c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
          c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
          c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
          c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2
          L403.1,151L403.1,151z M402.4,152.1c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
          s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3
          S402.8,152.1,402.4,152.1z M401.8,145.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
          c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C402.6,145.9,402.2,145.7,401.8,145.7z'
        />
      </g>
      <g id='sp' style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path
          d='M245.2,98.1c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S244.8,98.1,245.2,98.1z M244.7,98.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S245.1,98.7,244.7,98.7z'
        />
        <path
          d='M252.9,98.1c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S251.9,98.1,252.9,98.1z M252.7,98.6c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S253.1,98.6,252.7,98.6z'
        />
        <path
          d='M261.5,103.9c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5
          c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1
          c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3
          s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
          c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2
          L261.5,103.9L261.5,103.9z M260.8,105c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
          c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4
          c-0.2-0.1-0.4-0.2-0.7-0.3C261.5,105,261.2,105,260.8,105z M260.2,98.5c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
          s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S260.7,98.5,260.2,98.5z'
        />
        <path
          d='M267.2,98.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1
          c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          s-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4S266.6,98.1,267.2,98.1z'
        />
      </g>
      <g id='sp' style={{ opacity: selected === 'SP' ? 1 : 0 }}>
        <path
          d='M335.9,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S335.3,319,335.9,319z'
        />
        <path
          d='M343.7,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
			c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
			c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S343.4,319,343.7,319z'
        />
        <path
          d='M350.7,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
			s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
			s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
			s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S350.2,319.1,350.7,319.1z M349.9,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S349.6,325.2,349.9,325.2z'
        />
        <path
          d='M356.3,324.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
			c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V320c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
			h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
			c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
			c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
			s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4L356.3,324.1L356.3,324.1z'
        />
        <path
          d='M362.8,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S363,322,362.8,322z
			 M362.9,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S362.9,321.4,362.9,321.4z'
        />
        <path
          d='M370.9,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S370.3,319,370.9,319z'
        />
        <path
          d='M375.2,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
			s0.1-0.4,0.3-0.5S375,323.9,375.2,323.9z'
        />
        <path
          d='M384.5,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S383.9,319,384.5,319z'
        />
        <path
          d='M391.7,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
			s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
			c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S391.4,319,391.7,319z M391,319.9
			c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
			s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S391.7,319.9,391,319.9z'
        />
        <path
          d='M398,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L398,320.8L398,320.8z
			 M396.7,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			s-0.3,0.2-0.6,0.2S396.9,317.3,396.7,317.2z'
        />
        <path
          d='M403.7,319c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
			c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
			c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
			c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S403.3,319,403.7,319z M403.2,319.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
			s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S403.6,319.7,403.2,319.7z'
        />
        <path
          d='M409.1,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
			s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S409.3,322,409.1,322z
			 M409.2,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
			s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S409.2,321.4,409.2,321.4z'
        />
        <path
          d='M418.9,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
			c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
			c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S418.5,319,418.9,319z'
        />
        <path
          d='M423.2,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
			s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
			s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			S422.6,319,423.2,319z'
        />
      </g>
    </g>
  );
};

export default NetworkTexts;
