import React from 'react';

const BluePetal20 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_2)' }} d="M.16,24.46C-2.47,26,27.68,38.85,59.21,38.23S116.64,24.59,117.76,22c1.5-4.5-8.63-2.75-8.63-2.75h0c-3,.25-7.38.5-11.13.5-7.14,0-15.52-1.5-21.9-2.5L74.6,17c-4.75-.38-10.38-1.25-15.76-1.38C58.84,15.58,19.93,12.33.16,24.46Z" />
        <path className="cls-2" d="M13.3,21a47,47,0,0,1,10.26-.38,68.13,68.13,0,0,1,10,1.63c1.63.38,3.26,1.13,4.76,1.63s3.13,1.37,4.63,2,3.13,1.38,4.63,1.88a22.49,22.49,0,0,0,4.88,1.12,20.84,20.84,0,0,1-4.88-1A45,45,0,0,1,42.82,26c-1.5-.62-3.13-1.37-4.63-2a30.19,30.19,0,0,0-4.75-1.62,45.19,45.19,0,0,0-10-1.63,45.53,45.53,0,0,0-10.13.5Z" />
        <path className="cls-2" d="M13.3,21a45.55,45.55,0,0,1,10.13-.38,68.05,68.05,0,0,1,10,1.63c1.63.38,3.25,1.13,4.75,1.63l4.63,2,4.63,1.88a22.63,22.63,0,0,0,4.88,1.12V29a21.36,21.36,0,0,1-4.88-1,29.72,29.72,0,0,1-4.63-1.88,61.63,61.63,0,0,0-9.51-3.75,45.19,45.19,0,0,0-10-1.63,45.62,45.62,0,0,0-10.14.5h0Zm0,0v.25h0a38.89,38.89,0,0,1,10.13-.5,54.3,54.3,0,0,1,10,1.63A74.07,74.07,0,0,1,43,26.09c1.5.63,3.13,1.38,4.63,1.88a34.93,34.93,0,0,0,4.88,1v.12A16.28,16.28,0,0,1,47.58,28L43,26.09l-4.63-2c-1.63-.5-3.13-1.25-4.76-1.63a56,56,0,0,0-10-1.62A34.88,34.88,0,0,0,13.3,21Z" />
        <path className="cls-2" d="M60.71,21.09A6.14,6.14,0,0,1,64,22c1.12.5,1.87,1.13,3,1.63A13.13,13.13,0,0,0,70,25c1.13.25,2.25.38,3.25.5A13.66,13.66,0,0,1,70,25.09c-1.12-.25-2-.88-3.13-1.25a21.53,21.53,0,0,0-3-1.5,9.19,9.19,0,0,0-3.13-.63Z" /><path className="cls-2" d="M60.71,21.09A6.14,6.14,0,0,1,64,22l3,1.63A13.13,13.13,0,0,0,70,25l3.25.5v.13A13.65,13.65,0,0,1,70,25.21,32.37,32.37,0,0,1,66.84,24h0c-1-.5-1.87-1.12-2.87-1.5a8.9,8.9,0,0,0-3.13-.62h0Zm0,0v.5h0a8.31,8.31,0,0,1,3.13.62,22.69,22.69,0,0,1,3,1.5h0A32.37,32.37,0,0,0,70,25a15.33,15.33,0,0,0,3.25.38v.12L70,25a13.13,13.13,0,0,1-3-1.37L64,22A6.79,6.79,0,0,0,60.71,21.09Z" />
        <path className="cls-2" d="M.16,24.71C22.93,16.33,52.58,21,74.6,23.34c10.51,1.12,23.77,3.12,35.66.87a47.3,47.3,0,0,0,5.75-1.5,8.36,8.36,0,0,0,1.63-.62c-12.51,5.13-35.28,2.62-46.54.12-13.89-3.13-31.66-4.13-47-2.75A108.46,108.46,0,0,0,.16,24.71" /><path className="cls-2" d="M.16,24.71A89.92,89.92,0,0,1,14.3,21.09a94.77,94.77,0,0,1,14.51-1.26A224.74,224.74,0,0,1,58,21.34c9.76,1,19.39,2.25,29,3.12s19.64,1.13,28.9-2h0a8.28,8.28,0,0,0,1.62-.62l.13.25a48.16,48.16,0,0,1-14.51,2.75,117.45,117.45,0,0,1-14.77-.25c-4.88-.38-9.88-1.13-14.64-1.88a145.53,145.53,0,0,0-14.63-2.5,168.1,168.1,0,0,0-29.65-1A113.17,113.17,0,0,0,14.8,21,90.67,90.67,0,0,0,.16,24.71a111.65,111.65,0,0,1,14.39-3.87,94.05,94.05,0,0,1,14.76-1.76,167.68,167.68,0,0,1,29.65,1A143,143,0,0,1,73.6,22.59c4.88.87,9.88,1.5,14.76,1.87a115.59,115.59,0,0,0,14.77.25A52.17,52.17,0,0,0,117.64,22l.12.25a9,9,0,0,1-1.62.63h0c-9.39,3-19.4,2.87-28.9,1.87-9.76-1-19.4-2.25-29-3.12a253.88,253.88,0,0,0-29.27-1.38,93.64,93.64,0,0,0-14.52,1.25A60.83,60.83,0,0,0,.16,24.71Z" />
      </g>
    </g>
  );
};

export default BluePetal20;
