import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';

const Loader = () => {
  return (
    <div
      style={{ width: '100%', height: '100%', backgroundColor: '#43449a' }}
    >

    </div>
  );
};

export default Loader;
