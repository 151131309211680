import React from 'react';

const BluePetal11 = ({ id, value, selectedPetal }) => {
  return (
    <g
      className={`bluepetal blupetal-${id}`}
      style={{
        opacity: selectedPetal !== null ? (selectedPetal === id ? 1 : 0.5) : 1
      }}
    >
      <g id={`bluepetal-${id}`}>
        <path className="cls-1" style={{ fill: 'url(#blue_petal_gradient_11)' }} d="M.28,24C-4.35,26.74,49.57,49.76,105.87,48.5S208.59,24.23,210.46,19.35c2.75-8.13-15.39-4.88-15.39-4.88h0c-5.38.5-13.26,1-19.89,1-12.76,0-27.65-2.76-39.16-4.38l-2.75-.5c-8.63-.75-18.64-2.26-28.15-2.51C105.12,8.09,35.56,2.46.28,24Z" />
        <path className="cls-2" d="M23.8,17.85a75.62,75.62,0,0,1,18.39-.75,106.93,106.93,0,0,1,17.89,3,87.5,87.5,0,0,1,8.63,3c2.76,1,5.63,2.5,8.39,3.63a77.19,77.19,0,0,0,8.38,3.37,37.88,37.88,0,0,0,8.88,2,50.76,50.76,0,0,1-8.88-1.75c-2.75-.75-5.63-2.25-8.64-3.37-2.75-1.26-5.62-2.51-8.38-3.63a51.19,51.19,0,0,0-8.63-3,86.32,86.32,0,0,0-17.89-3,73.07,73.07,0,0,0-18.14,1Z" />
        <path className="cls-2" d="M23.8,17.85a73.09,73.09,0,0,1,18.14-.75,106.93,106.93,0,0,1,17.89,3,87.5,87.5,0,0,1,8.63,3l8.38,3.63,8.39,3.37a37.88,37.88,0,0,0,8.88,2v.25a50.76,50.76,0,0,1-8.88-1.75c-3-.75-5.63-2.25-8.39-3.37-5.62-2.51-11-5.13-16.88-6.64a86.42,86.42,0,0,0-17.9-3,73.07,73.07,0,0,0-18.14,1h0Zm0,0v.5h0a73.07,73.07,0,0,1,18.14-1,86.32,86.32,0,0,1,17.89,3c5.88,1.51,11.26,4.13,16.89,6.64a77.19,77.19,0,0,0,8.38,3.37A55.75,55.75,0,0,0,94,32.11v.25a35.26,35.26,0,0,1-8.88-2L76.72,27l-8.26-3.63c-3-1-5.63-2.25-8.63-3a111.4,111.4,0,0,0-17.89-3A73.63,73.63,0,0,0,23.8,17.85Z" />
        <path className="cls-2" d="M108.37,18.35a12.84,12.84,0,0,1,5.88,1.5c2,1,3.38,2,5.38,3a21.1,21.1,0,0,0,5.38,2.5,48.16,48.16,0,0,0,5.88,1,23.71,23.71,0,0,1-5.88-.75,52.82,52.82,0,0,1-5.63-2.25A37.25,37.25,0,0,0,114,20.6a15.27,15.27,0,0,0-5.63-1.25Z" />
        <path className="cls-2" d="M108.37,18.35a12.84,12.84,0,0,1,5.88,1.5l5.38,3a21.1,21.1,0,0,0,5.38,2.5l5.88,1v.25a23.71,23.71,0,0,1-5.88-.75,52.82,52.82,0,0,1-5.63-2.25h0a53.55,53.55,0,0,0-5.13-2.76,14.24,14.24,0,0,0-5.63-1.25h0Zm0,0v1h0A14.24,14.24,0,0,1,114,20.6a37.25,37.25,0,0,1,5.38,2.76h0A52.82,52.82,0,0,0,125,25.61a23.71,23.71,0,0,0,5.88.75v.25l-5.88-1a21.1,21.1,0,0,1-5.38-2.5l-5.38-3A10.68,10.68,0,0,0,108.37,18.35Z" />
        <path className="cls-2" d="M.28,24.48C40.94,9.6,93.86,17.85,133.39,22c18.9,2,42.42,5.63,63.69,1.5a77.82,77.82,0,0,0,10.25-2.75,15.37,15.37,0,0,0,3-1.25c-22.27,9-62.93,4.63-83.08.25-25-5.38-56.8-7.13-84.32-4.63C28.43,16.73,11.54,20.35.28,24.48" />
        <path className="cls-2" d="M.28,24.48A143.9,143.9,0,0,1,25.55,18.1a182.8,182.8,0,0,1,26-2.25c17.39-.5,34.78,1,52.17,2.75s34.53,3.88,51.92,5.63c17.14,1.5,35,2,51.67-3.63h0a15.37,15.37,0,0,0,3-1.25l.25.5c-8.39,3.13-17.14,4.38-26,4.88a187.88,187.88,0,0,1-26.52-.5c-8.88-.75-17.64-2-26.27-3.38a230.27,230.27,0,0,0-26.27-4.37,287.58,287.58,0,0,0-52.93-1.76A204.56,204.56,0,0,0,26.3,17.85a159.13,159.13,0,0,0-26,6.63A177.66,177.66,0,0,1,26.05,17.6a167.75,167.75,0,0,1,26.52-3.13,286.85,286.85,0,0,1,52.93,1.76c8.88,1,17.64,2.75,26.27,4.37A201.36,201.36,0,0,0,158,24a187.88,187.88,0,0,0,26.52.5,96.61,96.61,0,0,0,26-4.88l.25.5a15.18,15.18,0,0,1-3,1.26h0c-16.64,5.38-34.53,5.12-51.67,3.37-17.39-1.75-34.53-3.88-51.92-5.63S69.46,16,52.07,16.35a182.8,182.8,0,0,0-26,2.25A114.2,114.2,0,0,0,.28,24.48Z" />
      </g>
    </g>
  );
};

export default BluePetal11;
