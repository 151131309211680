import React from 'react';
import '../Network.scss';

const NetworkTexts = ({ selected }) => {
  return (
    <g id="subjects" transform="translate(-42,-40)">            

    
<g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path d="M241.5,98.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2S241.5,98.8,241.5,98.9z"/>
		<path d="M246.8,98.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C246.2,98.3,246.5,98.1,246.8,98.1z"/>
		<path d="M249.7,98.3c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C248.6,98.3,249.1,98.3,249.7,98.3z"/>
		<path d="M258.3,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6
			C258.4,99.9,258.3,99.9,258.3,99.9z M257,96.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
			c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S257.1,96.4,257,96.2z"/>
		<path d="M264.7,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2S261,99,260.9,99s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S264.3,98.1,264.7,98.1z"/>
		<path d="M272.7,103.9c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H274c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L272.7,103.9L272.7,103.9z M271.9,105c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C272.6,105,272.3,105,271.9,105z M271.3,98.5c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
			c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C272.1,98.7,271.8,98.5,271.3,98.5z"/>
	</g>
  <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path d="M271.8,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
			c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
			c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
			c0.2-0.4,0.4-0.8,0.8-1.1C271.2,408.6,271.5,408.4,271.8,408.4z"/>
		<path d="M276.8,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C275.8,408.5,276.3,408.4,276.8,408.4z M276,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S275.7,414.5,276,414.5z"/>
		<path d="M282.3,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L282.3,408.4L282.3,408.4z"/>
		<path d="M287.7,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C286.6,408.5,287.1,408.4,287.7,408.4z"/>
		<path d="M292.1,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S291.8,413.3,292.1,413.3z"/>
		<path d="M301.3,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C300.2,408.5,300.7,408.4,301.3,408.4z"/>
		<path d="M308,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C306.3,408.7,307.1,408.3,308,408.3z M307.8,408.9c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S308.3,408.9,307.8,408.9z"/>
		<path d="M317,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
			c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5
			c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
			v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C316.4,408.4,316.7,408.4,317,408.4z"/>
		<path d="M326.1,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C326.8,411.3,326.2,411.3,326.1,411.3z M326.1,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S326.1,410.7,326.1,410.7z"/>
		<path d="M335,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C333.3,408.7,334.1,408.3,335,408.3z M334.8,408.9c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S335.3,408.9,334.8,408.9z"/>
		<path d="M344,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S343.7,408.4,344,408.4z"/>
		<path d="M349.1,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C349.8,411.3,349.2,411.3,349.1,411.3z M349.1,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S349.1,410.7,349.1,410.7z"/>
	</g>
  <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path d="M153.6,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
			C154.7,415.2,154.2,415.3,153.6,415.3z M153.9,409.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
			v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C155.2,409.5,154.6,409.3,153.9,409.3z"/>
		<path d="M159.9,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C160.6,411.3,160,411.3,159.9,411.3z M159.9,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S159.9,410.7,159.9,410.7z"/>
		<path d="M167.7,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L167.7,410.1L167.7,410.1z
			 M166.4,406.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S166.6,406.6,166.4,406.5z"/>
		<path d="M174.1,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S173.8,408.4,174.1,408.4z"/>
		<path d="M182.1,414.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2h1.4V414.1z M181.4,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
			c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C182,415.3,181.7,415.3,181.4,415.3z M180.7,408.8c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C181.5,409,181.2,408.8,180.7,408.8z"/>
		<path d="M193.9,408.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C193.3,408.5,193.6,408.4,193.9,408.4z M193.2,409.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C194.3,409.5,193.8,409.3,193.2,409.3z"/>
		<path d="M202.1,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C201.4,408.6,201.7,408.4,202.1,408.4z"/>
		<path d="M205,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C205.7,411.3,205.1,411.3,205,411.3z M205,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S205,410.7,205,410.7z"/>
		<path d="M214.7,414.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H216c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2h1.4L214.7,414.1L214.7,414.1z M213.9,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C214.6,415.3,214.3,415.3,213.9,415.3z
			 M213.3,408.8c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C214.1,409,213.8,408.8,213.3,408.8z"/>
		<path d="M222.3,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S221.9,408.4,222.3,408.4z"/>
		<path d="M229.3,408.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C228.3,408.5,228.8,408.4,229.3,408.4z M228.5,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S228.2,414.5,228.5,414.5z"/>
		<path d="M237.3,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S237,408.4,237.3,408.4z"/>
		<path d="M243.1,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V409H241c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
			s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L243.1,408.4
			L243.1,408.4z"/>
		<path d="M247.1,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S246.8,413.3,247.1,413.3z"/>
		<path d="M171.6,424.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V425h-0.9c0,0,0,0,0-0.1
			s0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7
			c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2
			c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
			c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7V424.6z"/>
		<path d="M178.4,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C177.3,424.5,177.8,424.4,178.4,424.4z"/>
		<path d="M185.9,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S185.6,424.4,185.9,424.4z"/>
		<path d="M190.8,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S190.6,429.3,190.8,429.3z"/>
		<path d="M201.2,420.3c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
			c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1
			c-0.3,0-0.5,0.1-0.6,0.4s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1
			h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8
			C200.7,420.4,201,420.3,201.2,420.3z"/>
		<path d="M206.6,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C205.9,424.6,206.2,424.4,206.6,424.4z"/>
		<path d="M211.7,424.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
			s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C210,424.7,210.8,424.3,211.7,424.3z M211.5,424.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
			s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
			s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S212,424.9,211.5,424.9z"/>
		<path d="M220.4,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.1z M219.7,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
			c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C220.3,431.3,220,431.3,219.7,431.3z M219.1,424.8c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C219.9,425,219.5,424.8,219.1,424.8z"/>
		<path d="M226,424.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C224.9,424.5,225.4,424.4,226,424.4z"/>
		<path d="M230.4,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S230.1,429.3,230.4,429.3z"/>
		<path d="M156,440.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C154.9,440.5,155.4,440.4,156,440.4z"/>
		<path d="M163.8,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S163.4,440.4,163.8,440.4z"/>
		<path d="M170.8,440.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C169.8,440.5,170.3,440.4,170.8,440.4z M170,446.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S169.7,446.5,170,446.5z"/>
		<path d="M176.4,445.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
			c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
			c0,0-0.1,0-0.1-0.1H179h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
			c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
			s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
			c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
			v1.4L176.4,445.4L176.4,445.4z"/>
		<path d="M182.9,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C183.7,443.3,183.1,443.3,182.9,443.3z M183,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S183,442.7,183,442.7z"/>
		<path d="M190.9,440.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C189.8,440.5,190.3,440.4,190.9,440.4z"/>
		<path d="M195.3,445.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S195.1,445.3,195.3,445.3z"/>
		<path d="M204.5,440.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C203.4,440.5,203.9,440.4,204.5,440.4z"/>
		<path d="M211.8,440.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C211.2,440.5,211.5,440.4,211.8,440.4z M211.1,441.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C212.3,441.5,211.7,441.3,211.1,441.3z"/>
		<path d="M218.1,442.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L218.1,442.1L218.1,442.1z
			 M216.8,438.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S216.9,438.6,216.8,438.5z"/>
		<path d="M223.8,440.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H225
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C223,440.4,223.4,440.4,223.8,440.4z M223.3,441
			c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5S223.7,441,223.3,441z"/>
		<path d="M229.2,443.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C230,443.3,229.4,443.3,229.2,443.3z M229.3,442.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S229.3,442.7,229.3,442.7z"/>
		<path d="M239,440.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C238.3,440.6,238.6,440.4,239,440.4z"/>
		<path d="M243.2,440.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C242.1,440.5,242.6,440.4,243.2,440.4z"/>
	</g>
  <g id="en" style={{ opacity: selected === 'EN' ? 1 : 0 }}>
		<path d="M92.7,96.1c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
			c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
			c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
			C92,96.2,92.4,96.1,92.7,96.1z M92,97c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
			c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C93.1,97.2,92.6,97,92,97z"/>
		<path d="M100.5,96.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C99.8,96.3,100.2,96.1,100.5,96.1z"/>
		<path d="M103.1,99c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C103.9,99,103.2,99,103.1,99z M103.2,98.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C103.2,98.4,103.2,98.4,103.2,98.4z"/>
		<path d="M112.5,101.9c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H112.5z M111.7,103c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C112.4,103,112.1,103,111.7,103z
			 M111.1,96.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C111.9,96.7,111.6,96.5,111.1,96.5z"/>
		<path d="M119.8,96.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
			C119,96.2,119.4,96.1,119.8,96.1z"/>
		<path d="M126.5,96.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
			s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
			c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
			s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
			c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C125.5,96.2,126,96.1,126.5,96.1z M125.6,102.2c0.3,0,0.5-0.1,0.9-0.3
			c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
			c0,0.3,0.1,0.5,0.3,0.8S125.3,102.2,125.6,102.2z"/>
		<path d="M134.2,96.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
			C133.5,96.2,133.8,96.1,134.2,96.1z"/>
		<path d="M143.1,96.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			C143.1,96.7,143.1,96.8,143.1,96.9z"/>
		<path d="M145,96.3c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C143.9,96.2,144.5,96.3,145,96.3z"/>
		<path d="M151.5,101c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C151,101.1,151.3,101,151.5,101z"/>
		<path d="M163,96.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			C163,96.7,163,96.8,163,96.9z"/>
		<path d="M168.1,96.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C167.4,96.3,167.8,96.1,168.1,96.1z"/>
		<path d="M172.9,96c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1c-1,0-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5c0-1,0.3-1.8,1-2.5C171.2,96.4,172,96,172.9,96z M172.8,96.6c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.8-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.8,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2
			C173.6,96.7,173.2,96.6,172.8,96.6z"/>
		<path d="M182.6,96.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
			c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
			C182.5,96.7,182.6,96.8,182.6,96.9z"/>
		<path d="M186.8,96c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1c-1,0-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5c0-1,0.3-1.8,1-2.5C185.1,96.4,185.9,96,186.8,96z M186.7,96.6c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.8-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.8,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2
			C187.5,96.7,187.1,96.6,186.7,96.6z"/>
		<path d="M194.8,96.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
			c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
			c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C194.1,96.2,194.5,96.1,194.8,96.1z M194.3,96.7c-0.6,0-1,0.3-1.4,0.8
			c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5C195.1,96.8,194.8,96.7,194.3,96.7z"/>
		<path d="M201.1,97.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V97.8z M199.8,94.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
			s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S200,94.3,199.8,94.2z"/>
		<path d="M203.5,94.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2V94.2z"/>
		<path d="M207.5,99c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
			c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2
			c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
			s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C208.3,99,207.7,99,207.5,99z M207.6,98.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C207.6,98.4,207.6,98.4,207.6,98.4z"/>
		<path d="M215.2,96.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C214.1,96.3,214.6,96.1,215.2,96.1z"/>
		<path d="M219.3,101c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C218.8,101.1,219,101,219.3,101z"/>
		<path d="M112.6,112.4c1.1,0,1.9-0.1,2.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.2
			c-0.1,0.4-0.1,0.8-0.1,1.1v3.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.3,0,0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V113h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2
			c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5
			c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
			c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9
			c-0.3,0.6-0.5,1.2-0.5,2v0.8H112.6z"/>
		<path d="M120.8,112.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C120.1,112.3,120.5,112.1,120.8,112.1z"/>
		<path d="M123.4,115c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
			c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C124.2,115,123.5,115,123.4,115z M123.5,114.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C123.5,114.4,123.4,114.4,123.5,114.4z"/>
		<path d="M129.7,117c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C129.2,117.1,129.4,117,129.7,117z"/>
		<path d="M139.7,112.4c1.1,0,1.9-0.1,2.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.2
			c-0.1,0.4-0.1,0.8-0.1,1.1v3.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.3,0,0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V113h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2
			c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5
			c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
			c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9
			c-0.3,0.6-0.5,1.2-0.5,2v0.8H139.7z"/>
		<path d="M146.3,112.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C145.1,112.3,145.7,112.1,146.3,112.1z"/>
		<path d="M153.5,112.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
			C152.8,112.2,153.1,112.1,153.5,112.1z"/>
		<path d="M158.1,117c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C157.6,117.1,157.8,117,158.1,117z"/>
		<path d="M165.1,117.1c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1h-0.8c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.4c0.1-0.2,0.1-0.2,0.1-0.2h0.6
			c0.1,0,0.1,0,0.1-0.1v-0.2c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3c0.3-0.4,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
			c0.2,0,0.4,0.1,0.8,0.2c0.3,0.1,0.6,0.2,0.7,0.2c0.1,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.2-0.1h0
			c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.1c0,0.6,0,1.1,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2
			c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1c-0.1,0-0.6,0-1.7,0.1
			c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2c0.1,0,0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.3,0.1-0.7,0.1-1.1v-6
			c0-0.1,0-0.3-0.1-0.6c0-0.3-0.1-0.5-0.1-0.6c-0.1-0.2-0.3-0.5-0.7-0.8c-0.3-0.3-0.7-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.8,0.4
			c-0.2,0.3-0.4,0.6-0.5,1.1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8c0,0,0,0,0,0.1c0,0,0,0,0.1,0h1.7c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1
			c0,0.2-0.1,0.4-0.2,0.6h-1.6c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6c0,0.7,0.1,1.2,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2
			c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1c-0.1,0-0.6,0-1.7,0.1
			c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2c0.1,0,0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2C165.1,117.9,165.1,117.5,165.1,117.1z"/>
		<path d="M173.4,112.3c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C172.4,112.2,172.9,112.3,173.4,112.3z"/>
		<path d="M181.7,113.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V113.8z M180.4,110.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
			s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S180.6,110.3,180.4,110.2z"/>
		<path d="M187.8,112.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
			C187.1,112.2,187.5,112.1,187.8,112.1z"/>
		<path d="M195.4,117.9c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
			c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
			c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
			c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
			c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H195.4z M194.7,119c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C195.4,119,195.1,119,194.7,119z
			 M194.1,112.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C194.9,112.7,194.6,112.5,194.1,112.5z"/>
		<path d="M199.4,117c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C198.9,117.1,199.1,117,199.4,117z"/>
		<path d="M100,128.1c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9
			c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2
			c-0.3,0.1-0.5,0.3-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3
			c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.5-0.4,0.9-0.7,1.2-0.8C99.4,128.1,99.7,128.1,100,128.1
			z"/>
		<path d="M111,128c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1c-1,0-1.8-0.3-2.4-1
			c-0.6-0.6-0.9-1.5-0.9-2.5c0-1,0.3-1.8,1-2.5C109.3,128.4,110.1,128,111,128z M110.8,128.6c-0.4,0-0.8,0.1-1.1,0.4
			c-0.3,0.3-0.5,0.6-0.6,1c-0.1,0.4-0.2,0.8-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
			c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.8,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2
			C111.7,128.7,111.3,128.6,110.8,128.6z"/>
		<path d="M119.7,128.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
			C119,128.2,119.4,128.1,119.7,128.1z"/>
		<path d="M124.4,131c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
			c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C125.2,131,124.5,131,124.4,131z M124.5,130.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C124.5,130.4,124.5,130.4,124.5,130.4z"/>
		<path d="M130.6,128.3c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C129.6,128.2,130.1,128.3,130.6,128.3z"/>
		<path d="M137.2,133c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C136.7,133.1,136.9,133,137.2,133z"/>
		<path d="M147.5,128.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C146.8,128.3,147.1,128.1,147.5,128.1z"/>
		<path d="M152.1,128.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
			s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
			c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
			s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
			c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C151.1,128.2,151.6,128.1,152.1,128.1z M151.3,134.2
			c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
			c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S151,134.2,151.3,134.2z"/>
		<path d="M157.3,128.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9
			c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4
			c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0s0,0,0,0V128.2z"/>
		<path d="M162.4,128.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C161.2,128.3,161.8,128.1,162.4,128.1z"/>
		<path d="M166.4,133c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C165.9,133.1,166.2,133,166.4,133z"/>
		<path d="M175,128.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C173.9,128.3,174.4,128.1,175,128.1z"/>
		<path d="M182.4,128.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
			s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
			c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
			c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
			C181.7,128.2,182.1,128.1,182.4,128.1z"/>
		<path d="M189.2,128.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
			s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
			c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
			s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
			c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C188.1,128.2,188.7,128.1,189.2,128.1z M188.3,134.2
			c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
			c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S188,134.2,188.3,134.2z"/>
		<path d="M194.4,133.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
			c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
			c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2
			c0-0.1,0.1-0.1,0.1-0.1s0-0.1,0-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0
			c0,0-0.1,0-0.1,0c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.4-0.1c0,0,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
			c0.5,0.5,0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2
			c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4
			c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0,0V133.1z"/>
		<path d="M200.6,131c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
			c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C201.4,131,200.8,131,200.6,131z M200.7,130.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C200.7,130.4,200.7,130.4,200.7,130.4z"/>
		<path d="M208.3,128.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C207.2,128.3,207.7,128.1,208.3,128.1z"/>
		<path d="M212.4,133c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C211.9,133.1,212.1,133,212.4,133z"/>
		<path d="M115.7,144.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C114.6,144.3,115.1,144.1,115.7,144.1z"/>
		<path d="M122.6,144.1c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
			c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
			c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
			C122,144.2,122.4,144.1,122.6,144.1z M122,145c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
			c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C123.1,145.2,122.6,145,122,145z"/>
		<path d="M128.6,145.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V145.8z M127.3,142.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
			s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S127.5,142.3,127.3,142.2z"/>
		<path d="M134,144.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
			c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
			c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
			c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C133.2,144.2,133.6,144.1,134,144.1z M133.5,144.7c-0.6,0-1,0.3-1.4,0.8
			c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5C134.3,144.8,133.9,144.7,133.5,144.7z"/>
		<path d="M139.1,147c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
			c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C139.9,147,139.3,147,139.1,147z M139.2,146.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C139.2,146.4,139.2,146.4,139.2,146.4z"/>
		<path d="M148.5,144.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C147.9,144.3,148.2,144.1,148.5,144.1z"/>
		<path d="M152.5,144.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
			c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
			c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
			c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
			c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
			c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
			c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C151.4,144.3,151.9,144.1,152.5,144.1z"/>
		<path d="M156.5,149c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
			c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C156.1,149.1,156.3,149,156.5,149z"/>
		<path d="M163.7,144.3c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2
			c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8
			c0.1-0.3,0.3-0.7,0.4-1.1c0.2-0.4,0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.1,0,0.3,0,0.6,0
			c0.3,0,0.6,0,0.8,0c0,0,0.6,0,1.6-0.1c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2
			c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1
			c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.1,0,0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.5,0
			c0.2,0,0.3,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6
			c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2
			c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2
			c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S163.5,144.3,163.7,144.3z"/>
		<path d="M176.7,144.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
			c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
			s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
			c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
			s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
			c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
			c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C175.6,144.2,176.2,144.1,176.7,144.1z M175.8,150.2
			c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
			c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S175.5,150.2,175.8,150.2z"/>
		<path d="M181.8,144.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4
			c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
			c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9
			c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4
			c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0s0,0,0,0V144.2z"/>
		<path d="M185.6,147c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
			c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
			c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
			c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C186.4,147,185.7,147,185.6,147z M185.7,146.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			c-0.1,0.2-0.1,0.3-0.2,0.5C185.7,146.4,185.7,146.4,185.7,146.4z"/>
		<path d="M195,144.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
			c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
			C194.3,144.3,194.7,144.1,195,144.1z"/>
	</g>
		
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M341.2,319c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
          s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
          c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
          c0.5-0.4,0.9-0.7,1.2-0.8C340.6,319.1,340.9,319,341.2,319z"/>
        <path d="M352.5,319c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
          s0.3-1.8,1-2.5S351.5,319,352.5,319z M352.3,319.5c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
          s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
          S352.8,319.5,352.3,319.5z"/>
        <path d="M361.9,320.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
          s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
          h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2V325v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
          s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
          s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.4H361.9z"/>
        <path d="M367.6,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S367,319,367.6,319z"/>
        <path d="M372,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S372.1,322,372,322z
          M372.1,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S372.1,321.4,372.1,321.4z"/>
        <path d="M378.7,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S378.4,323.9,378.7,323.9z"/>
        <path d="M387.9,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S387.3,319,387.9,319z"/>
        <path d="M395.1,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S394.8,319,395.1,319z M394.5,319.9
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S395.1,319.9,394.5,319.9z"/>
        <path d="M401.4,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H401.4z M400.1,317.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6s-0.3,0.2-0.6,0.2
          S400.3,317.3,400.1,317.2z"/>
        <path d="M407.1,319c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S406.8,319,407.1,319z M406.6,319.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
          s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S407,319.7,406.6,319.7z"/>
        <path d="M412.6,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S412.7,322,412.6,322z
          M412.6,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S412.6,321.4,412.6,321.4z"/>
        <path d="M422.3,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S422,319,422.3,319z"/>
      </g>
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M276.4,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C276.4,409,276.4,409.1,276.4,409.2z"/>
        <path d="M281.1,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C279.3,408.7,280.1,408.3,281.1,408.3z M280.9,408.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S281.3,408.9,280.9,408.9z"/>
        <path d="M291,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C291,409,291,409.1,291,409.2z"/>
        <path d="M294.3,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H294.3z"/>
        <path d="M304.5,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C303.8,408.6,304.2,408.4,304.5,408.4z"/>
        <path d="M309.6,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C307.9,408.7,308.7,408.3,309.6,408.3z M309.5,408.9c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S309.9,408.9,309.5,408.9z"/>
        <path d="M317.4,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C316.4,408.5,316.9,408.4,317.4,408.4z M316.5,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S316.3,414.5,316.5,414.5z"/>
        <path d="M326.3,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C326.3,409,326.3,409.1,326.3,409.2z"/>
        <path d="M331.8,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S331.4,408.4,331.8,408.4z"/>
        <path d="M336.8,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C337.5,411.3,336.9,411.3,336.8,411.3z M336.8,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S336.8,410.7,336.8,410.7z"/>
        <path d="M344.8,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C343.7,408.5,344.2,408.4,344.8,408.4z"/>
        <path d="M349.2,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S348.9,413.3,349.2,413.3z"/>
        <path d="M277.2,420.3c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
          c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
          s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1
          c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-1,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.8
          C276.6,420.4,276.9,420.3,277.2,420.3z"/>
        <path d="M281.5,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C280.5,424.5,281,424.4,281.5,424.4z M280.7,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S280.4,430.5,280.7,430.5z"/>
        <path d="M285.9,422.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V422.5z"/>
        <path d="M289.9,422.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V422.5z"/>
        <path d="M295.3,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H295.3z M294,422.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S294.2,422.6,294,422.5z"/>
        <path d="M301.7,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S301.4,424.4,301.7,424.4z"/>
        <path d="M309.7,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H311c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
          c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
          c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.1z M309,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C309.6,431.3,309.3,431.3,309,431.3z M308.4,424.8
          c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
          c0-0.5-0.1-1-0.4-1.4C309.2,425,308.8,424.8,308.4,424.8z"/>
        <path d="M319.5,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V425h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L319.5,424.4L319.5,424.4z"/>
        <path d="M323.6,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C324.3,427.3,323.7,427.3,323.6,427.3z M323.6,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S323.6,426.7,323.6,426.7z"/>
        <path d="M330.3,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C331.1,427.3,330.4,427.3,330.3,427.3z M330.4,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S330.3,426.7,330.4,426.7z"/>
        <path d="M337.8,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V425h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L337.8,424.4L337.8,424.4z"/>
        <path d="M344.9,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S344.6,424.4,344.9,424.4z"/>
      </g>
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M164.2,406.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L164.2,406.5L164.2,406.5z"/>
        <path d="M169.6,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H169.6z M168.3,406.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S168.5,406.6,168.3,406.5z"/>
        <path d="M177,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C177,409,177,409.1,177,409.2z"/>
        <path d="M179.3,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C180.1,411.3,179.5,411.3,179.3,411.3z M179.4,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S179.4,410.7,179.4,410.7z"/>
        <path d="M186,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S185.7,413.3,186,413.3z"/>
        <path d="M197.2,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C196.6,408.4,196.9,408.4,197.2,408.4z"/>
        <path d="M208.5,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C206.7,408.7,207.5,408.3,208.5,408.3z M208.3,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S208.7,408.9,208.3,408.9z"/>
        <path d="M217.9,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7c-0.1-0.3-0.1-0.6-0.2-0.8
          s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
          c0.3-0.2,0.5-0.4,0.5-0.6v-3.5H217.9z"/>
        <path d="M223.6,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C222.4,408.5,222.9,408.4,223.6,408.4z"/>
        <path d="M228,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C228.8,411.3,228.1,411.3,228,411.3z M228.1,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S228.1,410.7,228.1,410.7z"/>
        <path d="M234.6,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S234.4,413.3,234.6,413.3z"/>
        <path d="M191.6,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C190.9,424.6,191.3,424.4,191.6,424.4z"/>
        <path d="M196.6,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C195.6,424.5,196.1,424.4,196.6,424.4z M195.7,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S195.5,430.5,195.7,430.5z"/>
        <path d="M202.4,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H202.4z M201.1,422.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S201.3,422.6,201.1,422.5z"/>
        <path d="M208.8,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S208.5,424.4,208.8,424.4z"/>
      </g>
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
        <path d="M341.2,145.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C341.2,144.9,341.2,145,341.2,145.1z"/>
        <path d="M346.6,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C346,144.5,346.3,144.3,346.6,144.3z"/>
        <path d="M349.5,144.5c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C348.4,144.5,348.9,144.5,349.5,144.5z"/>
        <path d="M358,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H358z
          M356.8,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
          s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S356.9,142.6,356.8,142.4z"/>
        <path d="M364.5,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S364.1,144.3,364.5,144.3z"/>
        <path d="M372.4,150.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
          s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
          c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
          c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L372.4,150.1L372.4,150.1z M371.7,151.2c-0.9,0-1.4,0-1.5,0.1
          c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
          s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
          C372.4,151.2,372.1,151.2,371.7,151.2z M371.1,144.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
          s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C371.9,144.9,371.5,144.7,371.1,144.7z"/>
      </g>
      <g id="ar" style={{ opacity: selected === 'AR' ? 1 : 0 }}>
		<path d="M119.9,124.2c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
			c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
			s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-1,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.8
			C119.4,124.3,119.7,124.2,119.9,124.2z"/>
		<path d="M124.3,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C123.3,128.4,123.8,128.3,124.3,128.3z M123.5,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S123.2,134.4,123.5,134.4z"/>
		<path d="M128.7,126.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0c-0.2,0-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1c0.2,0,0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L128.7,126.4L128.7,126.4z
			"/>
		<path d="M132.7,126.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L132.7,126.4L132.7,126.4z"/>
		<path d="M138.1,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H138.1
			z M136.8,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S137,126.6,136.8,126.4z"/>
		<path d="M144.5,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S144.2,128.3,144.5,128.3z"/>
		<path d="M152.5,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L152.5,134.1L152.5,134.1z M151.8,135.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C152.4,135.2,152.1,135.2,151.8,135.2z
			 M151.1,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C152,128.9,151.6,128.7,151.1,128.7z"/>
		<path d="M162.3,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H162.3z"/>
		<path d="M166.3,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C167.1,131.2,166.5,131.2,166.3,131.2z M166.4,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S166.4,130.7,166.4,130.7z"/>
		<path d="M173.1,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C173.8,131.2,173.2,131.2,173.1,131.2z M173.1,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S173.1,130.7,173.1,130.7z"/>
		<path d="M180.5,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H180.5z"/>
		<path d="M187.7,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S187.4,128.3,187.7,128.3z"/>
		<path d="M192.6,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S192.4,133.2,192.6,133.2z"/>
		<path d="M145.6,142.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
			c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
			c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
			s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
			L145.6,142.4L145.6,142.4z"/>
		<path d="M151,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H151z
			 M149.7,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S149.9,142.6,149.7,142.4z"/>
		<path d="M158.3,145.1c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
			c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
			c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4
			c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C158.3,144.9,158.3,145,158.3,145.1z"/>
		<path d="M160.7,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C161.5,147.2,160.8,147.2,160.7,147.2z M160.8,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S160.8,146.7,160.8,146.7z"/>
	</g>
    
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M216.7,98.1c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C216.1,98.2,216.4,98.1,216.7,98.1z"/>
        <path d="M228,98.1c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C226.3,98.4,227,98.1,228,98.1z M227.8,98.6c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S228.3,98.6,227.8,98.6z"/>
        <path d="M237.4,99.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7V104
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5s-0.2-0.5-0.3-0.7c-0.1-0.3-0.1-0.6-0.2-0.8
          s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
          c0.3-0.2,0.5-0.4,0.5-0.6v-3.5H237.4z"/>
        <path d="M243.1,98.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C241.9,98.3,242.5,98.1,243.1,98.1z"/>
        <path d="M247.5,101.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C248.3,101,247.7,101.1,247.5,101.1z M247.6,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S247.6,100.5,247.6,100.5z"/>
        <path d="M254.2,103c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S253.9,103,254.2,103z"/>
        <path d="M263.4,98.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4C262.3,98.3,262.8,98.1,263.4,98.1z"/>
        <path d="M270.6,98.1c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2V100
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6
          C270,98.2,270.4,98.1,270.6,98.1z M270,99c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
          c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C271.1,99.3,270.6,99,270,99
          z"/>
        <path d="M276.9,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H276.9z M275.6,96.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S275.8,96.4,275.6,96.2z"/>
        <path d="M282.6,98.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7
          c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
          c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3v-0.5
          c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
          c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C281.9,98.2,282.3,98.1,282.6,98.1z M282.1,98.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
          c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S282.5,98.7,282.1,98.7z"/>
        <path d="M288.1,101.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C288.8,101,288.2,101.1,288.1,101.1z M288.2,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S288.1,100.5,288.2,100.5z"/>
        <path d="M297.8,98.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
          c0.2-0.4,0.4-0.8,0.8-1.1C297.1,98.3,297.5,98.1,297.8,98.1z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M90.2,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4S88.3,326,88,326c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S90.2,319.8,90.2,319.8z"/>
        <path d="M94.6,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1H97h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S94.1,319.1,94.6,319.1z M93.8,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S93.5,325.2,93.8,325.2z"/>
        <path d="M100.1,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H98c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0C100.1,317,100.1,319.1,100.1,319.1z"/>
        <path d="M104.1,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S103.9,323.9,104.1,323.9z"/>
        <path d="M114.8,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S114.6,319,114.8,319z M114.2,319.9
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S114.8,319.9,114.2,319.9z"/>
        <path d="M123,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S122.7,319,123,319z"/>
        <path d="M125.9,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C126.7,322,126.1,322,125.9,322z M126,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4
          s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S126,321.4,126,321.4z"/>
        <path d="M135.6,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M134.9,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S135.2,325.9,134.9,325.9z M134.3,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S134.7,319.5,134.3,319.5z"/>
        <path d="M143.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S142.9,319,143.2,319z"/>
        <path d="M150.3,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S149.8,319.1,150.3,319.1z M149.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S149.1,325.2,149.4,325.2z"/>
        <path d="M158.3,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S157.9,319,158.3,319z"/>
        <path d="M167.5,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S167.5,319.8,167.5,319.8z"/>
        <path d="M169.7,319.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C168.7,319.2,169.2,319.2,169.7,319.2z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
        <path d="M152.3,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C151.7,408.4,152,408.4,152.3,408.4z"/>
        <path d="M163.6,408.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C161.8,408.7,162.6,408.3,163.6,408.3z M163.4,408.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S163.9,408.9,163.4,408.9z"/>
        <path d="M173,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7c-0.1-0.3-0.1-0.6-0.2-0.8
          s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
          c0.3-0.2,0.5-0.4,0.5-0.6v-3.5H173z"/>
        <path d="M178.7,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
          c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
          c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
          c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
          c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C177.5,408.5,178.1,408.4,178.7,408.4z"/>
        <path d="M183.1,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C183.9,411.3,183.2,411.3,183.1,411.3z M183.2,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S183.2,410.7,183.2,410.7z"/>
        <path d="M189.8,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S189.5,413.3,189.8,413.3z"/>
        <path d="M200.5,408.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C199.9,408.5,200.2,408.4,200.5,408.4z M199.8,409.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C200.9,409.5,200.4,409.3,199.8,409.3z"/>
        <path d="M205.6,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C206.4,411.3,205.8,411.3,205.6,411.3z M205.7,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S205.7,410.7,205.7,410.7z"/>
        <path d="M215.4,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C214.7,408.6,215,408.4,215.4,408.4z"/>
        <path d="M220.8,404.3c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
          c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1
          c-0.3,0-0.5,0.1-0.6,0.4s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1
          h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8
          C220.3,404.4,220.6,404.3,220.8,404.3z"/>
        <path d="M226.9,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7c-0.1-0.3-0.1-0.6-0.2-0.8
          s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
          c0.3-0.2,0.5-0.4,0.5-0.6v-3.5H226.9z"/>
        <path d="M234.4,408.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C233.9,408.4,234.2,408.4,234.4,408.4z"/>
        <path d="M243.5,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C244.3,411.3,243.7,411.3,243.5,411.3z M243.6,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S243.6,410.7,243.6,410.7z"/>
        <path d="M250.2,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S249.9,413.3,250.2,413.3z"/>
        <path d="M154.9,424.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
          c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
          c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
          c0.2-0.2,0.6-0.4,0.9-0.6C154.3,424.5,154.6,424.4,154.9,424.4z M154.2,425.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
          v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
          C155.3,425.5,154.8,425.3,154.2,425.3z"/>
        <path d="M163,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C162.4,424.6,162.7,424.4,163,424.4z"/>
        <path d="M166,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C166.7,427.3,166.1,427.3,166,427.3z M166,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S166,426.7,166,426.7z"/>
        <path d="M175.6,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.1z M174.9,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C175.6,431.3,175.3,431.3,174.9,431.3z M174.3,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C175.1,425,174.8,424.8,174.3,424.8z"/>
        <path d="M183.3,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S182.9,424.4,183.3,424.4z"/>
        <path d="M190.3,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C189.3,424.5,189.8,424.4,190.3,424.4z M189.5,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S189.2,430.5,189.5,430.5z"/>
        <path d="M198.3,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S198,424.4,198.3,424.4z"/>
        <path d="M207.6,425.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C207.5,425,207.6,425.1,207.6,425.2z"/>
        <path d="M209.8,424.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C208.7,424.5,209.2,424.6,209.8,424.6z"/>
        <path d="M216.6,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S216.4,429.3,216.6,429.3z"/>
        <path d="M227.5,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C226.9,424.6,227.2,424.4,227.5,424.4z"/>
        <path d="M231.6,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H231.6z M230.3,422.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S230.5,422.6,230.3,422.5z"/>
        <path d="M238,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S237.7,424.4,238,424.4z"/>
        <path d="M246,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.1z M245.3,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C246,431.3,245.6,431.3,245.3,431.3z M244.7,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C245.5,425,245.1,424.8,244.7,424.8z"/>
      </g>
      <g id="fr" style={{ opacity: selected === 'FR' ? 1 : 0 }}>
		<path d="M286.1,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
			c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
			s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C286,409,286.1,409.1,286.1,409.2z"/>
		<path d="M290.5,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C289.4,408.5,290,408.4,290.5,408.4z M289.6,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S289.3,414.5,289.6,414.5z"/>
		<path d="M295.9,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L295.9,408.4L295.9,408.4z"/>
		<path d="M299.9,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S299.7,413.3,299.9,413.3z"/>
		<path d="M309.2,408.4c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
			c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
			s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3
			c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
			c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7
			c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C308,408.5,308.6,408.4,309.2,408.4z"/>
		<path d="M316.9,408.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S316.6,408.4,316.9,408.4z"/>
		<path d="M324,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C322.9,408.5,323.5,408.4,324,408.4z M323.1,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S322.8,414.5,323.1,414.5z"/>
		<path d="M329.5,413.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
			c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1v-0.1
			c0,0-0.1,0-0.1-0.1h-0.1H332h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
			c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
			s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
			c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
			v1.4H329.5z"/>
		<path d="M336.1,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C336.8,411.3,336.2,411.3,336.1,411.3z M336.1,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S336.1,410.7,336.1,410.7z"/>
	</g>
    

      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M221.2,82.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C221.2,82.7,221.2,82.8,221.2,82.9z"/>
        <path d="M225.9,82.1c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C224.1,82.4,224.9,82.1,225.9,82.1z M225.7,82.6c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S226.1,82.6,225.7,82.6z"/>
        <path d="M235.8,82.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
          s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
          c0,0,0.1,0.1,0.1,0.2C235.8,82.7,235.8,82.8,235.8,82.9z"/>
        <path d="M239.1,87.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2C238,88,238,87.6,238,87v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1
          c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1V83
          c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1
          c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
          s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0
          v1.4H239.1z"/>
        <path d="M249.3,82.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C248.6,82.3,249,82.1,249.3,82.1z"/>
        <path d="M254.4,82.1c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C252.7,82.4,253.5,82.1,254.4,82.1z M254.3,82.6c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          c0.1-0.4,0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S254.7,82.6,254.3,82.6z"/>
        <path d="M262.2,82.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C261.2,82.3,261.7,82.1,262.2,82.1z M261.3,88.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S261,88.2,261.3,88.2z"/>
        <path d="M271.1,82.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C271.1,82.7,271.1,82.8,271.1,82.9z"/>
        <path d="M276.6,82.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S276.2,82.1,276.6,82.1z"/>
        <path d="M281.6,85.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C282.3,85,281.7,85.1,281.6,85.1z M281.6,84.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S281.6,84.5,281.6,84.5z"/>
        <path d="M289.6,82.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
          s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0S288.2,89,288,89c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
          c0-0.6,0.2-1,0.7-1.4C288.5,82.3,289,82.1,289.6,82.1z"/>
        <path d="M294,87c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          S293,88,293,87.8s0.1-0.4,0.3-0.5S293.7,87,294,87z"/>
        <path d="M222,94c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
          c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1
          c-0.3,0-0.5,0.1-0.6,0.4s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4
          h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8
          C221.4,94.1,221.7,94,222,94z"/>
        <path d="M226.3,98.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C225.3,98.3,225.8,98.1,226.3,98.1z M225.5,104.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S225.2,104.2,225.5,104.2z"/>
        <path d="M230.7,96.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L230.7,96.2L230.7,96.2z"/>
        <path d="M234.7,96.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L234.7,96.2L234.7,96.2z"/>
        <path d="M240.1,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H240.1z M238.8,96.2
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S239,96.4,238.8,96.2z"/>
        <path d="M246.5,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S246.2,98.1,246.5,98.1z"/>
        <path d="M254.5,103.9c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
          s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5
          s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9
          c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7
          s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1
          l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
          c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L254.5,103.9L254.5,103.9z
          M253.8,105c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3
          c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4
          s-0.4-0.2-0.7-0.3C254.4,105,254.1,105,253.8,105z M253.2,98.5c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
          c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C254,98.7,253.6,98.5,253.2,98.5z"/>
        <path d="M264.3,98.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L264.3,98.2L264.3,98.2z"/>
        <path d="M268.4,101.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C269.1,101,268.5,101.1,268.4,101.1z M268.4,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S268.4,100.5,268.4,100.5z"/>
        <path d="M275.1,101.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C275.8,101,275.2,101.1,275.1,101.1z M275.2,100.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S275.1,100.5,275.2,100.5z"/>
        <path d="M282.6,98.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L282.6,98.2L282.6,98.2z"/>
        <path d="M289.7,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
          c0.2-0.2,0.5-0.5,1-0.7S289.4,98.1,289.7,98.1z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M355.3,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S355.3,319.8,355.3,319.8z"/>
        <path d="M359.7,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S359.2,319.1,359.7,319.1z M358.9,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S358.6,325.2,358.9,325.2z"/>
        <path d="M365.2,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H363c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L365.2,319.1L365.2,319.1z"/>
        <path d="M369.2,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S369,323.9,369.2,323.9z"/>
        <path d="M378.4,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
          s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
          s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
          c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          S377.8,319,378.4,319z"/>
        <path d="M386.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S385.9,319,386.2,319z"/>
        <path d="M393.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S392.7,319.1,393.2,319.1z M392.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S392.1,325.2,392.4,325.2z"/>
        <path d="M398.8,324.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
          s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6c0.2-0.1,0.6-0.4,1.3-1
          c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2l0.1-0.1V320c0,0-0.1,0-0.1-0.1s-0.1,0-0.1,0h-0.1h-0.1
          h-0.1H401c0-0.1-0.1-0.1,0-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2
          c-0.1,0-0.3,0-0.6,0.1s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2s0.5,0.5,0.6,0.6
          c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
          s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4H398.8z"/>
        <path d="M405.3,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S405.5,322,405.3,322z
          M405.4,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
          s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S405.4,321.4,405.4,321.4z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M126.7,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S126.7,319.8,126.7,319.8z"/>
        <path d="M131.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          c-0.3,0-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S130.6,319.1,131.2,319.1z M130.3,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S130,325.2,130.3,325.2z"/>
        <path d="M136.6,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0v2.1H136.6z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M174.4,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
          s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
          c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
          v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
          s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
          C175.5,415.2,174.9,415.3,174.4,415.3z M174.7,409.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
          v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
          C175.9,409.5,175.4,409.3,174.7,409.3z"/>
        <path d="M184.3,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
          c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
          s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
          c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7c-0.1-0.3-0.1-0.6-0.2-0.8
          s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
          c0.3-0.2,0.5-0.4,0.5-0.6v-3.5H184.3z"/>
        <path d="M189.4,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H189.4z"/>
        <path d="M194.3,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H194.3z"/>
        <path d="M198.4,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C199.2,411.3,198.5,411.3,198.4,411.3z M198.5,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S198.4,410.7,198.5,410.7z"/>
        <path d="M208.1,408.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C207.4,408.6,207.8,408.4,208.1,408.4z"/>
        <path d="M210.9,413.4c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1H210c0,0,0,0,0-0.1s0-0.2,0.1-0.4c0.1-0.2,0.1-0.2,0.1-0.2h0.6
          c0.1,0,0.1,0,0.1-0.1v-0.2c0-0.5,0.1-1.1,0.3-1.6s0.4-0.9,0.7-1.3s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.2,0,0.4,0.1,0.8,0.2
          c0.3,0.1,0.6,0.2,0.7,0.2c0.1,0,0.3,0,0.5-0.1s0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.2-0.1l0,0c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v7.1c0,0.6,0,1.1,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3
          c-1-0.1-1.6-0.1-1.7-0.1c-0.1,0-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2
          c0.1-0.3,0.1-0.7,0.1-1.1v-6c0-0.1,0-0.3-0.1-0.6s-0.1-0.5-0.1-0.6c-0.1-0.2-0.3-0.5-0.7-0.8c-0.3-0.3-0.7-0.4-1.1-0.4
          c-0.3,0-0.6,0.1-0.8,0.4s-0.4,0.6-0.5,1.1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8c0,0,0,0,0,0.1c0,0,0,0,0.1,0h1.7h0.1c0,0,0,0,0,0.1
          c0,0.2-0.1,0.4-0.2,0.6H212c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6c0,0.7,0.1,1.2,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.5,0.1
          c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1c-0.1,0-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2
          s0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2C210.8,414.2,210.9,413.8,210.9,413.4z"/>
        <path d="M219.5,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
          c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
          c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
          c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
          c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
          c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C218.4,408.5,218.9,408.6,219.5,408.6z"/>
        <path d="M226.3,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S226.1,413.3,226.3,413.3z"/>
        <path d="M153.5,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.1z M152.8,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C153.4,431.3,153.1,431.3,152.8,431.3z M152.1,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C152.9,425,152.6,424.8,152.1,424.8z"/>
        <path d="M157.8,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C158.6,427.3,157.9,427.3,157.8,427.3z M157.9,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S157.9,426.7,157.9,426.7z"/>
        <path d="M165.3,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V425h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H165.3z"/>
        <path d="M170.1,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V425H168c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L170.1,424.4
          L170.1,424.4z"/>
        <path d="M175.3,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H175.3z M174.1,422.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S174.2,422.6,174.1,422.5z"/>
        <path d="M181.7,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S181.4,424.4,181.7,424.4z"/>
        <path d="M189.7,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1H191c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.1z M189,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C189.7,431.3,189.4,431.3,189,431.3z M188.4,424.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C189.2,425,188.8,424.8,188.4,424.8z"/>
        <path d="M202,424.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C201.4,424.4,201.7,424.4,202,424.4z"/>
        <path d="M213.1,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C212.1,424.5,212.6,424.4,213.1,424.4z M212.3,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S212,430.5,212.3,430.5z"/>
        <path d="M220.8,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C220.1,424.6,220.5,424.4,220.8,424.4z"/>
        <path d="M226.8,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
          c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
          c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
          c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C226.1,424.6,226.5,424.4,226.8,424.4z"/>
        <path d="M230.9,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H230.9z M229.6,422.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S229.7,422.6,229.6,422.5z"/>
        <path d="M234,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
          s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
          C234.8,427.3,234.1,427.3,234,427.3z M234.1,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
          S234.1,426.7,234.1,426.7z"/>
        <path d="M243.3,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
          c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
          c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
          s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
          c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
          s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C242.6,424.4,242.9,424.4,243.3,424.4z M242.8,425
          c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
          c0-0.2-0.2-0.4-0.5-0.5S243.2,425,242.8,425z"/>
        <path d="M248.7,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S248.4,429.3,248.7,429.3z"/>
        <path d="M172.9,440.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0
          s-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
          c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.1,0,0.2,0,0.4,0s0.3,0,0.4,0s0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0
          s0.3,0,0.5,0c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6
          c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.2-0.6-0.5-1.3-0.9-2.1c-0.3-0.8-0.6-1.6-0.9-2.2
          c-0.3-0.6-0.5-1.2-0.8-1.7c-0.1-0.2-0.4-0.3-0.9-0.3c-0.1-0.2-0.1-0.3,0-0.5c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0
          S172.7,440.6,172.9,440.6z"/>
        <path d="M182.4,440.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C180.7,440.7,181.5,440.3,182.4,440.3z M182.3,440.9c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1
          s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1
          s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S182.7,440.9,182.3,440.9z"/>
        <path d="M191.4,440.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
          c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
          s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
          c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
          s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
          v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C190.8,440.4,191.1,440.4,191.4,440.4z"/>
        <path d="M201.6,442.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H201.6z M200.3,438.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S200.5,438.6,200.3,438.5z"/>
        <path d="M205.5,440.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
          c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V441h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
          s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H205.5z"/>
        <path d="M210.7,442.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H210.7z M209.4,438.5
          c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
          c-0.2,0.2-0.3,0.2-0.6,0.2S209.6,438.6,209.4,438.5z"/>
        <path d="M217.1,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
          v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S216.8,440.4,217.1,440.4z"/>
        <path d="M225.1,446.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
          c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
          c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
          c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
          s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
          s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
          c0.2,0.1,0.4,0.2,0.7,0.2h1.4V446.1z M224.4,447.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
          c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C225,447.3,224.7,447.3,224.4,447.3z M223.7,440.8c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          C224.5,441,224.2,440.8,223.7,440.8z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
        <path d="M316.4,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
          c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
          c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
          l0,0c0.2-0.4,0.4-0.8,0.8-1.1C315.7,144.5,316.1,144.3,316.4,144.3z"/>
        <path d="M321.4,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C320.4,144.4,320.9,144.3,321.4,144.3z M320.6,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S320.3,150.4,320.6,150.4z"/>
        <path d="M326.9,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L326.9,144.4L326.9,144.4z"/>
        <path d="M332.3,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          C331.2,144.5,331.7,144.3,332.3,144.3z"/>
        <path d="M336.7,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
          s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S336.4,149.2,336.7,149.2z"/>
        <path d="M345.9,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
          c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
          c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
          s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
          c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
          c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
          c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
          s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
          C344.8,144.5,345.3,144.3,345.9,144.3z"/>
        <path d="M352.6,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C350.8,144.6,351.6,144.3,352.6,144.3z M352.4,144.8c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S352.9,144.8,352.4,144.8z"/>
        <path d="M361.6,144.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
          s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
          c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
          c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
          c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
          c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C361,144.3,361.3,144.3,361.6,144.3
          z"/>
        <path d="M370.6,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C371.4,147.2,370.8,147.2,370.6,147.2z M370.7,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S370.7,146.7,370.7,146.7z"/>
        <path d="M379.6,144.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
          s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C377.9,144.6,378.6,144.3,379.6,144.3z M379.4,144.8c-0.4,0-0.8,0.1-1.1,0.4
          c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
          c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S379.9,144.8,379.4,144.8z"/>
        <path d="M388.6,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
          c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
          c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
          c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
          c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
          s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
          c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
          c0.2-0.2,0.5-0.5,1-0.7S388.3,144.3,388.6,144.3z"/>
        <path d="M393.6,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
          c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
          c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
          s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
          c-0.2,0-0.9,0-2,0.1C394.4,147.2,393.8,147.2,393.6,147.2z M393.7,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
          s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
          s-0.1,0.3-0.2,0.5S393.7,146.7,393.7,146.7z"/>
      </g>
      <g id="jp" style={{ opacity: selected === 'JP' ? 1 : 0 }}>
		<path d="M119.9,108.2c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
			c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
			s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1
			c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-1,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.8
			C119.4,108.3,119.7,108.2,119.9,108.2z"/>
		<path d="M124.3,112.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
			s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C123.3,112.4,123.8,112.3,124.3,112.3z M123.5,118.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S123.2,118.4,123.5,118.4z"/>
		<path d="M128.7,110.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L128.7,110.4L128.7,110.4z"/>
		<path d="M132.7,110.4c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L132.7,110.4L132.7,110.4z"/>
		<path d="M138.1,114v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H138.1z M136.8,110.4
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S137,110.6,136.8,110.4z"/>
		<path d="M144.5,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S144.2,112.3,144.5,112.3z"/>
		<path d="M152.5,118.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L152.5,118.1L152.5,118.1z M151.8,119.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C152.4,119.2,152.1,119.2,151.8,119.2z
			 M151.1,112.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C152,112.9,151.6,112.7,151.1,112.7z"/>
		<path d="M162.3,112.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V113h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
			s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H162.3z"/>
		<path d="M166.3,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C167.1,115.2,166.5,115.2,166.3,115.2z M166.4,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S166.4,114.7,166.4,114.7z"/>
		<path d="M173.1,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C173.8,115.2,173.2,115.2,173.1,115.2z M173.1,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S173.1,114.7,173.1,114.7z"/>
		<path d="M180.5,112.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V113h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
			s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H180.5z"/>
		<path d="M187.7,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
			s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
			c0.2-0.2,0.5-0.5,1-0.7S187.4,112.3,187.7,112.3z"/>
		<path d="M192.6,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S192.4,117.2,192.6,117.2z"/>
		<path d="M108.9,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
			s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
			c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
			c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
			c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
			s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L108.9,134.1L108.9,134.1z M108.2,135.2c-0.9,0-1.4,0-1.5,0.1
			c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
			s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
			C108.9,135.2,108.6,135.2,108.2,135.2z M107.6,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4
			s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C108.4,128.9,108,128.7,107.6,128.7z"/>
		<path d="M113.2,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C114,131.2,113.4,131.2,113.2,131.2z M113.3,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S113.3,130.7,113.3,130.7z"/>
		<path d="M120.7,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H120.7z"/>
		<path d="M125.6,128.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V129h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H125.6z"/>
		<path d="M130.8,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H130.8
			z M129.5,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S129.7,126.6,129.5,126.4z"/>
		<path d="M137.2,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S136.9,128.3,137.2,128.3z"/>
		<path d="M145.2,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4
			s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5
			c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1
			c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7
			c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2L145.2,134.1L145.2,134.1z M144.5,135.2c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
			c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
			c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C145.1,135.2,144.8,135.2,144.5,135.2z
			 M143.8,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
			c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C144.6,128.9,144.3,128.7,143.8,128.7z"/>
		<path d="M157.4,128.3c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
			c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
			c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
			c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
			s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
			c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
			s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
			s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
			c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C156.9,128.3,157.2,128.3,157.4,128.3z"/>
		<path d="M168.6,128.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C167.5,128.4,168.1,128.3,168.6,128.3z M167.7,134.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
			c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S167.4,134.4,167.7,134.4z"/>
		<path d="M176.3,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C175.6,128.5,175.9,128.3,176.3,128.3z"/>
		<path d="M182.3,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C181.6,128.5,181.9,128.3,182.3,128.3z"/>
		<path d="M186.3,130v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H186.3
			z M185.1,126.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
			s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S185.2,126.6,185.1,126.4z"/>
		<path d="M189.4,131.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
			c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
			c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
			c-0.2,0-0.9,0-2,0.1C190.2,131.2,189.6,131.2,189.4,131.2z M189.5,130.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
			s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
			s-0.1,0.3-0.2,0.5S189.5,130.7,189.5,130.7z"/>
		<path d="M198.8,128.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
			c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
			c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0
			s0,0.1,0,0.2v0.1L202,135c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H200
			c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
			s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C198,128.4,198.4,128.3,198.8,128.3z M198.3,128.9
			c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
			c0-0.2-0.2-0.4-0.5-0.5S198.7,128.9,198.3,128.9z"/>
		<path d="M204.1,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S203.9,133.2,204.1,133.2z"/>
		<path d="M148,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C147.3,144.5,147.7,144.3,148,144.3z"/>
		<path d="M153,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
			s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C152,144.4,152.5,144.3,153,144.3z M152.2,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S151.9,150.4,152.2,150.4z"/>
		<path d="M158.5,144.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
			c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3
			c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8V145h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
			c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0v2.1H158.5z"/>
		<path d="M163.9,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
			c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
			s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
			c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1s0.1,0,0.1,0
			c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
			s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
			C162.7,144.5,163.3,144.3,163.9,144.3z"/>
	</g>
			
			<g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
				<path d="M220.1,80.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L220.1,80.2L220.1,80.2z"/>
				<path d="M225.5,83.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6
					C225.6,83.9,225.5,83.9,225.5,83.9z M224.2,80.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S224.4,80.4,224.2,80.2z"/>
				<path d="M232.9,82.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
					c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
					s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
					c0,0,0.1,0.1,0.1,0.2C232.9,82.7,232.9,82.8,232.9,82.9z"/>
				<path d="M235.2,85.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
					s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
					s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
					C236,85,235.4,85.1,235.2,85.1z M235.3,84.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
					c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
					S235.3,84.5,235.3,84.5z"/>
				<path d="M241.9,87c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
					S241,88,241,87.8s0.1-0.4,0.3-0.5S241.6,87,241.9,87z"/>
				<path d="M253.1,82.1c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
					c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
					c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5
					c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
					s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
					v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C252.5,82.2,252.8,82.1,253.1,82.1z"/>
				<path d="M264.4,82.1c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
					s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C262.6,82.4,263.4,82.1,264.4,82.1z M264.2,82.6c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.5,0.6-0.6,1
					s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1
					s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S264.6,82.6,264.2,82.6z"/>
				<path d="M273.8,83.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
					c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
					c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1H274c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7V88
					c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.5-0.3-0.7
					c-0.1-0.3-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
					s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.5-0.6v-3.5L273.8,83.9L273.8,83.9z"/>
				<path d="M279.4,82.1c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2
					s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0S278,89,277.8,89c-0.2-0.1-0.4-0.1-0.6-0.1
					c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
					c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
					s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1
					c0-0.6,0.2-1,0.7-1.4C278.3,82.3,278.8,82.1,279.4,82.1z"/>
				<path d="M283.9,85.1c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C284.7,85,284,85.1,283.9,85.1z M284,84.5h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
					s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
					S284,84.5,284,84.5z"/>
				<path d="M290.5,87c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S290.3,87,290.5,87z"/>
				<path d="M247.5,98.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
					s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
					c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C246.8,98.3,247.2,98.1,247.5,98.1z"/>
				<path d="M252.5,98.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
					s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
					c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
					c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
					c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
					c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
					C251.5,98.3,252,98.1,252.5,98.1z M251.6,104.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
					c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S251.4,104.2,251.6,104.2z"/>
				<path d="M258.3,99.9v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6
					C258.4,99.9,258.3,99.9,258.3,99.9z M257,96.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
					c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S257.2,96.4,257,96.2z"/>
				<path d="M264.7,98.1c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2S261,99,260.9,99s-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
					c0.2-0.2,0.5-0.5,1-0.7S264.4,98.1,264.7,98.1z"/>
			</g>
			<g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
				<path d="M332.6,319c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
					s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
					s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
					c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
					c0.5-0.4,0.9-0.7,1.2-0.8C332.1,319.1,332.4,319,332.6,319z"/>
				<path d="M344,319c1,0,1.8,0.3,2.4,1s0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5s-1.4,1-2.4,1s-1.8-0.3-2.4-1s-0.9-1.5-0.9-2.5
					s0.3-1.8,1-2.5S343,319,344,319z M343.8,319.5c-0.4,0-0.8,0.1-1.1,0.4s-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5
					s0.4,0.9,0.8,1.2s0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5s-0.4-0.9-0.8-1.2
					S344.2,319.5,343.8,319.5z"/>
				<path d="M353.4,320.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
					c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
					s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
					h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2V325v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
					s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
					s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.4L353.4,320.9L353.4,320.9z"/>
				<path d="M359,319c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
					s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
					s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
					s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
					c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
					s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
					S358.4,319,359,319z"/>
				<path d="M363.5,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
					s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
					s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S363.6,322,363.5,322z
					M363.6,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
					s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S363.6,321.4,363.6,321.4z"/>
				<path d="M370.1,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
					s0.1-0.4,0.3-0.5S369.9,323.9,370.1,323.9z"/>
				<path d="M380.8,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
					s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
					s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
					c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
					c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S380.6,319,380.8,319z M380.2,319.9
					c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
					s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S380.8,319.9,380.2,319.9z"/>
				<path d="M386,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
					s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
					s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S386.1,322,386,322z
					M386.1,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
					s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S386.1,321.4,386.1,321.4z"/>
				<path d="M395.7,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
					c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
					c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S395.4,319,395.7,319z"/>
				<path d="M401.2,315c0.2,0,0.4,0,0.4,0s0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3s-0.1,0.2-0.2,0.2
					s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4s-0.2,0.6-0.2,1.1v1.9h1.8
					c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.5,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
					s0.2-0.2,0.2-0.3h0.6V319c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8S401,315,401.2,315z"/>
				<path d="M407.3,320.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
					c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1
					s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1s-0.3,0.1-0.4,0.1
					h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2V325v-0.1c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5
					s-0.2-0.5-0.3-0.7s-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
					s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.4L407.3,320.9L407.3,320.9z"/>
				<path d="M414.8,319c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
					s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
					s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
					c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
					c0.5-0.4,0.9-0.7,1.2-0.8C414.3,319.1,414.6,319,414.8,319z"/>
				<path d="M423.9,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
					s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
					s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S424.1,322,423.9,322z
					M424,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
					s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S424,321.4,424,321.4z"/>
				<path d="M430.6,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
					s0.1-0.4,0.3-0.5S430.3,323.9,430.6,323.9z"/>
				<path d="M335.3,335c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
					s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
					s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
					c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
					c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S335,335,335.3,335z M334.6,335.9
					c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
					s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S335.2,335.9,334.6,335.9z"/>
				<path d="M343.4,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
					c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
					c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S343.1,335,343.4,335z"/>
				<path d="M346.3,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
					s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
					s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S346.5,338,346.3,338z
					M346.4,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
					s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S346.4,337.4,346.4,337.4z"/>
				<path d="M356,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
					c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
					c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
					c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
					s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
					s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4L356,340.8L356,340.8z M355.3,341.9c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S355.7,341.9,355.3,341.9z M354.7,335.5
					c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
					S355.1,335.5,354.7,335.5z"/>
				<path d="M363.6,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
					c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
					c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S363.3,335,363.6,335z"/>
				<path d="M370.7,335.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
					s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
					c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
					s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
					s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
					s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S370.2,335.1,370.7,335.1z M369.8,341.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
					c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S369.5,341.2,369.8,341.2z"/>
				<path d="M378.7,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
					c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
					c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S378.4,335,378.7,335z"/>
				<path d="M387.9,335.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
					c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
					c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
					s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
					c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
					S387.9,335.8,387.9,335.8z"/>
				<path d="M390.2,335.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
					c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
					c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
					c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
					c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C389.1,335.2,389.6,335.2,390.2,335.2z"/>
				<path d="M397,339.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
					s0.1-0.4,0.3-0.5S396.7,339.9,397,339.9z"/>
				<path d="M407.9,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
					c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
					c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S407.6,335,407.9,335z"/>
				<path d="M412,336.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L412,336.8L412,336.8z
					M410.7,333.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
					s-0.3,0.2-0.6,0.2S410.9,333.3,410.7,333.2z"/>
				<path d="M418.4,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
					c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
					c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S418.1,335,418.4,335z"/>
				<path d="M426.4,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
					c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
					c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
					c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
					s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
					s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4L426.4,340.8L426.4,340.8z M425.7,341.9c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S426,341.9,425.7,341.9z M425.1,335.5
					c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
					S425.5,335.5,425.1,335.5z"/>
			</g>
			<g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
				<path d="M106.1,326c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.2,0
					s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c-0.1,0.3-0.2,0.8-0.2,1.4v3.1v0.1c0,0,0,0,0,0.1s0,0,0,0s0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3
					c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2c-0.3,0.3-0.8,0.6-1.2,0.8
					C107.1,325.9,106.6,326,106.1,326z M106.3,319.9c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8
					c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
					S107,319.9,106.3,319.9z"/>
				<path d="M116,320.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
					c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
					c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2V325v-0.1
					c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.2-0.5-0.3-0.7
					s-0.1-0.6-0.2-0.8c0-0.3,0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
					s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3s0.5-0.4,0.5-0.6v-3.4L116,320.9L116,320.9z"/>
				<path d="M120,317.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
					c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V317.2z"/>
				<path d="M124,317.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
					c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V317.2z"/>
				<path d="M128.2,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C127.7,324,127.9,323.9,128.2,323.9z"/>
				<path d="M138.9,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
					c0.2-0.2,0.6-0.4,0.9-0.6C138.3,319.1,138.6,319,138.9,319z M138.3,319.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8
					c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C139.4,320.2,138.9,319.9,138.3,319.9z"/>
				<path d="M145.2,320.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c-0.1,0.5-0.1,0.9-0.1,1v0.6L145.2,320.8L145.2,320.8z M143.9,317.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6
					c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2
					S144.1,317.3,143.9,317.2z"/>
				<path d="M151.3,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
					c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
					c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
					c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
					c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
					s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
					c0.2,0.1,0.4,0.2,0.7,0.2h1.4V324.8z M150.6,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
					c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4
					s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C151.2,326,150.9,325.9,150.6,325.9z M149.9,319.5c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3
					s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S150.4,319.5,149.9,319.5z"/>
				<path d="M155.5,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C155,324,155.2,323.9,155.5,323.9z"/>
				<path d="M101.8,335c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
					c0.2-0.2,0.6-0.4,0.9-0.6C101.2,335.1,101.5,335,101.8,335z M101.2,335.9c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8
					c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C102.3,336.2,101.8,335.9,101.2,335.9z"/>
				<path d="M110,335c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
					s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0
					c0.2-0.4,0.4-0.8,0.8-1.1C109.3,335.2,109.7,335,110,335z"/>
				<path d="M112.9,338c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C113.7,338,113,338,112.9,338z M113,337.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
					s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
					C113,337.4,113,337.4,113,337.4z"/>
				<path d="M122.6,340.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
					s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
					c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
					c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
					c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
					c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9
					c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4L122.6,340.8L122.6,340.8z M121.9,341.9c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
					s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C122.5,342,122.2,341.9,121.9,341.9z
					M121.3,335.5c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2
					c0-0.5-0.1-1-0.4-1.4S121.7,335.5,121.3,335.5z"/>
				<path d="M130.2,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
					C129.5,335.2,129.9,335,130.2,335z"/>
				<path d="M137.3,335.1c0.5,0,0.8,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
					l0,0c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
					c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3
					c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
					c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
					c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
					C136.2,335.2,136.7,335.1,137.3,335.1z M136.4,341.2c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
					c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S136.1,341.2,136.4,341.2z"/>
				<path d="M145.3,335c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
					c0.2-0.2,0.5-0.5,1-0.7C144.5,335.2,144.9,335,145.3,335z"/>
				<path d="M154.5,335.8c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
					c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2
					c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
					s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2
					c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C154.5,335.7,154.5,335.8,154.5,335.8z"/>
				<path d="M156.7,335.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
					c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
					c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
					s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1
					c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2
					s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2C155.7,335.2,156.2,335.2,156.7,335.2z"/>
			</g>
			<g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
				<path d="M285.5,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
					s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
					c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
					v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
					c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
					C286.6,415.2,286,415.3,285.5,415.3z M285.8,409.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8
					c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
					C287,409.5,286.5,409.3,285.8,409.3z"/>
				<path d="M295.4,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
					c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
					c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
					c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7
					c-0.1-0.3-0.1-0.6-0.2-0.8s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9,0,1.6-0.1,2.1-0.3c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8
					s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.5-0.6v-3.5L295.4,410.2L295.4,410.2z"/>
				<path d="M300.5,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
					c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
					c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
					c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L300.5,408.4L300.5,408.4z"/>
				<path d="M305.4,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
					c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
					c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
					c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L305.4,408.4L305.4,408.4z"/>
				<path d="M309.5,411.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C310.3,411.3,309.6,411.3,309.5,411.3z M309.6,410.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					s-0.1,0.3-0.2,0.5S309.5,410.7,309.6,410.7z"/>
				<path d="M319.2,408.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
					c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
					c0.2-0.4,0.4-0.8,0.8-1.1C318.5,408.6,318.9,408.4,319.2,408.4z"/>
				<path d="M322,413.4c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1h-0.8c0,0,0,0,0-0.1s0-0.2,0.1-0.4c0.1-0.2,0.1-0.2,0.1-0.2h0.6
					c0.1,0,0.1,0,0.1-0.1v-0.2c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.2,0,0.4,0.1,0.8,0.2c0.3,0.1,0.6,0.2,0.7,0.2s0.3,0,0.5-0.1s0.3-0.1,0.4-0.2s0.2-0.1,0.2-0.1l0,0c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.1c0,0.6,0,1.1,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.1
					c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1s-0.6,0-1.7,0.1c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6-0.1
					c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.3,0.1-0.7,0.1-1.1v-6c0-0.1,0-0.3-0.1-0.6s-0.1-0.5-0.1-0.6c-0.1-0.2-0.3-0.5-0.7-0.8
					c-0.3-0.3-0.7-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.3-0.4,0.6-0.5,1.1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8c0,0,0,0,0,0.1
					c0,0,0,0,0.1,0h1.7h0.1c0,0,0,0,0,0.1c0,0.2-0.1,0.4-0.2,0.6h-1.6c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6c0,0.7,0.1,1.2,0.1,1.4
					c0,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2s0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1s-0.6,0-1.7,0.1
					c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2s0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2C321.9,414.2,322,413.8,322,413.4z"/>
				<path d="M330.6,408.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
					c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
					c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
					c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
					c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
					c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
					c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C329.5,408.5,330.1,408.6,330.6,408.6z"/>
				<path d="M337.4,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S337.2,413.3,337.4,413.3z"/>
				<path d="M264.6,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
					s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
					c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
					c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
					c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
					c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
					c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4L264.6,430.1L264.6,430.1z M263.9,431.3c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
					s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
					C264.5,431.3,264.2,431.3,263.9,431.3z M263.2,424.8c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
					c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C264,425,263.7,424.8,263.2,424.8z"/>
				<path d="M268.9,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C269.7,427.3,269,427.3,268.9,427.3z M269,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					s-0.1,0.3-0.2,0.5S269,426.7,269,426.7z"/>
				<path d="M276.4,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
					c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
					c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V425h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
					c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L276.4,424.4L276.4,424.4z"/>
				<path d="M281.2,424.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
					c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
					c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V425H279c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
					c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L281.2,424.4L281.2,424.4z"/>
				<path d="M286.4,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L286.4,426.1L286.4,426.1z
					M285.2,422.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
					s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S285.3,422.6,285.2,422.5z"/>
				<path d="M292.8,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
					v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
					c0.2-0.2,0.5-0.5,1-0.7S292.5,424.4,292.8,424.4z"/>
				<path d="M300.8,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
					s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
					c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
					c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
					c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
					c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
					c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4L300.8,430.1L300.8,430.1z M300.1,431.3c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
					s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
					C300.8,431.3,300.5,431.3,300.1,431.3z M299.5,424.8c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
					c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C300.3,425,299.9,424.8,299.5,424.8z"/>
				<path d="M313.1,424.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
					c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
					c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5
					c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
					s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
					v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C312.5,424.4,312.8,424.4,313.1,424.4z"/>
				<path d="M324.2,424.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
					s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
					s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
					c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
					c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
					c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
					C323.2,424.5,323.7,424.4,324.2,424.4z M323.4,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1
					c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S323.1,430.5,323.4,430.5z"/>
				<path d="M331.9,424.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
					c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
					c0.2-0.4,0.4-0.8,0.8-1.1C331.2,424.6,331.6,424.4,331.9,424.4z"/>
				<path d="M337.9,424.4c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
					c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
					c0.2-0.4,0.4-0.8,0.8-1.1C337.2,424.6,337.6,424.4,337.9,424.4z"/>
				<path d="M342,426.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L342,426.1L342,426.1z
					M340.7,422.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
					s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S340.9,422.6,340.7,422.5z"/>
				<path d="M345.1,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C345.9,427.3,345.2,427.3,345.1,427.3z M345.2,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					s-0.1,0.3-0.2,0.5S345.2,426.7,345.2,426.7z"/>
				<path d="M354.4,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
					s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
					c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
					s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C353.7,424.4,354,424.4,354.4,424.4z M353.9,425
					c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
					c0-0.2-0.2-0.4-0.5-0.5S354.3,425,353.9,425z"/>
				<path d="M359.8,429.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S359.5,429.3,359.8,429.3z"/>
				<path d="M284,440.6c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0
					c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5
					s-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.1,0,0.2,0,0.4,0s0.3,0,0.4,0s0.3,0,0.5,0
					s0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
					c-1.1,2.7-1.9,4.5-2.3,5.6c0,0.1-0.1,0.1-0.2,0.2s-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.1-0.1c-0.2-0.6-0.5-1.3-0.9-2.1
					c-0.3-0.8-0.6-1.6-0.9-2.2s-0.5-1.2-0.8-1.7c-0.1-0.2-0.4-0.3-0.9-0.3c-0.1-0.2-0.1-0.3,0-0.5c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0
					S283.8,440.6,284,440.6z"/>
				<path d="M293.5,440.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
					s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C291.8,440.7,292.6,440.3,293.5,440.3z M293.4,440.9c-0.4,0-0.8,0.1-1.1,0.4
					c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
					c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S293.8,440.9,293.4,440.9z"/>
				<path d="M302.5,440.4c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
					c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
					c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5
					c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
					s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8
					v0.1l0,0c0.5-0.4,0.9-0.7,1.2-0.8C301.9,440.4,302.2,440.4,302.5,440.4z"/>
				<path d="M312.7,442.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L312.7,442.1L312.7,442.1z
					M311.4,438.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
					s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S311.6,438.6,311.4,438.5z"/>
				<path d="M316.6,440.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
					c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
					c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V441h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
					c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L316.6,440.4L316.6,440.4z"/>
				<path d="M321.8,442.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6L321.8,442.1L321.8,442.1z
					M320.5,438.5c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
					s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S320.7,438.6,320.5,438.5z"/>
				<path d="M328.2,440.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
					v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
					c0.2-0.2,0.5-0.5,1-0.7S327.9,440.4,328.2,440.4z"/>
				<path d="M336.2,446.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
					s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
					c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
					c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
					c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
					c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
					c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2h1.4L336.2,446.1L336.2,446.1z M335.5,447.3c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
					s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
					C336.1,447.3,335.8,447.3,335.5,447.3z M334.8,440.8c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
					c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C335.6,441,335.3,440.8,334.8,440.8z"/>
			</g>
			<g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
				<path d="M309.3,119.2c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2-0.1s-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0-0.2,0
					s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
					c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
					v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
					c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
					C310.4,119.1,309.9,119.2,309.3,119.2z M309.6,113.2c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
					v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
					C310.8,113.5,310.3,113.2,309.6,113.2z"/>
				<path d="M315.5,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C316.3,115.2,315.7,115.2,315.5,115.2z M315.6,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					s-0.1,0.3-0.2,0.5S315.6,114.7,315.6,114.7z"/>
				<path d="M323.4,114v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6
					C323.5,114,323.4,114,323.4,114z M322.1,110.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
					c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S322.2,110.6,322.1,110.4z"/>
				<path d="M329.8,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
					v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
					c0.2-0.2,0.5-0.5,1-0.7S329.4,112.3,329.8,112.3z"/>
				<path d="M337.8,118.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
					s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
					c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
					c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
					c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
					c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
					c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L337.8,118.1L337.8,118.1z M337,119.2c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
					s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
					C337.7,119.2,337.4,119.2,337,119.2z M336.4,112.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
					c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C337.2,112.9,336.9,112.7,336.4,112.7z"/>
				<path d="M349.6,112.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4
					c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C348.9,112.4,349.3,112.3,349.6,112.3z M348.9,113.2c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C350,113.5,349.5,113.2,348.9,113.2z"/>
				<path d="M357.7,112.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
					c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
					c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0
					c0.2-0.4,0.4-0.8,0.8-1.1C357.1,112.5,357.4,112.3,357.7,112.3z"/>
				<path d="M360.6,115.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C361.4,115.2,360.8,115.2,360.6,115.2z M360.7,114.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					s-0.1,0.3-0.2,0.5S360.7,114.7,360.7,114.7z"/>
				<path d="M370.3,118.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
					s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
					c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
					c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
					c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
					c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
					c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L370.3,118.1L370.3,118.1z M369.6,119.2c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
					s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
					C370.3,119.2,370,119.2,369.6,119.2z M369,112.7c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
					c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C369.8,112.9,369.4,112.7,369,112.7z"/>
				<path d="M377.9,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
					v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
					c0.2-0.2,0.5-0.5,1-0.7S377.6,112.3,377.9,112.3z"/>
				<path d="M385,112.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
					s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
					s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
					c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
					c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
					c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
					C384,112.4,384.5,112.3,385,112.3z M384.1,118.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
					c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S383.9,118.4,384.1,118.4z"/>
				<path d="M393,112.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
					c0.2-0.2,0.5-0.5,1-0.7S392.7,112.3,393,112.3z"/>
				<path d="M398.8,112.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
					c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
					c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V113h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
					c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L398.8,112.4L398.8,112.4z"/>
				<path d="M402.8,117.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S402.5,117.2,402.8,117.2z"/>
				<path d="M327.2,128.6c1.1,0,1.9-0.1,2.3-0.2l0,0l0,0c0,0,0.1,0,0.1,0.1s0,0.2,0,0.2c-0.1,0.4-0.1,0.8-0.1,1.1v3.5
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8s-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
					s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
					c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V129h-0.9c0,0,0,0,0-0.1
					s0.1-0.2,0.2-0.3s0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2
					c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6
					c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.1
					c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8h1.7L327.2,128.6L327.2,128.6z"/>
				<path d="M334.1,128.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
					s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
					c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
					c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
					s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
					C332.9,128.5,333.5,128.3,334.1,128.3z"/>
				<path d="M341.6,128.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
					s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
					c0.2-0.2,0.5-0.5,1-0.7S341.3,128.3,341.6,128.3z"/>
				<path d="M346.5,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S346.3,133.2,346.5,133.2z"/>
				<path d="M356.9,124.2c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
					c0,0.1-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
					s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
					s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
					c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1
					c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-1,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.8
					C356.4,124.3,356.7,124.2,356.9,124.2z"/>
				<path d="M362.2,128.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
					c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
					l0,0c0.2-0.4,0.4-0.8,0.8-1.1C361.6,128.5,361.9,128.3,362.2,128.3z"/>
				<path d="M367.4,128.3c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1s-1.8-0.3-2.4-1
					s-0.9-1.5-0.9-2.5s0.3-1.8,1-2.5C365.7,128.6,366.4,128.3,367.4,128.3z M367.2,128.8c-0.4,0-0.8,0.1-1.1,0.4
					c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3s0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4
					c0.3-0.3,0.5-0.6,0.6-1s0.2-0.8,0.2-1.3s-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2S367.7,128.8,367.2,128.8z"/>
				<path d="M376.1,134.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
					s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
					c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
					c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
					c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
					c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
					c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
					s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L376.1,134.1L376.1,134.1z M375.4,135.2c-0.9,0-1.4,0-1.5,0.1
					c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
					s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
					C376,135.2,375.7,135.2,375.4,135.2z M374.7,128.7c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
					c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C375.5,128.9,375.2,128.7,374.7,128.7z"/>
				<path d="M381.7,128.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
					s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
					c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
					c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
					s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
					C380.6,128.5,381.1,128.3,381.7,128.3z"/>
				<path d="M386.1,133.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S385.8,133.2,386.1,133.2z"/>
				<path d="M311.7,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
					s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
					c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
					c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
					s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
					C310.6,144.5,311.1,144.3,311.7,144.3z"/>
				<path d="M319.4,144.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
					c0.2-0.2,0.5-0.5,1-0.7S319.1,144.3,319.4,144.3z"/>
				<path d="M326.5,144.3c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
					s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3s-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
					c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
					c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
					c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
					c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
					C325.5,144.4,326,144.3,326.5,144.3z M325.6,150.4c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
					c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S325.4,150.4,325.6,150.4z"/>
				<path d="M332.1,149.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2
					c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
					c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2
					c0-0.1,0.1-0.1,0.1-0.1v-0.1c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1h-0.1c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2
					c0.5,0.1,1,0.1,1.5,0.1s1,0,1.4-0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3
					c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3
					s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5
					c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1l-0.1-0.1l0,0v1.4L332.1,149.3L332.1,149.3z"/>
				<path d="M338.6,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C339.4,147.2,338.7,147.2,338.6,147.2z M338.7,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					s-0.1,0.3-0.2,0.5S338.7,146.7,338.7,146.7z"/>
				<path d="M346.6,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
					s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
					c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
					c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
					s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
					C345.5,144.5,346,144.3,346.6,144.3z"/>
				<path d="M351,149.2c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
					s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6
					s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S350.7,149.2,351,149.2z"/>
				<path d="M360.2,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
					s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
					c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
					c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
					s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
					C359.1,144.5,359.6,144.3,360.2,144.3z"/>
				<path d="M367.5,144.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4
					c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2
					c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
					c0.2-0.2,0.6-0.4,0.9-0.6C366.9,144.4,367.2,144.3,367.5,144.3z M366.8,145.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
					v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C367.9,145.5,367.4,145.2,366.8,145.2z"/>
				<path d="M373.7,146v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6
					L373.7,146L373.7,146z M372.5,142.4c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2
					c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S372.6,142.6,372.5,142.4z"/>
				<path d="M379.5,144.3c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0
					s0,0.1,0,0.2v0.1l-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1
					c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1
					s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8C378.7,144.4,379.1,144.3,379.5,144.3z M379,144.9
					c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4
					c0-0.2-0.2-0.4-0.5-0.5S379.4,144.9,379,144.9z"/>
				<path d="M384.9,147.2c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
					c0,0.1,0,0.2-0.1,0.3s-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3
					s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C385.7,147.2,385,147.2,384.9,147.2z M385,146.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					s-0.1,0.3-0.2,0.5S385,146.7,385,146.7z"/>
				<path d="M394.6,144.3c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
					c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
					c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
					c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7l0,0l0,0
					l0,0c0.2-0.4,0.4-0.8,0.8-1.1C394,144.5,394.3,144.3,394.6,144.3z"/>
				<path d="M398.9,144.3c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2
					s0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
					c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1
					c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4
					s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
					C397.8,144.5,398.3,144.3,398.9,144.3z"/>
			</g>
			<g id="po" style={{ opacity: selected === 'PO' ? 1 : 0 }}>
				<path d="M109.4,88c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
					c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1c0,0,0,0.1,0,0.1s0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5
					c0.4-0.2,0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8C110.4,87.9,109.9,88,109.4,88z M109.6,81.9c-0.2,0-0.4,0-0.7,0.1
					c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.4
					c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1C110.9,82.2,110.3,81.9,109.6,81.9z"/>
				<path d="M115.3,84c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
					c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2
					c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
					s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
					C116.1,84,115.4,84,115.3,84z M115.4,83.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
					c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C115.4,83.3,115.3,83.4,115.4,83.4z"/>
				<path d="M121.7,84c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
					c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2
					c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
					s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
					C122.4,84,121.8,84,121.7,84z M121.8,83.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
					c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C121.8,83.3,121.7,83.4,121.8,83.4z"/>
				<path d="M129.4,81c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
					c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
					c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
					c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
					c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
					c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
					c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C128.3,81.2,128.8,81,129.4,81z"
					/>
				<path d="M133.4,85.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C133,86,133.2,85.9,133.4,85.9z"/>
				<path d="M143.5,81c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
					c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C142.9,81.1,143.2,81,143.5,81z M142.8,81.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C144,82.2,143.5,81.9,142.8,81.9z"/>
				<path d="M151.4,81c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9V86c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C150.7,81.2,151,81,151.4,81z"/>
				<path d="M154,84c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
					c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2
					c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5
					s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
					C154.7,84,154.1,84,154,84z M154,83.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
					c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
					C154,83.3,154,83.4,154,83.4z"/>
				<path d="M163.3,86.8c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H163.3z M162.6,87.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C163.3,88,163,87.9,162.6,87.9z
					M162,81.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C162.8,81.7,162.4,81.5,162,81.5z"/>
				<path d="M170.6,81c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2V86
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
					s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3V86c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
					c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C169.9,81.1,170.3,81,170.6,81z"/>
				<path d="M177.3,81c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					S179,88,178.9,88c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
					s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
					c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
					c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C176.3,81.2,176.8,81,177.3,81z M176.5,87.1c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S176.2,87.1,176.5,87.1z"/>
				<path d="M185,81c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2V86
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
					s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3V86c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
					c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C184.3,81.1,184.7,81,185,81z"/>
				<path d="M194,81.8c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
					c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
					c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
					c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
					C193.9,81.7,194,81.8,194,81.8z"/>
				<path d="M195.9,81.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
					c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
					c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
					c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
					c-0.2,0.5-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
					c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
					c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C194.8,81.2,195.3,81.2,195.9,81.2z"/>
				<path d="M202.4,85.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C201.9,86,202.1,85.9,202.4,85.9z"/>
				<path d="M102.9,104c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
					c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1c0,0,0,0.1,0,0.1s0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5
					c0.4-0.2,0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8C104,103.9,103.4,104,102.9,104z M103.1,97.9
					c-0.2,0-0.4,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
					c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1C104.4,98.2,103.9,97.9,103.1,97.9z"/>
				<path d="M111.8,97c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C111.1,97.2,111.5,97,111.8,97z"/>
				<path d="M114.4,100c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
					c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
					c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
					c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C115.2,100,114.5,100,114.4,100z M114.5,99.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
					c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C114.5,99.3,114.5,99.4,114.5,99.4z"/>
				<path d="M122.8,97c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
					s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
					c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
					c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C121.8,97.2,122.3,97,122.8,97z M122,103.1c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S121.7,103.1,122,103.1z"/>
				<path d="M129.8,97c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
					c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
					c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C129.1,97.1,129.4,97,129.8,97z M129.3,97.7c-0.6,0-1,0.3-1.4,0.8
					c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
					c0-0.2-0.2-0.4-0.5-0.5C130.1,97.8,129.7,97.7,129.3,97.7z"/>
				<path d="M134.9,101.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C134.4,102,134.6,101.9,134.9,101.9z"/>
				<path d="M144.9,97.3c1.1,0,1.9-0.1,2.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.2
					c-0.1,0.4-0.1,0.8-0.1,1.1v3.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.3,0,0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V98H141c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2
					c0-0.6,0.1-1.2,0.4-1.9c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5
					c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
					c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9
					c-0.3,0.6-0.5,1.2-0.5,2v0.8H144.9z"/>
				<path d="M151.4,97c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
					c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
					c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
					c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
					c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
					c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
					c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C150.3,97.2,150.8,97,151.4,97z"
					/>
				<path d="M158.6,97c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
					s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7C157.9,97.1,158.3,97,158.6,97z"/>
				<path d="M163.2,101.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C162.7,102,163,101.9,163.2,101.9z"/>
				<path d="M173.5,102.8c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H173.5z M172.7,103.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C173.4,104,173.1,103.9,172.7,103.9z
					M172.1,97.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C172.9,97.7,172.6,97.5,172.1,97.5z"/>
				<path d="M177.4,100c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
					c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
					c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
					c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C178.2,100,177.6,100,177.4,100z M177.5,99.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
					c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C177.5,99.3,177.5,99.4,177.5,99.4z"/>
				<path d="M184.6,97.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
					c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9
					c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4
					c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0s0,0,0,0V97.1z"/>
				<path d="M189.2,97.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
					c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5H187
					c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4
					c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0s0,0,0,0V97.1z"/>
				<path d="M194,98.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V98.8z M192.8,95.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S192.9,95.3,192.8,95.1z"/>
				<path d="M200.1,97c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
					s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
					c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C199.4,97.1,199.8,97,200.1,97z"/>
				<path d="M207.8,102.8c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H207.8z M207.1,103.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C207.7,104,207.4,103.9,207.1,103.9z
					M206.4,97.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C207.3,97.7,206.9,97.5,206.4,97.5z"/>
				<path d="M111.6,113c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
					c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
					c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9
					c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2
					c-0.3,0.1-0.5,0.3-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3
					c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.5-0.4,0.9-0.7,1.2-0.8C111.1,113.1,111.4,113,111.6,113
					z"/>
				<path d="M122.4,113c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
					s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
					c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
					c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C121.4,113.2,121.9,113,122.4,113z M121.6,119.1c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S121.3,119.1,121.6,119.1z"/>
				<path d="M129.8,113c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C129.2,113.2,129.5,113,129.8,113z"/>
				<path d="M135.5,113c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C134.8,113.2,135.2,113,135.5,113z"/>
				<path d="M139.3,114.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V114.8z M138,111.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S138.1,111.3,138,111.1z"/>
				<path d="M142.1,116c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
					c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
					c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
					c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C142.8,116,142.2,116,142.1,116z M142.1,115.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C142.1,115.3,142.1,115.4,142.1,115.4z"/>
				<path d="M151,113c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
					c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
					c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C150.3,113.1,150.7,113,151,113z M150.5,113.7c-0.6,0-1,0.3-1.4,0.8
					c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
					c0-0.2-0.2-0.4-0.5-0.5C151.4,113.8,151,113.7,150.5,113.7z"/>
				<path d="M156.1,117.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C155.6,118,155.8,117.9,156.1,117.9z"/>
				<path d="M166.3,118.8c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H166.3z M165.6,119.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C166.3,120,166,119.9,165.6,119.9z
					M165,113.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C165.8,113.7,165.4,113.5,165,113.5z"/>
				<path d="M173.3,113c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C172.7,113.2,173,113,173.3,113z"/>
				<path d="M178,113c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
					s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
					c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
					c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C177,113.2,177.5,113,178,113z M177.2,119.1c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S176.9,119.1,177.2,119.1z"/>
				<path d="M185.2,113c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
					c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C184.6,113.1,184.9,113,185.2,113z M184.5,113.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C185.7,114.2,185.2,113.9,184.5,113.9z"/>
				<path d="M190.1,116c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
					c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
					c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
					c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C190.8,116,190.2,116,190.1,116z M190.1,115.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C190.1,115.3,190.1,115.4,190.1,115.4z"/>
				<path d="M197.8,113c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
					c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
					c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
					c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
					c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
					c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
					c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C196.6,113.2,197.2,113,197.8,113
					z"/>
				<path d="M201.8,117.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C201.3,118,201.6,117.9,201.8,117.9z"/>
				<path d="M108.3,134c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
					c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
					c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2
					c0-0.1,0.1-0.1,0.1-0.1s0-0.1,0-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0
					c0,0-0.1,0-0.1,0c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.4-0.1c0,0,0,0.1,0,0.2
					c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
					c0.5,0.5,0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2
					c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4
					c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0,0V134z"/>
				<path d="M116.3,130.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V130.8z M115,127.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S115.2,127.3,115,127.1z"/>
				<path d="M121.7,129c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
					c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
					c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C121,129.1,121.4,129,121.7,129z M121.2,129.7c-0.6,0-1,0.3-1.4,0.8
					c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
					c0-0.2-0.2-0.4-0.5-0.5C122,129.8,121.6,129.7,121.2,129.7z"/>
				<path d="M130.1,129c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
					s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
					c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C129.4,129.1,129.8,129,130.1,129z"/>
				<path d="M136.9,129c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
					s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
					c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
					c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C135.8,129.2,136.4,129,136.9,129z M136,135.1c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S135.7,135.1,136,135.1z"/>
				<path d="M144.1,129c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
					c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C143.4,129.1,143.8,129,144.1,129z M143.4,129.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C144.5,130.2,144,129.9,143.4,129.9z"/>
				<path d="M151.7,129c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
					c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C151.1,129.1,151.4,129,151.7,129z M151,129.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C152.2,130.2,151.7,129.9,151,129.9z"/>
				<path d="M157.7,130.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V130.8z M156.4,127.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S156.5,127.3,156.4,127.1z"/>
				<path d="M163.8,129c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
					s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
					c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C163,129.1,163.4,129,163.8,129z"/>
				<path d="M171.4,134.8c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H171.4z M170.7,135.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C171.4,136,171,135.9,170.7,135.9z
					M170.1,129.5c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C170.9,129.7,170.5,129.5,170.1,129.5z"/>
				<path d="M175.3,133.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C174.8,134,175.1,133.9,175.3,133.9z"/>
				<path d="M182.3,127.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
					c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2V127.1z"/>
				<path d="M187.4,130.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V130.8z M186.1,127.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S186.2,127.3,186.1,127.1z"/>
				<path d="M194.4,129.8c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
					c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
					c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4
					c-0.3,0.1-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3
					c0.2-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
					C194.4,129.7,194.4,129.8,194.4,129.8z"/>
				<path d="M196.4,132c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
					c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
					c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
					c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C197.2,132,196.6,132,196.4,132z M196.5,131.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C196.5,131.3,196.5,131.4,196.5,131.4z"/>
				<path d="M202.8,133.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C202.3,134,202.5,133.9,202.8,133.9z"/>
				<path d="M113.8,145c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
					c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
					c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
					c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
					c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
					c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
					c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C112.6,145.2,113.2,145,113.8,145
					z"/>
				<path d="M121.2,145c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2
					s-0.4,0.3-0.6,0.4c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
					c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9
					c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
					c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C120.5,145.1,120.9,145,121.2,145z"/>
				<path d="M127.9,145c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3
					s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5
					c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5
					c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C126.9,145.2,127.4,145,127.9,145z M127.1,151.1c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S126.8,151.1,127.1,151.1z"/>
				<path d="M133.2,150c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
					c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
					c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2
					c0-0.1,0.1-0.1,0.1-0.1s0-0.1,0-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0
					c0,0-0.1,0-0.1,0c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.4-0.1c0,0,0,0.1,0,0.2
					c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
					c0.5,0.5,0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2
					c0,0.1,0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4
					c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0,0V150z"/>
				<path d="M139.4,148c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
					c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
					c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
					c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C140.2,148,139.5,148,139.4,148z M139.5,147.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C139.5,147.3,139.5,147.4,139.5,147.4z"/>
				<path d="M147.1,145c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
					c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
					c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
					c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
					c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
					c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
					c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C146,145.2,146.5,145,147.1,145z"
					/>
				<path d="M151.2,149.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C150.7,150,150.9,149.9,151.2,149.9z"/>
				<path d="M159.7,145c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
					c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
					c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
					c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
					c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
					c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
					c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C158.6,145.2,159.1,145,159.7,145
					z"/>
				<path d="M166.7,145c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2
					c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C166.1,145.1,166.4,145,166.7,145z M166,145.9c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C167.1,146.2,166.6,145.9,166,145.9z"/>
				<path d="M172.6,146.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V146.8z M171.3,143.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S171.5,143.3,171.3,143.1z"/>
				<path d="M178,145c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
					c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
					c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8C177.3,145.1,177.7,145,178,145z M177.5,145.7c-0.6,0-1,0.3-1.4,0.8
					c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4
					c0-0.2-0.2-0.4-0.5-0.5C178.3,145.8,177.9,145.7,177.5,145.7z"/>
				<path d="M183.1,148c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
					c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
					c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5
					c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4
					c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2
					c-0.2,0-0.9,0-2,0.1C183.9,148,183.3,148,183.1,148z M183.2,147.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4
					s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5
					c-0.1,0.2-0.1,0.3-0.2,0.5C183.2,147.3,183.2,147.4,183.2,147.4z"/>
				<path d="M192.6,145c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C191.9,145.2,192.2,145,192.6,145z"/>
				<path d="M196.5,145c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
					c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
					c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
					c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
					c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
					c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
					c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
					c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C195.4,145.2,195.9,145,196.5,145
					z"/>
			</g>

      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path d="M340.2,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3s-0.2-0.2-0.3-0.2
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S340.2,319.8,340.2,319.8z"/>
        <path d="M344.6,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S344.1,319.1,344.6,319.1z M343.8,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S343.5,325.2,343.8,325.2z"/>
        <path d="M350.1,319.1h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H348c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L350.1,319.1L350.1,319.1z"/>
        <path d="M354.1,323.9c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
          s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
          s0.1-0.4,0.3-0.5S353.8,323.9,354.1,323.9z"/>
        <path d="M364.8,319c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8s0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2
          s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3s-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
          s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
          c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8
          c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
          s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3s-0.1,0.3-0.1,0.4v0.1l0,0c0.2-0.2,0.6-0.4,0.9-0.6S364.5,319,364.8,319z M364.1,319.9
          c-0.3,0-0.6,0.1-0.9,0.2s-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
          s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1S364.8,319.9,364.1,319.9z"/>
        <path d="M373,319c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
          c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
          c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
          c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
          c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S372.7,319,373,319z"/>
        <path d="M375.9,322c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
          c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
          s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
          s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S376,322,375.9,322z
          M376,321.4h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2
          s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S376,321.4,376,321.4z"/>
        <path d="M385.6,324.8c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
          c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3
          c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1
          c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1
          c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5
          s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
          s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V324.8z M384.9,325.9c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
          s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8
          c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S385.2,325.9,384.9,325.9z M384.2,319.5c-0.4,0-0.8,0.2-1,0.6
          s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
          S384.7,319.5,384.2,319.5z"/>
        <path d="M393.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S392.9,319,393.2,319z"/>
        <path d="M400.2,319.1c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
          s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
          c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
          s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
          s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
          s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S399.7,319.1,400.2,319.1z M399.4,325.2c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4v-2.1
          c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S399.1,325.2,399.4,325.2z"/>
        <path d="M408.2,319c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6
          c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
          c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
          s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8
          c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
          c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7S407.9,319,408.2,319z"/>
        <path d="M417.5,319.8c0,0.3-0.1,0.4-0.2,0.6s-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3S416,320,416,320
          c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9c0,0.9,0.2,1.5,0.7,2s1.1,0.7,1.8,0.7
          c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4
          s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5s-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5
          c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1s0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
          S417.5,319.8,417.5,319.8z"/>
        <path d="M419.7,319.2c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1s-0.2,0.1-0.2,0.2
          c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4s-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2
          s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3s0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4
          c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9s-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9
          c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8
          c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C418.6,319.2,419.2,319.2,419.7,319.2z"/>
      </g>
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
        <path d="M307.4,409.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
          c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
          c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
          c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5
          c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5
          s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2C307.4,409,307.4,409.1,307.4,409.2z"/>
        <path d="M311.8,408.4c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0
          s0,0,0,0s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3
          s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
          c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
          c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5s-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
          c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
          C310.8,408.5,311.3,408.4,311.8,408.4z M311,414.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
          c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S310.7,414.5,311,414.5z"/>
        <path d="M317.3,408.4h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
          c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
          c-0.2-0.1-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8V409h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
          c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1h0.1l0,0L317.3,408.4L317.3,408.4z"/>
      </g>
      <g id="ru" style={{ opacity: selected === 'RU' ? 1 : 0 }}>
		<path d="M175.7,415.3c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0-0.2,0
			s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1s-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
			c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1
			v0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5s0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8
			s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8
			C176.7,415.2,176.2,415.3,175.7,415.3z M175.9,409.3c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2
			v3.8c0,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3s0.7-0.1,1-0.4s0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1
			C177.2,409.5,176.6,409.3,175.9,409.3z"/>
		<path d="M185.6,410.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1v0.7v2.6c0,0.5,0,0.8,0.1,1.2c0,0.1,0.1,0.1,0.2,0.2
			c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.2,0c0,0,0,0.1,0,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
			s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c0,0-0.1,0-0.1-0.1s0-0.1,0-0.2v-0.7v-0.1
			c-0.2,0.3-0.6,0.5-1,0.7s-0.8,0.3-1.2,0.3c-0.3,0-0.6-0.1-0.8-0.2s-0.4-0.3-0.6-0.5s-0.2-0.4-0.3-0.7c-0.1-0.3-0.1-0.6-0.2-0.8
			s0-0.5,0-0.8v-2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9,0,1.6-0.1,2.1-0.3
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.1-0.1,0.5-0.1,1.1v0.7v1.6c0,0.8,0.1,1.4,0.4,1.8s0.6,0.6,1.2,0.6c0.3,0,0.7-0.1,1-0.3
			c0.3-0.2,0.5-0.4,0.5-0.6v-3.5H185.6z"/>
		<path d="M189.6,406.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L189.6,406.5L189.6,406.5z"/>
		<path d="M193.6,406.5c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
			c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
			s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L193.6,406.5L193.6,406.5z"/>
		<path d="M197.8,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S197.5,413.3,197.8,413.3z"/>
		<path d="M208.5,408.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C207.9,408.5,208.2,408.4,208.5,408.4z M207.8,409.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C209,409.5,208.5,409.3,207.8,409.3z"/>
		<path d="M214.8,410.1v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
			s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H214.8z M213.5,406.5
			c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
			c-0.2,0.2-0.3,0.2-0.6,0.2S213.7,406.6,213.5,406.5z"/>
		<path d="M220.9,414.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2h1.4V414.1z M220.1,415.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
			c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C220.8,415.3,220.5,415.3,220.1,415.3z M219.5,408.8c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C220.3,409,220,408.8,219.5,408.8z"/>
		<path d="M225.1,413.3c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
			c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
			s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5S224.8,413.3,225.1,413.3z"/>
		<path d="M171.4,424.4c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
			c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
			c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			s0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
			c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4v0.1l0,0
			c0.2-0.2,0.6-0.4,0.9-0.6C170.8,424.5,171.1,424.4,171.4,424.4z M170.7,425.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4
			v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3s0.8-0.1,1.1-0.4s0.6-0.6,0.7-1c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
			C171.9,425.5,171.4,425.3,170.7,425.3z"/>
		<path d="M179.6,424.4c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
			c-0.1,0.1-0.2,0.2-0.3,0.2s-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
			c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
			c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
			c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
			s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7
			c0,0.4,0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1C178.9,424.6,179.3,424.4,179.6,424.4z"/>
		<path d="M182.5,427.3c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
			c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
			s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
			s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
			C183.3,427.3,182.6,427.3,182.5,427.3z M182.6,426.7h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
			c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5
			S182.6,426.7,182.6,426.7z"/>
		<path d="M192.2,430.1c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3
			c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
			c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
			c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0
			c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2
			s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
			s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4
			c0.2,0.1,0.4,0.2,0.7,0.2h1.4V430.1z M191.5,431.3c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.4-0.3,0.6
			c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8
			c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C192.1,431.3,191.8,431.3,191.5,431.3z M190.8,424.8c-0.4,0-0.8,0.2-1,0.6
			s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
			C191.6,425,191.3,424.8,190.8,424.8z"/>
		<path d="M199.8,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S199.5,424.4,199.8,424.4z"/>
		<path d="M206.8,424.4c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
			s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
			c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
			c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
			c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
			c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
			C205.8,424.5,206.3,424.4,206.8,424.4z M206,430.5c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
			c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S205.7,430.5,206,430.5z"/>
		<path d="M214.8,424.4c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
			v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
			c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
			c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
			c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
			c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
			s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
			c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
			c0.2-0.2,0.5-0.5,1-0.7S214.5,424.4,214.8,424.4z"/>
		<path d="M224.1,425.2c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1s-0.2-0.2-0.3-0.3
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
			c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
			c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2
			s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4
			c0,0,0.1,0.1,0.1,0.2C224.1,425,224.1,425.1,224.1,425.2z"/>
		<path d="M226.3,424.6c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2s-0.2,0-0.3,0s-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5s-0.1-0.3-0.2-0.4
			c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
			c0,0.1,0,0.2-0.1,0.2s-0.3,0-0.5,0.1s-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
			c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
			c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9
			c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2s0-0.2,0-0.2C225.2,424.5,225.8,424.6,226.3,424.6z"/>
	</g>
    
			<g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
					<path d="M279.9,404.6c0.2,0,0.4,0,0.4,0s0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
						s-0.1,0.2-0.2,0.2s-0.1,0.1-0.2,0.1s-0.1,0-0.2,0s-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.6,0.4
						s-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7v4.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
						s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
						c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9
						c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8
						S279.7,404.6,279.9,404.6z"/>
					<path d="M284,408.7c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
						s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
						c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
						s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
						s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
						s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S283.5,408.7,284,408.7z M283.1,414.8c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4V412
						c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S282.8,414.8,283.1,414.8z"/>
					<path d="M288,406.8c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
						c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
						c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
						s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V406.8z"/>
					<path d="M291.7,406.8c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1
						c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
						c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
						s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
						V406.8z"/>
					<path d="M296.8,410.4v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
						c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
						s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
						c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H296.8z
						M295.5,406.8c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
						s-0.3,0.2-0.6,0.2S295.7,407,295.5,406.8z"/>
					<path d="M302.9,408.7c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
						c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
						s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V412
						c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
						c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
						s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
						v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
						c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
						S302.6,408.7,302.9,408.7z"/>
					<path d="M310.6,414.5c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
						s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4
						s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
						s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
						c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1
						h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
						c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V414.5z M309.8,415.6
						c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
						s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S310.2,415.6,309.8,415.6z M309.2,409.1
						c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
						S309.7,409.1,309.2,409.1z"/>
					<path d="M319.7,408.8h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
						c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
						s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
						c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L319.7,408.8L319.7,408.8z"/>
					<path d="M323.5,411.6c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
						c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
						s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
						s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S323.6,411.6,323.5,411.6z
						M323.5,411.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
						s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S323.5,411.1,323.5,411.1z"/>
					<path d="M329.9,411.6c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
						c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
						s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
						s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S330,411.6,329.9,411.6z
						M329.9,411.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
						s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S329.9,411.1,329.9,411.1z"/>
					<path d="M339.3,408.7c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
						c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
						s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V412
						c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
						c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
						s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
						v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
						c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0c0.2-0.2,0.5-0.5,1-0.7S339,408.7,339.3,408.7z"/>
					<path d="M343.9,413.6c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
						s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
						s0.1-0.4,0.3-0.5S343.6,413.6,343.9,413.6z"/>
					<path d="M294.5,430.5c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
						s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4
						s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
						s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
						c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1
						h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
						c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V430.5z M293.7,431.6
						c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
						s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S294.1,431.6,293.7,431.6z M293.1,425.1
						c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
						S293.6,425.1,293.1,425.1z"/>
					<path d="M298.5,427.6c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
						c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
						s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
						s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S298.6,427.6,298.5,427.6z
						M298.5,427.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
						s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S298.5,427.1,298.5,427.1z"/>
					<path d="M305.6,424.8h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
						c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
						s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
						c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L305.6,424.8L305.6,424.8z"/>
					<path d="M310.2,424.8h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
						c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
						s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5H308c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
						c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L310.2,424.8L310.2,424.8z"/>
					<path d="M315,426.4v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
						c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
						s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
						c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H315z
						M313.8,422.8c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
						s-0.3,0.2-0.6,0.2S313.9,423,313.8,422.8z"/>
					<path d="M321.1,424.7c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2
						c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
						s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V428
						c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2
						c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
						s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
						v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2
						c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0c0.2-0.2,0.5-0.5,1-0.7
						S320.8,424.7,321.1,424.7z"/>
					<path d="M328.8,430.5c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1s-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
						s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7s-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5s0.4-0.3,0.5-0.4
						s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5s-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6s0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9
						s-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0s0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
						c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3s-0.2,0.3-0.2,0.3l-1-0.1
						h-0.1c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1
						c-0.2,0.1-0.3,0.2-0.4,0.3s-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4s0.4,0.2,0.7,0.2h1.4V430.5z M328.1,431.6
						c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3s0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
						s0.7-0.4,0.9-0.6s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3S328.4,431.6,328.1,431.6z M327.4,425.1
						c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5s0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4
						S327.9,425.1,327.4,425.1z"/>
					<path d="M276.9,440.7c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
						s0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2
						s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
						c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V444c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7s-0.1-0.4-0.2-0.5
						s-0.2-0.2-0.4-0.3s-0.4-0.1-0.6-0.1s-0.5,0.1-0.7,0.2s-0.5,0.3-0.6,0.4s-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1s0,0.1,0,0.1
						c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
						c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
						s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2s-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3s-0.5,0.4-0.5,0.5v3.3
						c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
						c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
						c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
						s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8v0.1l0,0
						c0.5-0.4,0.9-0.7,1.2-0.8C276.3,440.7,276.6,440.7,276.9,440.7z"/>
					<path d="M287.7,440.7c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
						s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
						c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
						s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
						s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
						s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S287.2,440.7,287.7,440.7z M286.8,446.8c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4V444
						c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S286.5,446.8,286.8,446.8z"/>
					<path d="M295.1,440.7c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
						c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
						c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
						c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
						c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
						c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S294.7,440.7,295.1,440.7z"/>
					<path d="M300.7,440.7c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
						c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
						c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
						c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
						c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
						c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S300.4,440.7,300.7,440.7z"/>
					<path d="M304.5,442.4v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
						c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
						s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
						c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H304.5z
						M303.2,438.8c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2s0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
						s-0.3,0.2-0.6,0.2S303.4,439,303.2,438.8z"/>
					<path d="M307.3,443.6c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1s0,0.1,0,0.1
						c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
						s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2s0.6-0.6,1-0.8s0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4
						s0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1S307.4,443.6,307.3,443.6z
						M307.4,443.1h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2
						s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S307.3,443.1,307.4,443.1z"/>
					<path d="M316.3,440.7c0.2,0,0.4,0,0.6,0.1s0.4,0.1,0.5,0.1s0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3
						c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4
						v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2s0.2,0.1,0.3,0.1s0.2,0,0.3,0s0.1,0,0.1,0s0,0.1,0,0.2v0.1l-0.1,0.1
						c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1s-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1h-0.1c-0.1,0-0.1-0.1-0.1-0.3V447
						c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
						s0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8S315.9,440.7,316.3,440.7z M315.8,441.3c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
						c0,0.8,0.2,1.4,0.6,2s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S316.2,441.3,315.8,441.3z
						"/>
					<path d="M321.3,445.6c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
						s-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7
						s0.1-0.4,0.3-0.5S321.1,445.6,321.3,445.6z"/>
					<path d="M331.6,440.7c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6s-0.2,0.2-0.3,0.2
						c-0.1,0-0.1,0-0.2,0s-0.1-0.1-0.2-0.1s-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4
						c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1
						c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2v-2.9
						c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
						c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2s0,0.3-0.1,0.7s0,0.7,0,0.7l0,0l0,0l0,0c0.2-0.4,0.4-0.8,0.8-1.1S331.3,440.7,331.6,440.7z"/>
					<path d="M336.3,440.7c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0s0,0,0,0
						s0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7
						c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5
						s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1s-0.6-0.5-0.9-0.5
						s-0.5,0.1-0.6,0.2s-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
						s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9S335.8,440.7,336.3,440.7z M335.4,446.8c0.3,0,0.5-0.1,0.9-0.3s0.5-0.3,0.5-0.4V444
						c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S335.2,446.8,335.4,446.8z"/>
					<path d="M341.4,440.8h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
						c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1s-0.4-0.1-0.5-0.3
						s-0.3-0.3-0.4-0.5s-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2s0.2-0.2,0.2-0.3h0.6v-1
						c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2s0.1,0,0.1-0.1s0.1,0,0.1,0l0,0L341.4,440.8L341.4,440.8z"/>
					<path d="M346.5,440.7c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1s-0.2,0-0.3-0.1
						c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3s-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.1,0.3
						s0.1,0.2,0.2,0.2s0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.3,0.2s0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2s0.2,0.1,0.3,0.2
						s0.3,0.2,0.3,0.3s0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4s0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0
						s-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8s-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
						c0.1,0.4,0.3,0.7,0.6,1s0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5s-0.2-0.3-0.3-0.4
						s-0.3-0.2-0.4-0.3s-0.3-0.2-0.5-0.3s-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
						S345.9,440.7,346.5,440.7z"/>
				</g>
			<g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
			<path d="M240.3,72.9c0.2,0,0.4,0,0.4,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.2s0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3
				c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.5-0.1
				c-0.3,0-0.5,0.1-0.6,0.4c-0.2,0.3-0.2,0.6-0.2,1.1v1.9h1.8c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.7V82
				c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.3,0,0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-4.1h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
				c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-0.3c0-0.5,0.1-1.1,0.3-1.6s0.4-1,0.7-1.3s0.6-0.6,0.9-0.8C239.8,73,240.1,72.9,240.3,72.9z"/>
			<path d="M244.4,77c0.5,0,0.9,0.2,1.2,0.6s0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7h0.1h0.1h0.1h0.1c0,0,0,0,0.1,0l0,0
				c0,0,0.1,0,0.1,0.1s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4S246,84,245.9,84c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7
				c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8
				c0.2-0.2,0.3-0.4,0.5-0.5s0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4
				c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8
				c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9
				C243.4,77.1,243.9,77,244.4,77z M243.6,83.1c0.3,0,0.5-0.1,0.9-0.3c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5
				c-0.3,0.1-0.5,0.3-0.7,0.5s-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.8S243.3,83.1,243.6,83.1z"/>
			<path d="M248.4,75.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
				c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4V82c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
				c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
				c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L248.4,75.1L248.4,75.1z"/>
			<path d="M252.1,75.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
				c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4V82c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
				c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
				c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2L252.1,75.1L252.1,75.1z"/>
			<path d="M257.2,78.7V82c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2V79c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
				c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
				c-0.1,0.5-0.1,0.9-0.1,1v0.6H257.2z M255.9,75.1c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
				s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S256.1,75.2,255.9,75.1z"/>
			<path d="M263.3,76.9c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2V82
				c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
				s-0.2,0.2-0.2,0.3V82c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9v0.1l0,0
				c0.2-0.2,0.5-0.5,1-0.7S263,76.9,263.3,76.9z"/>
			<path d="M271,82.7c0.6,0,1.2,0.2,1.7,0.5s0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1s-0.8,0.5-1.3,0.7
				s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2s-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5
				c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6
				c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1c0-0.6,0.3-1.2,0.8-1.7s1.1-0.7,1.9-0.7
				c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2
				c0.1,0,0.2,0.1,0.2,0.2s0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1h-0.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9
				c0,0.6-0.3,1.1-0.8,1.6s-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3
				c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2L271,82.7L271,82.7z M270.2,83.8c-0.9,0-1.4,0-1.5,0.1
				c-0.2,0.1-0.4,0.3-0.6,0.5s-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2
				s0.7-0.4,0.9-0.6c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3
				C270.9,83.9,270.6,83.8,270.2,83.8z M269.6,77.4c-0.4,0-0.8,0.2-1,0.6s-0.4,0.8-0.4,1.3s0.1,1,0.4,1.4s0.6,0.6,1.1,0.6
				c0.4,0,0.7-0.2,1-0.5c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C270.4,77.6,270.1,77.4,269.6,77.4z"/>
			<path d="M230.9,93h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4v0.1
				c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0-0.6-0.1
				c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1
				c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2
				s0.1,0,0.1-0.1h0.1l0,0V93H230.9z"/>
			<path d="M234.7,95.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
				s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
				s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
				C235.5,95.9,234.8,95.9,234.7,95.9z M234.8,95.3h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
				c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
				C234.8,95.3,234.8,95.3,234.8,95.3z"/>
			<path d="M241.1,95.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9
				s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2
				s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1
				C241.9,95.9,241.2,95.9,241.1,95.9z M241.2,95.3h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5
				c-0.1-0.2-0.2-0.3-0.4-0.4s-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5
				C241.2,95.3,241.2,95.3,241.2,95.3z"/>
			<path d="M250.6,92.9c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2V98
				c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.2,0.2-0.2,0.3V98c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V91c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
				s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5l0,0l0,0
				c0.2-0.2,0.5-0.5,1-0.7C249.8,93.1,250.2,92.9,250.6,92.9z"/>
			<path d="M255.2,97.8c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1s-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5s0.1-0.4,0.1-0.6s-0.1-0.4-0.3-0.6
				s-0.3-0.5-0.3-0.7s0.1-0.4,0.3-0.5C254.7,97.9,254.9,97.8,255.2,97.8z"/>
			<path d="M262.1,91.1c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1s0-0.1,0-0.2s0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6
				c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4V98c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
				c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
				s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V91.1z"/>
			<path d="M267.2,94.7V98c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2s0.1-0.5,0.1-1.2V95c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
				s0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1v0.6H267.2z M265.9,91.1
				c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6
				c-0.2,0.2-0.3,0.2-0.6,0.2S266.1,91.2,265.9,91.1z"/>
			<path d="M274.2,93.7c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
				c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5l0,0c0,0,0,0,0.1,0.1v0.1
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
				c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1
				c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
				C274.2,93.6,274.2,93.7,274.2,93.7z"/>
			<path d="M276.3,95.9c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6l0,0c0,0,0,0,0.1,0.1v0.1
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
				c-0.9,0-1.7-0.3-2.2-0.9c-0.6-0.6-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.6,1-0.8
				c0.4-0.2,0.7-0.3,1.1-0.3s0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6s0.1,0.3,0.1,0.5s0,0.4-0.1,0.5
				s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C277,95.9,276.4,95.9,276.3,95.9z M276.4,95.3h2.5c0.2,0,0.3-0.1,0.4-0.4
				c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2s-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
				s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S276.3,95.3,276.4,95.3z"/>
		</g>
			<g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
				<path d="M111.4,415c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
					c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1c0,0,0,0.1,0,0.1s0,0,0,0.1s0,0,0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5
					c0.4-0.2,0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8C112.4,414.9,111.9,415,111.4,415z M111.6,409
					c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
					c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S112.3,409,111.6,409z"/>
				<path d="M117.6,411c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C118.4,411,117.7,411,117.6,411z M117.7,410.5h2.5
					c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
					s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C117.7,410.4,117.6,410.5,117.7,410.5z"/>
				<path d="M124.3,411c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C125.1,411,124.4,411,124.3,411z M124.4,410.5h2.5
					c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
					s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C124.4,410.4,124.4,410.5,124.4,410.5z"/>
				<path d="M132.3,408.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
					c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
					c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
					c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S131.7,408.1,132.3,408.1z"/>
				<path d="M136.7,413c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C136.2,413.1,136.4,413,136.7,413z"/>
				<path d="M147.4,408.1c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C146.8,408.2,147.1,408.1,147.4,408.1z M146.7,409c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C147.9,409.2,147.4,409,146.7,409z"/>
				<path d="M155.6,408.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C154.9,408.3,155.3,408.1,155.6,408.1z"/>
				<path d="M158.5,411c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C159.3,411,158.6,411,158.5,411z M158.6,410.5h2.5
					c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
					s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C158.6,410.4,158.6,410.5,158.6,410.5z"/>
				<path d="M168.2,413.9c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H168.2z M167.5,415c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C168.1,415,167.8,415,167.5,415z M166.8,408.5
					c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S167.3,408.5,166.8,408.5z"/>
				<path d="M175.8,408.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C175.1,408.2,175.5,408.1,175.8,408.1z"/>
				<path d="M182.8,408.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
					s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
					c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
					s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C181.8,408.2,182.3,408.1,182.8,408.1z M182,414.2c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S181.7,414.2,182,414.2z"/>
				<path d="M190.9,408.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C190.1,408.2,190.5,408.1,190.9,408.1z"/>
				<path d="M200.1,408.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
					s-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9
					c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1
					c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
					C200.1,408.7,200.1,408.8,200.1,408.9z"/>
				<path d="M202.3,408.3c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0.1
					c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
					c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1c0,0,0,0.1,0,0.3
					s0,0.2-0.1,0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6c-0.1,0.1-0.2,0.4-0.3,0.9
					s-0.3,0.8-0.5,1.1c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5
					c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8s0.3-0.7,0.3-0.8c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3
					c0,0,0-0.1,0-0.2s0-0.2,0-0.2C201.3,408.2,201.8,408.3,202.3,408.3z"/>
				<path d="M209.2,413c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C208.7,413.1,208.9,413,209.2,413z"/>
				<path d="M218.6,415c-0.2,0-0.6-0.1-1.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
					c0,0-0.1,0-0.2,0s-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.2-0.1-0.2c0.1-0.3,0.2-0.7,0.2-1.2v-7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.1c0,0,0,0.1,0,0.1s0,0,0,0.1s0,0,0,0c0,0,0,0,0.1,0c0.2-0.2,0.5-0.3,0.9-0.5
					c0.4-0.2,0.7-0.3,1-0.3c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1
					c0,0.5-0.1,1-0.3,1.4s-0.5,0.9-0.8,1.2c-0.3,0.3-0.7,0.6-1.2,0.8C219.7,414.9,219.2,415,218.6,415z M218.9,409
					c-0.2,0-0.4,0-0.7,0.1s-0.4,0.2-0.5,0.3c-0.1,0.1-0.1,0.1-0.1,0.2v3.8c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
					c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.6-0.6,0.8-1s0.3-0.8,0.3-1.2c0-0.9-0.2-1.6-0.6-2.1S219.6,409,218.9,409z"/>
				<path d="M227.9,408.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C227.2,408.3,227.5,408.1,227.9,408.1z"/>
				<path d="M230.8,411c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C231.6,411,230.9,411,230.8,411z M230.9,410.5h2.5
					c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
					s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C230.9,410.4,230.8,410.5,230.9,410.5z"/>
				<path d="M239.5,408.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
					s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
					c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
					s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C238.5,408.2,239,408.1,239.5,408.1z M238.7,414.2c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S238.4,414.2,238.7,414.2z"/>
				<path d="M246.8,408.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
					c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
					c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S246.5,408.1,246.8,408.1z M246.3,408.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
					c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
					C247.1,408.8,246.8,408.7,246.3,408.7z"/>
				<path d="M252.2,413c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C251.7,413.1,251.9,413,252.2,413z"/>
				<path d="M78.6,424.4c1.1,0,1.9-0.1,2.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.2
					c-0.1,0.4-0.1,0.8-0.1,1.1v3.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2c0.3,0,0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V425h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3
					c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9s0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2s0.6,0.3,0.7,0.5
					c0.3,0.3,0.5,0.7,0.5,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2
					c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4s-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9
					c-0.3,0.6-0.5,1.2-0.5,2v0.8H78.6z"/>
				<path d="M85.4,424.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
					c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
					c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
					c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S84.8,424.1,85.4,424.1z"/>
				<path d="M92.9,424.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
					c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5
					c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7C92.2,424.2,92.6,424.1,92.9,424.1z"/>
				<path d="M97.8,429c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C97.4,429.1,97.6,429,97.8,429z"/>
				<path d="M108.7,429.9c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H108.7z M108,431c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C108.7,431,108.3,431,108,431z M107.4,424.5
					c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S107.8,424.5,107.4,424.5z"/>
				<path d="M113,427c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C113.8,427,113.2,427,113,427z M113.1,426.5h2.5c0.2,0,0.3-0.1,0.4-0.4
					c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
					s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C113.1,426.4,113.1,426.5,113.1,426.5z"/>
				<path d="M120.5,424.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
					c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9
					c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4
					c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1s0.1,0,0.1,0s0,0,0,0V424.2z"/>
				<path d="M125.4,424.2h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.2
					c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9
					c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4s0.3-0.3,0.4-0.4
					c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1s0.1,0,0.1,0s0,0,0,0V424.2z"/>
				<path d="M130.6,425.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V425.8z M129.3,422.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S129.4,422.3,129.3,422.2z"/>
				<path d="M137,424.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C136.3,424.2,136.7,424.1,137,424.1z"/>
				<path d="M145,429.9c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H145z M144.2,431c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C144.9,431,144.6,431,144.2,431z M143.6,424.5
					c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S144.1,424.5,143.6,424.5z"/>
				<path d="M157.2,424.1c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4
					c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
					c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0
					c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9
					c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2
					c-0.3,0.1-0.5,0.3-0.6,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3
					c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.5-0.4,0.9-0.7,1.2-0.8
					C156.7,424.1,157,424.1,157.2,424.1z"/>
				<path d="M168.3,424.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
					s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
					c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
					s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C167.3,424.2,167.8,424.1,168.3,424.1z M167.5,430.2c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S167.2,430.2,167.5,430.2z"/>
				<path d="M176.1,424.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C175.4,424.3,175.7,424.1,176.1,424.1z"/>
				<path d="M182,424.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.2
					c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6,0.2-0.9,0.5
					s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0
					c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7
					c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1C181.4,424.3,181.7,424.1,182,424.1z"/>
				<path d="M186.1,425.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V425.8z M184.8,422.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S185,422.3,184.8,422.2z"/>
				<path d="M189.2,427c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C190,427,189.4,427,189.2,427z M189.3,426.5h2.5c0.2,0,0.3-0.1,0.4-0.4
					c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
					s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C189.3,426.4,189.3,426.5,189.3,426.5z"/>
				<path d="M198.5,424.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
					c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
					c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S198.2,424.1,198.5,424.1z M198,424.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
					c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
					C198.9,424.8,198.5,424.7,198,424.7z"/>
				<path d="M203.9,429c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C203.4,429.1,203.7,429,203.9,429z"/>
				<path d="M214.8,429.9c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H214.8z M214.1,431c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C214.7,431,214.4,431,214.1,431z M213.5,424.5
					c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S213.9,424.5,213.5,424.5z"/>
				<path d="M222.1,424.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C221.5,424.3,221.8,424.1,222.1,424.1z"/>
				<path d="M227.1,424.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
					s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
					c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
					s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C226.1,424.2,226.6,424.1,227.1,424.1z M226.3,430.2c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S226,430.2,226.3,430.2z"/>
				<path d="M234.6,424.1c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C234,424.2,234.4,424.1,234.6,424.1z M234,425c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C235.1,425.2,234.6,425,234,425z"/>
				<path d="M239.8,427c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C240.6,427,239.9,427,239.8,427z M239.9,426.5h2.5
					c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
					s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C239.9,426.4,239.9,426.5,239.9,426.5z"/>
				<path d="M247.8,424.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
					c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
					c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
					c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S247.2,424.1,247.8,424.1z"/>
				<path d="M252.2,429c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C251.7,429.1,251.9,429,252.2,429z"/>
				<path d="M54.9,445.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
					c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
					c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1
					s0-0.1,0-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0s-0.1,0-0.1,0s-0.1,0-0.1,0c0-0.1-0.1-0.1,0-0.3
					s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.4-0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1
					s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2c0.5,0.5,0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6
					c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
					s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0,0V445.1z"/>
				<path d="M63.3,441.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V441.8z M62,438.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S62.2,438.3,62,438.2z"/>
				<path d="M69,440.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
					c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
					c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S68.6,440.1,69,440.1z M68.5,440.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
					c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
					C69.3,440.8,68.9,440.7,68.5,440.7z"/>
				<path d="M77.7,440.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2S77.6,441,77,441c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7C77,440.2,77.4,440.1,77.7,440.1z"
					/>
				<path d="M84.8,440.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
					s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
					c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
					s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C83.8,440.2,84.3,440.1,84.8,440.1z M83.9,446.2c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S83.6,446.2,83.9,446.2z"/>
				<path d="M92.3,440.1c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6C91.7,440.2,92,440.1,92.3,440.1z
					M91.6,441c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3
					c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C92.8,441.2,92.2,441,91.6,441z"/>
				<path d="M100.3,440.1c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C99.6,440.2,100,440.1,100.3,440.1z M99.6,441c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C100.7,441.2,100.2,441,99.6,441z"/>
				<path d="M106.5,441.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V441.8z M105.2,438.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S105.4,438.3,105.2,438.2z"/>
				<path d="M112.9,440.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C112.2,440.2,112.6,440.1,112.9,440.1z"/>
				<path d="M120.9,445.9c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
					c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
					c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
					c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
					c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5s0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
					c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
					c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H120.9z M120.2,447c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
					s0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4s-0.4-0.2-0.7-0.3C120.9,447,120.6,447,120.2,447z M119.6,440.5
					c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4s0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
					c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4S120,440.5,119.6,440.5z"/>
				<path d="M125.2,445c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C124.7,445.1,124.9,445,125.2,445z"/>
				<path d="M132.7,438.2c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
					c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
					c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
					c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
					c0.1-0.2,0.1-0.6,0.1-1.2V438.2z"/>
				<path d="M138.1,441.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V441.8z M136.9,438.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S137,438.3,136.9,438.2z"/>
				<path d="M145.5,440.9c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
					s-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7s-0.5,1.1-0.5,1.9
					c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3c0.2-0.4,0.4-0.8,0.6-1.1
					c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
					C145.5,440.7,145.5,440.8,145.5,440.9z"/>
				<path d="M147.9,443c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C148.6,443,148,443,147.9,443z M148,442.5h2.5c0.2,0,0.3-0.1,0.4-0.4
					c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
					s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C148,442.4,147.9,442.5,148,442.5z"/>
				<path d="M154.5,445c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C154,445.1,154.2,445,154.5,445z"/>
				<path d="M163.7,440.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
					c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
					c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
					c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S163.1,440.1,163.7,440.1z"/>
				<path d="M171.5,440.1c0.3,0,0.6,0,0.9,0.1s0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9s0,0.7,0,1.2v1.7
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2s-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
					s-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-2.8c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7
					C170.8,440.2,171.2,440.1,171.5,440.1z"/>
				<path d="M178.5,440.1c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
					s0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
					s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4
					c-0.5,0-0.9-0.2-1.3-0.5s-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
					s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
					c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
					s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C177.5,440.2,178,440.1,178.5,440.1z M177.7,446.2c0.3,0,0.5-0.1,0.9-0.3
					c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
					c0,0.3,0.1,0.5,0.3,0.8S177.4,446.2,177.7,446.2z"/>
				<path d="M184.1,445.1c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1
					c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
					c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4s0.3-0.3,0.4-0.3s0.2-0.2,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0-0.1,0.1-0.1,0.1-0.1
					s0-0.1,0-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0s-0.1,0-0.1,0s-0.1,0-0.1,0c0-0.1-0.1-0.1,0-0.3
					s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.4-0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1
					s-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2c0.5,0.5,0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6
					c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2s0,0.2,0,0.2c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2
					s-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1l0,0V445.1z"/>
				<path d="M190.6,443c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C191.4,443,190.8,443,190.6,443z M190.7,442.5h2.5
					c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2
					s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C190.7,442.4,190.7,442.5,190.7,442.5z"/>
				<path d="M198.7,440.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
					c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
					c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
					c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S198.1,440.1,198.7,440.1z"/>
				<path d="M203,445c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6c-0.4,0.6-0.8,1.1-1.2,1.3
					c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5
					c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5C202.6,445.1,202.8,445,203,445z"/>
				<path d="M212.3,440.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
					c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
					c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
					c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S211.7,440.1,212.3,440.1z"/>
				<path d="M219.5,440.1c0.5,0,0.9,0.1,1.2,0.3s0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1s0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
					c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
					c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
					s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2c-0.2,0-0.3-0.1-0.4-0.1
					c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
					c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6
					C218.9,440.2,219.2,440.1,219.5,440.1z M218.9,441c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8
					c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1s0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1
					C220,441.2,219.5,441,218.9,441z"/>
				<path d="M225.8,441.8v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2-0.1,0.2
					c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V441.8z M224.5,438.2c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2
					s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S224.7,438.3,224.5,438.2z"/>
				<path d="M231.5,440.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
					c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
					c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1
					s-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1
					c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.2-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1c-0.6-0.6-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4
					c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.8-0.6,1.2-0.8S231.1,440.1,231.5,440.1z M231,440.7c-0.6,0-1,0.3-1.4,0.8s-0.5,1.1-0.5,1.8
					c0,0.8,0.2,1.4,0.6,2c0.4,0.5,0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5
					C231.8,440.8,231.4,440.7,231,440.7z"/>
				<path d="M237,443c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.6c0,0,0,0,0,0c0,0,0,0,0.1,0.1
					c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4s-0.3,0.3-0.5,0.4s-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1
					c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.4-0.9,0.7-1.2s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
					c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6c0,0.2,0.1,0.3,0.1,0.5
					c0,0.2,0,0.4-0.1,0.5s-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C237.7,443,237.1,443,237,443z M237,442.5h2.5c0.2,0,0.3-0.1,0.4-0.4
					c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5
					s-0.2,0.4-0.3,0.5c-0.1,0.2-0.1,0.3-0.2,0.5C237,442.4,237,442.5,237,442.5z"/>
				<path d="M246.7,440.1c0.2,0,0.4,0,0.6,0.1s0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
					c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.3,0-0.6,0.2-0.9,0.5s-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2c0.3,0,0.6,0.1,0.8,0.1c0,0,0,0.1,0.1,0.2
					c0,0.1,0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2
					c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2
					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1
					s0.1,0.1,0.1,0.2c0,0.1,0,0.3-0.1,0.7s0,0.7,0,0.7c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
					C246,440.3,246.4,440.1,246.7,440.1z"/>
				<path d="M251,440.1c0.2,0,0.5,0,0.9,0.1s0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3-0.1
					c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.1,0,0.3,0.1,0.4
					s0.1,0.2,0.1,0.3s0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2s0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2
					c0.2,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.2,0.2,0.4
					c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5s-1,0.6-1.7,0.6c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1
					c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
					c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.2-0.5-0.3
					c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4S250.4,440.1,251,440.1z"/>
			</g>
			<g id="sp" style={{ opacity: selected === 'SP' ? 1 : 0 }}>
			<path d="M326.9,76.8c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
				c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
				c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
				c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4
				c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6C326.3,76.9,326.7,76.8,326.9,76.8z M326.3,77.7c-0.3,0-0.6,0.1-0.9,0.2
				c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
				c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C327.4,78,326.9,77.7,326.3,77.7z"/>
			<path d="M334.8,76.8c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
				c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
				c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
				c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
				s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1S333,77,333,77c0,0.1,0,0.3-0.1,0.7
				c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1C334.1,77,334.5,76.8,334.8,76.8z"/>
			<path d="M337.4,79.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
				c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2
				c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
				c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C338.2,79.8,337.5,79.8,337.4,79.8z
				 M337.5,79.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
				c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S337.5,79.2,337.5,79.2z"
				/>
			<path d="M346.8,82.6c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
				c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
				c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
				c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
				c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
				c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
				c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
				c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
				c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H346.8z M346,83.7c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
				c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
				c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C346.7,83.8,346.4,83.7,346,83.7z
				 M345.4,77.3c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
				c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C346.2,77.5,345.9,77.3,345.4,77.3z"/>
			<path d="M354.1,76.8c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
				v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V79c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1S352,77,352,77c-0.1,0.4-0.1,0.7-0.1,0.9
				c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7S353.7,76.8,354.1,76.8z"/>
			<path d="M360.8,76.9c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0s0.1,0,0.1,0
				c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0s0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
				s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5
				c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
				s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
				c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
				s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C359.7,77,360.3,76.9,360.8,76.9z M359.9,82.9c0.3,0,0.5-0.1,0.9-0.3
				c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
				c0,0.3,0.1,0.5,0.3,0.8S359.6,82.9,359.9,82.9z"/>
			<path d="M368.5,76.8c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
				v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V79c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9
				c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7S368.1,76.8,368.5,76.8z"/>
			<path d="M377.4,77.6c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
				c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
				c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1
				c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
				C377.4,77.5,377.4,77.6,377.4,77.6z"/>
			<path d="M379.3,77c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
				c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
				c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6
				c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7
				c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8
				c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C378.2,77,378.7,77,379.3,77z"/>
			<path d="M385.8,81.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S385.6,81.7,385.8,81.7z"/>
			<path d="M315.4,93.6c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
				c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
				c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1
				c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
				C315.4,93.5,315.4,93.6,315.4,93.6z"/>
			<path d="M320.4,92.8c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
				c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
				c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
				c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
				s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
				c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
				C319.8,93,320.1,92.8,320.4,92.8z"/>
			<path d="M325.3,92.8c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1c-1,0-1.8-0.3-2.4-1
				s-0.9-1.5-0.9-2.5c0-1,0.3-1.8,1-2.5C323.5,93.1,324.3,92.8,325.3,92.8z M325.1,93.3c-0.4,0-0.8,0.1-1.1,0.4
				c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
				c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.8,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2
				S325.5,93.3,325.1,93.3z"/>
			<path d="M334.9,93.6c0,0.3-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
				c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.3-0.2-0.4-0.2s-0.3-0.1-0.5-0.1c-0.4,0-0.9,0.2-1.2,0.7c-0.4,0.5-0.5,1.1-0.5,1.9
				c0,0.9,0.2,1.5,0.7,2c0.5,0.4,1.1,0.7,1.8,0.7c0.4,0,0.7-0.1,1-0.3s0.5-0.4,0.7-0.5c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
				c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1
				c-0.6,0-1.2-0.2-1.6-0.5c-0.5-0.3-0.9-0.7-1.1-1.2s-0.4-1-0.4-1.5c0-0.4,0.1-0.9,0.2-1.3s0.4-0.8,0.6-1.1
				c0.3-0.3,0.6-0.6,0.9-0.8s0.7-0.4,1-0.5s0.7-0.2,1-0.2c0.6,0,1,0.1,1.4,0.4c0,0,0.1,0.1,0.1,0.2
				C334.9,93.5,334.9,93.6,334.9,93.6z"/>
			<path d="M339.2,92.8c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1c-1,0-1.8-0.3-2.4-1
				s-0.9-1.5-0.9-2.5c0-1,0.3-1.8,1-2.5C337.4,93.1,338.2,92.8,339.2,92.8z M339,93.3c-0.4,0-0.8,0.1-1.1,0.4
				c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
				c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.8,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2
				S339.5,93.3,339,93.3z"/>
			<path d="M347.2,92.8c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
				c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
				s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
				c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1s-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1
				c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6
				s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8
				C346.4,92.9,346.8,92.8,347.2,92.8z M346.7,93.5c-0.6,0-1,0.3-1.4,0.8c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
				s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S347.1,93.5,346.7,93.5z"/>
			<path d="M353.4,94.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V94.6z
				 M352.1,91c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
				s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S352.3,91.1,352.1,91z"/>
			<path d="M355.9,90.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1
				c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.4c0,0.6,0,1,0.1,1.2
				c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
				s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
				V90.9z"/>
			<path d="M359.9,95.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
				c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2
				c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
				c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C360.6,95.8,360,95.8,359.9,95.8z
				 M360,95.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2
				c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5S360,94.9,360,95S359.9,95.2,360,95.2z"/>
			<path d="M367.6,92.8c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
				c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
				c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
				c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
				c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
				c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
				c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4C366.5,93,367,92.8,367.6,92.8z"
				/>
			<path d="M371.6,97.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S371.4,97.7,371.6,97.7z"/>
			<path d="M381.7,93.1c1.1,0,1.9-0.1,2.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.2
				c-0.1,0.4-0.1,0.8-0.1,1.1v3.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2
				c0,0.1,0.2,0.1,0.4,0.2s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
				s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
				v-4.1h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9
				c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1
				c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2
				c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8H381.7z"/>
			<path d="M389.9,92.8c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
				c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
				c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
				c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
				s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
				c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
				C389.2,93,389.6,92.8,389.9,92.8z"/>
			<path d="M392.5,95.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
				c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2
				c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
				c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C393.3,95.8,392.6,95.8,392.5,95.8z
				 M392.6,95.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
				c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S392.6,95.2,392.6,95.2z"
				/>
			<path d="M398.8,97.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S398.6,97.7,398.8,97.7z"/>
			<path d="M302.7,109.1c1.1,0,1.9-0.1,2.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2,0,0.2
				c-0.1,0.4-0.1,0.8-0.1,1.1v3.5c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-3c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.5-0.3-1.1-0.3h-1.7v4.1c0,0.6,0,1,0.1,1.2
				c0,0.1,0.2,0.1,0.4,0.2s0.5,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0
				s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2
				v-4.1h-0.9c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2h0.5v-0.2c0-0.6,0.1-1.2,0.4-1.9
				c0.3-0.6,0.7-1.2,1.2-1.6s1.1-0.7,1.6-0.7c0.3,0,0.6,0.1,1,0.2c0.3,0.2,0.6,0.3,0.7,0.5c0.3,0.3,0.5,0.7,0.5,1
				c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6-0.2-0.7-0.6c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2
				c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.1c-0.4,0-0.8,0.3-1.1,0.9s-0.5,1.2-0.5,2v0.8H302.7z"/>
			<path d="M309.2,108.8c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
				c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
				c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
				c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
				c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
				c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
				c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
				C308.1,109,308.6,108.8,309.2,108.8z"/>
			<path d="M316.4,108.8c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
				v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
				c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v3.5c0,0,0,0,0,0
				c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7S316.1,108.8,316.4,108.8z"/>
			<path d="M321,113.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S320.8,113.7,321,113.7z"/>
			<path d="M328.1,113.9c0-1.7,0-3,0-3.9c0-0.1,0-0.1-0.1-0.1h-0.8c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.4c0.1-0.2,0.1-0.2,0.1-0.2h0.6
				c0.1,0,0.1,0,0.1-0.1v-0.2c0-0.5,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3s0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1.1-0.3
				c0.2,0,0.4,0.1,0.8,0.2c0.3,0.1,0.6,0.2,0.7,0.2c0.1,0,0.3,0,0.5-0.1c0.2-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.2-0.1h0
				c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v7.1c0,0.6,0,1.1,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2
				c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1c-0.1,0-0.6,0-1.7,0.1
				c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2c0.1,0,0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2c0.1-0.3,0.1-0.7,0.1-1.1v-6
				c0-0.1,0-0.3-0.1-0.6s-0.1-0.5-0.1-0.6c-0.1-0.2-0.3-0.5-0.7-0.8c-0.3-0.3-0.7-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.8,0.4
				c-0.2,0.3-0.4,0.6-0.5,1.1c-0.1,0.4-0.2,0.9-0.2,1.3v0.8c0,0,0,0,0,0.1c0,0,0,0,0.1,0h1.7c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1
				c0,0.2-0.1,0.4-0.2,0.6h-1.6c0,0,0,0-0.1,0c0,0,0,0,0,0.1v3.6c0,0.7,0.1,1.2,0.1,1.4c0,0.1,0.2,0.1,0.4,0.2
				c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c-1-0.1-1.6-0.1-1.7-0.1c-0.1,0-0.6,0-1.7,0.1
				c0,0-0.1-0.1-0.1-0.3c0-0.1,0-0.2,0.1-0.2c0.1,0,0.3,0,0.6-0.1c0.2-0.1,0.4-0.1,0.4-0.2C328,114.6,328.1,114.3,328.1,113.9z"/>
			<path d="M336.4,109c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
				c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
				c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6
				c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7
				c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8
				c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C335.3,109,335.8,109,336.4,109z"/>
			<path d="M344.7,110.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V110.6z
				 M343.4,107c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
				s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S343.5,107.1,343.4,107z"/>
			<path d="M350.8,108.8c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
				v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V111c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9
				c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7S350.4,108.8,350.8,108.8z"/>
			<path d="M358.4,114.6c0.6,0,1.2,0.2,1.7,0.5c0.5,0.3,0.7,0.7,0.7,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.7-0.9,1
				c-0.4,0.3-0.8,0.5-1.3,0.7s-1,0.3-1.5,0.3c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.3-0.3-0.4-0.7-0.4-1.1
				c0-0.2,0.1-0.3,0.2-0.5s0.3-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.5-0.4s0.3-0.2,0.5-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
				c-0.3-0.3-0.4-0.6-0.4-0.9c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.5-0.4,0.7-0.5c-0.4-0.2-0.7-0.5-0.9-0.9c-0.2-0.4-0.4-0.7-0.4-1.1
				c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.1-0.7,1.9-0.7c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
				c0.1,0,0.1,0,0.3,0.1l0.2,0.1c0.1,0.1,0.3,0.1,0.4,0.1l1.7-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.1,0.3
				c-0.1,0.2-0.2,0.3-0.2,0.3l-1-0.1c0,0-0.1,0-0.1,0c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.2,0.6,0.2,0.9c0,0.6-0.3,1.1-0.8,1.6
				c-0.5,0.5-1.1,0.7-1.9,0.7c-0.1,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.2,0.4
				c0,0.1,0.1,0.2,0.3,0.4c0.2,0.1,0.4,0.2,0.7,0.2H358.4z M357.7,115.7c-0.9,0-1.4,0-1.5,0.1c-0.2,0.1-0.4,0.3-0.6,0.5
				c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.5,0.2,0.9,0.6,1.3c0.4,0.3,0.9,0.5,1.5,0.5c0.4,0,0.7-0.1,1.1-0.2s0.7-0.4,0.9-0.6
				c0.2-0.3,0.4-0.5,0.4-0.8c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.3C358.4,115.8,358,115.7,357.7,115.7z
				 M357.1,109.3c-0.4,0-0.8,0.2-1,0.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0.1,1,0.4,1.4c0.3,0.4,0.6,0.6,1.1,0.6c0.4,0,0.7-0.2,1-0.5
				c0.2-0.4,0.4-0.8,0.4-1.2c0-0.5-0.1-1-0.4-1.4C357.9,109.5,357.5,109.3,357.1,109.3z"/>
			<path d="M362.3,113.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S362.1,113.7,362.3,113.7z"/>
			<path d="M372.8,108.8c0.5,0,0.9,0.1,1.2,0.3s0.5,0.5,0.6,1c1-0.9,1.7-1.3,2.3-1.3c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4
				c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1
				c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2
				s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.7c0-0.3,0-0.6,0-0.9s-0.1-0.5-0.1-0.7
				c-0.1-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.3,0.1-0.5,0.3-0.6,0.4
				c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.1c0,0.3,0.1,0.6,0.1,1.1v1.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
				s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
				c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.6-0.3-2
				c-0.2-0.4-0.6-0.5-1.2-0.5c-0.3,0-0.6,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.5,0.5v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2
				s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0
				c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V111c0-0.5,0-0.8-0.1-0.9
				c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7
				c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.5-0.4,0.9-0.7,1.2-0.8
				C372.3,108.9,372.6,108.8,372.8,108.8z"/>
			<path d="M383.8,108.8c1,0,1.8,0.3,2.4,1c0.6,0.6,0.9,1.5,0.9,2.4c0,1-0.3,1.9-0.9,2.5c-0.6,0.7-1.4,1-2.4,1c-1,0-1.8-0.3-2.4-1
				s-0.9-1.5-0.9-2.5c0-1,0.3-1.8,1-2.5C382.1,109.1,382.9,108.8,383.8,108.8z M383.7,109.3c-0.4,0-0.8,0.1-1.1,0.4
				c-0.3,0.3-0.5,0.6-0.6,1s-0.2,0.8-0.2,1.3c0,0.5,0.1,1,0.2,1.5c0.2,0.5,0.4,0.9,0.8,1.2c0.3,0.3,0.7,0.5,1.2,0.5
				c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.6-1c0.1-0.4,0.2-0.8,0.2-1.3c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.8-1.2
				S384.1,109.3,383.7,109.3z"/>
			<path d="M392.6,108.8c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2
				v1.7c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V111c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9
				c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7S392.2,108.8,392.6,108.8z"/>
			<path d="M397.2,111.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
				c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2
				c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
				c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C398,111.8,397.4,111.8,397.2,111.8z
				 M397.3,111.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
				c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S397.3,111.2,397.3,111.2z
				"/>
			<path d="M403.5,109c0.6,0,1.1,0,1.6-0.1c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l1.7,4.2c0.5-1.4,1-2.6,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.3-0.2-0.4
				c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.3-0.1
				c0,0,0,0.1,0,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.3,0-0.5,0.1c-0.2,0.1-0.4,0.2-0.4,0.4c-1.1,2.7-1.9,4.5-2.3,5.6
				c-0.1,0.1-0.2,0.4-0.3,0.9c-0.2,0.5-0.3,0.8-0.5,1.1s-0.3,0.5-0.5,0.8c-0.4,0.6-0.8,0.9-1.4,0.9c-0.5,0-0.7-0.2-0.7-0.7
				c0-0.2,0.1-0.3,0.2-0.5c0.4,0,0.7-0.1,1-0.2s0.5-0.3,0.8-0.7c0.1-0.1,0.3-0.4,0.4-0.8c0.2-0.4,0.3-0.7,0.3-0.8
				c-1-2.7-1.9-4.6-2.5-5.9c-0.1-0.2-0.4-0.3-0.9-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2C402.4,109,402.9,109,403.5,109z"/>
			<path d="M410,113.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S409.8,113.7,410,113.7z"/>
			<path d="M324,124.8c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
				c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
				c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
				c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
				s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
				c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
				C323.3,125,323.7,124.8,324,124.8z"/>
			<path d="M328.7,124.9c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0s0.1,0,0.1,0
				c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0s0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
				s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5
				c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
				s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
				c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
				s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C327.7,125,328.2,124.9,328.7,124.9z M327.8,130.9c0.3,0,0.5-0.1,0.9-0.3
				c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
				c0,0.3,0.1,0.5,0.3,0.8S327.6,130.9,327.8,130.9z"/>
			<path d="M333.8,124.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4
				c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
				s-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
				s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0
				s0,0,0,0V124.9z"/>
			<path d="M338.9,124.8c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
				c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
				c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
				c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
				c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
				c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
				c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
				C337.8,125,338.3,124.8,338.9,124.8z"/>
			<path d="M343,129.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S342.7,129.7,343,129.7z"/>
			<path d="M351.6,124.8c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
				c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
				c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
				c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
				c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
				c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
				c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
				C350.4,125,351,124.8,351.6,124.8z"/>
			<path d="M359,124.8c0.3,0,0.6,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.7s0.1,0.6,0.2,0.9c0,0.3,0,0.7,0,1.2v1.7
				c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0.1-0.2,0.1-0.6,0.1-1.2v-1.6c0-1-0.1-1.7-0.3-2c-0.2-0.4-0.6-0.5-1.2-0.5c-0.2,0-0.4,0.1-0.6,0.2s-0.4,0.3-0.6,0.4
				c-0.2,0.1-0.2,0.2-0.2,0.3v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2V127c0-0.5,0-0.8-0.1-0.9c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.4-0.1,0.7-0.1,0.9
				c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.5-0.5,1-0.7S358.7,124.8,359,124.8z"/>
			<path d="M365.7,124.9c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0s0.1,0,0.1,0
				c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0s0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
				s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5
				c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
				s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
				c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
				s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C364.7,125,365.2,124.9,365.7,124.9z M364.9,130.9c0.3,0,0.5-0.1,0.9-0.3
				c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
				c0,0.3,0.1,0.5,0.3,0.8S364.6,130.9,364.9,130.9z"/>
			<path d="M371,129.9c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0.1-0.2,0.1-0.6,0.1-1.2v-6.9c0-0.4,0-0.7-0.1-0.8c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
				c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v5.6
				c0.2-0.1,0.6-0.4,1.3-1c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.3,0.4-0.3c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.2-0.2
				c0-0.1,0.1-0.1,0.1-0.1s0-0.1,0-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0s-0.1,0-0.1,0c0,0-0.1,0-0.1,0
				c0,0-0.1,0-0.1,0c0-0.1-0.1-0.1,0-0.3c0-0.1,0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5,0,1,0,1.4-0.1c0,0,0,0.1,0,0.2
				c0,0.1,0,0.2,0,0.2c-0.1,0-0.3,0-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3c-1.2,0.8-2,1.5-2.5,1.9c0.3,0.3,0.7,0.7,1.1,1.2
				s0.8,0.9,1.1,1.2c0.3,0.3,0.5,0.5,0.6,0.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5,0.1,0.8,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
				c-0.1,0.1-0.5,0.1-1,0.1c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.2-0.6-0.3-0.8-0.5c-0.9-1-1.6-1.8-2.2-2.4c0,0,0,0-0.1-0.1
				c0,0-0.1-0.1-0.1-0.1l0,0V129.9z"/>
			<path d="M377.2,127.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
				c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2
				c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
				c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C378,127.8,377.3,127.8,377.2,127.8z
				 M377.3,127.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
				c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S377.3,127.2,377.3,127.2z
				"/>
			<path d="M384.9,124.8c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
				c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
				c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
				c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
				c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
				c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
				c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
				C383.8,125,384.3,124.8,384.9,124.8z"/>
			<path d="M389,129.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S388.7,129.7,389,129.7z"/>
			<path d="M316.1,140.8c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
				c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
				c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
				c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
				c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
				c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
				c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
				C315,141,315.5,140.8,316.1,140.8z"/>
			<path d="M323,140.8c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,0.9,0.8s0.4,0.7,0.5,1c0.1,0.4,0.2,0.7,0.2,1.1c0,0.5-0.1,1-0.3,1.4
				c-0.2,0.5-0.5,0.9-0.8,1.2s-0.7,0.6-1.2,0.8s-0.9,0.3-1.3,0.3c-0.4,0-0.7-0.1-1.1-0.2c0,0.1-0.1,0.1-0.1,0.2v1.5
				c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.6-0.1-1.7-0.1
				c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2
				c0-0.1,0.1-0.5,0.1-1.2v-6.4c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0c0,0,0-0.1,0-0.2
				c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.3c0,0.2-0.1,0.3-0.1,0.4
				c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.2-0.2,0.6-0.4,0.9-0.6C322.4,140.9,322.8,140.8,323,140.8z M322.4,141.7c-0.3,0-0.6,0.1-0.9,0.2
				c-0.3,0.1-0.4,0.3-0.4,0.4v3.8c0,0.4,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.1,1.1-0.4s0.6-0.6,0.7-1
				c0.2-0.4,0.2-0.8,0.2-1.2c0-0.9-0.2-1.6-0.6-2.1C323.5,142,323,141.7,322.4,141.7z"/>
			<path d="M329,142.6v3.3c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.4,0.2s0.4,0.1,0.6,0.1c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2
				c-1.1-0.1-1.6-0.1-1.7-0.1c-0.2,0-0.4,0-0.5,0s-0.4,0-0.6,0s-0.4,0-0.5,0c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2c0.2,0,0.3,0,0.6-0.1
				s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1s-0.2,0-0.3,0
				c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2c-0.1,0.5-0.1,0.9-0.1,1V142.6z
				 M327.7,139c-0.2-0.2-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6c0.2-0.2,0.3-0.2,0.6-0.2s0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.3,0.2,0.6
				s-0.1,0.4-0.2,0.6c-0.2,0.2-0.3,0.2-0.6,0.2S327.9,139.1,327.7,139z"/>
			<path d="M334.4,140.8c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0.1,0.5,0.1c0.1,0,0.1-0.1,0.1-0.3v-1.7c0-0.4,0-0.7-0.1-0.8
				c-0.1-0.2-0.4-0.3-1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0.7-0.1,1.5-0.3,2.2-0.6c0,0,0.1,0,0.1,0.1
				s0.1,0.1,0.1,0.2c-0.1,0.3-0.2,0.8-0.2,1.4v6.7c0,0.8,0,1.4,0.1,1.5c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
				c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1s-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0.1s-0.4,0.1-0.5,0.1
				c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.1,0c-0.1,0-0.1-0.1-0.1-0.3v-0.5c0-0.1,0-0.1,0-0.1c-0.2,0.2-0.5,0.4-0.9,0.6
				s-0.8,0.3-1.2,0.3c-0.7,0-1.4-0.3-1.9-1s-0.8-1.4-0.8-2.4c0-0.5,0.1-1,0.4-1.4c0.3-0.5,0.6-0.9,0.9-1.2s0.8-0.6,1.2-0.8
				C333.6,140.9,334,140.8,334.4,140.8z M333.9,141.5c-0.6,0-1,0.3-1.4,0.8c-0.4,0.5-0.5,1.1-0.5,1.8c0,0.8,0.2,1.4,0.6,2
				s0.9,0.8,1.6,0.8c0.3,0,0.6-0.1,0.9-0.2s0.4-0.3,0.4-0.4v-4c0-0.2-0.2-0.4-0.5-0.5S334.3,141.5,333.9,141.5z"/>
			<path d="M339.5,143.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
				c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2
				c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
				c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C340.3,143.8,339.7,143.8,339.5,143.8z
				 M339.6,143.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
				c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S339.6,143.2,339.6,143.2z
				"/>
			<path d="M348.9,140.8c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
				c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
				c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
				c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
				s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
				c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
				C348.3,141,348.6,140.8,348.9,140.8z"/>
			<path d="M352.9,140.8c0.2,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.1,0.7,0.1c0.1,0.5,0.2,1,0.2,1.7c0,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.3-0.7-0.5-1s-0.5-0.5-0.8-0.5c-0.4,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
				c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.3,0.2
				c0.2,0.1,0.3,0.2,0.3,0.2c0,0,0.2,0.1,0.3,0.2s0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.2,0.3,0.3
				c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.2,1.1-0.7,1.5c-0.5,0.4-1,0.6-1.7,0.6
				c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.1-0.2-0.1-0.4-0.2-0.8c-0.1-0.4-0.1-0.6-0.1-0.8
				c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.7,0.5,1.1,0.5c0.3,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.3-0.3-0.4s-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3
				c-0.2-0.1-0.4-0.2-0.5-0.3c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.6-0.3-1c0-0.6,0.2-1,0.7-1.4
				C351.8,141,352.3,140.8,352.9,140.8z"/>
			<path d="M356.9,145.7c0.1,0,0.3,0,0.4,0.1c0.2,0.3,0.3,0.7,0.3,1.1c0,0.4-0.2,1-0.6,1.6s-0.8,1.1-1.2,1.3c-0.1,0-0.1,0-0.2-0.1
				c-0.1-0.1-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.2s0.2-0.2,0.3-0.3s0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
				c0-0.2-0.1-0.4-0.3-0.6s-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.3-0.5S356.7,145.7,356.9,145.7z"/>
			<path d="M364.1,141c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.4,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.3,0
				c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0.1,0.2c0.1,0.3,0.6,1.6,1.6,3.8c0.1-0.3,0.3-0.7,0.4-1.1
				s0.3-0.7,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.4s-0.2-0.3-0.3-0.5
				c-0.1-0.2-0.4-0.3-0.7-0.3c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0.1-0.2c0.1,0,0.3,0,0.6,0s0.6,0,0.8,0c0,0,0.6,0,1.6-0.1
				c0,0.1,0,0.1,0,0.2s0,0.2,0,0.2c-0.5,0-0.8,0.2-0.8,0.5c0,0,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.2,0.5
				s0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.6,0.4,1c0.2,0.4,0.3,0.8,0.5,1.2c0.8-1.9,1.2-3.1,1.4-3.5c0.1-0.2,0.1-0.3,0.1-0.4
				c0-0.1,0-0.2-0.1-0.3s-0.1-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c0,0,0-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.1,0,0.3,0,0.6,0s0.5,0,0.8,0c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0s0.3,0,0.5,0c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2
				c-0.1,0-0.3,0-0.6,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.9,2.3-1.7,4.2-2.3,5.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0-0.2-0.1l-1.7-4.5l-1.7,4.3c0,0-0.1,0.1-0.1,0.2s-0.2,0.1-0.2,0.1s-0.1,0-0.1-0.1c-0.9-2.2-1.7-4.2-2.5-6
				c-0.1-0.2-0.4-0.3-0.8-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0S363.9,141,364.1,141z"/>
			<path d="M377.1,140.9c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.5,0.9,0.5,1.5v3.2c0,0.5,0.2,0.7,0.5,0.7c0,0,0.1,0,0.1,0s0.1,0,0.1,0
				c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0s0,0,0,0c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.6,0.4
				s-0.5,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.3s-0.3-0.4-0.4-0.7c-0.2,0.2-0.4,0.4-0.9,0.6s-0.8,0.4-1,0.4c-0.5,0-0.9-0.2-1.3-0.5
				c-0.4-0.3-0.5-0.8-0.5-1.3c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.9-0.3s0.8-0.3,1.1-0.4
				s0.5-0.2,0.7-0.3c0.1,0,0.1-0.1,0.2-0.1s0-0.1,0-0.3v-0.4c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.5-0.9-0.5c-0.3,0-0.5,0.1-0.6,0.2
				c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.5-0.3,0.8-0.7,0.8c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.2
				s0-0.1,0.1-0.2c0.3-0.3,0.8-0.6,1.3-0.9C376,141,376.6,140.9,377.1,140.9z M376.2,146.9c0.3,0,0.5-0.1,0.9-0.3
				c0.3-0.2,0.5-0.3,0.5-0.4v-2.1c-0.8,0.2-1.3,0.4-1.5,0.5c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
				c0,0.3,0.1,0.5,0.3,0.8S375.9,146.9,376.2,146.9z"/>
			<path d="M382.2,140.9h2c0.1,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.2,0.5h-1.9v4.1c0,0.9,0.3,1.3,1,1.3c0.3,0,0.7-0.1,1-0.4
				c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.6,0.3s-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1
				s-0.4-0.1-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.5-0.1-0.8v-4.5h-0.9c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.2-0.2
				s0.2-0.2,0.2-0.3h0.6v-1c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.3,0.4-0.4s0.2-0.2,0.2-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0
				s0,0,0,0V140.9z"/>
			<path d="M386,143.8c0,1,0.2,1.7,0.7,2.2s1.1,0.8,1.8,0.8c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.4,0.7-0.6c0,0,0,0,0,0
				c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.3-0.5,0.4
				c-0.2,0.1-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.9,0-1.7-0.3-2.2-0.9s-0.9-1.4-0.9-2.3c0-0.5,0.1-1,0.3-1.5s0.4-0.9,0.7-1.2
				c0.3-0.3,0.6-0.6,1-0.8s0.7-0.3,1.1-0.3c0.4,0,0.7,0.1,1,0.2s0.5,0.2,0.7,0.4c0.2,0.2,0.3,0.3,0.4,0.5s0.2,0.4,0.2,0.6
				c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0-0.9,0-2,0.1C386.8,143.8,386.1,143.8,386,143.8z
				 M386.1,143.2h2.5c0.2,0,0.3-0.1,0.4-0.4c0-0.1,0-0.2-0.1-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4
				c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.4-0.3,0.5s-0.1,0.3-0.2,0.5S386.1,143.2,386.1,143.2z
				"/>
			<path d="M395.4,140.8c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3-0.1,0.5-0.2,0.6
				c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.6-0.2
				c-0.3,0-0.6,0.2-0.9,0.5c-0.3,0.4-0.4,0.6-0.4,0.9v2.4c0,0.6,0,1,0.1,1.2c0,0.1,0.2,0.1,0.6,0.2s0.6,0.1,0.8,0.1
				c0,0,0,0.1,0.1,0.2s0,0.2-0.1,0.2c-1.1-0.1-1.7-0.1-1.9-0.1c-0.1,0-0.7,0-1.8,0.1c0,0-0.1-0.1-0.1-0.2s0-0.2,0.1-0.2
				c0.2,0,0.3,0,0.6-0.1s0.4-0.1,0.4-0.2c0-0.1,0.1-0.5,0.1-1.2v-2.9c0-0.4,0-0.7-0.1-0.8c0-0.1-0.1-0.1-0.3-0.2s-0.3-0.1-0.4-0.1
				s-0.2,0-0.3,0c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0.9-0.1,1.6-0.3,2.2-0.7c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.2
				c0,0.1,0,0.3-0.1,0.7c0,0.4,0,0.7,0,0.7c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0.2-0.4,0.4-0.8,0.8-1.1
				C394.7,141,395.1,140.8,395.4,140.8z"/>
		</g>
    </g>
  );
};

export default NetworkTexts;
